export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'

export const DEFAULT_STALE_TIME = 10 * 60 * 1000 // 10 minutes

export const DEFAULT_TABLE_PAGE = 1

export const DEFAULT_TABLE_ROWS_PER_PAGE = 20

export const DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100]

export const DEFAULT_DEBOUNCE_MS = 250

export const DEFAULT_SERVER_DEBOUNCE_MS = 500
