import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { OrganizationIntegrationsQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { useSelectedOrganizationId } from '@ssp/modules/organization'

import { integrations } from './integrations.queries'

export const useGetIntegrationsQuery = <TResult = OrganizationIntegrationsQueryResult>(
  options?: Omit<UseQueryOptions<OrganizationIntegrationsQueryResult, Error, TResult>, 'queryKey'>
) => {
  const selectedOrganizationId = useSelectedOrganizationId()

  return useQuery<OrganizationIntegrationsQueryResult, Error, TResult>({
    queryKey: integrations.queryKey(selectedOrganizationId),
    queryFn: async () => await integrations.queryFn(selectedOrganizationId),
    enabled: !!selectedOrganizationId,
    staleTime: integrations.staleTime,
    ...options,
  })
}
