import { useTranslation } from 'react-i18next'

import Badge, { Variant } from '@sherweb/core/components/Badge'

import { UserRole, UserStatus } from './user.model'

export const useUserRolesTranslation = () => {
  const { t } = useTranslation()
  return (value?: UserRole[] | null) => {
    return value
      ?.map(s => {
        switch (s) {
          case UserRole.BasicUser:
            return t('ssp:user.userRole.basicUser')
          case UserRole.OrganizationAdmin:
            return t('ssp:user.userRole.organizationAdmin')
          case UserRole.ResellerAdmin:
            return t('ssp:user.userRole.resellerAdmin')
          case UserRole.InternalAdmin:
            return t('ssp:user.userRole.internalAdmin')
          case UserRole.ResellerGuest:
            return t('ssp:user.userRole.resellerGuest')
          default:
            return ''
        }
      })
      .join(', ')
  }
}

export const useUserStatusBadge = () => {
  const { t } = useTranslation()

  const userStatusMapper = {
    [UserStatus.Active]: (
      <Badge variant={Variant.Success}>{t('ssp:pages.users.status.Active')}</Badge>
    ),
    [UserStatus.InvitationSent]: (
      <Badge variant={Variant.Warning}>{t('ssp:pages.users.status.InvitationSent')}</Badge>
    ),
    [UserStatus.Inactive]: (
      <Badge variant={Variant.Danger}>{t('ssp:pages.users.status.Inactive')}</Badge>
    ),
  }

  return (value?: UserStatus | null) => {
    return value ? userStatusMapper[value] : null
  }
}
