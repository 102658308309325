import { useCallback, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  HelpDeskTicketNoteQueryResult,
  Permission,
} from '@sherweb/core/openapi-generated/index.defs'

import { Avatar, AvatarFallback } from '@sherweb/core/components/Avatar'
import { Markdown, useGetMarkdownPreviewer } from '@sherweb/core/components/Markdown'
import { ScrollArea } from '@sherweb/core/components/ScrollArea'
import { Separator } from '@sherweb/core/components/Separator'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import Tooltip from '@sherweb/core/components/Tooltip'
import { Typography } from '@sherweb/core/components/Typography'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'
import { getInitials } from '@sherweb/core/utils/initials'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { HasAccess } from '@ssp/modules/authorization'

import { HelpDeskTicketSendMessage } from './HelpDeskTicketSendMessage'

type HelpDeskTicketNoteListProps = {
  notes?: HelpDeskTicketNoteQueryResult[]
}

export const HelpDeskTicketNoteList = ({ notes }: HelpDeskTicketNoteListProps) => {
  const notesContainerRef = useRef<HTMLDivElement>(null)

  const {
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const { isLoading, convertMarkdown } = useGetMarkdownPreviewer()

  const handleScrollToLastMessage = useCallback(() => {
    notesContainerRef?.current?.scrollIntoView(false)
  }, [])

  useLayoutEffect(() => {
    if (!isLoading) {
      handleScrollToLastMessage()
    }
  }, [isLoading, handleScrollToLastMessage, notesContainerRef])

  return (
    <>
      <Separator decorative={false} className="bg-slate-300 dark:bg-slate-700" />
      <div className="flex h-full w-full flex-col justify-between overflow-y-auto overflow-x-hidden">
        {isLoading ? (
          <Spinner className={spinnerStyles({ type: 'layOverSpinner' })} />
        ) : (
          <ScrollArea>
            <div ref={notesContainerRef} className="flex flex-col gap-4">
              {notes?.map(note => (
                <div
                  key={note.id}
                  className={mergeClassName(
                    'flex flex-col gap-2 px-6 whitespace-pre-wrap first:mt-8 last:mb-8',
                    note.isCurrentUserNote ? 'items-end text-right' : 'items-start'
                  )}
                >
                  <div className="flex items-start gap-3">
                    {!note.isCurrentUserNote ? (
                      <Tooltip tooltip={note.createdByName ?? note.createdByEmail}>
                        <Avatar className="flex items-center justify-center">
                          <AvatarFallback className="dark:bg-slate-950 dark:text-slate-200">
                            {getInitials(note.createdByName ?? note.createdByEmail)}
                          </AvatarFallback>
                        </Avatar>
                      </Tooltip>
                    ) : null}
                    <div className="flex flex-col gap-y-1 break-words">
                      <Markdown
                        content={convertMarkdown(note.text)}
                        variant="body2"
                        className={mergeClassName('max-w-xs rounded-md p-3 min-w-[100px]', {
                          'bg-blue-500 text-white': note.isCurrentUserNote,
                          'bg-slate-100 text-black dark:bg-slate-950 dark:text-white':
                            !note.isCurrentUserNote,
                        })}
                      />
                      <div className="flex w-full justify-end gap-2">
                        <Typography variant="label" colors="light">
                          {formatDate(note?.createdDate)}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ScrollArea>
        )}

        <HasAccess permission={Permission.HelpDeskEdit}>
          <HelpDeskTicketSendMessage onScrollToLastMessage={handleScrollToLastMessage} />
        </HasAccess>
      </div>
    </>
  )
}
