import { useTranslation } from 'react-i18next'

import { UserStatus as UserStatusType } from '@sherweb/core/openapi-generated/index.defs'

import Badge, { Variant } from '@sherweb/core/components/Badge'

type UserStatusProps = {
  status?: UserStatusType | null
}

export const UserStatus = ({ status }: UserStatusProps) => {
  const { t } = useTranslation()

  const userStatusMapper = {
    [UserStatusType.Active]: (
      <Badge variant={Variant.Success}>{t('core:user.status.Active')}</Badge>
    ),
    [UserStatusType.InvitationSent]: (
      <Badge variant={Variant.Warning}>{t('core:user.status.InvitationSent')}</Badge>
    ),
    [UserStatusType.Inactive]: (
      <Badge variant={Variant.Danger}>{t('core:user.status.Inactive')}</Badge>
    ),
  }

  return status ? userStatusMapper[status] : null
}
