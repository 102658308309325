import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import { Form } from '@sherweb/core/components/Form'
import { NoResults } from '@sherweb/core/components/NoResults'
import { usePrompt } from '@sherweb/core/components/Prompt'
import Spinner from '@sherweb/core/components/Spinner'
import { Typography } from '@sherweb/core/components/Typography'

import { protectPage } from '@rsp/app/ProtectedPage'
import Routes from '@rsp/app/Routes'
import { FormSubmitButton } from '@rsp/components/FormSubmitButton'
import { FormSplitScreen } from '@rsp/components/layout/FormSplitScreen'
import {
  useGetConnectWiseConfiguredQuery,
  useGetUnMappedOrganizations,
} from '@rsp/modules/integrations/helpdesk'
import { IUnMappedOrganizationWithValueType } from '@rsp/modules/integrations/helpdesk/core/helpdesk.model'

import { useOrganizationMapping } from './hooks/useOrganizationMapping'
import {
  OrganizationMappingFormType,
  useOrganizationMappingSchema,
} from './hooks/useOrganizationMappingSchema'
import { DEFAULT_VALUES } from './organizationMapping.helper'
import { OrganizationMappingForm } from './OrganizationMappingForm'

export const OrganizationMapping = () => {
  const { t } = useTranslation()

  const schema = useOrganizationMappingSchema()

  const [submittedOrganizations, setSubmittedOrganizations] = useState<
    IUnMappedOrganizationWithValueType[]
  >([])

  const form = useForm<OrganizationMappingFormType>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_VALUES,
  })

  const connectWiseConfiguredQuery = useGetConnectWiseConfiguredQuery()

  const mappedOrganizations = useGetUnMappedOrganizations()

  const hasNoMappedOrganizations =
    !mappedOrganizations?.isLoading &&
    !!mappedOrganizations?.data &&
    mappedOrganizations?.data?.length === 0

  const { setRequiredError, getFailedOrganizationsMapping, connetWiseMapOrganizationMutation } =
    useOrganizationMapping({ form, submittedOrganizations })

  const onSubmit = (values: OrganizationMappingFormType) => {
    const emptyFormSubmitted = Object.keys(values.organizations)?.every(
      organizationId => !values.organizations[organizationId]
    )

    if (emptyFormSubmitted) {
      setRequiredError(values, mappedOrganizations?.data)

      return
    }

    setSubmittedOrganizations([])
    connetWiseMapOrganizationMutation.mutate(values)
  }

  usePrompt({
    isDirty: Object.values(form.getValues('organizations')).some(Boolean),
  })

  useEffect(() => {
    setSubmittedOrganizations(getFailedOrganizationsMapping(mappedOrganizations?.data))
  }, [getFailedOrganizationsMapping, mappedOrganizations?.data])

  if (!connectWiseConfiguredQuery?.isEnabled) {
    return <Navigate replace to={Routes.IntegrationsConnectWiseApiInformation} />
  }

  if (
    connectWiseConfiguredQuery.isLoading ||
    mappedOrganizations?.isLoading ||
    !mappedOrganizations?.data
  ) {
    return <Spinner floating />
  }

  if (hasNoMappedOrganizations) {
    return (
      <NoResults
        dataTestId="organizationMapping"
        className="mt-8 md:mt-16"
        emptyMessage={t(
          'rsp:pages.integrations.helpdesk.connectWise.organizationMapping.allOrganizationsMappedTitle'
        )}
      />
    )
  }

  return (
    <Form {...form}>
      <FormSplitScreen className="md:py-4" onSubmit={form.handleSubmit(onSubmit)}>
        <Typography className="mt-1 md:mt-8" variant="body2">
          {t('rsp:pages.integrations.helpdesk.connectWise.organizationMapping.pageHeading')}
        </Typography>
        <FormSplitScreen.Wrapper>
          <OrganizationMappingForm disabled={connetWiseMapOrganizationMutation?.isLoading} />
        </FormSplitScreen.Wrapper>
        <FormSubmitButton
          showLoader={connetWiseMapOrganizationMutation?.isLoading}
          disabled={connetWiseMapOrganizationMutation?.isLoading}
        />
      </FormSplitScreen>
    </Form>
  )
}

export default protectPage(OrganizationMapping, [
  Permission.IntegrationConfigure,
  Permission.IntegrationList,
])
