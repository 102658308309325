import {
  PortalSettingsQueryResult,
  Theme,
  ImageResultDto,
  ImageType,
  SetPortalSettingsCommand,
  ResellersQueryResult,
  ResellerSettingsQueryResult,
  SupportInformationQueryResult,
  OrderSettingsQueryResult,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class PortalSettingsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getPortalSettingsByHostname(
    params: {
      /**  */
      hostname: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalSettingsQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PortalSettings/{hostname}';
      url = url.replace('{hostname}', params['hostname'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setPortalSettings(
    params: {
      /**  */
      command: SetPortalSettingsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResellersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PortalSettings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateImage(
    params: {
      /**  */
      resellerId?: string;
      /**  */
      file?: any;
      /**  */
      type?: string;
      /**  */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResellersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PortalSettings/update-image';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['resellerId']) {
        if (Object.prototype.toString.call(params['resellerId']) === '[object Array]') {
          for (const item of params['resellerId']) {
            data.append('ResellerId', item as any);
          }
        } else {
          data.append('ResellerId', params['resellerId'] as any);
        }
      }

      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('File', item as any);
          }
        } else {
          data.append('File', params['file'] as any);
        }
      }

      if (params['type']) {
        if (Object.prototype.toString.call(params['type']) === '[object Array]') {
          for (const item of params['type']) {
            data.append('Type', item as any);
          }
        } else {
          data.append('Type', params['type'] as any);
        }
      }

      if (params['size']) {
        if (Object.prototype.toString.call(params['size']) === '[object Array]') {
          for (const item of params['size']) {
            data.append('Size', item as any);
          }
        } else {
          data.append('Size', params['size'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
