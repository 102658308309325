import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

type ErrorProps = {
  className?: string
  title?: string
  /** Icon represents any icon coming from heroicons */
  Icon?: typeof ExclamationTriangleIcon
  description?: string
  children?: React.ReactNode
  errorCode?: number
}

export const Error = ({
  errorCode,
  Icon = ExclamationTriangleIcon,
  title,
  description,
  className,
  children,
}: ErrorProps) => {
  const { t } = useTranslation()

  return (
    <div data-testid="pageErrorContent" className={mergeClassName('h-full', className)}>
      <div className="mx-auto flex h-full max-w-2xl flex-col items-center text-center">
        <Icon data-testid="pageErrorIcon" className="mx-auto mt-20 w-12 text-red-600 sm:mt-24" />
        {errorCode ? (
          <p className="text-base font-semibold leading-8 dark:text-white">{errorCode}</p>
        ) : null}
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white  sm:text-5xl">
          {title ?? t('core:errors.page.title')}
        </h1>
        <p className="mt-4 text-base leading-7 text-gray-600 dark:text-white sm:mt-6 sm:text-lg  sm:leading-8">
          {description ?? t('core:errors.page.description')}
        </p>
        {children}
      </div>
    </div>
  )
}
