import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import { useNavigate, usePathGenerator } from '@sherweb/core/modules/browserRouter'

import Routes from '@ssp/app/Routes'
import { usePermissionCheck } from '@ssp/modules/authorization'
import { OrganizationEmptyContainer, useOrganizationIsSuspended } from '@ssp/modules/organization'

const OrganizationSuspended = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const generatePath = usePathGenerator()

  const {
    isLoading: isSuspendedLoading,
    isActive,
    selectedOrganizationUniqueName,
  } = useOrganizationIsSuspended()

  const { hasAccess } = usePermissionCheck()

  const redirectToDashboard =
    !isSuspendedLoading && (hasAccess(Permission.AccessToSuspendedOrganization) || isActive)

  useEffect(() => {
    if (redirectToDashboard && selectedOrganizationUniqueName) {
      navigate(
        generatePath(Routes.OrganizationDispatcher, {
          organizationUniqueName: selectedOrganizationUniqueName,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToDashboard])

  return (
    <OrganizationEmptyContainer
      icon={<ExclamationTriangleIcon className="mx-auto w-12" />}
      title={t('ssp:pages.organizationSuspended.title')}
      content={t('ssp:pages.organizationSuspended.content')}
    />
  )
}

export default OrganizationSuspended
