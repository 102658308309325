import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import PageTitle, { PageTitleWrapper } from '@sherweb/core/components/PageTitle'

import { protectPage } from '@rsp/app/ProtectedPage'
import ContainedPage from '@rsp/components/layout/ContainedPage'

import OrdersTable from './OrdersTable'
import OrdersTableMobile from './OrdersTableMobile'

const OrdersPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <ContainedPage>
        <div className="flex flex-col gap-12 md:gap-16">
          <PageTitleWrapper
            title={
              <PageTitle data-testid="pageOrdersTitle">{t('rsp:pages.orders.title')}</PageTitle>
            }
          />
          <div className="hidden lg:block">
            <OrdersTable />
          </div>
          <div className="mt-4 lg:hidden">
            <OrdersTableMobile />
          </div>
        </div>
      </ContainedPage>
      <Outlet />
    </>
  )
}

export default protectPage(OrdersPage)
