import {
  HelpDeskTicketStatus,
  HelpDeskTicketStatusType,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class HelpDeskTicketsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getHelpDeskTicketStatuses(
    params: {
      /**  */
      resellerId: string;
      /**  */
      integrationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpDeskTicketStatus[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/resellers/{resellerId}/helpdesk/{integrationId}/tickets/statuses';
      url = url.replace('{resellerId}', params['resellerId'] + '');
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}
