import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { OrderSortField } from '@sherweb/core/openapi-generated/index.defs'

import { Pagination } from '@sherweb/core/components/DataTable/types'
import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { buildOrder } from '@sherweb/core/modules/orders/core/orders.builder'
import { getOrdersPaginationDefaultValues } from '@sherweb/core/modules/orders/core/orders.helpers'
import { DEFAULT_TABLE_PAGE } from '@sherweb/core/utils/const'

import { useSelectedOrganization } from '@ssp/modules/organization'

import {
  organizationOrderDetailsById,
  queryOrganizationOrdersAsync,
  queryOrganizationOrdersAsyncWithInfiniteScroll,
} from './orders.queries'

export const useGetOrdersQuery = (
  queryOptions: Pagination<OrderSortField> = getOrdersPaginationDefaultValues()
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganization = useSelectedOrganization()

  return useQuery({
    queryKey: queryOrganizationOrdersAsync.queryKey(selectedOrganization?.id, queryOptions),
    keepPreviousData: true,
    queryFn: async () =>
      await queryOrganizationOrdersAsync.queryFn(selectedOrganization?.id, queryOptions),
    enabled: isLoggedIn && !!selectedOrganization?.id,
    staleTime: queryOrganizationOrdersAsync.staleTime,
  })
}

export const useGetInfiniteScrollOrderssQuery = (queryOptions: Pagination<OrderSortField>) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganization = useSelectedOrganization()

  return useInfiniteQuery({
    queryKey: queryOrganizationOrdersAsyncWithInfiniteScroll.queryKey(
      selectedOrganization?.id,
      queryOptions
    ),
    queryFn: async ({ pageParam = DEFAULT_TABLE_PAGE }) =>
      await queryOrganizationOrdersAsyncWithInfiniteScroll.queryFn(selectedOrganization?.id, {
        ...queryOptions,
        page: pageParam,
      }),
    enabled: isLoggedIn && !!selectedOrganization?.id,
    staleTime: queryOrganizationOrdersAsyncWithInfiniteScroll.staleTime,
    getNextPageParam: lastPage => (lastPage?.hasNextPage ? lastPage.pageIndex + 1 : null),
  })
}

export const useGetOrderQuery = (orderId?: string) => {
  const selectedOrganization = useSelectedOrganization()

  const organizationId = selectedOrganization?.id

  return useQuery({
    queryKey: organizationOrderDetailsById.queryKey(organizationId, orderId),
    queryFn: async () => await organizationOrderDetailsById.queryFn(organizationId, orderId),
    enabled: !!organizationId && !!orderId,
    staleTime: organizationOrderDetailsById.staleTime,
    select: data => buildOrder(data),
  })
}
