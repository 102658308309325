import {
  OrganizationSortField,
  SetOrganizationSettingsCommand,
} from '@sherweb/core/openapi-generated/index.defs'

import { getDefaultParameters } from '@sherweb/core/components/DataTable/const'
import { Pagination } from '@sherweb/core/components/DataTable/types'

export const getOrganizationsPaginationDefaultValues = (
  options?: Partial<Pagination<OrganizationSortField>>
) => {
  return getDefaultParameters<OrganizationSortField>({
    sort: {
      field: OrganizationSortField.None,
      ascending: true,
    },
    ...options,
  })
}

export const getOrganizationsSettingsDefaultValues = (): SetOrganizationSettingsCommand => {
  return {
    allowSubscriptionCancellation: false,
    isOrderApprovalRequired: false,
    isPriceVisible: false,
    orgAdminCanCreateUsers: false,
    showBilling: false,
    showHelpDesk: false,
    showLicenses: false,
    showSubscriptions: false,
    showUsers: false,
    showMarketplace: false,
  }
}
