import * as z from 'zod'

import { looseOptional } from '@sherweb/core/types/zod'

export type OrganizationMappingFormType = z.infer<ReturnType<typeof useOrganizationMappingSchema>>

export const useOrganizationMappingSchema = () => {
  return z.object({
    organizations: z.record(z.string(), looseOptional(z.string())),
  })
}
