import { validateUuid } from '@sherweb/core/utils/uuid'

import { useParams } from '@ssp/modules/navigation'

export const useGetSubscriptionId = () => {
  const params = useParams()
  const subscriptionId = validateUuid(params.subscriptionId)
    ? String(params.subscriptionId)
    : undefined

  return { subscriptionId }
}
