import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { HelpDeskApiInformation } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import { CardTitle } from '@sherweb/core/components/Card'
import { Typography } from '@sherweb/core/components/Typography'

import { ApiInformationConfigurationBlock } from './ApiInformationConfigurationBlock'
import { ApiInformationFormType } from './hooks/useApiInformationSchema'

type ApiInformationCardProps = {
  apiInformation?: HelpDeskApiInformation
  onEditMode: (apiInformation?: HelpDeskApiInformation) => void
}

export const ApiInformationCard = ({ apiInformation, onEditMode }: ApiInformationCardProps) => {
  const { t } = useTranslation()

  const form = useFormContext<ApiInformationFormType>()

  const [enabled] = form.watch(['enabled'])

  if (!enabled) {
    return null
  }

  return (
    <ApiInformationConfigurationBlock>
      <CardTitle>
        <Typography variant="body1" weight="semiBold" data-testid="textConnectWiseCompanyName">
          {apiInformation?.companyName}
        </Typography>
      </CardTitle>
      <CardTitle>
        <Typography colors="light" variant="body2" data-testid="textConnectWisePublicKey">
          {t('rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.publicKey')}{' '}
          {apiInformation?.publicApiKey}
        </Typography>
      </CardTitle>
      <div className="flex justify-end pt-6">
        <Button
          variant="None"
          className="bg-indigo-600 px-4 py-2 hover:bg-indigo-700"
          onClick={() => {
            onEditMode(apiInformation)
          }}
          data-testid="btnApiInformationReset"
        >
          {t('rsp:actions.reset')}
        </Button>
      </div>
    </ApiInformationConfigurationBlock>
  )
}
