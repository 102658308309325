import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'

import { OrganizationEmptyContainer } from '@ssp/modules/organization'
import { useReseller } from '@ssp/modules/reseller'

export const HelpDeskTicketNotAvailable = () => {
  const { t } = useTranslation()

  const resellerQuery = useReseller()

  const supportInformationEmail = resellerQuery?.data?.supportInformation?.email ?? ''

  return (
    <OrganizationEmptyContainer
      showSidebar
      title={t('ssp:pages.helpdesk.notReady.title')}
      content={t('ssp:pages.helpdesk.notReady.description')}
    >
      {supportInformationEmail !== '' ? (
        <Button
          variant="None"
          className="mt-4 bg-indigo-600 px-4 py-2 hover:bg-indigo-700"
          data-testid="btnContactReseller"
          asChild
        >
          <a href={'mailto:' + supportInformationEmail}>
            {t('ssp:pages.helpdesk.notReady.contact')}
          </a>
        </Button>
      ) : null}
    </OrganizationEmptyContainer>
  )
}
