import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import { useAuthenticationState } from '@sherweb/core/modules/authentication'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import RspRoutes from '@rsp/app/Routes'
import ContainedPage from '@rsp/components/layout/ContainedPage'
import FullWidthPage from '@rsp/components/layout/FullWidthPage/FullWidthPage'
import Link from '@rsp/components/Link'
import { usePermissionCheck } from '@rsp/modules/authorization'

import SspRoutes from '@ssp/app/Routes'

type Error403Props = {
  className?: string
}

const Error403 = ({ className }: Error403Props) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthenticationState()
  const { hasAccess } = usePermissionCheck()
  const Wrapper = isAuthenticated ? ContainedPage : FullWidthPage

  return (
    <Wrapper className="dark:bg-slate-900" showSidebar={false}>
      <div data-testid="error-403" className={mergeClassName('h-full', className)}>
        <div className="mx-auto flex max-w-2xl flex-col items-center text-center ">
          <ExclamationTriangleIcon className="mx-auto mt-20 w-12 text-red-600 sm:mt-24" />

          <p className="text-base font-semibold leading-8 dark:text-white">403</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
            {t('rsp:pages.403.title')}
          </h1>
          <p className="mt-4 text-base leading-7 text-gray-600 dark:text-white sm:mt-6 sm:text-lg sm:leading-8">
            {t('rsp:pages.403.description')}
          </p>

          <Button asChild className="mt-8">
            <Link
              data-testid="linkBackToHomePage"
              to={hasAccess(Permission.ResellerAdmin) ? RspRoutes.Orders : SspRoutes.Root}
            >
              {t('rsp:pages.403.backToHomepage')}
            </Link>
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

export default Error403
