import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '@sherweb/core/components/Drawer'
import { Skeleton } from '@sherweb/core/components/Skeleton'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import {
  isRequestFetching,
  isRequestLoading,
  isRequestRefetching,
} from '@sherweb/core/modules/reactQuery'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { protectPage } from '@ssp/app/ProtectedPage'
import { InvoiceStatus } from '@ssp/modules/invoices/ui/InvoiceStatus'

import { useGetInvoiceDetail } from '../hooks/useGetInvoiceDetail'
import { usePayInvoiceMutation } from './hooks/usePayInvoiceMutation'
import { useRedirectToInvoicesPage } from './hooks/useRedirectToInvoicesPage'
import { InvoiceDetailContent } from './InvoiceDetailContent'
import { InvoiceDetailFooter } from './InvoiceDetailFooter'

const InvoiceDetailPage = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatMoneyAmount = useMoneyFormatter(language)

  const invoiceDetail = useGetInvoiceDetail()

  const [isOpen, setIsOpen] = useState(true)

  const [selectedCardId, setSelectedCardId] = useState<string | undefined>(
    invoiceDetail.primaryCardId
  )

  const handleRedirectToInvoicesPage = useRedirectToInvoicesPage()

  const payInvoiceMutation = usePayInvoiceMutation()

  const handleCloseDrawer = () => {
    setIsOpen(false)
    handleRedirectToInvoicesPage()
  }

  const showSpinner =
    isRequestRefetching(invoiceDetail.invoiceDetailsAsyncQuery) ||
    isRequestFetching(invoiceDetail.downloadInvoiceQuery) ||
    payInvoiceMutation?.status === 'loading'

  return (
    <Drawer open={isOpen} onClose={handleCloseDrawer}>
      <DrawerContent
        className="ml-0 flex h-5/6 flex-col outline-none lg:ml-[260px] lg:mr-[4px]"
        data-testid="pageInvoiceDetail"
        aria-describedby=""
        onEscapeKeyDown={handleCloseDrawer}
      >
        <div
          className={mergeClassName(
            'flex flex-col min-h-full',
            showSpinner ? spinnerStyles({ type: 'layOverBackground' }) : ''
          )}
        >
          {showSpinner ? <Spinner className={spinnerStyles({ type: 'layOverSpinner' })} /> : null}
          <DrawerHeader className="mx-auto max-w-4xl p-6 pb-2 md:pb-4" onClose={handleCloseDrawer}>
            <DrawerTitle className="flex items-center gap-2 dark:text-white">
              {isRequestLoading(invoiceDetail.invoiceDetailsAsyncQuery) ? (
                <Skeleton className="h-[18px] w-[264px]" />
              ) : (
                <div className="flex items-center gap-2" data-testid="pageInvoiceDetailTitle">
                  {t('ssp:pages.invoices.detail.title', {
                    invoiceId: invoiceDetail.invoiceDetailsAsyncQuery?.data?.displayInvoiceNumber,
                  })}
                  <div className="flex md:hidden">
                    - {formatMoneyAmount(invoiceDetail.invoiceDetailsAsyncQuery?.data?.amountDue)}
                  </div>
                  <InvoiceStatus
                    invoiceStatus={invoiceDetail.invoiceDetailsAsyncQuery?.data?.status}
                  />
                </div>
              )}
            </DrawerTitle>
          </DrawerHeader>
          <InvoiceDetailContent
            selectedCardId={selectedCardId ?? invoiceDetail.primaryCardId}
            setSelectedCardId={setSelectedCardId}
          />
          <InvoiceDetailFooter
            selectedCardId={selectedCardId ?? invoiceDetail.primaryCardId}
            closeDrawer={handleCloseDrawer}
          />
        </div>
      </DrawerContent>
    </Drawer>
  )
}

export default protectPage(InvoiceDetailPage, Permission.InvoiceDetails)
