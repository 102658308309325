import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import { SheetPage } from '@sherweb/core/components/Sheet'
import { Typography } from '@sherweb/core/components/Typography'

import { protectPage } from '@ssp/app/ProtectedPage'
import { useGetHelpDeskEnabledQuery } from '@ssp/modules/integrations/helpdesk'
import { useNavigateToHelpDeskPage } from '@ssp/modules/integrations/helpdesk/core/helpdesk.helpers'
import Error404 from '@ssp/pages/errors/Error404'

import { HelpDeskCreateTicketContent } from './HelpDeskCreateTicketContent'

export const HelpDeskCreateTicketPage = () => {
  const { t } = useTranslation()

  const navigateToHelpDeskPage = useNavigateToHelpDeskPage()

  const helpDeskEnabledQuery = useGetHelpDeskEnabledQuery()

  const handleRedirectToHelpDesk = useCallback(() => {
    navigateToHelpDeskPage()
  }, [navigateToHelpDeskPage])

  if (
    helpDeskEnabledQuery?.data !== undefined &&
    !helpDeskEnabledQuery?.data &&
    !helpDeskEnabledQuery.isLoading
  ) {
    return <Error404 />
  }

  return (
    <SheetPage
      title={
        <Typography variant="heading6" weight="bold" className="text-wrap">
          {t('ssp:pages.helpdesk.create.newRequest')}
        </Typography>
      }
      onClose={handleRedirectToHelpDesk}
    >
      <HelpDeskCreateTicketContent onRedirectToHelpDesk={handleRedirectToHelpDesk} />
    </SheetPage>
  )
}

export default protectPage(HelpDeskCreateTicketPage, Permission.HelpDeskEdit)
