import { useMemo } from 'react'
import {
  createSearchParams,
  generatePath,
  useLocation as reactRouterUseLocation,
  useNavigate as reactRouterUseNavigate,
  useParams as reactRouterUseParams,
  useMatch,
} from 'react-router-dom'

export const useHostname = () => {
  /* istanbul ignore next ; cannot test ssr */
  if (typeof window === 'undefined') {
    return 'localhost'
  }
  return window.location.hostname ?? 'localhost'
}

export const useOrigin = () => window.location.origin

export const useLocation = () => {
  return reactRouterUseLocation()
}

export const useParams = () => {
  return reactRouterUseParams()
}

export const useQueryString = (key?: string) => {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  if (key) {
    return getStringOrArray(searchParams.getAll(key))
  }
  const keys = Array.from(searchParams.keys())
  const entries = keys.map((key: string) => [key, getStringOrArray(searchParams.getAll(key))])
  return Object.fromEntries(entries)
}

export const usePathGenerator = () => {
  const params = useParams()
  return (path: string, extraParams?: Record<string, string | undefined>) => {
    return generatePath(path, { ...params, ...extraParams })
  }
}

export const useNavigate = () => {
  const navigate = reactRouterUseNavigate()
  const generatePath = usePathGenerator()
  return {
    navigateWithRedirectToReferrer: (to: string) => {
      return navigate({
        pathname: generatePath(to),
        search: createSearchParams({
          redirectTo: encodeURIComponent(window.location.href),
        }).toString(),
      })
    },
    navigate: (to: string, params?: Record<string, string | undefined>) =>
      navigate(generatePath(to, params)),
    push: (to: string, params?: Record<string, string>) => navigate(generatePath(to, params)),
    replace: (to: string, params?: Record<string, string>) =>
      navigate(generatePath(to, params), { replace: true }),
  }
}

const getStringOrArray = (values: string | string[]) => (values.length > 1 ? values : values[0])

export const useExactMatch = (path: string) => {
  const location = useLocation()

  const match = useMatch(path)

  if (!match) {
    return false
  }

  const actualPath = generatePath(path, match.params)

  return actualPath === location.pathname
}
