import { CheckIcon } from '@heroicons/react/24/outline'
import { ReactNode } from 'react'

import { DataTestId } from '../../types/dataTestIdType'
import { mergeClassName } from '../../utils/mergeClassName'
import { CommandItem } from '../Command'
import { ComboboxCommonProps } from './types'

type ComboboxItemProps = ComboboxCommonProps & {
  optionValue: string
  label: string | ReactNode
  searchableValue?: string
} & DataTestId

export const ComboboxItem = ({
  optionValue,
  searchableValue,
  label,
  value,
  dataTestId,
  onSelectItem,
}: ComboboxItemProps) => (
  <CommandItem
    key={optionValue}
    onSelect={() => onSelectItem(optionValue)}
    data-testid={dataTestId}
    className={mergeClassName(
      'cursor-pointer py-2 pl-10 pr-4 text-gray-900 dark:text-white',
      optionValue === value ? 'font-medium' : ''
    )}
    value={searchableValue}
  >
    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-purple-600">
      <CheckIcon
        className={mergeClassName('h-4 w-4', optionValue === value ? 'opacity-100' : 'opacity-0')}
      />
    </span>

    {label}
  </CommandItem>
)
