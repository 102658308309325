import { OrderSortField } from '@sherweb/core/openapi-generated/index.defs'
import { ResellerOrdersService } from '@sherweb/core/openapi-generated/ResellerOrdersService'

import { Pagination } from '@sherweb/core/components/DataTable/types'
import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { rejectMissingParameters } from '@sherweb/core/utils/error'

export const ordersByResellerAsyncQueryPrefix = 'ResellerOrdersService/queryOrdersByResellerAsync'

const getOrdersQueryFn = async (resellerId?: string, queryOptions?: Pagination<OrderSortField>) => {
  if (!resellerId || !queryOptions?.sort?.field || queryOptions?.sort?.ascending === undefined) {
    return await rejectMissingParameters()
  }

  return await ResellerOrdersService.queryOrdersByResellerAsync({
    resellerId,
    query: queryOptions,
  })
}

export const ordersByResellerAsync = {
  queryKey: (resellerId?: string, queryOptions?: Pagination<OrderSortField>) => [
    ordersByResellerAsyncQueryPrefix,
    resellerId,
    queryOptions,
  ],
  queryFn: getOrdersQueryFn,
  staleTime: DEFAULT_STALE_TIME,
}

export const ordersByResellerAsyncWithInfiniteScroll = {
  queryKey: (resellerId?: string, queryOptions?: Pagination<OrderSortField>) => [
    ordersByResellerAsyncQueryPrefix,
    'infiniteScroll',
    resellerId,
    queryOptions,
  ],
  queryFn: getOrdersQueryFn,
  staleTime: DEFAULT_STALE_TIME,
}

export const resellerOrderDetailsById = {
  queryKey: (resellerId?: string, orderId?: string) => [
    'ResellerOrdersService/getResellerOrderDetailsById',
    resellerId,
    orderId,
  ],
  queryFn: async (resellerId?: string, orderId?: string) => {
    if (!resellerId || !orderId) {
      return await rejectMissingParameters()
    }

    return await ResellerOrdersService.getResellerOrderDetailsById({ resellerId, orderId })
  },
  staleTime: DEFAULT_STALE_TIME,
}
