import { useCallback, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { usePostConnetWiseMapOrganizationMutation } from '@rsp/modules/integrations/helpdesk'
import {
  IUnMappedOrganizationType,
  IUnMappedOrganizationWithValueType,
} from '@rsp/modules/integrations/helpdesk/core/helpdesk.model'

import { OrganizationMappingFormType } from './useOrganizationMappingSchema'

type UseOrganizationMappingProps = {
  form: UseFormReturn<OrganizationMappingFormType>
  submittedOrganizations: IUnMappedOrganizationWithValueType[]
}

export const useOrganizationMapping = ({
  form,
  submittedOrganizations,
}: UseOrganizationMappingProps) => {
  const { t } = useTranslation()

  const connetWiseMapOrganizationMutation = usePostConnetWiseMapOrganizationMutation()

  useEffect(() => {
    if (connetWiseMapOrganizationMutation?.isSuccess) {
      connetWiseMapOrganizationMutation?.data?.organizationMappingResults?.forEach(organization => {
        if (organization.isMapped) {
          form.setValue(`organizations.${organization.id}`, undefined)
        }
      })
    }
  }, [
    connetWiseMapOrganizationMutation?.data?.organizationMappingResults,
    connetWiseMapOrganizationMutation?.isSuccess,
    form,
  ])

  useEffect(() => {
    if (submittedOrganizations?.length !== 0) {
      submittedOrganizations?.forEach(organization => {
        if (organization?.isMapped !== undefined && !organization?.isMapped) {
          form.setValue('organizations', {
            [organization?.id]: organization?.value,
          })
          form.setError(`organizations.${organization?.id}`, {
            message: t(
              'rsp:pages.integrations.helpdesk.connectWise.organizationMapping.failedMappingError'
            ),
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedOrganizations])

  const setRequiredError = useCallback(
    (values: OrganizationMappingFormType, organizations?: IUnMappedOrganizationType[]) => {
      organizations?.forEach(organization => {
        if (!values.organizations?.[organization?.id]) {
          form.setError(`organizations.${organization?.id}`, {
            message: t('rsp:validations.mixed.required'),
          })
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getFailedOrganizationsMapping = useCallback(
    (organizations?: IUnMappedOrganizationType[]) => {
      if (
        !!connetWiseMapOrganizationMutation?.data &&
        connetWiseMapOrganizationMutation?.data?.organizationMappingResults?.length !== 0
      ) {
        return (
          organizations?.map(mappedOrg => {
            const organizations =
              connetWiseMapOrganizationMutation?.data?.organizationMappingResults?.find(
                responseOrg => responseOrg.id === mappedOrg.id && !responseOrg?.isMapped
              )

            const organizationsWithValue = {
              ...organizations,
              value:
                connetWiseMapOrganizationMutation?.variables?.organizations?.[mappedOrg.id] ?? '',
            }

            return {
              ...mappedOrg,
              ...organizationsWithValue,
            }
          }) ?? []
        )
      }
      return []
    },
    [
      connetWiseMapOrganizationMutation?.data,
      connetWiseMapOrganizationMutation?.variables?.organizations,
    ]
  )

  return { setRequiredError, getFailedOrganizationsMapping, connetWiseMapOrganizationMutation }
}
