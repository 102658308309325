import { IntegrationQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { TabsContent } from '@sherweb/core/components/Tabs'

import Integration from '.'

type IntegrationTabContentProps = {
  integrations?: IntegrationQueryResult[]
  dataTestId: string
  value: string
}

export const IntegrationTabContent = ({
  integrations = [],
  dataTestId,
  value,
}: IntegrationTabContentProps) => (
  <TabsContent
    value={value}
    data-testid={dataTestId}
    className="focus-visible:ring-indigo-300 md:py-4"
  >
    <div className="mt-4 grid grid-cols-1 gap-4 lg:gap-6 xl:grid-cols-1">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2  lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
        {integrations?.map(integration => (
          <Integration integration={integration} key={integration.id} />
        ))}
      </div>
    </div>
  </TabsContent>
)
