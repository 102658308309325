import { ResellerUsersService } from '@sherweb/core/openapi-generated/ResellerUsersService'

import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { rejectMissingParameters } from '@sherweb/core/utils/error'

export const resellerAdminsAndGuests = {
  queryKey: (resellerId?: string) => [
    'ResellerUsersService/getResellerAdminsAndGuests',
    resellerId,
  ],
  queryFn: async (resellerId?: string) => {
    if (!resellerId) {
      return await rejectMissingParameters()
    }

    return await ResellerUsersService.getResellerAdminsAndGuests({ resellerId })
  },
  staleTime: DEFAULT_STALE_TIME,
}
