import Card, { CardTitle } from '@sherweb/core/components/Card'
import { Skeleton } from '@sherweb/core/components/Skeleton'

type ShopLoaderProps = {
  count?: number
}

export const ShopProductsLoader = ({ count = 8 }: ShopLoaderProps) => (
  <>
    {Array.from({ length: count }, (_, index) => (
      <Card
        key={index}
        className="flex min-h-52 flex-col justify-between md:p-4"
        data-testid="loaderProduct"
      >
        <div className="flex h-full flex-col justify-between gap-2">
          <div>
            <Skeleton className="mb-3 h-[16px] w-20" />
            <CardTitle className="min-h-12 text-slate-900 dark:text-slate-200">
              <Skeleton className="h-[40px] w-full" />
            </CardTitle>
            <div className="mt-3 flex items-center gap-x-2">
              <Skeleton className="h-[20px] w-full" />
            </div>
          </div>
          <div className="flex flex-col gap-y-1">
            <Skeleton className="h-[16px] w-20" />
            <Skeleton className="h-[16px] w-20" />
          </div>
        </div>
      </Card>
    ))}
  </>
)
