import {
  ArrowPathIcon,
  CheckCircleIcon,
  TrashIcon,
  UserPlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '@sherweb/core/components/Tooltip'
import { Permission } from '@sherweb/core/modules/authorization'

import { usePermissionCheck } from '@ssp/modules/authorization'
import { License, LicenseUpdateStatus, Subscriber } from '@ssp/modules/license'

import ConditionalActionButton from '../ConditionalActionButton'

type SubscriberActionsButtonProps = {
  subscriber: Subscriber
  license: License
  subscriberEmailsToAdd: string[]
  subscriberIdsToRemove: string[]
  onAddLicense: (email: string | null | undefined) => void
  onRemoveLicense: (subscriberId: string | null | undefined) => void
}

const SubscriberActionsButton: React.FC<SubscriberActionsButtonProps> = ({
  subscriber,
  license,
  subscriberEmailsToAdd,
  subscriberIdsToRemove,
  onAddLicense,
  onRemoveLicense,
}) => {
  const { t } = useTranslation()
  const pendingUpdates = subscriber.licenseUpdates?.filter(
    x => x.licenseId === license.id && x.updateStatus === LicenseUpdateStatus.Pending
  )
  const pendingUpdate = pendingUpdates && pendingUpdates.length > 0 ? pendingUpdates[0] : null
  const hasLicense = subscriber.assignedLicenses?.includes(license.id)
  const { hasAccess } = usePermissionCheck()

  return (
    <div className="flex">
      {pendingUpdate && (
        <Tooltip tooltip={t('ssp:pages.licenses.subscribersDialog.actions.updateInProgress')}>
          <ArrowPathIcon
            className="h-5 w-5 stroke-1 text-green-400 dark:stroke-2"
            data-testid={'subscriber-arrow-icon-' + (subscriber.id ?? '')}
          />
        </Tooltip>
      )}
      {!pendingUpdate && (
        <ConditionalActionButton
          disabled={!hasAccess(Permission.LicenseAssign)}
          items={hasLicense ? subscriberIdsToRemove : subscriberEmailsToAdd}
          onHandle={hasLicense ? onRemoveLicense : onAddLicense}
          value={hasLicense ? subscriber.id : subscriber.email ?? ''}
          childFlagged={
            <Tooltip
              tooltip={
                hasLicense
                  ? t('ssp:pages.licenses.subscribersDialog.actions.subscriberToBeDeleted')
                  : t('ssp:pages.licenses.subscribersDialog.actions.subscriberToBeAdded')
              }
            >
              {hasLicense ? (
                <XCircleIcon
                  className="h-5 w-5 cursor-pointer stroke-1 text-red-500 dark:stroke-2"
                  data-testid={'subscriber-x-icon-' + (subscriber.id ?? '')}
                />
              ) : (
                <CheckCircleIcon
                  className="h-5 w-5 cursor-pointer stroke-1 text-green-500 dark:stroke-2"
                  data-testid={'subscriber-cercle-icon-' + (subscriber.id ?? '')}
                />
              )}
            </Tooltip>
          }
          childNotFlagged={
            <Tooltip
              tooltip={
                hasLicense
                  ? t('ssp:pages.licenses.subscribersDialog.actions.removeSubscriber')
                  : t('ssp:pages.licenses.subscribersDialog.actions.addSubscriber')
              }
            >
              {hasLicense ? (
                <TrashIcon
                  className="h-5 w-5 cursor-pointer stroke-1 text-red-500 dark:stroke-2"
                  data-testid={'subscriber-trash-icon-' + (subscriber.id ?? '')}
                />
              ) : (
                <UserPlusIcon
                  className="h-5 w-5 cursor-pointer stroke-1 text-indigo-500 dark:stroke-2"
                  data-testid={'subscriber-plus-icon-' + (subscriber.id ?? '')}
                />
              )}
            </Tooltip>
          }
        />
      )}
    </div>
  )
}

export default SubscriberActionsButton
