import { useTranslation } from 'react-i18next'

import { ServiceProviderConfigurationQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import { CardTitle } from '@sherweb/core/components/Card'
import { Typography } from '@sherweb/core/components/Typography'

import { SherwebServiceProviderConfigurationBlock } from './SherwebServiceProviderConfigurationBlock'

type SherwebServiceProviderCardProps = {
  serviceProvider?: ServiceProviderConfigurationQueryResult
  onResetEditMode: () => void
}

export const SherwebServiceProviderCard = ({
  serviceProvider,
  onResetEditMode,
}: SherwebServiceProviderCardProps) => {
  const { t } = useTranslation()

  return (
    <SherwebServiceProviderConfigurationBlock>
      <CardTitle>
        <Typography variant="body1" weight="semiBold">
          {t('rsp:pages.integrations.serviceProvider.sherweb.form.title')}
        </Typography>
      </CardTitle>
      <CardTitle>
        <Typography
          colors="light"
          variant="body2"
          data-testid="textSherwebServiceProviderPublicKey"
        >
          {t('rsp:pages.integrations.serviceProvider.sherweb.form.clientIdKey')}:{' '}
          {serviceProvider?.clientId}
        </Typography>
      </CardTitle>
      <div className="flex justify-end pt-6">
        <Button
          variant="None"
          className="bg-indigo-600 px-4 py-2 hover:bg-indigo-700"
          onClick={() => {
            onResetEditMode()
          }}
          data-testid="btnSherwebServiceProviderReset"
        >
          {t('rsp:actions.reset')}
        </Button>
      </div>
    </SherwebServiceProviderConfigurationBlock>
  )
}
