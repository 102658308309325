import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Form } from '@sherweb/core/components/Form'
import { usePrompt } from '@sherweb/core/components/Prompt'
import { Separator } from '@sherweb/core/components/Separator'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import {
  useGetHelpDeskOrganizationTicketTypesQuery,
  useHelpDeskCreateTicketMutation,
} from '@ssp/modules/integrations/helpdesk'

import { DEFAULT_VALUES } from './helpdeskCreateTicket.helper'
import { HelpDeskCreateTicketForm } from './HelpDeskCreateTicketForm'
import {
  HelpDeskCreateTicketFormType,
  useHelpDeskCreateTicketSchema,
} from './hooks/useHelpDeskCreateTicketSchema'

type HelpDeskCreateTicketContentProps = {
  onRedirectToHelpDesk: () => void
}

export const HelpDeskCreateTicketContent = ({
  onRedirectToHelpDesk,
}: HelpDeskCreateTicketContentProps) => {
  const { t } = useTranslation()

  const schema = useHelpDeskCreateTicketSchema()

  const form = useForm<HelpDeskCreateTicketFormType>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_VALUES,
  })

  const helpDeskOrganizationTicketTypesQuery = useGetHelpDeskOrganizationTicketTypesQuery()

  const isHelpDeskOrganizationTicketTypesQueryLoading =
    helpDeskOrganizationTicketTypesQuery.isLoading || !helpDeskOrganizationTicketTypesQuery?.data

  const helpDeskCreateTicketMutation = useHelpDeskCreateTicketMutation()

  const onSubmit = (values: HelpDeskCreateTicketFormType) =>
    helpDeskCreateTicketMutation.mutate(values)

  const handleRedirectWithResettingFormValues = useCallback(() => {
    form.reset(DEFAULT_VALUES)
    onRedirectToHelpDesk()
  }, [form, onRedirectToHelpDesk])

  useEffect(() => {
    if (helpDeskCreateTicketMutation?.isSuccess) {
      handleRedirectWithResettingFormValues()
    }
  }, [handleRedirectWithResettingFormValues, helpDeskCreateTicketMutation?.isSuccess])

  usePrompt({
    isDirty: form.formState.isDirty,
    title: t('ssp:pages.helpdesk.create.prompt.title'),
    children: t('ssp:pages.helpdesk.create.prompt.body'),
    onConfirm: handleRedirectWithResettingFormValues,
  })

  if (isHelpDeskOrganizationTicketTypesQueryLoading) {
    return <Spinner className={spinnerStyles({ type: 'layOverSpinner' })} />
  }

  return (
    <>
      <Separator className="bg-slate-300 dark:bg-slate-700" />
      <div
        className={mergeClassName('px-6 pb-4 py-6', {
          'bg-background/80 opacity-50 backdrop-blur-sm': helpDeskCreateTicketMutation?.isLoading,
        })}
      >
        {helpDeskCreateTicketMutation?.isLoading ? (
          <Spinner className={spinnerStyles({ type: 'layOverSpinner' })} />
        ) : null}
        <div className="flex w-full flex-row justify-between">
          <Form {...form}>
            <form className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
              <HelpDeskCreateTicketForm
                isLoading={helpDeskCreateTicketMutation?.isLoading}
                onRedirectToHelpDesk={onRedirectToHelpDesk}
              />
            </form>
          </Form>
        </div>
      </div>
    </>
  )
}
