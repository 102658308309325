import clsx from 'clsx'
import { forwardRef } from 'react'
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'

import { usePathGenerator } from '@sherweb/core/modules/browserRouter'

import { Typography, TypographyProps } from '../Typography'

type ReactLink = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

export type LinkProps = {
  to: string
  isAbsolute?: boolean
  currentClassName?: string
  params?: Record<string, string | undefined>
  typography?: Omit<TypographyProps, 'children'>
} & Omit<ReactLink, 'ref'>

export const LinkText = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { to, children, currentClassName, isAbsolute = false, params, typography = {}, ...linkProps },
    ref
  ) => {
    const location = useLocation()

    const generatePath = usePathGenerator()

    const current = location.pathname === to

    const renderLink = (children: React.ReactNode) => {
      return (
        <Typography
          className="text-indigo-600 hover:underline dark:text-blue-500"
          weight="bold"
          {...typography}
          asChild
        >
          {children}
        </Typography>
      )
    }

    if (isAbsolute) {
      return renderLink(
        <a href={to} {...linkProps} ref={ref}>
          {children}
        </a>
      )
    }

    return renderLink(
      <ReactRouterLink
        ref={ref}
        to={generatePath(to, params)}
        className={clsx(linkProps.className, current && currentClassName)}
        {...linkProps}
      >
        {children}
      </ReactRouterLink>
    )
  }
)
