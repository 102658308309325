import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import Spinner from '@sherweb/core/components/Spinner'

import { protectPage } from '@ssp/app/ProtectedPage'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import {
  useGetHelpDeskAccessQuery,
  useGetHelpDeskEnabledQuery,
  useGetHelpDeskTicketsQuery,
} from '@ssp/modules/integrations/helpdesk'
import { useReseller } from '@ssp/modules/reseller'
import Error404 from '@ssp/pages/errors/Error404'

import { HelpDeskTicketEmptyContainer } from './HelpDeskTicketEmptyContainer'
import { HelpDeskTicketNotAvailable } from './HelpDeskTicketNotAvailable'
import { HelpDeskTicketTable } from './HelpDeskTicketTable'
import { HelpDeskTicketTableMobile } from './HelpDeskTicketTableMobile'

export const HelpDeskTicketPage = () => {
  const { t } = useTranslation()

  const resellerQuery = useReseller()

  const isResellerQueryLoading = resellerQuery?.isLoading || !resellerQuery?.data

  const helpdeskAccessQuery = useGetHelpDeskAccessQuery()

  const helpdeskTicketsQuery = useGetHelpDeskTicketsQuery()

  const helpDeskEnabledQuery = useGetHelpDeskEnabledQuery()

  const isHelpdeskAccessQueryLoading =
    helpdeskAccessQuery?.isLoading || helpdeskAccessQuery?.data === undefined

  const isHelpdeskTicketsQueryLoading =
    helpdeskTicketsQuery?.isLoading || !helpdeskTicketsQuery?.data

  const noTickets =
    !helpdeskTicketsQuery?.isLoading &&
    !!helpdeskTicketsQuery?.data &&
    helpdeskTicketsQuery?.data?.length === 0

  if (
    helpDeskEnabledQuery?.data !== undefined &&
    !helpDeskEnabledQuery?.data &&
    !helpDeskEnabledQuery.isLoading
  ) {
    return <Error404 showSidebar />
  }

  if (
    !helpdeskAccessQuery?.data?.isPsaAvailable &&
    !isHelpdeskAccessQueryLoading &&
    !helpDeskEnabledQuery.isLoading
  ) {
    return <HelpDeskTicketNotAvailable />
  }

  return (
    <ContainedPage>
      {isHelpdeskAccessQueryLoading || isResellerQueryLoading || isHelpdeskTicketsQueryLoading ? (
        <Spinner floating />
      ) : (
        <div className="flex flex-col gap-12 md:gap-16">
          <PageTitleWrapper
            title={
              <PageTitle data-testid="pageHelpDeskTitle">{t('ssp:pages.helpdesk.title')}</PageTitle>
            }
            subTitle={<PageSubTitle>{t('ssp:pages.helpdesk.subTitle')}</PageSubTitle>}
          />
          {noTickets ? <HelpDeskTicketEmptyContainer /> : null}
          {helpdeskTicketsQuery?.data?.length > 0 ? (
            <>
              <div className="hidden lg:block" data-testid="containerHelpDeskTable">
                <HelpDeskTicketTable />
              </div>
              <div className="mt-4 lg:hidden" data-testid="containerHelpDeskTableMobile">
                <HelpDeskTicketTableMobile />
              </div>
            </>
          ) : null}
        </div>
      )}
      <Outlet />
    </ContainedPage>
  )
}

export default protectPage(HelpDeskTicketPage, Permission.HelpDeskList)
