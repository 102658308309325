import { MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import Switch from '../Switch'
import Tooltip from '../Tooltip'

export type DarkModeSwitchProps = {
  darkMode: boolean
  setDarkMode: (darkMode: boolean) => void
}

export const DarkModeSwitch = forwardRef<HTMLButtonElement, DarkModeSwitchProps>(
  ({ darkMode, setDarkMode }, ref) => {
    const { t } = useTranslation()

    const darkModeTooltip = darkMode
      ? t('core:theme.darkModeToggle.disableDarkModeTooltip')
      : t('core:theme.darkModeToggle.enableDarkModeTooltip')

    return (
      <div>
        <Tooltip tooltip={darkModeTooltip}>
          <Switch
            data-testid="btnDarkModeSwitch"
            defaultChecked={darkMode}
            checked={darkMode}
            onCheckedChange={() => setDarkMode(!darkMode)}
            ref={ref}
          >
            <MoonIcon
              data-testid="btnDarkModeIcon"
              className="absolute left-1.5 h-4 w-4 text-slate-200"
            />
            <SunIcon
              data-testid="btnLightModeIcon"
              className="absolute right-1.5 h-4 w-4 text-slate-400"
            />
          </Switch>
        </Tooltip>
      </div>
    )
  }
)
