import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { SheetPage } from '@sherweb/core/components/Sheet'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import { Typography } from '@sherweb/core/components/Typography'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import Routes from '@rsp/app/Routes'
import { useGetDetailedOrganizationQuery } from '@rsp/modules/organizations'
import { GoToOrganizationLink } from '@rsp/pages/organizations/components/GoToOrganizationLink'

import { useRenderStatusBadge } from '../organizations.helper'
import { OrganizationDetailContent } from './OrganizationDetailContent'

const OrganizationDetailPage = () => {
  const { t } = useTranslation()

  const renderStatusBadge = useRenderStatusBadge()

  const { organizationId } = useParams()

  const organizationQuery = useGetDetailedOrganizationQuery(organizationId)

  const navigate = useNavigate()

  const isLoading = organizationQuery.isLoading || !organizationQuery?.data

  const handleClose = () => {
    navigate(Routes.Organizations)
  }

  return (
    <SheetPage
      title={
        isLoading ? null : (
          <>
            <div className="flex items-center">
              <Typography variant="heading6" weight="bold" className="text-wrap">
                {organizationQuery?.data?.organizationName}
              </Typography>
              <GoToOrganizationLink
                className="ml-2"
                organizationUniqueName={organizationQuery?.data?.uniqueName}
                dataTestId="linkGoToOrganization"
              >
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </GoToOrganizationLink>
              {renderStatusBadge(organizationQuery.data.isSuspended)}
            </div>
            <Typography variant="body2" weight="default" className="text-sm text-muted-foreground">
              {t('rsp:pages.organizations.detail.subTitle')}
            </Typography>
          </>
        )
      }
      onClose={handleClose}
    >
      {isLoading || organizationQuery.isFetching ? (
        <Spinner
          dataTestId="layoverSpinner"
          className={spinnerStyles({ type: 'layOverSpinner' })}
        />
      ) : null}
      <div
        className={mergeClassName({
          'bg-background/80 opacity-50 backdrop-blur-sm': organizationQuery.isFetching,
        })}
      >
        <OrganizationDetailContent organization={organizationQuery.data} onClose={handleClose} />
      </div>
    </SheetPage>
  )
}

export default OrganizationDetailPage
