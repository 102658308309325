import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { BillingCycle, Facet } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import { useQueryParams } from '@sherweb/core/hooks'
import {
  MarketPlaceFilter,
  MarketPlaceFilterTriggerContent,
} from '@sherweb/core/modules/marketplace/ui/Filter'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

type MarketplaceProductsBillingCycleFilterProps = {
  facets?: Facet[]
}

export const MarketplaceProductsBillingCycleFilter = ({
  facets,
}: MarketplaceProductsBillingCycleFilterProps) => {
  const { t } = useTranslation()

  const billingCycleOptions = facets
    ?.find(({ fieldName }) => fieldName === 'BillingCycle')
    ?.countsByValue?.map(option => ({
      ...option,
      label: t(`rsp:pages.marketplace.catalogs.billingCycleValues.${option.value as BillingCycle}`),
    }))

  const { getArrayParam } = useQueryParams<'productsBillingCycle'>()

  const queryBillingCycleSelectedValues = getArrayParam<BillingCycle>('productsBillingCycle')

  const queryBillingCycleSelectedLabels = queryBillingCycleSelectedValues?.map(value =>
    t(`rsp:pages.marketplace.catalogs.billingCycleValues.${value}`)
  )

  return (
    <MarketPlaceFilter
      dataTestId="billingCycle"
      className="min-w-[250px]"
      options={billingCycleOptions}
      queryParamType="productsBillingCycle"
      trigger={
        <Button
          size="small"
          variant="None"
          className={mergeClassName(
            'flex items-center px-4 py-2 text-slate-900 hover:bg-white/50 dark:text-slate-100',
            {
              'bg-white/50': queryBillingCycleSelectedLabels.length !== 0,
            }
          )}
        >
          <MarketPlaceFilterTriggerContent
            text={t('rsp:pages.marketplace.addProducts.filters.billingCycle')}
            icon={<ArrowPathIcon className="mr-1 h-4 w-4" />}
            value={queryBillingCycleSelectedLabels}
          />
        </Button>
      }
    />
  )
}
