import { useQuery } from '@tanstack/react-query'

import { useAuthenticationLoggedInState } from '../../../modules/authentication'
import { UsersQueryResult } from '../../../openapi-generated/index.defs'
import { QueryResult } from '../../reactQuery'
import { buildUser } from './user.builder'
import { User } from './user.model'
import { currentUser } from './user.queries'

/**
 * returns current user profile
 */
export const useCurrentUser = ({ ...queryArgs } = {}): QueryResult<User | null> => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const { data, isLoading, isFetching, ...queryProps } = useQuery<UsersQueryResult, Error>({
    queryKey: currentUser.queryKey(),
    queryFn: async () => await currentUser.queryFn(),
    enabled: isLoggedIn,
    staleTime: currentUser.staleTime,
  })

  return {
    data: buildUser(data),
    isLoading: isLoading && isFetching,
    ...queryProps,
  }
}
