import { UsersQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { User } from './user.model'

export const buildUser = (
  organizationId: string | undefined,
  userResult: UsersQueryResult
): User => {
  const roles = userResult.roles
    ?.filter(x => x.organizationId === organizationId)
    .map(o => o.userRole)

  return {
    ...userResult,
    roles,
  }
}

export const buildUsers = (
  organizationId: string | undefined,
  users?: UsersQueryResult[]
): User[] => (users ? users.map(u => buildUser(organizationId, u)) : [])
