import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { FormSplitScreen } from '@rsp/components/layout/FormSplitScreen'

type SherwebServiceProviderConfigurationBlockProps = {
  className?: string
  children?: React.ReactNode
}

export const SherwebServiceProviderConfigurationBlock = ({
  className,
  children,
}: SherwebServiceProviderConfigurationBlockProps) => {
  const { t } = useTranslation()

  return (
    <FormSplitScreen.Wrapper>
      <FormSplitScreen.RowCard
        contentContainerClassName={mergeClassName(
          'md:max-w-md max-w-full bg-white/50 gap-y-2 dark:bg-slate-950',
          className
        )}
        leftContent={
          <FormSplitScreen.LeftContent
            label={t('rsp:pages.integrations.serviceProvider.sherweb.keysTitle')}
            subLabel={
              <Trans i18nKey="rsp:pages.integrations.serviceProvider.sherweb.keysDescription">
                <Link
                  to="https://github.com/sherweb/Public-Apis/blob/master/README.md#prerequisite-for-using-the-apis"
                  target="_blank"
                  className="font-semibold text-indigo-600 hover:underline"
                />
              </Trans>
            }
          />
        }
      >
        {children}
      </FormSplitScreen.RowCard>
    </FormSplitScreen.Wrapper>
  )
}
