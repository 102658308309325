import { combineReducers } from 'redux'

import * as organization from '@ssp/modules/organization'
import * as reseller from '@ssp/modules/reseller'
import * as theme from '@ssp/modules/theme'

const mainReducer = combineReducers({
  [theme.key]: theme.reducer,
  [organization.key]: organization.reducer,
  [reseller.key]: reseller.reducer,
})

export default mainReducer
