import {
  ImageType,
  SetResellerSettingsCommand,
  SetSupportInformationCommand,
  Theme,
} from '@sherweb/core/openapi-generated/index.defs'
import { PortalSettingsService } from '@sherweb/core/openapi-generated/PortalSettingsService'
import { ResellersService } from '@sherweb/core/openapi-generated/ResellersService'

export const updateImage = {
  mutationFn: async (params: { resellerId: string; file: File; size: string; type: ImageType }) => {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await PortalSettingsService.updateImage({
      resellerId: params.resellerId,
      file: params.file,
      type: params.type,
      size: params.size,
    })
  },
}

export const updateOnboarding = {
  mutationFn: async (params: { resellerId: string }) => {
    return await ResellersService.onboardedReseller({
      resellerId: params.resellerId,
    })
  },
}

export const setSupportInformation = {
  mutationFn: async (params: {
    resellerId: string
    email: string
    phoneNumber: string
    address: string
  }) => {
    const command: SetSupportInformationCommand = {
      email: params.email,
      phoneNumber: params.phoneNumber,
      address: params.address,
    }

    return await ResellersService.setSupportInformation({
      resellerId: params.resellerId,
      command,
    })
  },
}

export const setResellerSettings = {
  mutationFn: async (params: {
    resellerId: string
    priceVisible: boolean
    isSubresellerShown: boolean
    showSubscriptions: boolean
    showUsers: boolean
    showLicenses: boolean
    showBilling: boolean
    showHelpDesk: boolean
    orgAdminCanCreateUsers: boolean
    allowSubscriptionCancellation: boolean
    showMarketplace: boolean
  }) => {
    const command: SetResellerSettingsCommand = {
      priceVisible: params.priceVisible,
      isSubresellerShown: params.isSubresellerShown,
      showSubscriptions: params.showSubscriptions,
      showUsers: params.showUsers,
      showLicenses: params.showLicenses,
      showBilling: params.showBilling,
      showHelpDesk: params.showHelpDesk,
      orgAdminCanCreateUsers: params.orgAdminCanCreateUsers,
      allowSubscriptionCancellation: params.allowSubscriptionCancellation,
      showMarketplace: params.showMarketplace,
    }

    return await ResellersService.setResellerSettings({
      resellerId: params.resellerId,
      command,
    })
  },
}

export const updatePortalSettings = {
  mutationFn: async (params: { resellerId: string; theme: Theme }) => {
    return await PortalSettingsService.setPortalSettings({
      /** TODO: Remove command and provide directly the params */
      command: {
        resellerId: params.resellerId,
        theme: params.theme,
      },
    })
  },
}
