import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ProcessOrderDecision } from '@sherweb/core/openapi-generated/index.defs'

import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'
import { Textarea } from '@sherweb/core/components/Textarea'
import { Order } from '@sherweb/core/modules/orders/core/orders.model'

export type OrderAprovalConfirmationDialogProps = {
  onSubmit: (decision: ProcessOrderDecision, decisionNote?: string) => void
  cancelConfirmation: () => void
  confirmationDialogOpened: boolean
  decision: ProcessOrderDecision
  order: Order
}

export const OrderAprovalConfirmationDialog = ({
  onSubmit,
  cancelConfirmation,
  confirmationDialogOpened,
  decision,
  order,
}: OrderAprovalConfirmationDialogProps) => {
  const { t } = useTranslation()
  const [decisionNote, setDecisionNote] = useState<string | undefined>(undefined)

  const dialogBodyKey =
    decision === ProcessOrderDecision.Approve
      ? 'rsp:pages.orders.list.actions.confirmationModal.approveBody'
      : 'rsp:pages.orders.list.actions.confirmationModal.rejectBody'

  const dialogButton =
    decision === ProcessOrderDecision.Approve
      ? t('rsp:pages.orders.list.actions.approve')
      : t('rsp:pages.orders.list.actions.reject')

  const dialogTitle =
    decision === ProcessOrderDecision.Approve
      ? t('rsp:pages.orders.list.actions.confirmationModal.approveTitle')
      : t('rsp:pages.orders.list.actions.confirmationModal.rejectTitle')

  return (
    <ConfirmationDialog
      open={confirmationDialogOpened}
      onClose={() => {
        setDecisionNote(undefined)
        cancelConfirmation()
      }}
      onConfirm={async () => onSubmit(decision, decisionNote)}
      title={dialogTitle}
      confirmButtonText={dialogButton}
      cancelButtonText={t('rsp:actions.cancel')}
    >
      <>
        <Trans
          i18nKey={dialogBodyKey}
          values={{
            orderId: order.humanReadableId,
            organizationName: order.organizationName,
          }}
          components={{ 1: <br /> }}
        />
        {decision === ProcessOrderDecision.Reject && (
          <Textarea
            data-testid="textareaDecisionNote"
            value={decisionNote ?? ''}
            onChange={e => setDecisionNote(e.target.value)}
            placeholder={
              t('rsp:pages.orders.list.actions.confirmationModal.decisionNotePlaceholder') ?? ''
            }
            className="mt-4"
          />
        )}
      </>
    </ConfirmationDialog>
  )
}
