import { InvoiceStatus } from '@sherweb/core/openapi-generated/index.defs'

import {
  useDownloadInvoiceQuery,
  useGetInvoiceDetailsAsyncQuery,
  useGetPaymentMethodsByOrganizationIdQuery,
} from '@ssp/modules/invoices/core/invoices.hooks'
import { useSelectedOrganizationId } from '@ssp/modules/organization'

import { useGetPagetParams } from '../detail/hooks/useGetPagetParams'

export const useGetInvoiceDetail = () => {
  const { invoiceId, integrationType } = useGetPagetParams()

  const organizationId = useSelectedOrganizationId()

  const invoiceDetailsAsyncQuery = useGetInvoiceDetailsAsyncQuery(invoiceId)

  const downloadInvoiceQuery = useDownloadInvoiceQuery(invoiceId)

  const isInvoiceNotPaid =
    invoiceDetailsAsyncQuery?.data?.status &&
    invoiceDetailsAsyncQuery?.data?.status !== InvoiceStatus.Paid

  const paymentMethodsByOrganizationIdQuery = useGetPaymentMethodsByOrganizationIdQuery({
    enabled: isInvoiceNotPaid,
  })

  const primaryCardId = paymentMethodsByOrganizationIdQuery?.data?.find(
    paymentMethod => paymentMethod.isPrimary
  )?.id

  return {
    organizationId,
    invoiceDetailsAsyncQuery,
    isInvoiceNotPaid,
    paymentMethodsByOrganizationIdQuery,
    invoiceId,
    integrationType,
    primaryCardId,
    downloadInvoiceQuery,
  }
}
