import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
  InvoicesQuery,
  OrganizationInvoiceQueryResult,
  OrganizationPaymentMethodResponse,
  PayOrganizationInvoiceCommand,
} from '@sherweb/core/openapi-generated/index.defs'
import { OrganizationInvoicesService } from '@sherweb/core/openapi-generated/OrganizationInvoicesService'

import { successNotification } from '@sherweb/core/components/ToastNotifications'
import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { DEFAULT_TABLE_PAGE } from '@sherweb/core/utils/const'
import { missingParametersError } from '@sherweb/core/utils/error'

import { useSelectedOrganizationId } from '@ssp/modules/organization'

import { getOrganizationInvoicesQueryOptions } from './invoices.helpers'
import { payInvoiceMutation } from './invoices.mutation'
import {
  downloadInvoiceAsyncQueryOptions,
  invoiceDetailsAsyncQueryOptions,
  paymentMethodsByOrganizationIdQueryOptions,
  queryOrganizationInvoicesAsync,
  queryOrganizationInvoicesAsyncWithInfiniteScroll,
} from './invoices.queries'

export const useGetInvoicesQuery = (
  queryOptions: InvoicesQuery = getOrganizationInvoicesQueryOptions()
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganizationId = useSelectedOrganizationId()

  return useQuery({
    queryKey: queryOrganizationInvoicesAsync.queryKey(selectedOrganizationId, queryOptions),
    queryFn: async () =>
      await queryOrganizationInvoicesAsync.queryFn(selectedOrganizationId, queryOptions),
    select: data => data.results,
    enabled: isLoggedIn && !!selectedOrganizationId,
    staleTime: queryOrganizationInvoicesAsync.staleTime,
  })
}

export const useGetInfiniteScrollInvoicessQuery = (
  queryOptions: InvoicesQuery = getOrganizationInvoicesQueryOptions()
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganizationId = useSelectedOrganizationId()

  return useInfiniteQuery({
    queryKey: queryOrganizationInvoicesAsyncWithInfiniteScroll.queryKey(
      selectedOrganizationId,
      queryOptions
    ),
    queryFn: async ({ pageParam = DEFAULT_TABLE_PAGE }) =>
      await queryOrganizationInvoicesAsyncWithInfiniteScroll.queryFn(selectedOrganizationId, {
        ...queryOptions,
        page: pageParam,
      }),
    enabled: isLoggedIn && !!selectedOrganizationId,
    staleTime: queryOrganizationInvoicesAsyncWithInfiniteScroll.staleTime,
    getNextPageParam: lastPage => (lastPage?.hasNextPage ? lastPage.pageIndex + 1 : null),
  })
}

export const useGetInvoiceDetailsAsyncQuery = <TResult = OrganizationInvoiceQueryResult>(
  invoiceId?: string,
  options: Omit<UseQueryOptions<OrganizationInvoiceQueryResult, Error, TResult>, 'queryKey'> = {}
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganizationId = useSelectedOrganizationId()

  return useQuery({
    queryKey: invoiceDetailsAsyncQueryOptions.queryKey(selectedOrganizationId, invoiceId),
    queryFn: async () =>
      await invoiceDetailsAsyncQueryOptions.queryFn(selectedOrganizationId, invoiceId),
    enabled: isLoggedIn && !!selectedOrganizationId && !!invoiceId,
    staleTime: invoiceDetailsAsyncQueryOptions.staleTime,
    ...options,
  })
}

export const useDownloadInvoiceQuery = <TResult = File>(
  invoiceId?: string,
  options: Omit<UseQueryOptions<File, Error, TResult>, 'queryKey'> = {}
) => {
  const selectedOrganizationId = useSelectedOrganizationId()

  return useQuery({
    queryKey: downloadInvoiceAsyncQueryOptions.queryKey(selectedOrganizationId, invoiceId),
    queryFn: async () =>
      await downloadInvoiceAsyncQueryOptions.queryFn(selectedOrganizationId, invoiceId),
    enabled: false,
    staleTime: downloadInvoiceAsyncQueryOptions.staleTime,
    ...options,
  })
}

export const useGetPaymentMethodsByOrganizationIdQuery = <
  TResult = OrganizationPaymentMethodResponse[],
>(
  options: Omit<
    UseQueryOptions<OrganizationPaymentMethodResponse[], Error, TResult>,
    'queryKey'
  > = {}
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganizationId = useSelectedOrganizationId()

  return useQuery({
    queryKey: paymentMethodsByOrganizationIdQueryOptions.queryKey(selectedOrganizationId),
    queryFn: async () =>
      await paymentMethodsByOrganizationIdQueryOptions.queryFn(selectedOrganizationId),
    enabled: isLoggedIn && !!selectedOrganizationId,
    staleTime: paymentMethodsByOrganizationIdQueryOptions.staleTime,
    ...options,
  })
}

export const usePayInvoiceMutation = (invoiceId?: string, displayInvoiceNumber?: string) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const organizationId = useSelectedOrganizationId()

  return useMutation({
    mutationKey: payInvoiceMutation.mutationKey(organizationId, invoiceId),
    mutationFn: async (data: Partial<PayOrganizationInvoiceCommand>) => {
      if (
        !organizationId ||
        !invoiceId ||
        !data?.amount?.amount ||
        !data?.amount?.currency ||
        !data?.paymentMethodId
      ) {
        throw missingParametersError()
      }

      return await OrganizationInvoicesService.payOrganizationInvoice({
        organizationId,
        invoiceId,
        command: {
          paymentMethodId: data.paymentMethodId,
          amount: {
            amount: data.amount.amount,
            currency: data.amount.currency,
          },
        },
      })
    },
    onSuccess: async () => {
      successNotification(
        t('ssp:pages.invoices.payment.successMessage', { invoiceId: displayInvoiceNumber })
      )

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryOrganizationInvoicesAsync.queryKey(organizationId),
        }),
        queryClient.invalidateQueries({
          queryKey: invoiceDetailsAsyncQueryOptions.queryKey(organizationId, invoiceId),
        }),
      ])
    },
    onError: () => {},
  })
}
