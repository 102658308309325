import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export type CardProps = {
  padded?: boolean
} & ReactDiv

const Card: React.FC<CardProps> = ({ children, padded = true, className = '', ...divProps }) => {
  return (
    <div
      {...divProps}
      className={mergeClassName(
        'rounded-xl bg-gradient-to-b from-white/60 to-white/50  shadow-[0px_4px_24px_#00000012,inset_0px_-1px_1px_#ffffff20,inset_0px_1px_1px_#ffffff40] backdrop-blur-[32px] backdrop-brightness-[100%]  border-[rgba(255,255,255,0.18)]',
        'dark:bg-none dark:bg-slate-900 dark:text-white dark:backdrop-blur-none dark:border dark:border-slate-800 dark:shadow-none',
        padded && 'p-4 md:p-8 border-1 border-solid border-white',
        className
      )}
    >
      {children}
    </div>
  )
}

export default Card
