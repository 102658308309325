import { showApiErrorToast } from '@sherweb/core/components/ToastNotifications'

import { useTestWebHookConnection } from '@rsp/modules/notification'
import { useSetOrderSettings } from '@rsp/modules/reseller'

import { OrderSettingsFormType } from './useOrderSettingsSchema'

type UseSubmitOrderSettingsProps = {
  webHookUrl?: string
}

export const useSubmitOrderSettings = ({ webHookUrl }: UseSubmitOrderSettingsProps) => {
  const testWebHookMutation = useTestWebHookConnection()

  const setOrderSettingsMutation = useSetOrderSettings()

  const hasConnectionFailed = !!testWebHookMutation?.data && !testWebHookMutation?.data?.isValid

  const isSaveDisabled = setOrderSettingsMutation.isLoading || testWebHookMutation?.isLoading

  const handleTestWebHookConnection = async (
    isWebHookValid: () => Promise<boolean>,
    teamNotificationsWebHookUrl?: string
  ) => {
    if (await isWebHookValid()) {
      testWebHookMutation.mutate({
        webhookUrl: teamNotificationsWebHookUrl ?? '',
      })
    }
  }

  const handleResetMutationResult = () => testWebHookMutation.reset()

  const handleTestConnectionAndUpdateSettings = async (values: OrderSettingsFormType) => {
    try {
      const { isValid } = await testWebHookMutation.mutateAsync({
        webhookUrl: values.teamNotifications.webHookUrl ?? '',
      })

      if (isValid) {
        setOrderSettingsMutation.mutate(values)
      }
    } catch (error) {
      showApiErrorToast(error)
    }
  }

  const handleSubmit = async (values: OrderSettingsFormType) => {
    const updatedValues: OrderSettingsFormType = {
      ...values,
      emailNotifications: {
        ...values.emailNotifications,
        emails: !values.emailNotifications?.enabled ? [] : values.emailNotifications.emails,
      },
    }

    if (!values.teamNotifications.enabled) {
      handleResetMutationResult()

      setOrderSettingsMutation.mutate({
        ...updatedValues,
        teamNotifications: {
          ...updatedValues.teamNotifications,
          webHookUrl: '',
        },
      })

      return
    }

    if (
      testWebHookMutation?.data?.isValid ??
      webHookUrl === updatedValues.teamNotifications.webHookUrl
    ) {
      setOrderSettingsMutation.mutate(updatedValues)

      return
    }

    await handleTestConnectionAndUpdateSettings(updatedValues)
  }

  return {
    hasConnectionFailed,
    isTestConnectionValid: testWebHookMutation?.data?.isValid,
    isTestConnectionLoading: testWebHookMutation.isLoading,
    isOrderSettingsLoading: setOrderSettingsMutation.isLoading,
    isSaveDisabled,
    onSubmit: handleSubmit,
    onTestWebHookConnection: handleTestWebHookConnection,
    onResetMutationResult: handleResetMutationResult,
  }
}
