import { UseMutationResult } from '@tanstack/react-query'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  HelpDeskApiInformation,
  SetHelpDeskApiInformationCommand,
} from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import {
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import { getErrorCodeFromAxios } from '@sherweb/core/utils/error'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { ApiInformationConfigurationBlock } from './ApiInformationConfigurationBlock'
import { ApiInformationPermissionFailed } from './ApiInformationPermissionFailed'
import { ApiInformationFormType } from './hooks/useApiInformationSchema'

type ApiInformationFormProps = {
  isEditMode?: boolean
  apiInformationMutation?: UseMutationResult<
    HelpDeskApiInformation,
    unknown,
    Omit<SetHelpDeskApiInformationCommand, 'clientId'> & {
      clientId?: string
    },
    unknown
  >
  onEditMode: (apiInformation?: HelpDeskApiInformation) => void
  apiInformation?: HelpDeskApiInformation
}

export const ApiInformationForm = ({
  isEditMode = false,
  apiInformation,
  apiInformationMutation,
  onEditMode,
}: ApiInformationFormProps) => {
  const { t } = useTranslation()

  const form = useFormContext<ApiInformationFormType>()

  const apiInformationError = getErrorCodeFromAxios(apiInformationMutation?.error)

  const [enabled] = form.watch(['enabled'])

  if (!enabled) {
    return null
  }

  return (
    <ApiInformationConfigurationBlock
      className={mergeClassName('md:max-w-md max-w-full dark:bg-slate-950 py-8', {
        'pb-12': !isEditMode,
      })}
    >
      <ApiInformationPermissionFailed apiInformationError={apiInformationError} />
      <FormField
        control={form.control}
        name="companyName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t(
                'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.fields.companyNameLabel'
              )}
            </FormLabel>
            <FormControl>
              <FormInput
                {...field}
                data-testid="inputApiInformationCompanyName"
                disabled={apiInformationMutation?.isLoading}
                placeholder={t(
                  'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.fields.companyNamePlaceholder'
                )}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="publicApiKey"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t(
                'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.fields.publicApiKeyLabel'
              )}
            </FormLabel>
            <FormControl>
              <FormInput
                {...field}
                data-testid="inputApiInformationPublicApiKey"
                disabled={apiInformationMutation?.isLoading}
                placeholder={t(
                  'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.fields.publicApiKeyPlaceholder'
                )}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="privateApiKey"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t(
                'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.fields.privateApiKeyLabel'
              )}
            </FormLabel>
            <FormControl>
              <FormInput
                {...field}
                data-testid="inputApiInformationPrivateApiKey"
                disabled={apiInformationMutation?.isLoading}
                placeholder={t(
                  'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.fields.privateApiKeyPlaceholder'
                )}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="clientId"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t(
                'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.fields.clientIdLabel'
              )}
            </FormLabel>
            <FormControl>
              <FormInput
                {...field}
                data-testid="inputApiInformationClientId"
                disabled={apiInformationMutation?.isLoading}
                placeholder={t(
                  'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.fields.clientIdPlaceholder'
                )}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="url"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t(
                'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.fields.urlLabel'
              )}
            </FormLabel>
            <FormControl>
              <FormInput
                {...field}
                data-testid="inputApiInformationUrl"
                disabled={apiInformationMutation?.isLoading}
                placeholder={t(
                  'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.fields.urlPlaceholder'
                )}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {isEditMode ? (
        <div className="flex justify-end pt-6">
          <Button
            className="px-4 py-2"
            variant={Variant.Outline}
            onClick={() => {
              onEditMode(apiInformation)
            }}
            data-testid="btnApiInformationCancel"
          >
            {t('rsp:actions.cancel')}
          </Button>
        </div>
      ) : null}
    </ApiInformationConfigurationBlock>
  )
}
