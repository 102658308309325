import persistReducer from '@ssp/modules/redux/core/persistReducer'

import { ACTION_TYPES, Actions } from './reseller.actions'

export const key = 'reseller'
export const version = 1
export const persistFields = ['selectedReseller']

type ReducerState = {
  selectedReseller?: string
}

const initialState: ReducerState = {
  selectedReseller: undefined,
}

const rawReducer = (state = initialState, action: Actions) => {
  if (action.type === ACTION_TYPES.SET_SELECTED_RESELLER) {
    return {
      ...state,
      selectedReseller: action.id,
    }
  }

  return state
}

export const reducer = persistReducer(rawReducer, {
  key,
  shared: true,
  version,
  persistFields,
})
