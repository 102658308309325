import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import config from '@config'

import { useCurrentUser } from '../../modules/user'
import { usePrevious } from '../usePrevious'
import { useGetGoogleAnalyticsInstance } from './useLoadGoogleAnalytics.helper'

export const useLoadGoogleAnalytics = () => {
  const location = useLocation()

  const previousLocationPathname = usePrevious(location.pathname)

  const user = useCurrentUser()

  const googleAnalytics = useGetGoogleAnalyticsInstance()

  const isGoogleAnalyticsAvailable =
    !!googleAnalytics && user?.data?.id && !!config.googleAnalyticsTrackingId

  const sendEvent = useCallback(
    (pathname: string) => {
      googleAnalytics?.send({
        hitType: 'pageview',
        page: pathname,
        title: pathname,
      })
    },
    [googleAnalytics]
  )

  useEffect(() => {
    if (isGoogleAnalyticsAvailable) {
      googleAnalytics.initialize(config.googleAnalyticsTrackingId, {
        gaOptions: {
          userId: user?.data?.id,
        },
      })

      sendEvent(location.pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEvent, isGoogleAnalyticsAvailable])

  const sendPageViewEvent = useCallback(() => {
    if (isGoogleAnalyticsAvailable && previousLocationPathname !== location.pathname) {
      sendEvent(location.pathname)
    }
  }, [isGoogleAnalyticsAvailable, location.pathname, previousLocationPathname, sendEvent])

  return {
    sendPageViewEvent,
  }
}
