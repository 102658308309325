import { useQuery } from '@tanstack/react-query'

import { canLocationMakeCallForData } from '../../reactQuery'
import { buildSettings } from './portalMetadata.builder'
import { portalMetadataQuery } from './portalMetadata.queries'
import { PortalMode } from './portalMetadata.utils'

export const usePortalMetadata = ({ portalMode = PortalMode.SSP } = {}) => {
  return useQuery({
    queryKey: portalMetadataQuery.queryKey(portalMode),
    queryFn: async () => await portalMetadataQuery.queryFn(portalMode),
    staleTime: portalMetadataQuery.staleTime,
    select: data => buildSettings(data),
    enabled: canLocationMakeCallForData(),
  })
}
