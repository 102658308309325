import { getMutationKey } from '@sherweb/core/modules/reactQuery'

export const payInvoiceMutation = {
  mutationKey: (organizationId?: string, invoiceId?: string) =>
    getMutationKey([
      'OrganizationInvoicesService/payOrganizationInvoice',
      organizationId,
      invoiceId,
    ]),
}
