import * as z from 'zod'

import i18n from '@i18n'

import { UserRole } from '@sherweb/core/openapi-generated/index.defs'

const VALIDATION_MINIMUM_TRANSLATION_KEY = 'ssp:validations.string.min'
const VALIDATION_MAXIMUM_TRANSLATION_KEY = 'ssp:validations.string.max'

const userFormSchema = z.object({
  firstName: z
    .string()
    .min(2, {
      message: `${i18n.t(VALIDATION_MINIMUM_TRANSLATION_KEY, { path: i18n.t('ssp:pages.user.form.fields.firstName.label'), min: 2 })}`,
    })
    .max(30, {
      message: `${i18n.t(VALIDATION_MAXIMUM_TRANSLATION_KEY, { path: i18n.t('ssp:pages.user.form.fields.firstName.label'), max: 30 })}`,
    }),
  lastName: z
    .string()
    .min(2, {
      message: `${i18n.t(VALIDATION_MINIMUM_TRANSLATION_KEY, { path: i18n.t('ssp:pages.user.form.fields.lastName.label'), min: 2 })}`,
    })
    .max(30, {
      message: `${i18n.t(VALIDATION_MAXIMUM_TRANSLATION_KEY, { path: i18n.t('ssp:pages.user.form.fields.lastName.label'), max: 30 })}`,
    }),
  jobTitle: z
    .string()
    .min(2, {
      message: `${i18n.t(VALIDATION_MINIMUM_TRANSLATION_KEY, { path: i18n.t('ssp:pages.user.form.fields.jobTitle.label'), min: 2 })}`,
    })
    .max(30, {
      message: `${i18n.t(VALIDATION_MAXIMUM_TRANSLATION_KEY, { path: i18n.t('ssp:pages.user.form.fields.jobTitle.label'), max: 30 })}`,
    }),
  role: z.nativeEnum(UserRole),
  email: z.string().email({
    message: `${i18n.t('ssp:validations.string.email', { path: i18n.t('ssp:pages.user.form.fields.email.label') })}`,
  }),
  preferredCulture: z.string().min(1, {
    message: `${i18n.t('ssp:validations.mixed.required', { path: i18n.t('ssp:pages.user.form.fields.preferredCulture.label') })}`,
  }),
})

export default userFormSchema
