import {
  ImageResultDto,
  ImageType,
  OrderSettingsQueryResult,
  ResellerSettingsQueryResult,
  ResellersQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import { Reseller } from './reseller.models'

const setResellerSettings = (resellerSettings?: ResellerSettingsQueryResult) => {
  if (!resellerSettings) {
    return {
      priceVisible: false,
      isSubresellerShown: false,
      showUsers: false,
      showBilling: false,
      showLicenses: false,
      showHelpDesk: false,
      showSubscriptions: false,
      orgAdminCanCreateUsers: false,
      allowSubscriptionCancellation: false,
      showMarketplace: false,
    }
  }

  return {
    priceVisible: resellerSettings?.priceVisible,
    isSubresellerShown: resellerSettings?.isSubresellerShown,
    showUsers: resellerSettings?.showUsers,
    showBilling: resellerSettings?.showBilling,
    showLicenses: resellerSettings?.showLicenses,
    showSubscriptions: resellerSettings?.showSubscriptions,
    showHelpDesk: resellerSettings?.showHelpDesk,
    orgAdminCanCreateUsers: resellerSettings?.orgAdminCanCreateUsers,
    allowSubscriptionCancellation: resellerSettings?.allowSubscriptionCancellation,
    showMarketplace: resellerSettings?.showMarketplace,
  }
}

export const setOrderSettings = (orderSettings?: OrderSettingsQueryResult) => {
  if (!orderSettings) {
    return {
      isOrderApprovalRequired: false,
      teamNotifications: {
        enabled: false,
        webHookUrl: '',
      },
      emailNotifications: {
        enabled: false,
        allUsersEnabled: false,
        emails: [],
      },
    }
  }

  return {
    isOrderApprovalRequired: orderSettings?.isOrderApprovalRequired,
    teamNotifications: {
      enabled: orderSettings?.teamsNotificationsEnabled,
      webHookUrl: orderSettings?.teamsIncomingWebhook,
    },
    emailNotifications: {
      enabled: orderSettings?.emailNotificationsEnabled,
      allUsersEnabled: orderSettings?.emailRecipientsList?.length === 0,
      emails: orderSettings?.emailRecipientsList,
    },
  }
}

export const buildReseller = (response?: ResellersQueryResult): Reseller => {
  const addHash = (url?: string, hash?: number) => {
    if (url && hash) {
      return `${url}?${hash}`
    }
    return url
  }

  return {
    id: response?.id ?? '',
    name: response?.name ?? '',
    isOnboarded: response?.isOnboarded ?? false,
    portalSettings: {
      logo: addHash(
        getImageByType(response?.portalSettings?.images, ImageType.Logo)?.url,
        response?.portalSettings?.logoHash
      ),
      favicon: addHash(
        getImageByType(response?.portalSettings?.images, ImageType.Icon)?.url,
        response?.portalSettings?.logoHash
      ),
      logoHash: response?.portalSettings?.logoHash,
      theme: response?.portalSettings?.theme,
    },
    supportInformation: {
      address: response?.supportInformation?.address,
      email: response?.supportInformation?.email,
      phoneNumber: response?.supportInformation?.phoneNumber,
    },
    resellerSettings: setResellerSettings(response?.resellerSettings),
    orderSettings: setOrderSettings(response?.orderSettings),
  }
}

export const getImageByType = (images: ImageResultDto[] | undefined, type: ImageType) => {
  return images?.find(p => p.type === type)
}
