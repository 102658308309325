import { EyeIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { OrganizationInvoiceQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'

type InvoicesTableActionRowProps = {
  invoice?: OrganizationInvoiceQueryResult
}

export const InvoicesTableActionRow = ({ invoice }: InvoicesTableActionRowProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-center">
      <Button asChild variant={Variant.Outline} size="sm" data-testid="btnInvoicePay">
        <Link
          to={Routes.InvoiceDetail}
          params={{
            invoiceId: invoice?.id,
          }}
        >
          <EyeIcon className="mr-1 h-5 w-5" />
          {t('ssp:pages.invoices.list.actions.viewInvoiceDetail')}
        </Link>
      </Button>
    </div>
  )
}
