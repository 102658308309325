import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetConnectWiseTicketStatusMappingsQuery } from '@rsp/modules/integrations/helpdesk'

import { getSelectedTicketStatusMapping } from '../boardConfiguration.helper'
import { BoardConfigurationFormType } from './useBoardConfigurationSchema'

export const useGetFilteredTicketStatusMapping = () => {
  const form = useFormContext<BoardConfigurationFormType>()

  const [serviceBoard, ticketStatusMappingList] = form.watch([
    'serviceBoard',
    'ticketStatusMappingList',
  ])

  const connectWiseTicketStatusMappingsQuery =
    useGetConnectWiseTicketStatusMappingsQuery(serviceBoard)

  return useCallback(
    (value: string[] | undefined) => {
      return connectWiseTicketStatusMappingsQuery?.data?.integrationStatuses?.filter(
        connectWiseTicketStatusMapping => {
          const isIntegrationTicketStatusIncluded = getSelectedTicketStatusMapping(
            ticketStatusMappingList,
            serviceBoard
          ).includes(connectWiseTicketStatusMapping?.integrationTicketStatusId)

          if (!isIntegrationTicketStatusIncluded) {
            return connectWiseTicketStatusMapping
          }

          if (
            value?.length !== 0 &&
            value?.includes(connectWiseTicketStatusMapping?.integrationTicketStatusId ?? '')
          ) {
            return connectWiseTicketStatusMapping
          }

          return undefined
        }
      )
    },
    [
      connectWiseTicketStatusMappingsQuery?.data?.integrationStatuses,
      serviceBoard,
      ticketStatusMappingList,
    ]
  )
}
