import {
  Product,
  Currency,
  Category,
  Offer,
  BillingCycle,
  CommitmentTermType,
  PricingModelType,
  ProductSearchCommand,
  ProductSearchFilter,
  SearchProductsResponse,
  SimpleProduct,
  Money,
  Facet,
  FacetCount,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ProductsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getProductById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Product> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Products/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static searchProducts(
    params: {
      /**  */
      command: ProductSearchCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchProductsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Products/search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
