import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Dispatch, SetStateAction } from 'react'

import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import List from '../../List'
import { Typography } from '../../Typography'
import { CollapsibleColumn } from './types'

type CollapsibleDataTriggerRowProps<TData, TNestedData> = {
  columns: CollapsibleColumn<TData, TNestedData>
  data: TData
  setData: Dispatch<SetStateAction<TData[]>>
}

export const CollapsibleDataTriggerRow = <TData, TNestedData>({
  columns,
  data,
  setData,
}: CollapsibleDataTriggerRowProps<TData, TNestedData>) => {
  const hasCheckbox = columns.find(col => col.id === 'select')

  return (
    <>
      {columns.map(column => {
        if (column.id === 'select' && typeof column.header === 'function') {
          return (
            <List.Item
              key={`collapse-trigger-select-${String(column.accessorKey ?? column.id)}`}
              className="first:border-1 relative col-span-2 flex items-center justify-center px-2 first:rounded-none first:border-solid hover:cursor-pointer"
            >
              {column?.cell?.({
                data: data as any,
                setData,
                isCollapsible: true,
              })}
              <ChevronRightIcon className="absolute left-[12px] h-4 w-4 group-data-[state=open]:hidden lg:left-8" />
              <ChevronDownIcon className="absolute left-[12px] h-4 w-4 group-data-[state=closed]:hidden lg:left-8" />
            </List.Item>
          )
        }

        return (
          <List.Item
            key={`collapse-trigger-row-${String(column.accessorKey ?? column.id)}`}
            className={mergeClassName(
              'first:border-1 first:rounded-none first:border-solid col-span-2 px-2 py-4 md:px-8 hover:cursor-pointer',
              column.className
            )}
          >
            <Typography variant="body2" as="div" className="relative">
              {column?.render
                ? column?.render({
                    accessorKey: column?.accessorKey,
                    parentData: data,
                  })
                : null}
              {!column?.render && column?.accessorKey ? (data as any)?.[column?.accessorKey] : ''}
            </Typography>
            {!hasCheckbox ? (
              <div className="items-center">
                <ChevronRightIcon className="absolute left-[12px] h-4 w-4 group-data-[state=open]:hidden" />
                <ChevronDownIcon className="absolute left-[12px] h-4 w-4 group-data-[state=closed]:hidden" />
              </div>
            ) : null}
          </List.Item>
        )
      })}
    </>
  )
}
