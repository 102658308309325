import { ApiInformationFormType } from './hooks/useApiInformationSchema'

export const DEFAULT_VALUES: ApiInformationFormType = {
  enabled: false,
  companyName: '',
  clientId: '',
  privateApiKey: '',
  publicApiKey: '',
  url: '',
}

export const API_INFORMATION_HELP_LINK =
  'https://docs.connectwise.com/ConnectWise_Documentation/090/040/010/010/060'
