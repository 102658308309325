import { cva } from 'class-variance-authority'

export const spinnerStyles = cva('', {
  variants: {
    type: {
      layOverBackground: 'bg-background/80 pointer-events-none opacity-50 backdrop-blur-sm',
      layOverSpinner: 'absolute left-[calc(50%-3rem)] top-[calc(50%)] z-50',
    },
  },
})
