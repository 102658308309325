import { OrganizationPaymentMethodResponse } from '@sherweb/core/openapi-generated/index.defs'

import List from '@sherweb/core/components/List'

import { useGetPaymentMethodsByOrganizationIdQuery } from '@ssp/modules/invoices/core/invoices.hooks'

import { InvoiceDetailPaymentCard } from './InvoiceDetailPaymentCard'

export type InvoiceDetailPaymentMethodContainerProps = {
  selectedCardId: string | undefined
  setSelectedCardId: (cardId: string) => void
}

export const InvoiceDetailPaymentMethodContainer = ({
  selectedCardId,
  setSelectedCardId,
}: InvoiceDetailPaymentMethodContainerProps) => {
  const paymentMethodsByOrganizationIdQuery = useGetPaymentMethodsByOrganizationIdQuery()

  const handleSelectPaymentMethod = (currentCard: OrganizationPaymentMethodResponse) => {
    setSelectedCardId(currentCard.id)
  }

  return (
    <List className="mt-1 w-full">
      {paymentMethodsByOrganizationIdQuery?.data?.map(paymentMethod => (
        <InvoiceDetailPaymentCard
          key={paymentMethod.id}
          selectedPaymentMethodId={selectedCardId}
          paymentMethod={paymentMethod}
          onSelectPaymentMethod={handleSelectPaymentMethod}
        />
      ))}
    </List>
  )
}
