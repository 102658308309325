// Based on https://shadcnui-expansions.typeart.cc/docs/autosize-textarea#Installation

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { Textarea } from './Textarea'
import { useAutosizeTextArea } from './useAutosizeTextArea'

export type AutosizeTextAreaRef = {
  textArea: HTMLTextAreaElement
  maxHeight: number
  minHeight: number
}

interface AutosizeTextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  maxHeight?: number
  minHeight?: number
}

export const AutosizeTextarea = forwardRef<AutosizeTextAreaRef, AutosizeTextAreaProps>(
  (
    {
      maxHeight = Number.MAX_SAFE_INTEGER,
      minHeight = 52,
      className,
      onChange,
      value,
      ...props
    }: AutosizeTextAreaProps,
    ref: React.Ref<AutosizeTextAreaRef>
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

    const [triggerAutoSize, setTriggerAutoSize] = useState('')

    useAutosizeTextArea({
      textAreaRef: textAreaRef.current,
      triggerAutoSize,
      maxHeight,
      minHeight,
    })

    useImperativeHandle(ref, () => ({
      textArea: textAreaRef.current as HTMLTextAreaElement,
      focus: () => textAreaRef.current?.focus(),
      maxHeight,
      minHeight,
    }))

    useEffect(() => {
      setTriggerAutoSize(value as string)
    }, [props?.defaultValue, value])

    return (
      <Textarea
        {...props}
        value={value}
        ref={textAreaRef}
        className={className}
        onChange={e => {
          setTriggerAutoSize(e.target.value)
          onChange?.(e)
        }}
      />
    )
  }
)

AutosizeTextarea.displayName = 'AutosizeTextarea'
