import { useEffect, useState } from 'react'
import { Navigate } from 'react-router'
import StepWizard from 'react-step-wizard'

import Spinner from '@sherweb/core/components/Spinner/Spinner'

import { protectPage } from '@rsp/app/ProtectedPage'
import { LanguageSelector } from '@rsp/components/LanguageSelector'
import { useReseller } from '@rsp/modules/reseller'

import Routes from '@ssp/app/Routes'

import BootstrapingStep from './BootstrapingStep'
import PortalConfigurationStep from './PortalConfigurationStep'
import ServiceProviderConfigurationStep from './ServiceProviderConfigurationStep'
import Steps from './Steps'
import SupportInformationStep from './SupportInformationStep'

const OnboardingPage = () => {
  const [activeStep, setActiveStep] = useState(0)

  const [serviceProviderStepValid, setServiceProviderStepValid] = useState(false)

  const [isFormValid, setIsFormValid] = useState(false)

  const { isLoading, data: reseller } = useReseller()

  const handleStepChange = (stepChange: { previousStep: number; activeStep: number }) => {
    setActiveStep(stepChange.activeStep - 1)
  }

  useEffect(() => {
    setIsFormValid(serviceProviderStepValid)
  }, [serviceProviderStepValid])

  if (reseller?.isOnboarded) {
    return <Navigate replace to={Routes.Root} />
  }

  if (isLoading || !reseller) {
    return <Spinner floating />
  }

  return (
    <div className="flex h-screen w-full justify-center dark:bg-slate-950">
      <div className="m-2 flex h-fit max-w-xl flex-col gap-12 rounded-md dark:border dark:border-slate-800 dark:bg-slate-900 sm:m-4 md:mx-auto md:my-8">
        <div className="p-4 sm:p-8">
          <div className="fixed left-0 top-0 flex w-full justify-end px-8 py-4 shadow-sm md:px-4">
            <LanguageSelector />
          </div>
          <div className="flex flex-col gap-8">
            <StepWizard
              onStepChange={handleStepChange}
              isHashEnabled
              nav={
                <Steps
                  activeStep={activeStep}
                  serviceProviderStepValid={serviceProviderStepValid}
                />
              }
            >
              <PortalConfigurationStep reseller={reseller} hashKey="portalConfiguration" />
              <ServiceProviderConfigurationStep
                setValid={setServiceProviderStepValid}
                hashKey="serviceProvider"
              />
              <SupportInformationStep
                reseller={reseller}
                isFormValid={isFormValid}
                hashKey="supportInformation"
              />
              <BootstrapingStep hashKey="bootstraping" />
            </StepWizard>
          </div>
        </div>
      </div>
    </div>
  )
}

export default protectPage(OnboardingPage)
