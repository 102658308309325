const COLOR_WHITE = '#ffffff!important'
const COLOR_INDIGO = '#4f46e5!important'

export const ligthModeStyle = {
  layout: {
    root: {
      background: 'rgba(255, 255, 255, 0.5)',
      backdropFilter: 'blur(32px) brightness(1)',
      padding: '8px',
    },
  },
  unseenBadge: {
    root: { color: COLOR_WHITE, background: COLOR_INDIGO },
  },
  tabs: {
    tabLabel: {
      '::after': { background: COLOR_INDIGO },
    },
  },
  bellButton: {
    dot: {
      rect: {
        fill: COLOR_INDIGO,
        strokeWidth: '0',
      },
    },
  },
  notifications: {
    listItem: {
      unread: {
        fontWeight: '300',
        fontSize: '14px',
        ':before': {
          background: 'transparent',
        },
        color: '#0f172a',
      },
      read: {
        background: '#f1f5f9',
      },
      timestamp: {
        color: '#0f172a',
        marginTop: '4px',
      },
      dotsButton: {
        stopColor: COLOR_INDIGO,
      },
    },
  },
}

export const darkModeStyle = {
  layout: {
    root: {
      background: 'rgb(15, 23, 42)',
      backdropFilter: 'blur(32px) brightness(1)',
      border: '1px solid rgb(30, 41, 59)',
      padding: '8px',
    },
  },
  unseenBadge: {
    root: { color: COLOR_WHITE, background: COLOR_INDIGO },
  },
  tabs: {
    tabLabel: {
      '::after': { background: COLOR_INDIGO },
    },
  },
  bellButton: {
    dot: {
      rect: {
        fill: COLOR_WHITE,
        strokeWidth: '2',
        stroke: '#828299',
      },
    },
  },
  notifications: {
    listItem: {
      unread: {
        fontWeight: '300',
        fontSize: '14px',
        ':before': {
          background: 'transparent',
        },
        background: 'rgb(2, 6, 23)',
        border: '1px solid #94a3b8',
      },
      read: {
        background: 'rgb(2, 6, 23)',
      },
      timestamp: {
        color: '#828299',
        marginTop: '4px',
      },
      dotsButton: {
        stopColor: COLOR_INDIGO,
      },
    },
  },
}

export enum NotificationsScheme {
  Light = 'light',
  Dark = 'dark',
}

export const getStyle = (scheme?: NotificationsScheme) => {
  switch (scheme) {
    case NotificationsScheme.Dark:
      return darkModeStyle
    case NotificationsScheme.Light:
    default:
      return ligthModeStyle
  }
}
