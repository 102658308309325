import * as z from 'zod'

export type OrganizationDetailFormType = z.infer<ReturnType<typeof useOrganizationDetailSchema>>

const useOrganizationDetailSchema = () => {
  return z.object({
    allowSubscriptionCancellation: z.boolean(),
    isOrderApprovalRequired: z.boolean(),
    isPriceVisible: z.boolean(),
    orgAdminCanCreateUsers: z.boolean(),
    showSubscriptions: z.boolean(),
    showUsers: z.boolean(),
    showLicenses: z.boolean(),
    showHelpDesk: z.boolean(),
    showBilling: z.boolean(),
    showMarketplace: z.boolean(),
  })
}

export default useOrganizationDetailSchema
