import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess } from '@ssp/modules/authorization'

export const HelpDeskTicketEmptyContainer = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-4">
      <HasAccess permission={Permission.HelpDeskEdit}>
        <div className="flex w-full justify-end">
          <Button variant="primary" data-testid="btnHelpDeskCreateTicket" size="sm" asChild>
            <Link
              to={Routes.HelpDeskCreate}
              className="rounded-lg focus-visible:outline-indigo-300"
            >
              {t('ssp:pages.helpdesk.create.newRequest')}
            </Link>
          </Button>
        </div>
      </HasAccess>
      <div
        className="flex w-full flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-300 p-8 font-normal text-gray-400 md:h-60"
        data-testid="containerNoTickets"
      >
        <ArchiveBoxXMarkIcon className="h-16 w-16 stroke-1 text-gray-400" />
        <p>{t('ssp:pages.helpdesk.emptyMessage')}</p>
      </div>
    </div>
  )
}
