import { useCallback } from 'react'
import isEqual from 'react-fast-compare'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  BillingCycle,
  CommitmentTermType,
  Facet,
  FacetCount,
  SearchProductsResponse,
} from '@sherweb/core/openapi-generated/index.defs'

import Routes from '@ssp/app/Routes'
import { usePathGenerator } from '@ssp/modules/navigation'
import { useSelectedOrganization } from '@ssp/modules/organization'

export const useGetShopRouteParams = (params?: Record<string, string | undefined>) => {
  const organization = useSelectedOrganization()

  const organizationUniqueName = organization?.uniqueName

  if (params && organizationUniqueName) {
    return { organizationUniqueName, ...params }
  }

  if (organizationUniqueName) {
    return { organizationUniqueName }
  }
}

export const useNavigateToShopPage = () => {
  const generatePath = usePathGenerator()

  const navigate = useNavigate()

  const shopRouteParams = useGetShopRouteParams()

  return useCallback(
    (searchParams?: string) => {
      if (shopRouteParams?.organizationUniqueName) {
        navigate({
          pathname: generatePath(Routes.Shop, {
            organizationUniqueName: shopRouteParams?.organizationUniqueName,
          }),
          search: searchParams,
        })
      }
    },
    [generatePath, shopRouteParams?.organizationUniqueName, navigate]
  )
}

export const useBillingCycleTranslation = () => {
  const { t } = useTranslation()
  return (value?: BillingCycle | null) => {
    if (!value) {
      return ''
    }

    return t(`ssp:pages.shop.detail.billingCycleValues.${value}`)
  }
}

export const useCommitmentTermTranslation = () => {
  const { t } = useTranslation()
  return (value?: CommitmentTermType | null) => {
    if (!value) {
      return ''
    }

    return t(`ssp:pages.shop.detail.commitmentTermValues.${value}`)
  }
}

export const useContractDurationCommitmentTermTranslation = () => {
  const { t } = useTranslation()
  return (value?: CommitmentTermType | null) => {
    if (!value) {
      return ''
    }

    return t(`ssp:pages.shop.detail.contractDurationCommitmentTermValues.${value}`)
  }
}

export const useBillingCycleSummaryTranslation = () => {
  const { t } = useTranslation()
  return (value?: BillingCycle | null) => {
    if (!value) {
      return ''
    }

    return t(`ssp:pages.shop.detail.commitmentSummary.billingCycleValues.${value}`)
  }
}

export const useBillingCyclePeriodSummaryTranslation = () => {
  const { t } = useTranslation()
  return (value?: BillingCycle | null) => {
    if (!value) {
      return ''
    }

    return t(`ssp:pages.shop.detail.commitmentSummary.billingCyclePeriodValues.${value}`)
  }
}

export const useCommitmentTermSummaryTranslation = () => {
  const { t } = useTranslation()
  return (value?: CommitmentTermType | null) => {
    if (!value) {
      return ''
    }

    return t(`ssp:pages.shop.detail.commitmentSummary.commitmentTermValues.${value}`)
  }
}

export const getMergedFacets = (initialFacets?: Facet[], latestFacets?: Facet[]) => {
  if (!latestFacets) {
    return undefined
  }

  if (isEqual(initialFacets, latestFacets)) {
    return latestFacets
  }

  const mergedMap = new Map<string, FacetCount[]>()

  const getMergedValues = (newFacets: FacetCount[], existingFacets?: FacetCount[]) => {
    const newFacetsMapper = new Map(newFacets?.map(facet => [facet.value, facet]))

    return existingFacets?.map(facet => {
      const newItem = newFacetsMapper.get(facet.value)

      if (newItem) {
        return newItem
      }

      return { ...facet, count: 0 }
    })
  }

  initialFacets?.forEach(facet => {
    if (facet?.fieldName && facet.countsByValue) {
      mergedMap.set(facet.fieldName, [...facet.countsByValue])
    }
  })

  latestFacets?.forEach(facet => {
    if (facet?.fieldName && facet.countsByValue) {
      const existingFacets = mergedMap.get(facet.fieldName)

      const mergedFacets = getMergedValues(facet.countsByValue, existingFacets)

      if (mergedFacets) {
        mergedMap.set(facet.fieldName, mergedFacets)
      }
    }
  })

  return Array.from(mergedMap, ([fieldName, countsByValue]) => ({
    fieldName,
    countsByValue,
  }))
}

export const getNextPageParamForProducts = (lastPage: SearchProductsResponse, pageSize: number) => {
  return Math.ceil(lastPage.foundProducts / pageSize) > lastPage.page ? lastPage.page + 1 : null
}
