import { useDispatch, useSelector } from 'react-redux'

import * as actions from './theme.actions'
import * as selectors from './theme.selectors'

export const useTheme = () => useSelector(selectors.theme)

export const useThemeDarkModePreference = () => useSelector(selectors.darkMode)

export const useThemeDarkModeState = () => {
  const darkModePreference = useThemeDarkModePreference()
  if (darkModePreference !== undefined) {
    return darkModePreference
  }
  try {
    return window.matchMedia?.('(prefers-color-scheme: dark)')?.matches
  } catch (error) {
    /* istanbul ignore next */
    return false
  }
}

export const useThemeActions = () => {
  const dispatch = useDispatch()
  return {
    setTheme: (...args: any) => dispatch(actions.setTheme.apply(null, args)),
    setDarkMode: (...args: any) => dispatch(actions.setDarkMode.apply(null, args)),
  }
}
