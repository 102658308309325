import { useTranslation } from 'react-i18next'

import { Separator } from '@sherweb/core/components/Separator'
import { SheetPage } from '@sherweb/core/components/Sheet'
import { Typography } from '@sherweb/core/components/Typography'
import { OrderStatus } from '@sherweb/core/modules/orders/ui/OrderStatus'
import { OrderType } from '@sherweb/core/modules/orders/ui/OrderType'
import { useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { Order } from '../core/orders.model'
import { OrderCreator } from './OrderCreator'

type OrderDetailContentProps = {
  order?: Order
}

export const OrderDetailContent = ({ order }: OrderDetailContentProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language)

  const formatMoneyAmount = useMoneyFormatter(language)

  const hasComment = Boolean(order?.error?.message ?? order?.decisionNote)

  const hasServiceProviderError = Boolean(order?.error?.title)

  if (!order) {
    return null
  }

  return (
    <>
      <Separator className="bg-slate-300 dark:bg-slate-700" />
      <SheetPage.Layout>
        <SheetPage.Row className="gap-x-4">
          <SheetPage.Column>
            <SheetPage.Content>
              <SheetPage.ContentLabel>
                {t('core:orders.detail.organizationName')}
              </SheetPage.ContentLabel>
              <SheetPage.ContentValue>{order?.organizationUniqueName}</SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content className="mt-2">
              <SheetPage.ContentLabel>{t('core:orders.detail.createdOn')}</SheetPage.ContentLabel>
              <SheetPage.ContentValue>{formatDate(order?.createdOn)}</SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content>
              <SheetPage.ContentLabel>{t('core:orders.detail.orderValue')}</SheetPage.ContentLabel>
              <SheetPage.ContentValue>{formatMoneyAmount(order.orderValue)}</SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content>
              <SheetPage.ContentLabel>{t('core:orders.detail.productName')}</SheetPage.ContentLabel>
              <SheetPage.ContentValue>{order?.firstProductName}</SheetPage.ContentValue>
            </SheetPage.Content>
          </SheetPage.Column>
          <SheetPage.Column>
            <SheetPage.Content>
              <SheetPage.ContentLabel>{t('core:orders.detail.createdBy')}</SheetPage.ContentLabel>
              <SheetPage.ContentValue>
                <OrderCreator
                  firstName={order?.userFirstName}
                  lastName={order?.userLastName}
                  email={order?.userEmail}
                />
              </SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content>
              <SheetPage.ContentLabel>{t('core:orders.detail.status')}</SheetPage.ContentLabel>
              <SheetPage.ContentValue>
                <OrderStatus orderStatus={order?.orderStatus} />
              </SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content>
              <SheetPage.ContentLabel>{t('core:orders.detail.type')}</SheetPage.ContentLabel>
              <SheetPage.ContentValue>
                <OrderType orderType={order?.orderType} />
              </SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content>
              <SheetPage.ContentLabel>{t('core:orders.detail.quantity')}</SheetPage.ContentLabel>
              <SheetPage.ContentValue>{order?.firstQuantity}</SheetPage.ContentValue>
            </SheetPage.Content>
          </SheetPage.Column>
        </SheetPage.Row>
      </SheetPage.Layout>
      <Separator className="bg-slate-300 dark:bg-slate-700" />
      <SheetPage.Layout className="flex flex-col gap-y-2">
        <div className="flex flex-col gap-y-6">
          {hasComment ? (
            <div className="flex flex-col gap-y-1">
              <Typography variant="body2" weight="medium">
                {t('core:orders.detail.comment')}
              </Typography>
              <Typography variant="body2">
                {order?.error?.message ?? order?.decisionNote}
              </Typography>
            </div>
          ) : null}
          {hasServiceProviderError ? (
            <div className="flex flex-col gap-y-1">
              <Typography variant="body2" weight="medium" as="div">
                {t('core:orders.detail.serverError')}
              </Typography>
              <Typography variant="body2" as="div">
                {order?.error?.title && !order?.error?.detail?.includes(order?.error.title) && (
                  <p>{decodeURIComponent(encodeURIComponent(order?.error?.title ?? ''))}</p>
                )}
                <p>{decodeURIComponent(encodeURIComponent(order?.error?.detail ?? ''))}</p>
              </Typography>
            </div>
          ) : null}
        </div>
      </SheetPage.Layout>
    </>
  )
}
