import {
  UsersQueryResult,
  UserStatus,
  RoleQueryResult,
  UserRole,
  InviteUserCommand,
  ReInviteUserCommand,
  DeleteUserCommand,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class UsersService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getCurrentUserAsync(options: IRequestOptions = {}): Promise<UsersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Users/current';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static invite(
    params: {
      /**  */
      command: InviteUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UsersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Users/invite';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reInvite(
    params: {
      /**  */
      emailAddress: string;
      /**  */
      command: ReInviteUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UsersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Users/{emailAddress}/reinvite';
      url = url.replace('{emailAddress}', params['emailAddress'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      command: DeleteUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UsersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Users';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
