import { ComponentChildrenType } from '@sherweb/core/types/reactChildrenType'

import { Typography } from '../Typography'

interface SheetPageContentValueProps extends ComponentChildrenType {}

export const SheetPageContentValue = ({ children }: SheetPageContentValueProps) => (
  <Typography variant="body2" as="div" className="mb-2">
    {children}
  </Typography>
)
