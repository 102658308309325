import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { AmendmentOrdersService } from '@sherweb/core/openapi-generated/AmendmentOrdersService'
import {
  OrderSortField,
  PaginatedListOfListOfOrdersQueryResult,
  ProcessOrderCommand,
  ProcessOrderDecision,
} from '@sherweb/core/openapi-generated/index.defs'

import { Pagination } from '@sherweb/core/components/DataTable/types'
import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { buildOrder, buildOrders } from '@sherweb/core/modules/orders/core/orders.builder'
import { getOrdersPaginationDefaultValues } from '@sherweb/core/modules/orders/core/orders.helpers'
import { IPaginatedListOfListOfOrdersQueryResult } from '@sherweb/core/modules/orders/core/orders.model'
import { DEFAULT_TABLE_PAGE } from '@sherweb/core/utils/const'

import { useSelectedResellerId } from '@rsp/modules/reseller'

import {
  ordersByResellerAsync,
  ordersByResellerAsyncQueryPrefix,
  ordersByResellerAsyncWithInfiniteScroll,
  resellerOrderDetailsById,
} from './orders.queries'

export const useGetOrdersQuery = (
  queryOptions: Pagination<OrderSortField> = getOrdersPaginationDefaultValues()
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const resellerId = useSelectedResellerId()

  return useQuery({
    queryKey: ordersByResellerAsync.queryKey(resellerId, queryOptions),
    keepPreviousData: true,
    queryFn: async () => await ordersByResellerAsync.queryFn(resellerId, queryOptions),
    enabled: isLoggedIn && !!resellerId,
    select: data => buildOrders(data),
    staleTime: ordersByResellerAsync.staleTime,
  })
}

export const useGetInfiniteScrollOrderssQuery = (queryOptions: Pagination<OrderSortField>) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedResellerId = useSelectedResellerId()

  return useInfiniteQuery<
    PaginatedListOfListOfOrdersQueryResult,
    Error,
    IPaginatedListOfListOfOrdersQueryResult
  >({
    queryKey: ordersByResellerAsyncWithInfiniteScroll.queryKey(selectedResellerId, queryOptions),
    queryFn: async ({ pageParam = DEFAULT_TABLE_PAGE }) =>
      await ordersByResellerAsyncWithInfiniteScroll.queryFn(selectedResellerId, {
        ...queryOptions,
        page: pageParam,
      }),
    enabled: isLoggedIn && !!selectedResellerId,
    select: data => ({
      ...data,
      pages: data.pages?.map(page => ({
        ...page,
        results: page?.results?.map(order => buildOrder(order)),
      })),
    }),
    staleTime: ordersByResellerAsyncWithInfiniteScroll.staleTime,
    getNextPageParam: lastPage => (lastPage?.hasNextPage ? lastPage.pageIndex + 1 : null),
  })
}

export const useGetOrderQuery = (orderId?: string) => {
  const resellerId = useSelectedResellerId()

  return useQuery({
    queryKey: resellerOrderDetailsById.queryKey(resellerId, orderId),
    queryFn: async () => await resellerOrderDetailsById.queryFn(resellerId, orderId),
    select: data => buildOrder(data),
    enabled: !!resellerId && !!orderId,
    staleTime: resellerOrderDetailsById.staleTime,
  })
}

export const useProcessOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (processOrder: ProcessOrderCommand) => {
      if (!processOrder.decision) {
        throw new Error('Missing decision')
      }

      if (processOrder?.orderIds?.length === 0) {
        throw new Error('Missing order id')
      }

      if (!processOrder.organizationId) {
        throw new Error('Missing organization id')
      }

      return await AmendmentOrdersService.amendmentOrdersProcessOrder({
        command: {
          ...processOrder,
          decision:
            processOrder.decision === ProcessOrderDecision.Approve
              ? ProcessOrderDecision.Approve
              : ProcessOrderDecision.Reject,
        },
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ordersByResellerAsyncQueryPrefix],
      })
    },
  })
}
