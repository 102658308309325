import { useMemo } from 'react'

import {
  BillingCycle,
  CommitmentTermType,
  ProductSort,
} from '@sherweb/core/openapi-generated/index.defs'

import { useQueryParams } from '@sherweb/core/hooks'

import { DEFAULT_FILTER } from '../../shop.const'

export const useGetSearchOptions = () => {
  const { getParams, getArrayParam } = useQueryParams<
    | 'productsQuery'
    | 'productsBillingCycle'
    | 'productsCommitment'
    | 'productsVendor'
    | 'productsCategories'
    | 'productsSortBy'
  >()

  const searchInputValue = getParams<string>('productsQuery')

  const billingCycleSelectedValue = getArrayParam<BillingCycle>('productsBillingCycle')

  const commitmentSelectedValue = getArrayParam<CommitmentTermType>('productsCommitment')

  const vendorSelectedValue = getArrayParam<string>('productsVendor')

  const categorySelectedValue = getArrayParam<string>('productsCategories')

  const allFilters = useMemo(
    () => ({
      ...(billingCycleSelectedValue.length !== 0
        ? {
            billingCycleFilter: billingCycleSelectedValue,
          }
        : {}),
      ...(commitmentSelectedValue.length !== 0
        ? {
            commitmentTermFilter: commitmentSelectedValue,
          }
        : {}),
      ...(vendorSelectedValue.length !== 0
        ? {
            vendorFilter: vendorSelectedValue,
          }
        : {}),
      ...(categorySelectedValue.length !== 0
        ? {
            categoryFilter: categorySelectedValue,
          }
        : {}),
    }),
    [billingCycleSelectedValue, commitmentSelectedValue, vendorSelectedValue, categorySelectedValue]
  )

  const filterProps = useMemo(() => {
    return Object.keys(allFilters).length !== 0
      ? {
          filters: allFilters,
        }
      : {}
  }, [allFilters])

  return useMemo(
    () => ({
      query: searchInputValue ?? '',
      sortBy:
        getParams('productsSortBy') === ''
          ? DEFAULT_FILTER.sortBy
          : getParams<ProductSort>('productsSortBy'),
      ...filterProps,
    }),
    [filterProps, getParams, searchInputValue]
  )
}
