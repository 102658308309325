import { ArrowPathIcon, CalendarDaysIcon, ClockIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { useDateFormatter } from '@sherweb/core/utils/date'

import {
  useCommitmentRenewalTranslation,
  useCycleBillingFullTranslation,
  useSubscription,
} from '@ssp/modules/subscription'

import SummaryItem from './SummaryItem'

type SummaryProps = {
  subscriptionId?: string
  quantity?: number
}

const Summary: React.FC<SummaryProps> = ({ subscriptionId }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const formatDate = useDateFormatter(language)

  const { data: subscription } = useSubscription(subscriptionId)
  const getCycleBillingFullTranslation = useCycleBillingFullTranslation()
  const getCommitmentRenewalTranslation = useCommitmentRenewalTranslation()

  const cycleFull = getCycleBillingFullTranslation(subscription?.billingCycle)
  const commitmentRenewalTranslation = getCommitmentRenewalTranslation(subscription?.commitmentType)
  const commitmentDateRenewalDateTranslation = formatDate(subscription?.renewalDate)
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const commitmentDateRenewalFullTranslation = `${t('ssp:pages.subscription.summary.autoRenewalOn')} ${commitmentDateRenewalDateTranslation}`

  if (!(commitmentRenewalTranslation ?? subscription?.renewalDate ?? cycleFull)) {
    return null
  }

  return (
    <div className="grid w-fit grid-cols-1 gap-2 md:grid-cols-2 md:gap-4">
      {commitmentRenewalTranslation && (
        <SummaryItem description={commitmentRenewalTranslation} Icon={ArrowPathIcon} />
      )}

      {subscription?.renewalDate && (
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        <SummaryItem
          description={commitmentDateRenewalDateTranslation && commitmentDateRenewalFullTranslation}
          Icon={CalendarDaysIcon}
        />
      )}

      {cycleFull && <SummaryItem description={cycleFull} Icon={ClockIcon} />}
    </div>
  )
}

export default Summary
