import { QueryKey } from '@tanstack/react-query'

import { PortalSettingsService } from '@sherweb/core/openapi-generated/PortalSettingsService'
import { ResellersService } from '@sherweb/core/openapi-generated/ResellersService'

export const portalSettingsByHostname = {
  queryKey: (hostname: string): QueryKey => ['portalSettingsByHostname', hostname],
  queryFn: async (hostname: string) =>
    await PortalSettingsService.getPortalSettingsByHostname({ hostname }),
  staleTime: 10 * 60000,
}

export const reseller = {
  queryKey: (hostname: string): QueryKey => ['reseller', hostname],
  queryFn: async (hostname: string) => await ResellersService.getReseller({ hostname }),
  staleTime: 60 * 60 * 1000,
}
