import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import { Form } from '@sherweb/core/components/Form'
import { usePrompt } from '@sherweb/core/components/Prompt'
import Spinner from '@sherweb/core/components/Spinner'

import { protectPage } from '@rsp/app/ProtectedPage'
import { FormSubmitButton } from '@rsp/components/FormSubmitButton'
import { FormSplitScreen } from '@rsp/components/layout/FormSplitScreen'
import {
  useGetSherwebServiceProviderQuery,
  usePostServiceProviderConfigurationMutation,
} from '@rsp/modules/integrations/serviceProvider'

import { useServiceProvider } from './hooks/useSherwebServiceProvider'
import {
  SherwebServiceProviderFormType,
  useSherwebServiceProviderSchema,
} from './hooks/useSherwebServiceProviderSchema'
import { DEFAULT_VALUES } from './sherwebServiceProvider.helper'
import { SherwebServiceProviderCard } from './SherwebServiceProviderCard'
import { SherwebServiceProviderForm } from './SherwebServiceProviderForm'

export const SherwebServiceProvider = () => {
  const sherwebServiceProviderQuery = useGetSherwebServiceProviderQuery()

  const serviceProviderConfigurationMutation = usePostServiceProviderConfigurationMutation()

  const schema = useSherwebServiceProviderSchema()

  const form = useForm<SherwebServiceProviderFormType>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_VALUES,
  })

  const hasData = !!sherwebServiceProviderQuery?.data

  const { isEditMode, setIsEditMode, setFormValues, onResetEditMode } = useServiceProvider({
    form,
  })

  usePrompt({
    isDirty: form.formState.isDirty && isEditMode,
  })

  useEffect(() => {
    if (sherwebServiceProviderQuery?.data) {
      setFormValues(sherwebServiceProviderQuery?.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sherwebServiceProviderQuery?.data])

  useEffect(() => {
    if (serviceProviderConfigurationMutation?.isSuccess) {
      setIsEditMode(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceProviderConfigurationMutation?.isSuccess])

  const onSubmit = (values: SherwebServiceProviderFormType) => {
    serviceProviderConfigurationMutation.mutate(values)
  }

  if (sherwebServiceProviderQuery?.isLoading || !sherwebServiceProviderQuery?.data) {
    return <Spinner floating />
  }

  return (
    <Form {...form}>
      <FormSplitScreen className="!py-3" onSubmit={form.handleSubmit(onSubmit)}>
        {hasData && !isEditMode ? (
          <SherwebServiceProviderCard
            serviceProvider={sherwebServiceProviderQuery?.data}
            onResetEditMode={onResetEditMode}
          />
        ) : (
          <SherwebServiceProviderForm
            isEditMode={isEditMode}
            onResetEditMode={onResetEditMode}
            isLoading={serviceProviderConfigurationMutation?.isLoading}
          />
        )}
        <FormSubmitButton
          showLoader={serviceProviderConfigurationMutation?.isLoading}
          disabled={serviceProviderConfigurationMutation?.isLoading}
        />
      </FormSplitScreen>
    </Form>
  )
}

export default protectPage(SherwebServiceProvider, Permission.ResellerAdmin)
