import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { DebouncedSearchInput } from '@sherweb/core/components/DebouncedSearchInput'
import { useQueryParams } from '@sherweb/core/hooks'

export const MarketplaceProductsSearch = () => {
  const { t } = useTranslation()

  const { getParams, setSearchParams } = useQueryParams<'productsQuery'>()

  const querySearchValue = getParams<string>('productsQuery')

  const handleChange = (inputValue: string) => {
    setSearchParams('productsQuery', inputValue)
  }

  return (
    <div className="relative flex w-full items-center md:mb-0 md:w-1/4 md:min-w-[275px]">
      <MagnifyingGlassIcon
        className="absolute left-3 h-5 w-5 text-gray-500 focus-within:text-gray-600"
        aria-hidden="true"
      />
      <DebouncedSearchInput
        type="text"
        autoFocus
        className="w-full rounded-lg border border-gray-300 py-2 pl-10 text-left text-sm font-normal leading-none text-gray-600 outline-none focus:border-blue-500 focus:ring-blue-500 focus-visible:outline-offset-0 focus-visible:outline-indigo-300 dark:border-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700"
        placeholder={t('rsp:pages.marketplace.addProducts.filters.search')}
        data-testid="inputFilterProducts"
        value={querySearchValue}
        onChange={handleChange}
      />
    </div>
  )
}
