import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { DebouncedSearchInput } from '@sherweb/core/components/DebouncedSearchInput'
import { SheetPage } from '@sherweb/core/components/Sheet'

type MarketplaceCatalogsAssignedOrganizationsHeaderProps = {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
}

export const MarketplaceCatalogsAssignedOrganizationsHeader = ({
  search,
  setSearch,
}: MarketplaceCatalogsAssignedOrganizationsHeaderProps) => {
  const { t } = useTranslation()

  const handleChange = (value: string) => {
    setSearch(value)
  }

  return (
    <SheetPage.Layout className="sticky top-0 px-6 py-0">
      <div className="relative top-0 flex w-full items-center">
        <MagnifyingGlassIcon
          className="absolute left-3 h-5 w-5 text-gray-500 focus-within:text-gray-600"
          aria-hidden="true"
        />
        <DebouncedSearchInput
          type="text"
          className="w-full rounded-lg border border-gray-300 py-2 pl-10 text-left text-sm font-normal leading-none text-gray-600 outline-none focus:border-blue-500 focus:ring-blue-500 focus-visible:outline-offset-0 focus-visible:outline-indigo-300 dark:border-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700"
          placeholder={t('rsp:pages.marketplace.manageOrganizations.searchPlaceholder')}
          value={search}
          onChange={handleChange}
          data-testid="inputFilterTable"
        />
      </div>
    </SheetPage.Layout>
  )
}
