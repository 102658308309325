import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import { protectPage } from '@ssp/app/ProtectedPage'
import ContainedPage from '@ssp/components/layout/ContainedPage/ContainedPage'
import { useNavigate, useParams } from '@ssp/modules/navigation'
import {
  useAvailableOrganizations,
  useAvailableOrganizationsRepository,
  useOrganizationActions,
  useSelectedOrganizationId,
} from '@ssp/modules/organization'
import Error404 from '@ssp/pages/errors/Error404'

const OrganizationDispatcherPage: React.FC = () => {
  const [invalidOrganization, setInvalidOrganization] = useState(false)
  const { navigate } = useNavigate()
  const { organizationUniqueName } = useParams()
  const selectedOrganizationId = useSelectedOrganizationId()
  const { data: organizations } = useAvailableOrganizations()
  const { findById, findByUniqueName } = useAvailableOrganizationsRepository()
  const { setSelectedOrganization } = useOrganizationActions()

  const selectedOrganization = organizationUniqueName
    ? findByUniqueName(organizationUniqueName)
    : undefined

  /**
   * reset state when organization is updated
   */
  useEffect(() => {
    setInvalidOrganization(false)
  }, [organizationUniqueName])

  /**
   * if there is no organization in path, redirect to the proper organization
   */
  useEffect(() => {
    if (!!organizationUniqueName || organizations.length === 0) {
      return
    }
    if (selectedOrganizationId) {
      const savedOrganization = findById(selectedOrganizationId)
      if (savedOrganization) {
        navigate(`/${savedOrganization.uniqueName}`)
        return
      }
    }
    navigate(`/${organizations[0].uniqueName}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationUniqueName])

  /**
   * persist selected organization in redux
   */
  useEffect(() => {
    if (!organizationUniqueName || organizations.length === 0) {
      return
    }
    const selectedOrganization = findByUniqueName(organizationUniqueName) ?? null
    if (selectedOrganization) {
      setSelectedOrganization(selectedOrganization.id)
    } else {
      setInvalidOrganization(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationUniqueName, selectedOrganization])

  if (invalidOrganization || organizations.length === 0) {
    return <Error404 />
  }

  return selectedOrganization ? <Outlet /> : <ContainedPage />
}

export default protectPage(OrganizationDispatcherPage, Permission.AccessToSuspendedOrganization)
