import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import Card from '@sherweb/core/components/Card'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { NoResultsMobile } from '@sherweb/core/components/NoResults/NoResultsMobile'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'
import { useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { useGetInvoicesQuery } from '@ssp/modules/invoices/core/invoices.hooks'
import { InvoiceStatus } from '@ssp/modules/invoices/ui/InvoiceStatus'

import { InvoiceActions } from './InvoiceActions'

export const InvoicesTableMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const invoicesQuery = useGetInvoicesQuery()

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const formatMoneyAmount = useMoneyFormatter(language)

  const filteredInvoices = invoicesQuery?.data?.filter(invoice =>
    invoice.displayInvoiceNumber?.includes(searchText.toLowerCase())
  )

  const formatDate = useDateFormatter(language)

  const renderContent = () =>
    filteredInvoices?.length === 0 ? (
      <NoResultsMobile>{t('ssp:pages.invoices.noInvoice')}</NoResultsMobile>
    ) : (
      filteredInvoices?.map(invoice => (
        <Accordion key={invoice.displayInvoiceNumber} type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
              #{invoice.displayInvoiceNumber} - {formatMoneyAmount(invoice.amountDue)}
              <AccordionTriggerSubContent>
                <InvoiceStatus invoiceStatus={invoice.status} />
              </AccordionTriggerSubContent>
            </AccordionTrigger>
            <AccordionContent>
              <AccordionContentList>
                <AccordionContentListItem
                  label={t('ssp:pages.invoices.list.id')}
                  value={invoice.displayInvoiceNumber}
                />
                <AccordionContentListItem
                  label={t('ssp:pages.invoices.list.dueDate')}
                  value={formatDate(invoice.dueDate)}
                />
                <AccordionContentListItem
                  label={t('ssp:pages.invoices.list.customer')}
                  value={invoice.externalClientName}
                />
                <AccordionContentListItem
                  label={t('ssp:pages.invoices.list.total')}
                  value={formatMoneyAmount(invoice.amountDue)}
                />
                <AccordionContentListItem
                  label={t('ssp:pages.invoices.list.outstanding')}
                  value={formatMoneyAmount(invoice.amountRemaining)}
                />
                <AccordionContentListItem
                  label={t('ssp:pages.invoices.list.status')}
                  value={<InvoiceStatus invoiceStatus={invoice.status} />}
                />
                <AccordionContentListItem
                  className="justify-end"
                  labelClassName="flex flex-wrap"
                  label={<InvoiceActions invoice={invoice} className="flex-wrap" />}
                />
              </AccordionContentList>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ))
    )

  return (
    <Card padded data-testid="mobileLayoutInvoices">
      <div className="flex flex-col gap-4">
        <DataTableMobileHeader>
          <DataTableMobileHeader.Input
            placeholder={t('ssp:pages.invoices.filterPlaceholder')}
            filteredText={searchText}
            setFilteredText={onSetSearchParams}
          />
        </DataTableMobileHeader>
        {isRequestLoading(invoicesQuery) ? <SkeletonTable /> : renderContent()}
      </div>
    </Card>
  )
}

export default InvoicesTableMobile
