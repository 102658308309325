// based on: https://ui.shadcn.com/docs/components/popover#installation

import * as PopoverPrimitive from '@radix-ui/react-popover'
import { forwardRef } from 'react'

import { mergeClassName } from '../../utils/mergeClassName'

export const Popover = PopoverPrimitive.Root

export const PopoverTrigger = PopoverPrimitive.Trigger

export const PopoverContent = forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = 'start', sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={mergeClassName(
        'z-50 animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 rounded-xl bg-transparent shadow-[0px_4px_24px_#00000012,inset_0px_-1px_1px_#ffffff20,inset_0px_1px_1px_#ffffff40] backdrop-blur-[32px] backdrop-brightness-[100%]  border-[rgba(255,255,255,0.18)] bg-white/50 dark:bg-slate-900 dark:shadow-none dark:border dark:border-slate-800',
        className
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
))
PopoverContent.displayName = PopoverPrimitive.Content.displayName
