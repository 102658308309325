import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { Table } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import Button from '../Button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../Select'

type DataTablePaginationProps<TData> = {
  table: Table<TData>
  rowsPerPageOptions: number[]
}

const Pagination = <TData,>({ table, rowsPerPageOptions }: DataTablePaginationProps<TData>) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center justify-end border-t border-slate-200 p-4 dark:border-slate-700 dark:bg-slate-900">
      <div className="lg:space-x-8' flex grow items-center justify-end space-x-6">
        {rowsPerPageOptions.length > 1 && (
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium">{t('core:dataTable.resultsPerPage')}</p>
            <Select
              value={`${table.getState().pagination.pageSize}`}
              onValueChange={value => {
                table.setPageSize(Number(value))
              }}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue placeholder={table.getState().pagination.pageSize} />
              </SelectTrigger>
              <SelectContent side="top">
                {rowsPerPageOptions.map(pageSize => (
                  <SelectItem key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}

        <div className="flex w-[100px] items-center justify-start text-sm font-medium">
          {t('core:dataTable.pagination', {
            currentPage: table.getState().pagination.pageIndex + 1,
            totalPage: table.getPageCount(),
          })}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 text-gray-900 dark:bg-slate-950 lg:flex"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">{t('core:dataTable.goToFirstPage')}</span>
            <ChevronDoubleLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0 text-gray-900 dark:bg-slate-950"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">{t('core:dataTable.goToPreviousPage')}</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0 text-gray-900 dark:bg-slate-950"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">{t('core:dataTable.goToNextPage')}</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 text-gray-900 dark:bg-slate-950 lg:flex"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">{t('core:dataTable.goToLastPage')}</span>
            <ChevronDoubleRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Pagination
