import { InvoiceSortField, InvoicesQuery } from '@sherweb/core/openapi-generated/index.defs'

import { getDefaultParameters } from '@sherweb/core/components/DataTable/const'
import { Pagination } from '@sherweb/core/components/DataTable/types'
import { DEFAULT_TABLE_PAGE } from '@sherweb/core/utils/const'

export const getInvoicesPaginationDefaultValues = (
  options?: Partial<Pagination<InvoiceSortField>>
) => {
  return getDefaultParameters<InvoiceSortField>({
    sort: {
      field: InvoiceSortField.DueDate,
      ascending: false,
    },
    ...options,
  })
}

export const getOrganizationInvoicesQueryOptions = (
  queryOptions?: Partial<Pagination<InvoiceSortField>>
): InvoicesQuery => {
  const paginationDefaultValues = getInvoicesPaginationDefaultValues()

  return {
    page: DEFAULT_TABLE_PAGE,
    pageSize: 20,
    searchTerm: '',
    sort: paginationDefaultValues?.sort,
    ...queryOptions,
  }
}
