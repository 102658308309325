/* eslint-disable tailwindcss/no-custom-classname */
import clsx from 'clsx'
import { useState } from 'react'

import MainGradientBackground from '@sherweb/core/components/MainGradientBackground'
import Sidebar from '@sherweb/core/components/Sidebar'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import FullWidthPage from '@rsp/components/layout/FullWidthPage/FullWidthPage'
import Header from '@rsp/components/layout/Header'
import SidebarMenu from '@rsp/components/layout/SidebarMenu'

type ContainedPageProps = {
  children: React.ReactNode
  padded?: boolean
  centered?: boolean
  className?: string
  showSidebar?: boolean
}

const ContainedPage: React.FC<ContainedPageProps> = ({
  children,
  padded = true,
  centered = true,
  className,
  showSidebar = true,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const openMenu = () => {
    setMenuOpen(true)
  }
  const closeMenu = () => {
    setMenuOpen(false)
  }

  return (
    <>
      <MainGradientBackground className="bg-[#E5EFF7]" />
      <FullWidthPage className={className}>
        <div
          className={mergeClassName('flex min-h-screen', {
            'lg:grid lg:grid-cols-[16rem_auto]': showSidebar,
          })}
        >
          {showSidebar ? (
            <Sidebar open={menuOpen} onClose={closeMenu}>
              <SidebarMenu />
            </Sidebar>
          ) : null}
          <div className="z-20 flex w-full flex-col">
            <Header onHamburgerButtonClick={openMenu} />
            <div className="lg:w-full">
              <div
                className={clsx(
                  'text-gray-900 dark:text-slate-200',
                  padded && 'px-4 py-8 sm:px-6 sm:py-10 lg:px-10 lg:py-8',
                  centered && 'mx-auto w-full max-w-screen-2xl'
                )}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </FullWidthPage>
    </>
  )
}

export default ContainedPage
