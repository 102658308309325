import { Squares2X2Icon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { Facet } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import { useQueryParams } from '@sherweb/core/hooks'
import {
  MarketPlaceFilter,
  MarketPlaceFilterTriggerContent,
} from '@sherweb/core/modules/marketplace/ui/Filter'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

type MarketplaceProductsVendorFilteProps = {
  facets?: Facet[]
}

export const MarketplaceProductsVendorFilter = ({
  facets,
}: MarketplaceProductsVendorFilteProps) => {
  const { t } = useTranslation()

  const vendorOptions = facets
    ?.find(({ fieldName }) => fieldName === 'Vendor')
    ?.countsByValue?.map(option => ({
      ...option,
      label: option.value,
    }))

  const { getArrayParam } = useQueryParams<'productsVendor'>()

  const queryVendorSelectedValues = getArrayParam<string>('productsVendor')

  return (
    <MarketPlaceFilter
      dataTestId="vendor"
      options={vendorOptions}
      queryParamType="productsVendor"
      trigger={
        <Button
          size="small"
          variant="None"
          className={mergeClassName(
            'flex items-center px-4 py-2 text-slate-900 hover:bg-white/50 dark:text-slate-100',
            {
              'bg-white/50': queryVendorSelectedValues.length !== 0,
            }
          )}
        >
          <MarketPlaceFilterTriggerContent
            text={t('rsp:pages.marketplace.addProducts.filters.vendor')}
            icon={<Squares2X2Icon className="mr-1 h-4 w-4" />}
            value={queryVendorSelectedValues}
          />
        </Button>
      }
    />
  )
}
