import { useCallback } from 'react'
import { UseFormReturn } from 'react-hook-form'

import {
  useGetConnectWiseActiveServiceBoardIdQuery,
  useGetConnectWiseServiceBoardsQuery,
  useGetConnectWiseTicketStatusMappingsQuery,
  useGetConnectWiseTicketTypesQuery,
} from '@rsp/modules/integrations/helpdesk'

import { BoardConfigurationFormType } from './useBoardConfigurationSchema'

type UseApiInformationProps = {
  form: UseFormReturn<BoardConfigurationFormType>
}

export const useBoardConfiguration = ({ form }: UseApiInformationProps) => {
  const [serviceBoard] = form.watch(['serviceBoard'])

  const connectWiseTicketStatusMappingsQuery =
    useGetConnectWiseTicketStatusMappingsQuery(serviceBoard)

  const connectWiseTicketTypesQuery = useGetConnectWiseTicketTypesQuery(serviceBoard)

  const connectWiseServiceBoardsQuery = useGetConnectWiseServiceBoardsQuery()

  const connectWiseActiveServiceBoardIdQuery = useGetConnectWiseActiveServiceBoardIdQuery()

  const setFormServiceBoard = useCallback(
    (serviceBoard?: string) => {
      if (serviceBoard !== undefined) {
        form.setValue('serviceBoard', serviceBoard)
      }
    },
    [form]
  )

  const setFormValues = useCallback(
    (serviceBoard?: string) => {
      if (serviceBoard !== undefined) {
        form.reset({
          serviceBoard: connectWiseActiveServiceBoardIdQuery?.data,
          ticketStatusMappingList: {
            ...form.getValues('ticketStatusMappingList'),
            [serviceBoard]: {
              ...connectWiseTicketStatusMappingsQuery?.data?.ticketStatusMappingList?.[
                serviceBoard
              ],
            },
          },
          ticketTypes: {
            ...form.getValues('ticketTypes'),
            [serviceBoard]: Array.from(
              new Set([...(connectWiseTicketTypesQuery?.data?.configured?.[serviceBoard] ?? [])])
            ),
          },
        })
      }
    },
    [
      connectWiseActiveServiceBoardIdQuery?.data,
      connectWiseTicketStatusMappingsQuery?.data?.ticketStatusMappingList,
      connectWiseTicketTypesQuery?.data?.configured,
      form,
    ]
  )

  return {
    setFormServiceBoard,
    setFormValues,
    isServiceBoardLoading:
      connectWiseServiceBoardsQuery?.isLoading || !connectWiseServiceBoardsQuery?.data,
  }
}
