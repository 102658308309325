import { useCallback, useEffect } from 'react'

import { useQueryParams } from '@sherweb/core/hooks'
import {
  DEFAULT_TABLE_PAGE,
  DEFAULT_TABLE_ROWS_PER_PAGE,
  DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS,
} from '@sherweb/core/utils/const'

const isValidNumber = (value: string | number) => {
  const valueAsNumber = Number(value)
  return !Number.isNaN(valueAsNumber) && valueAsNumber > 0
}

export const useSetTablePaginationParams = () => {
  const { getParams, setSearchParams, removeSearchParam } = useQueryParams<'page' | 'pageSize'>()

  const pageValue = getParams('page')

  const pageSizeValue = getParams('pageSize')

  const pageSizeValueAsNumber = Number(pageSizeValue)

  const currentPage = !isValidNumber(pageValue) ? DEFAULT_TABLE_PAGE : Number(pageValue)

  const currentPageSize: number =
    !isValidNumber(pageSizeValue) ||
    !DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS.includes(pageSizeValueAsNumber)
      ? DEFAULT_TABLE_ROWS_PER_PAGE
      : pageSizeValueAsNumber

  const updateSearchParam = useCallback(
    (param: 'page' | 'pageSize', value: number, defaultValue: number) => {
      if (!isValidNumber(value) || value === defaultValue) {
        removeSearchParam(param)
      } else {
        setSearchParams(param, String(value))
      }
    },
    [removeSearchParam, setSearchParams]
  )

  useEffect(() => {
    if (currentPage !== Number(pageValue)) {
      updateSearchParam('page', currentPage, DEFAULT_TABLE_PAGE)
    }
  }, [currentPage, pageValue, updateSearchParam])

  useEffect(() => {
    if (currentPageSize !== Number(pageSizeValue)) {
      updateSearchParam('pageSize', currentPageSize, DEFAULT_TABLE_ROWS_PER_PAGE)
    }
  }, [currentPageSize, pageSizeValue, updateSearchParam])

  const onSetPaginationParams = useCallback(
    (pageValue: number, pageSize: number) => {
      updateSearchParam('page', pageValue, DEFAULT_TABLE_PAGE)
      updateSearchParam('pageSize', pageSize, DEFAULT_TABLE_ROWS_PER_PAGE)
    },
    [updateSearchParam]
  )

  return {
    currentPage,
    currentPageSize,
    onSetPaginationParams,
  }
}
