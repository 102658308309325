import { RefObject, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import config from '@config'

import { Avatar, AvatarFallback } from '@sherweb/core/components/Avatar'
import { DarkModeSwitch } from '@sherweb/core/components/DarkModeSwitch'
import Menu, { MenuItem } from '@sherweb/core/components/Menu'
import { ExposedHLMenuItemRenderProps } from '@sherweb/core/components/Menu/MenuItem'
import { useOrigin } from '@sherweb/core/modules/browserRouter'
import { useCurrentUser } from '@sherweb/core/modules/user'
import { getInitials } from '@sherweb/core/utils/initials'

import { LanguageSelector } from '@rsp/components/LanguageSelector'
import Link from '@rsp/components/Link/Link'

import { useThemeActions, useThemeDarkModeState } from '@ssp/modules/theme'

const UserMenu = () => {
  const { t } = useTranslation()

  const { data: user } = useCurrentUser()

  const darkMode = useThemeDarkModeState()
  const { setDarkMode } = useThemeActions()

  const { bff } = config
  const signOutUrl = `${bff.url}${bff.signOutPath}`

  const sspUrl = useOrigin()
  const darkModeRef = useRef<HTMLButtonElement>(null)
  const languageSelectorRef = useRef<HTMLAnchorElement>(null)

  if (!user) {
    return null
  }

  const forwardClick = (reference: RefObject<HTMLAnchorElement | HTMLButtonElement>) => {
    reference.current?.click()
  }

  const getUserFullName = () => {
    if (!user.firstName || !user.lastName) {
      return
    }
    return `${user.firstName} ${user.lastName}`
  }

  const fullName = getUserFullName()

  return (
    <Menu
      buttonContent={
        <div className="flex flex-row items-center gap-3 rounded-full md:pr-0">
          <Avatar>
            <AvatarFallback className="dark:text-slate-200">{getInitials(fullName)}</AvatarFallback>
          </Avatar>
        </div>
      }
      className="border border-slate-800 bg-slate-950 focus-visible:outline-offset-0 focus-visible:outline-indigo-300 dark:border dark:border-slate-800 dark:bg-slate-900 sm:border-slate-200 sm:bg-slate-200"
      data-testid="user-menu"
    >
      <div>
        <MenuItem
          className="cursor-default text-gray-900 hover:bg-transparent dark:text-white dark:hover:text-white dark:focus-visible:text-primary"
          disabled
        >
          {(render: ExposedHLMenuItemRenderProps) => (
            <div className="flex min-w-[225px] flex-col items-center gap-y-2 px-1 pb-2 pt-1">
              <div className="flex h-11 w-11 items-center justify-center rounded-full border border-slate-200 bg-slate-300 dark:border-slate-800 dark:bg-slate-900">
                <Avatar>
                  <AvatarFallback className="dark:bg-slate-950 dark:text-slate-200">
                    {getInitials(fullName)}
                  </AvatarFallback>
                </Avatar>
              </div>
              <div className="flex flex-col items-center justify-center gap-1 pl-4 pr-2">
                {fullName && <div className="text-sm font-semibold leading-none">{fullName}</div>}
                {user.jobTitle && <div className="text-sm text-gray-500">{user.jobTitle}</div>}
                <Link
                  isAbsolute
                  to={sspUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block text-sm text-blue-600 focus-visible:outline-indigo-300"
                  onClick={render.close}
                >
                  {t('rsp:layout.userMenu.goToPortal')}
                </Link>
              </div>
            </div>
          )}
        </MenuItem>
      </div>

      <div>
        <MenuItem
          className="flex items-center justify-between whitespace-nowrap"
          onClick={() => forwardClick(darkModeRef)}
          autoClose={false}
        >
          {t('rsp:layout.userMenu.darkModeToggle.label')}
          <DarkModeSwitch darkMode={darkMode} setDarkMode={setDarkMode} ref={darkModeRef} />
        </MenuItem>
      </div>

      <div>
        <MenuItem
          className="flex items-center justify-between whitespace-nowrap"
          onClick={() => forwardClick(languageSelectorRef)}
        >
          {(render: ExposedHLMenuItemRenderProps) => (
            <>
              {t('rsp:layout.userMenu.languageToggle.label')}
              <LanguageSelector ref={languageSelectorRef} onClick={render.close} />
            </>
          )}
        </MenuItem>
      </div>

      <a href={signOutUrl} className="mt-10 w-3/4">
        <MenuItem>{t('rsp:layout.userMenu.signOut')}</MenuItem>
      </a>
    </Menu>
  )
}

export default UserMenu
