import { useTranslation } from 'react-i18next'

import Badge, { Variant } from '@sherweb/core/components/Badge'

import { ICategory } from '../../core/marketplace.model'

type MarketPlaceCategoryBadgeProps = {
  category?: string
}

export const MarketPlaceCategoryBadge = ({ category }: MarketPlaceCategoryBadgeProps) => {
  const { t } = useTranslation()

  const categoryMapper = {
    [ICategory.productivity]: (
      <Badge variant={Variant.Info}>{t('core:marketplace.category.productivity')}</Badge>
    ),
    [ICategory.security]: (
      <Badge variant={Variant.Danger}>{t('core:marketplace.category.security')}</Badge>
    ),
    [ICategory.continuity]: (
      <Badge variant={Variant.Warning}>{t('core:marketplace.category.continuity')}</Badge>
    ),
    [ICategory.business_apps]: (
      <Badge className="border-blue-600/10 bg-blue-100 dark:border-blue-800 dark:text-blue-600">
        {t('core:marketplace.category.business_apps')}
      </Badge>
    ),
    [ICategory.unified_communications]: (
      <Badge variant={Variant.Default}>
        {t('core:marketplace.category.unified_communications')}
      </Badge>
    ),
    [ICategory.infrastructure]: (
      <Badge variant={Variant.Success}>{t('core:marketplace.category.infrastructure')}</Badge>
    ),
    category: <Badge variant={Variant.Info}>{category}</Badge>,
  }

  return category ? categoryMapper[category as ICategory] : null
}
