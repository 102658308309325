import { Theme } from '@sherweb/core/openapi-generated/index.defs'

import persistReducer from '@ssp/modules/redux/core/persistReducer'

import { ACTION_TYPES, Actions } from './theme.actions'
import { defaultTheme } from './theme.const'

export const key = 'theme'
export const version = 1
export const persistFields = ['darkMode']

type ReducerState = {
  theme: Theme
  darkMode?: boolean
}

const initialState: ReducerState = {
  theme: defaultTheme,
  darkMode: undefined,
}

const rawReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ACTION_TYPES.SET_THEME: {
      return {
        ...state,
        theme: action.theme,
      }
    }
    case ACTION_TYPES.SET_DARK_MODE: {
      return {
        ...state,
        darkMode: action.darkMode,
      }
    }
    default:
      return state
  }
}

export const reducer = persistReducer(rawReducer, {
  key,
  shared: true,
  version,
  persistFields,
})
