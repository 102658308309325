import { BuildingOffice2Icon, ListBulletIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { CatalogListQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import Card, { CardTitle } from '@sherweb/core/components/Card'
import { Typography } from '@sherweb/core/components/Typography'

import Routes from '@rsp/app/Routes'
import { useNavigate } from '@rsp/modules/navigation'

type MarketplaceCatalogCardProps = {
  catalog: CatalogListQueryResult
}

export const MarketplaceCatalogCard = ({ catalog }: MarketplaceCatalogCardProps) => {
  const { t } = useTranslation()

  const { navigate } = useNavigate()

  const handleNavigateToCatalogProductsPage = () => {
    navigate(Routes.MarketplaceCatalogProducts, { catalogId: catalog.id })
  }

  return (
    <Card
      className="flex flex-col justify-between gap-12 hover:cursor-pointer md:p-4"
      key={catalog.id}
      data-testid="cardCatalog"
      onClick={handleNavigateToCatalogProductsPage}
    >
      <div className="flex flex-col gap-2">
        <CardTitle>{catalog.name}</CardTitle>
        <Typography variant="label">{catalog.description}</Typography>
      </div>
      <div className="mt-4">
        <div className="flex flex-wrap justify-start gap-x-4 gap-y-2">
          <div className="flex items-center gap-1">
            <BuildingOffice2Icon className="h-4 w-4 shrink-0 opacity-50 dark:text-white" />
            <Typography variant="label" colors="light">
              {t('rsp:pages.marketplace.labels.organizations')} - {catalog.numberOrganizations}
            </Typography>
          </div>
          <div className="flex items-center gap-1">
            <ListBulletIcon className="h-4 w-4 shrink-0 opacity-50 dark:text-white" />
            <Typography variant="label" colors="light">
              {t('rsp:pages.marketplace.labels.skus')} - {catalog.numberSkus}
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  )
}
