import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { Label } from '@sherweb/core/components/Label'
import Tooltip from '@sherweb/core/components/Tooltip'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'

import { OrderStatus, useOrderStatusBadge } from '@ssp/modules/subscription'

let operationSign = ''

type LastOrderProps = {
  humanReadableid?: string | null
  createdAt?: Date | null
  deltaQuantity?: number
  operationType?: string
  decisionNote?: string
  orderStatus?: OrderStatus | null
}

const LastOrder: React.FC<LastOrderProps> = ({
  humanReadableid,
  createdAt,
  deltaQuantity,
  operationType,
  orderStatus,
  decisionNote,
}) => {
  switch (operationType) {
    case 'remove':
      operationSign = '-'
      break
    case 'add':
      operationSign = '+'
  }
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)
  const formatDateToNumeric = useDateFormatter(language, dateFormatTypes.numeric)
  const getOrderStatusBadge = useOrderStatusBadge()
  const decisionNoteShowFlag = orderStatus === OrderStatus.Rejected && !!decisionNote

  return (
    <div className="flex flex-col gap-2 md:gap-4" data-testid="containerLastOrder">
      <Label className="text-slate-500 dark:text-slate-200">
        {t('ssp:subscription.order.label')}
      </Label>
      <table className="w-10/12  text-left text-sm">
        <thead className="border-b border-slate-300  text-slate-500 dark:text-slate-200">
          <tr className="[&>th]:pb-2 [&>th]:font-normal">
            <th>{t('ssp:subscription.order.tableHeader.id')}</th>
            <th>{t('ssp:subscription.order.tableHeader.date')}</th>
            <th>{t('ssp:subscription.order.tableHeader.operations')}</th>
            <th>{t('ssp:subscription.order.tableHeader.status')}</th>
          </tr>
        </thead>
        <tbody className="text-slate-800 dark:text-white">
          <tr className="font-medium [&>td]:pt-2">
            <td className="font-semibold">{humanReadableid}</td>
            <td>
              <Tooltip tooltip={formatDate(createdAt)}>{formatDateToNumeric(createdAt)}</Tooltip>
            </td>
            <td className="pl-8">
              {operationSign}
              {deltaQuantity}
            </td>
            <td className="flex">
              {getOrderStatusBadge(orderStatus)}
              {decisionNoteShowFlag && (
                <Tooltip wrapperClassName="align-middle pl-3" tooltip={decisionNote}>
                  <ChatBubbleOvalLeftEllipsisIcon
                    data-testid="decisionTooltip"
                    className="h-6 w-6"
                  />
                </Tooltip>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default LastOrder
