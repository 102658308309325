import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'

import Routes from '@rsp/app/Routes'
import Link from '@rsp/components/Link'

import { useGetCatalogId } from '../hooks/useGetCatalogId'

type MarketplaceCatalogActionsProps = {
  children: React.ReactNode
  organizationCount?: number
  productCount?: number
}

export const MarketplaceCatalogActions = ({
  children,
  organizationCount,
  productCount,
}: MarketplaceCatalogActionsProps) => {
  const { t } = useTranslation()

  const { catalogId } = useGetCatalogId()

  return (
    <div className="flex w-full flex-col gap-4 sm:flex-row sm:justify-between">
      {children}
      <div className="flex flex-row gap-4">
        <Link
          to={Routes.MarketplaceCatalogAssignedOrganizations}
          params={{
            catalogId,
          }}
          className="w-full sm:w-auto"
        >
          <Button variant="outline" className="w-full text-nowrap sm:w-auto">
            {t('rsp:pages.marketplace.catalogs.manageAssignedOrganizations')} ({organizationCount})
          </Button>
        </Link>
        <Link
          to={Routes.MarketplaceCatalogAddProducts}
          params={{
            catalogId,
          }}
          className="w-full sm:w-auto"
        >
          <Button variant="primary" className="w-full sm:w-auto">
            {t('rsp:pages.marketplace.catalogs.manageProducts')} ({productCount})
          </Button>
        </Link>
      </div>
    </div>
  )
}
