import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Backlink from '@sherweb/core/components/Backlink'
import PageTitle, { PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import { ScrollArea, ScrollBar } from '@sherweb/core/components/ScrollArea'
import Spinner from '@sherweb/core/components/Spinner'
import { TabsList } from '@sherweb/core/components/Tabs'
import { TabsLink, TabsLinkContent, TabsTriggerLink } from '@sherweb/core/components/Tabs/TabsLink'
import { Typography } from '@sherweb/core/components/Typography'

import { protectPage } from '@rsp/app/ProtectedPage'
import Routes from '@rsp/app/Routes'
import ContainedPage from '@rsp/components/layout/ContainedPage'
import Link from '@rsp/components/Link'
import {
  useGetConnectWiseConfiguredQuery,
  useGetUnMappedOrganizations,
} from '@rsp/modules/integrations/helpdesk'

export const ConnectWisePage = () => {
  const { t } = useTranslation()

  const connectWiseConfiguredQuery = useGetConnectWiseConfiguredQuery()

  const unMappedOrganizations = useGetUnMappedOrganizations()

  if (connectWiseConfiguredQuery?.isLoading) {
    return <Spinner floating />
  }

  return (
    <ContainedPage>
      <div className="mb-8 sm:mb-16">
        <Link to={Routes.Integrations} data-testid="linkBackToIntegrations">
          <Backlink>{t('rsp:pages.integrations.backLink')}</Backlink>
        </Link>
        <PageTitleWrapper
          title={
            <PageTitle data-testid="pageConnectWiseTitle">
              {t('rsp:pages.integrations.helpdesk.connectWise.title')}
            </PageTitle>
          }
        />
      </div>
      <TabsLink className="w-full">
        <ScrollArea>
          <TabsList className="h-16 sm:h-auto">
            <TabsTriggerLink
              to={Routes.IntegrationsConnectWiseApiInformation}
              data-testid="tabsTriggerApiInformation"
              className="focus-visible:ring-indigo-300"
            >
              {t('rsp:pages.integrations.helpdesk.connectWise.tabs.apiInformation')}
            </TabsTriggerLink>
            <TabsTriggerLink
              to={Routes.IntegrationsConnectWiseBoardConfiguration}
              disabled={!connectWiseConfiguredQuery?.isEnabled}
              data-testid="tabsTriggerBoardConfiguration"
              className="focus-visible:ring-indigo-300"
            >
              {t('rsp:pages.integrations.helpdesk.connectWise.tabs.boardConfiguration')}
            </TabsTriggerLink>
            <TabsTriggerLink
              to={Routes.IntegrationsConnectWiseOrganizationMapping}
              disabled={!connectWiseConfiguredQuery?.isEnabled}
              data-testid="tabsTriggerOrganizationMapping"
              className="focus-visible:ring-indigo-300"
            >
              {t('rsp:pages.integrations.helpdesk.connectWise.tabs.organizationMapping')}
              {unMappedOrganizations?.data && unMappedOrganizations?.data?.length !== 0 ? (
                <Typography
                  variant="body2"
                  weight="medium"
                  data-testid="textUnMappedOrganizationCount"
                  className="ml-2.5 flex h-4 items-center rounded-xl bg-indigo-100 px-2.5 text-indigo-600 dark:text-indigo-600"
                >
                  {unMappedOrganizations?.data?.length}
                </Typography>
              ) : null}
            </TabsTriggerLink>
          </TabsList>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </TabsLink>
      <TabsLinkContent />
    </ContainedPage>
  )
}

export default protectPage(ConnectWisePage, [
  Permission.IntegrationConfigure,
  Permission.IntegrationList,
])
