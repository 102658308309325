import { useEffect } from 'react'

import { Theme } from '@sherweb/core/openapi-generated/index.defs'

import { usePortalMetadata } from '@sherweb/core/modules/portalMetadata'

import { useThemeActions, useThemeDarkModeState } from '../core/theme.hooks'
import ThemeVariables from './ThemeVariables'

type ThemeProviderProps = {
  children: React.ReactNode
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const portalMetadata = usePortalMetadata()
  const theme = portalMetadata?.data?.theme
  const darkMode = useThemeDarkModeState()
  const { setTheme } = useThemeActions()

  useEffect(() => {
    if (document.body) {
      setTheme(theme ?? Theme.Default)
      document.body.className = darkMode ? 'dark' : ''
      document.body.dataset.theme = theme?.toLowerCase()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode, theme])

  return (
    <>
      <ThemeVariables theme={theme} />
      {children}
    </>
  )
}

export default ThemeProvider
