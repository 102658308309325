// based on: https://ui.shadcn.com/docs/components/badge#installation

import { cva, type VariantProps } from 'class-variance-authority'

import { mergeClassName } from '../../utils/mergeClassName'

export enum Variant {
  Default = 'default',
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Success = 'success',
}

export const badgeVariants = cva(
  'font-regular inline-flex items-center whitespace-nowrap rounded-md border px-2 py-1 text-xs font-normal text-slate-900 transition-colors dark:bg-slate-950',
  {
    variants: {
      variant: {
        [Variant.Default as string]:
          'border-gray-600/10 bg-gray-100 dark:border-gray-800 dark:text-gray-600',
        [Variant.Info as string]:
          'border-indigo-600/10 bg-indigo-100 dark:border-indigo-800 dark:text-indigo-600',
        [Variant.Warning as string]:
          'border-orange-600/10 bg-orange-100 dark:border-yellow-800 dark:text-yellow-600',
        [Variant.Danger as string]:
          'border-red-600/10 bg-red-100 dark:border-red-800 dark:text-red-600',
        [Variant.Success as string]:
          'border-green-600/10 bg-green-100 dark:border-green-800 dark:text-green-600',
      },
    },
    defaultVariants: {
      variant: Variant.Default,
    },
  }
)

export type BadgeProps = React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof badgeVariants>

const Badge: React.FC<BadgeProps> = ({ className, variant = Variant.Default, ...props }) => {
  return (
    <div
      className={mergeClassName(badgeVariants({ variant }), className)}
      data-testid={`badge-${String(variant)}`}
      {...props}
    />
  )
}

export default Badge
