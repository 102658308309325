import { OrganizationsQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { Organization } from './organization.model'

export const buildOrganization = (p: OrganizationsQueryResult): Organization => {
  return {
    id: p.id,
    name: p.name ?? '',
    uniqueName: p.uniqueName ?? '',
    subreseller: p.subreseller ?? '',
    isSuspended: p.isSuspended,
  }
}
