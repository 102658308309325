// based on: https://ui.shadcn.com/docs/components/button#installation

import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'

import { mergeClassName } from '../../utils/mergeClassName'

export enum Variant {
  Primary = 'primary',
  Secondary = 'secondary',
  Outline = 'outline',
  PrimaryF = 'primaryf',
  Success = 'success',
  Danger = 'danger',
  None = 'none',
}

export enum Size {
  Default = 'default',
  Small = 'sm',
  Large = 'lg',
}

export const buttonVariants = cva(
  'font-regular flex items-center justify-center rounded-lg leading-none text-white ring-offset-background focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-indigo-300 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:bg-none disabled:text-gray-500 dark:disabled:text-gray-600',
  {
    variants: {
      variant: {
        [Variant.Primary as string]:
          'ease bg-gradient-to-r from-indigo-600 from-5% to-purple-600 to-100% hover:from-indigo-600 hover:to-purple-700 dark:disabled:bg-gray-800 dark:disabled:bg-none',
        [Variant.Secondary as string]:
          'border border-blue-600 bg-white/50 text-blue-700 hover:border-blue-700 hover:bg-slate-100 dark:border-slate-800 dark:bg-slate-900 dark:text-slate-200 dark:hover:border-slate-700',
        [Variant.Outline as string]:
          'hover:text-accent-fore ground border border-input bg-white text-gray-900 hover:bg-accent dark:border-slate-800 dark:bg-slate-900 dark:text-slate-200 dark:hover:border-slate-700',
        [Variant.PrimaryF as string]:
          'border-input text-gray-900 hover:bg-accent hover:text-accent-foreground',
        [Variant.Success as string]:
          'ease bg-green-600 hover:shadow-lg dark:disabled:bg-gray-800 dark:disabled:bg-none',
        [Variant.Danger as string]:
          'ease bg-red-600 hover:shadow-lg dark:disabled:bg-gray-800 dark:disabled:bg-none',
        [Variant.None as string]: '',
      },
      size: {
        [Size.Default as string]: 'px-8 py-2.5 text-sm',
        [Size.Small as string]: 'px-8 py-2 text-sm',
        [Size.Large as string]: 'px-8 py-3.5 text-base',
      },
    },
    defaultVariants: {
      variant: Variant.Primary,
      size: Size.Default,
    },
  }
)

export type ButtonProps = {
  asChild?: boolean
  fullWidth?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants>

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, fullWidth = false, ...props }, ref) => {
    const Component = asChild ? Slot : 'button'
    return (
      <Component
        className={mergeClassName(
          buttonVariants({ variant, size, className }),
          fullWidth && 'w-full'
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export default Button
