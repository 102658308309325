import { useTranslation } from 'react-i18next'
import { StepWizardChildProps } from 'react-step-wizard'

export type StepsProps = {
  activeStep: number
  serviceProviderStepValid: boolean
} & Partial<StepWizardChildProps>

const COLOR = 'text-indigo-600'

const Steps: React.FC<StepsProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const maxSteps = 3
  const widthPercentage: number = (props.activeStep / (maxSteps - 1)) * 100

  const handleStepClick = (stepNumber: number, clickable: boolean): void => {
    if (props?.goToStep && clickable) {
      props.goToStep(stepNumber)
    }
  }

  return (
    <div data-testid="steps">
      <div className="my-6" aria-hidden="true">
        <div className="overflow-hidden rounded-full bg-gray-200 dark:bg-slate-950">
          <div
            className="h-2 rounded-full bg-indigo-600"
            style={{ width: `${widthPercentage}%` }}
          />
        </div>
        <div className="mt-6 hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
          <div className={`${props.activeStep >= 0 ? COLOR : ''}`}>
            <span
              className="cursor-pointer"
              onClick={() => handleStepClick(1, true)}
              data-testid="stepPortalConfiguration"
            >
              {t('rsp:pages.onboarding.portalConfiguration.stepTitle')}
            </span>
          </div>
          <div className={`text-center ${props.activeStep >= 1 ? COLOR : ''}`}>
            <span
              className="cursor-pointer"
              onClick={() => handleStepClick(2, true)}
              data-testid="stepServiceProvider"
            >
              {t('rsp:pages.onboarding.serviceProviderConfiguration.stepTitle')}
            </span>
          </div>
          <div className={`text-right ${props.activeStep === 2 ? COLOR : ''}`}>
            <span
              data-testid="stepSupportInformation"
              className={`${props.serviceProviderStepValid ? 'cursor-pointer' : ''}`}
              onClick={() => handleStepClick(3, props.serviceProviderStepValid)}
            >
              {t('rsp:pages.onboarding.supportInformation.stepTitle')}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Steps
