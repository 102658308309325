import Card, { CardTitle } from '@sherweb/core/components/Card'
import { Skeleton } from '@sherweb/core/components/Skeleton'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

type MarketplaceLoaderProps = {
  count?: number
  className?: string
  children?: React.ReactNode
}

export const MarketplaceCardsLoader = ({
  count = 2,
  className,
  children,
}: MarketplaceLoaderProps) => (
  <>
    {Array.from({ length: count }, (_, index) => (
      <Card
        className={mergeClassName('flex h-56 flex-col justify-between md:p-4', className)}
        key={index}
        data-testid="skeletonMarketplaceCard"
      >
        <div className="flex flex-col">
          <CardTitle>
            <Skeleton className="mb-2 h-[16px] w-20" />
          </CardTitle>
          <Skeleton className="h-[16px] w-20" />
        </div>
        {children}
        <div className="mt-4">
          <div className="flex justify-start">
            <div className="flex items-center gap-1">
              <Skeleton className="h-[16px] w-20" />
            </div>
            <div className="ml-4 flex items-center gap-1">
              <Skeleton className="h-[16px] w-20" />
            </div>
          </div>
        </div>
      </Card>
    ))}
  </>
)
