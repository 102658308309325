import { DataTestId } from '@sherweb/core/types/dataTestIdType'

type FullWidthPageProps = {
  children: React.ReactNode
  className?: string
} & DataTestId

const FullWidthPage = ({ children, className = '', dataTestId }: FullWidthPageProps) => (
  <main className={className} data-testid={dataTestId}>
    {children}
  </main>
)

export default FullWidthPage
