import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import {
  MultiSelect,
  MultiSelectInput,
  MultiSelectTrigger,
} from '@sherweb/core/components/MultiSelect'
import Spinner from '@sherweb/core/components/Spinner'

import { FormSplitScreen } from '@rsp/components/layout/FormSplitScreen'
import {
  useGetConnectWiseServiceBoardsQuery,
  useGetConnectWiseTicketStatusesQuery,
  useGetConnectWiseTicketStatusMappingsQuery,
} from '@rsp/modules/integrations/helpdesk'

import { BoardConfigurationFormType } from './hooks/useBoardConfigurationSchema'
import { useGetFilteredTicketStatusMapping } from './hooks/useGetFilteredTicketStatusMapping'

export const BoardConfigurationStatusMapping = () => {
  const { t } = useTranslation()

  const [isMoreStatusVisible, setIsMoreStatusVisible] = useState<Record<string, boolean>>({})

  const form = useFormContext<BoardConfigurationFormType>()

  const connectWiseTicketStatusesQuery = useGetConnectWiseTicketStatusesQuery()

  const [serviceBoard] = form.watch(['serviceBoard'])

  const connectWiseServiceBoardsQuery = useGetConnectWiseServiceBoardsQuery()

  const connectWiseTicketStatusMappingsQuery =
    useGetConnectWiseTicketStatusMappingsQuery(serviceBoard)

  const filteredTicketStatusMapping = useGetFilteredTicketStatusMapping()

  const isConnectWiseTicketStatusesQueryLoading =
    connectWiseTicketStatusesQuery?.isLoading || !connectWiseTicketStatusesQuery?.data

  const isConnectWiseTicketStatusMappingsQueryLoading =
    connectWiseTicketStatusMappingsQuery?.isLoading || !connectWiseTicketStatusMappingsQuery?.data

  const isConnectWiseServiceBoardsQueryLoading =
    connectWiseServiceBoardsQuery?.isLoading || !connectWiseServiceBoardsQuery?.data

  useEffect(() => {
    const optionalTicketTypeIds = connectWiseTicketStatusesQuery?.data?.optionalStatuses?.map(
      status => status?.id
    )
    const ticketStatusMappingList =
      form.getValues('ticketStatusMappingList')?.[form.getValues('serviceBoard') ?? '']

    if (
      ticketStatusMappingList &&
      Object.keys(ticketStatusMappingList).some(item => optionalTicketTypeIds?.includes(item))
    ) {
      setIsMoreStatusVisible(prevIsMoreStatusVisible => ({
        ...prevIsMoreStatusVisible,
        [form.getValues('serviceBoard') ?? '']: true,
      }))
    }
  }, [connectWiseTicketStatusesQuery?.data?.optionalStatuses, form])

  const handleShowStatuses = () => {
    if (serviceBoard !== undefined) {
      setIsMoreStatusVisible(prevIsMoreStatusVisible => ({
        ...prevIsMoreStatusVisible,
        [serviceBoard]: !prevIsMoreStatusVisible[serviceBoard],
      }))
    }
  }

  if (!serviceBoard) {
    return null
  }

  if (
    isConnectWiseTicketStatusMappingsQueryLoading ||
    isConnectWiseTicketStatusesQueryLoading ||
    isConnectWiseServiceBoardsQueryLoading
  ) {
    return <Spinner floating />
  }

  return (
    <FormSplitScreen.Row
      contentContainerClassName="md:max-w-md max-w-full"
      leftContent={
        <FormSplitScreen.LeftContent
          label={t(
            'rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.statusMapping.title'
          )}
          subLabel={t(
            'rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.statusMapping.description'
          )}
        />
      }
    >
      {connectWiseTicketStatusMappingsQuery.isFetching ? <Spinner floating /> : null}
      {connectWiseTicketStatusesQuery?.data?.requiredStatuses?.map(ticketStatus => (
        <FormField
          key={`ticketStatusMappingList.${serviceBoard}.${ticketStatus.id}`}
          control={form.control}
          name={`ticketStatusMappingList.${serviceBoard}.${ticketStatus.id}`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{`${ticketStatus.name ?? ''}*`}</FormLabel>
              <MultiSelect
                onValuesChange={field.onChange}
                options={filteredTicketStatusMapping(field.value)?.map(option => ({
                  label: option.integrationTicketStatusName,
                  value: option?.integrationTicketStatusId,
                }))}
                values={field.value}
              >
                <FormControl>
                  <MultiSelectTrigger>
                    <MultiSelectInput
                      placeholder={ticketStatus?.placeholder}
                      dataTestId={`multiSelectInput${ticketStatus.type}`}
                    />
                  </MultiSelectTrigger>
                </FormControl>
                <FormMessage />
              </MultiSelect>
            </FormItem>
          )}
        />
      ))}
      {isMoreStatusVisible?.[serviceBoard]
        ? connectWiseTicketStatusesQuery?.data?.optionalStatuses?.map(ticketStatus => (
            <FormField
              key={`ticketStatusMappingList.${serviceBoard}.${ticketStatus.id}`}
              control={form.control}
              name={`ticketStatusMappingList.${serviceBoard}.${ticketStatus.id}`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{ticketStatus.name}</FormLabel>
                  <MultiSelect
                    onValuesChange={field.onChange}
                    options={filteredTicketStatusMapping(field.value)?.map(option => ({
                      label: option.integrationTicketStatusName,
                      value: option?.integrationTicketStatusId,
                    }))}
                    values={field.value}
                  >
                    <FormControl>
                      <MultiSelectTrigger>
                        <MultiSelectInput
                          dataTestId={`multiSelectInput${ticketStatus.type}`}
                          placeholder={ticketStatus?.placeholder}
                        />
                      </MultiSelectTrigger>
                    </FormControl>
                    <FormMessage />
                  </MultiSelect>
                </FormItem>
              )}
            />
          ))
        : null}
      <div>
        <Button
          variant="None"
          type="button"
          className="inline-block pl-0 pt-0 text-sm text-blue-600 underline focus-visible:outline-indigo-300"
          onClick={handleShowStatuses}
          data-testid="btnShowHideOptionalStatuses"
        >
          {isMoreStatusVisible?.[serviceBoard]
            ? `- ${t('rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.statusMapping.showLessStatuses')}`
            : `+ ${t('rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.statusMapping.showMoreStatuses')}`}
        </Button>
      </div>
    </FormSplitScreen.Row>
  )
}
