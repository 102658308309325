import { mergeClassName } from '../../../utils/mergeClassName'
import Card, { CardProps } from '../Card'

type ChartCardProps = {
  chart: React.ReactNode
} & CardProps

const ChartCard: React.FC<ChartCardProps> = ({ chart, children, ...cardProps }) => {
  return (
    <Card
      {...cardProps}
      className={mergeClassName('relative overflow-hidden', cardProps?.className)}
    >
      {children}
      <div className="absolute bottom-0 left-0 h-2/5 w-full opacity-40 hover:opacity-80">
        {chart}
      </div>
    </Card>
  )
}

export default ChartCard
