import { OrganizationSortField } from '@sherweb/core/openapi-generated/index.defs'
import { OrganizationsService } from '@sherweb/core/openapi-generated/OrganizationsService'
import { ResellerOrganizationsService } from '@sherweb/core/openapi-generated/ResellerOrganizationsService'

import { Pagination } from '@sherweb/core/components/DataTable/types'
import { getQueryKey } from '@sherweb/core/modules/reactQuery'
import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { rejectMissingParameters } from '@sherweb/core/utils/error'

export const detailedOrganizationsListQueryPrefix =
  'ResellerOrganizationsService/queryDetailedOrganizations'

const getDetailedOrganizationQueryFn = async (
  resellerId?: string,
  queryOptions?: Pagination<OrganizationSortField>
) => {
  if (!resellerId || !queryOptions?.sort?.field || queryOptions?.sort?.ascending === undefined) {
    return await rejectMissingParameters()
  }

  return await ResellerOrganizationsService.queryDetailedOrganizations({
    resellerId,
    detailedOrganizationsQuery: queryOptions,
  })
}

export const detailedOrganizationsList = {
  queryKey: (resellerId?: string, queryOptions?: Pagination<OrganizationSortField>) => [
    detailedOrganizationsListQueryPrefix,
    resellerId,
    queryOptions,
  ],
  queryFn: getDetailedOrganizationQueryFn,
  staleTime: DEFAULT_STALE_TIME,
}

export const detailedOrganizationsWithInfiniteScroll = {
  queryKey: (resellerId?: string, queryOptions?: Pagination<OrganizationSortField>) => [
    detailedOrganizationsListQueryPrefix,
    'infiniteScroll',
    resellerId,
    queryOptions,
  ],
  queryFn: getDetailedOrganizationQueryFn,
  staleTime: DEFAULT_STALE_TIME,
}

export const detailedOrganization = {
  queryKey: (organizationId?: string) =>
    getQueryKey(['OrganizationsService/getOrganizationDetail', organizationId]),
  queryFn: async (organizationId?: string) => {
    if (!organizationId) {
      return await rejectMissingParameters()
    }

    return await OrganizationsService.getOrganizationDetail({ organizationId })
  },
  staleTime: DEFAULT_STALE_TIME,
}
