import persistReducer from '@ssp/modules/redux/core/persistReducer'

import { ACTION_TYPES, Actions } from './organization.actions'

export const key = 'organization'
export const version = 1
export const persistFields = ['selectedOrganization']

type ReducerState = {
  selectedOrganization?: string
}

const initialState: ReducerState = {
  selectedOrganization: undefined,
}

const rawReducer = (state = initialState, action: Actions) => {
  if (action.type === ACTION_TYPES.SET_SELECTED_ORGANIZATION) {
    return {
      ...state,
      selectedOrganization: action.id,
    }
  }

  return state
}

export const reducer = persistReducer(rawReducer, {
  key,
  shared: true,
  version,
  persistFields,
})
