import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import {
  MultiSelect,
  MultiSelectInput,
  MultiSelectTrigger,
} from '@sherweb/core/components/MultiSelect'
import Switch from '@sherweb/core/components/Switch'

import { useGetResellerAdminEmails } from '@rsp/modules/reseller/core/reseller.hooks'

import { OrderSettingsFormType } from './hooks/useOrderSettingsSchema'

export const OrderEmailsNotifications = () => {
  const { t } = useTranslation()

  const form = useFormContext<OrderSettingsFormType>()

  const [notificationsEnabled, notifcationsAllUsersEnabled] = form.watch([
    'emailNotifications.enabled',
    'emailNotifications.allUsersEnabled',
  ])

  const resellerAdminEmails = useGetResellerAdminEmails()

  const isEmailInputVisible =
    notificationsEnabled &&
    !notifcationsAllUsersEnabled &&
    (!resellerAdminEmails?.isLoading || !!resellerAdminEmails?.data)

  return (
    <div className="flex flex-col gap-8">
      <div className="grid grid-cols-1 gap-y-4">
        <FormField
          control={form.control}
          name="emailNotifications.enabled"
          render={({ field }) => (
            <FormItem className="settings-form-item">
              <FormLabel>
                {' '}
                {t('rsp:pages.settings.orderSettings.orders.notifications.emails.labels.orderSend')}
              </FormLabel>
              <FormControl className="settings-form-control">
                <Switch
                  checked={field.value}
                  disabled={form.formState.isSubmitting}
                  onCheckedChange={checked => {
                    field.onChange(checked)
                    form.clearErrors('emailNotifications.emails')
                  }}
                  data-testid="emailNotificationsEnabled"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {notificationsEnabled ? (
          <FormField
            control={form.control}
            name="emailNotifications.allUsersEnabled"
            render={({ field }) => (
              <FormItem className="settings-form-item">
                <FormLabel>
                  {t('rsp:pages.settings.orderSettings.orders.notifications.emails.sendAll')}
                </FormLabel>
                <FormControl className="settings-form-control">
                  <Switch
                    checked={field.value}
                    disabled={form.formState.isSubmitting}
                    data-testid="emailNotificationsAllAdmin"
                    onCheckedChange={checked => field.onChange(checked)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : null}
        {isEmailInputVisible ? (
          <FormField
            control={form.control}
            name="emailNotifications.emails"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('rsp:pages.settings.orderSettings.orders.notifications.emails.listOfUsers')}
                </FormLabel>
                <MultiSelect
                  onValuesChange={field.onChange}
                  values={field.value}
                  options={resellerAdminEmails.data?.map(option => ({
                    label: option?.email,
                    value: option?.email,
                  }))}
                >
                  <FormControl>
                    <MultiSelectTrigger>
                      <MultiSelectInput
                        placeholder={t(
                          'rsp:pages.settings.orderSettings.orders.notifications.emails.placeholderInput'
                        )}
                      />
                    </MultiSelectTrigger>
                  </FormControl>
                  <FormMessage />
                </MultiSelect>
              </FormItem>
            )}
          />
        ) : null}
      </div>
    </div>
  )
}
