import React from 'react'

import { DataTestId } from '@sherweb/core/types/dataTestIdType'

import Link from '@rsp/components/Link'

import SspRoutes from '@ssp/app/Routes'
import { usePathGenerator } from '@ssp/modules/navigation'

type GoToOrganizationLinkProps = {
  organizationUniqueName?: string
  children: React.ReactNode
  className?: string
} & DataTestId

export const GoToOrganizationLink = React.forwardRef<HTMLAnchorElement, GoToOrganizationLinkProps>(
  ({ organizationUniqueName = '', children, className, dataTestId }, ref) => {
    const generatePath = usePathGenerator()

    return (
      <Link
        isAbsolute
        to={generatePath(SspRoutes.Dashboard, {
          organizationUniqueName,
        })}
        target="_blank"
        className={className}
        data-testid={dataTestId}
      >
        {children}
      </Link>
    )
  }
)
