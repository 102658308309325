import { useTranslation } from 'react-i18next'

import { OrderType as IOrderType } from '@sherweb/core/openapi-generated/index.defs'

type OrderTypeProps = {
  orderType?: IOrderType | null
}

export const OrderType = ({ orderType }: OrderTypeProps) => {
  const { t } = useTranslation()

  if (orderType === IOrderType.Amendment) {
    return <div>{t('core:orders.types.update')}</div>
  }

  return <div>{t('core:orders.types.purchase')}</div>
}
