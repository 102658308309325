import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'

import { protectPage } from '@ssp/app/ProtectedPage'
import ContainedPage from '@ssp/components/layout/ContainedPage'

import OrdersTable from './OrdersTable/OrdersTable'
import OrdersTableMobile from './OrdersTableMobile/OrdersTableMobile'

const OrdersPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <ContainedPage>
        <div className="flex flex-col gap-12 md:gap-16">
          <PageTitleWrapper
            title={
              <PageTitle data-testid="pageOrdersTitle">{t('ssp:pages.orders.title')}</PageTitle>
            }
            subTitle={<PageSubTitle>{t('ssp:pages.orders.subtitle')}</PageSubTitle>}
          />
          <div className="hidden lg:block">
            <OrdersTable />
          </div>
          <div className="mt-4 lg:hidden">
            <OrdersTableMobile />
          </div>
        </div>
      </ContainedPage>
      <Outlet />
    </>
  )
}

export default protectPage(OrdersPage, Permission.OrderList)
