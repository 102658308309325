import { useContext } from 'react'

import { PromptContext } from './prompt.context'

export const usePromptContext = () => {
  const context = useContext(PromptContext)

  if (!context) {
    throw new Error('usePrompt must be used within a PromptProvider')
  }

  return context
}
