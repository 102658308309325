import { useEffect } from 'react'

import { useLocation } from '..'

/**
 * this scroll to top when we change page (like regular routing)
 */
const ScrollRestoration: React.FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window?.scrollTo?.(0, 0)
  }, [pathname])

  return null
}

export default ScrollRestoration
