import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Backlink from '@sherweb/core/components/Backlink'
import Card from '@sherweb/core/components/Card'
import PageTitle, { PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import Spinner from '@sherweb/core/components/Spinner'
import { Permission } from '@sherweb/core/modules/authorization'
import { validateUuid } from '@sherweb/core/utils/uuid'

import { protectPage } from '@ssp/app/ProtectedPage'
import Routes from '@ssp/app/Routes'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import Link from '@ssp/components/Link'
import { useGetUserQuery } from '@ssp/modules/user'
import UserForm from '@ssp/modules/user/ui'

import { UserLicenses } from './UserLicenses'

const UserEditPage = () => {
  const { t } = useTranslation()
  const params = useParams()

  const userId = validateUuid(params.userId) ? String(params.userId) : undefined

  const userQuery = useGetUserQuery(userId)

  const hasLicenses = userQuery?.data?.licenses && userQuery?.data?.licenses.length > 0

  return (
    <ContainedPage>
      {userQuery?.isLoading || !userQuery?.data || !userId ? (
        <Spinner floating />
      ) : (
        <div className="flex flex-col gap-12 md:gap-16">
          <div className="flex flex-col gap-2">
            <Link to={Routes.Users} data-testid="linkBackToUsers">
              <Backlink>{t('ssp:pages.user.backlink')}</Backlink>
            </Link>
            <PageTitleWrapper
              title={
                <PageTitle data-testid="pageEditUserTitle">
                  {userQuery?.data?.firstName} {userQuery?.data?.lastName}
                </PageTitle>
              }
            />
          </div>
          <Card padded>
            <UserForm user={userQuery?.data} />
          </Card>
          {hasLicenses ? (
            <Card padded>
              <UserLicenses user={userQuery?.data} />
            </Card>
          ) : null}
        </div>
      )}
    </ContainedPage>
  )
}

export default protectPage(UserEditPage, Permission.UsersEdit)
