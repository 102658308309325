import { useCallback } from 'react'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'

export const useQueryParams = <T extends string>() => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getParams = useCallback(
    <R = string>(key: T) => (searchParams.get(key) ?? '') as R,
    [searchParams]
  )

  const handleSearchParam = useCallback(
    (newSearchParam: URLSearchParams | ((prev: URLSearchParams) => URLSearchParamsInit)) => {
      setSearchParams(newSearchParam, { replace: true })
    },
    [setSearchParams]
  )

  const setArrayParams = useCallback(
    (key: T, arr: string) => {
      handleSearchParam(previousParam => {
        const value = previousParam.get(key)
          ? [previousParam.get(key), arr].join(',')
          : [arr].join(',')

        if (value.length !== 0) {
          previousParam.set(
            key,
            previousParam.get(key) ? [previousParam.get(key), arr].join(',') : [arr].join(',')
          )
        } else {
          searchParams.delete(key)
        }

        return previousParam
      })
    },
    [handleSearchParam, searchParams]
  )

  const updateSearchParams = useCallback(
    (key: T, value?: string) => {
      handleSearchParam(previousParam => {
        if (value !== '' && value !== undefined) {
          previousParam.set(key, value ?? '')
        } else {
          searchParams.delete(key)
        }

        return previousParam
      })
    },
    [handleSearchParam, searchParams]
  )

  const getArrayParam = useCallback(
    <R = string>(key: T) => {
      const param = getParams(key)

      return (param ? param.split(',') : []) as R[]
    },
    [getParams]
  )

  const removeSearchParam = useCallback(
    (key: T) => {
      if (searchParams.has(key)) {
        searchParams.delete(key)
        handleSearchParam(searchParams)
      }
    },
    [searchParams, handleSearchParam]
  )

  const removeArrayParam = useCallback(
    (key: T, value = '') => {
      if (searchParams.has(key)) {
        const values = getParams(key)
          .split(',')
          .filter(v => v !== value)

        if (values.length === 0) {
          searchParams.delete(key)
          handleSearchParam(searchParams)
        } else {
          handleSearchParam(previousParam => {
            previousParam.set(key, values.join(','))

            return previousParam
          })
        }
      }
    },
    [getParams, searchParams, handleSearchParam]
  )

  const removeAllQueryParams = useCallback(() => {
    handleSearchParam({} as unknown as URLSearchParams)
  }, [handleSearchParam])

  return {
    searchParams,
    getParams,
    setSearchParams: updateSearchParams,
    removeAllQueryParams,
    removeSearchParam,
    setArrayParams,
    getArrayParam,
    removeArrayParam,
  }
}
