import {
  ArrowRightStartOnRectangleIcon,
  CheckIcon,
  EyeIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  OrderStatus,
  OrderType,
  ProcessOrderDecision,
} from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import { Permission } from '@sherweb/core/modules/authorization'
import { usePathGenerator } from '@sherweb/core/modules/browserRouter'
import { Order } from '@sherweb/core/modules/orders/core/orders.model'
import { EMPTY_GUID } from '@sherweb/core/utils/const'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import Routes from '@rsp/app/Routes'
import Link from '@rsp/components/Link'
import { HasAccess } from '@rsp/modules/authorization'
import { useProcessOrderMutation } from '@rsp/modules/orders'

import { SubscriptionStatus } from '@ssp/modules/subscription'

import { OrderAprovalConfirmationDialog } from '../components/OrderAprovalConfirmationDialog'

export type OrderActionsProps = {
  order: Order
  className?: string
}

export const OrderActions = ({ order, className }: OrderActionsProps) => {
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)
  const [decision, setDecision] = useState<ProcessOrderDecision | null>(null)
  const processOrderMutation = useProcessOrderMutation()
  const pathGenerator = usePathGenerator()

  const decisionFlag = order?.orderStatus === OrderStatus.WaitingForApproval

  const viewSubscriptionFlag =
    order?.firstSubscriptionId !== EMPTY_GUID &&
    order?.firstSubscriptionStatus !== SubscriptionStatus.Inactive &&
    order?.organizationUniqueName

  const viewSubscriptionOptionFlag =
    order.orderType === OrderType.Amendment &&
    order.firstSubscriptionStatus !== SubscriptionStatus.Inactive

  const { t } = useTranslation()

  const setDecisionAndOpenDialog = (decision: ProcessOrderDecision) => {
    setDecision(decision)
    setConfirmationDialogOpened(true)
  }

  const cancelConfirmation = () => {
    setDecision(null)
    setConfirmationDialogOpened(false)
  }

  const navigateToSubscriptionView = () => {
    if (viewSubscriptionFlag) {
      const subscriptionPath = pathGenerator(Routes.Subscription, {
        subscriptionId: order?.firstSubscriptionId ?? '',
        subscriptionStatus: order?.firstSubscriptionStatus ?? '',
        organizationUniqueName: order?.organizationUniqueName ?? '',
      })
      window.open(subscriptionPath, '_blank')
    }
  }

  const onSubmit = (decision: ProcessOrderDecision | null, decisionNote?: string) => {
    processOrderMutation.mutate({
      orderIds: [order?.id],
      organizationId: order?.organizationId ?? '',
      decision: decision ?? undefined,
      decisionNote,
    })
  }

  return (
    <>
      <div
        className={mergeClassName('flex flex-row gap-4', className)}
        data-testid="divOrderActions"
      >
        <Button asChild variant={Variant.Outline} size="sm" data-testid="btnOrderViewDetail">
          <Link to={Routes.Order} params={{ orderId: order.id }}>
            <EyeIcon className="mr-1 h-5 w-5  hover:bg-slate-50" />
            {t('rsp:pages.orders.list.actions.viewDetailsMobile')}
          </Link>
        </Button>
        {viewSubscriptionOptionFlag && (
          <Button
            variant={Variant.Primary}
            size="sm"
            onClick={navigateToSubscriptionView}
            data-testid="btnViewSubscription"
          >
            <ArrowRightStartOnRectangleIcon className="mr-1 h-5 w-5 stroke-2" />
            {t('rsp:pages.orders.list.actions.viewSubscriptionMobile')}
          </Button>
        )}
        {decisionFlag && (
          <HasAccess permission={Permission.OrderApproval}>
            <Button
              variant={Variant.Success}
              size="sm"
              onClick={() => setDecisionAndOpenDialog(ProcessOrderDecision.Approve)}
              data-testid="btnApprove"
            >
              <CheckIcon className="mr-1 h-5 w-5  stroke-2" />{' '}
              {t('rsp:pages.orders.list.actions.approve')}
            </Button>
            <Button
              variant={Variant.Danger}
              size="sm"
              onClick={() => setDecisionAndOpenDialog(ProcessOrderDecision.Reject)}
              data-testid="btnReject"
            >
              <XMarkIcon className="mr-1 h-5 w-5 stroke-2" />
              {t('rsp:pages.orders.list.actions.reject')}
            </Button>
          </HasAccess>
        )}
      </div>
      {decisionFlag && decision && (
        <HasAccess permission={Permission.OrderApproval}>
          <OrderAprovalConfirmationDialog
            decision={decision}
            cancelConfirmation={cancelConfirmation}
            onSubmit={onSubmit}
            order={order}
            confirmationDialogOpened={confirmationDialogOpened}
          />
        </HasAccess>
      )}
    </>
  )
}
