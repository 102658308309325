import React from 'react'

import Spinner from '../../components/Spinner'
import { ErrorBoundary } from '../../modules/datadog'
import { ServerError } from './ServerError'

export const withLazyLoad = (
  page: React.ReactNode,
  { showLoader = true, fallback }: { showLoader?: boolean; fallback?: React.ReactNode } = {}
) => (
  <ErrorBoundary fallback={<ServerError />}>
    <React.Suspense fallback={showLoader ? fallback ?? <Spinner floating /> : null}>
      {page}
    </React.Suspense>
  </ErrorBoundary>
)
