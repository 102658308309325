import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@sherweb/core/components/Drawer'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import { usePrompt } from '@sherweb/core/components/Prompt'
import { Skeleton, SkeletonTable } from '@sherweb/core/components/Skeleton'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import { Typography } from '@sherweb/core/components/Typography'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'

import { protectPage } from '@rsp/app/ProtectedPage'
import Routes from '@rsp/app/Routes'
import { useGetAvailableProductsQuery, useGetCatalogQuery } from '@rsp/modules/martkeplace'
import { useNavigate } from '@rsp/modules/navigation'

import { useGetCatalogId } from '../hooks/useGetCatalogId'
import { MarketplaceProductsAllFilters } from './components/MarketplaceProductsAllFilters'
import { MarketplaceProductsSearch } from './components/MarketplaceProductsSearch'
import { useGetFilteredProducts } from './hooks/useGetFilteredProducts'
import { useSelectedOffersChanged } from './hooks/useSelectedOffersChanged'
import { useUpdateCatalogProducts } from './hooks/useUpdateCatalogProducts'
import { MarketplaceCatalogsAddProductsConfirmationModal } from './MarketplaceCatalogsAddProductsConfirmationModal'
import { MarketplaceCatalogsAddProductsPartialSuccessDialog } from './MarketplaceCatalogsAddProductsPartialSuccessDialog'
import { MarketplaceCatalogsAddProductsTable } from './MarketplaceCatalogsAddProductsTable'

const MarketplaceCatalogsAddProductsPage = () => {
  const { t } = useTranslation()

  const [isDrawerOpen, setIsDrawerOpen] = useState(true)

  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false)

  const [isPartialDialogOpened, setIsPartialDialogOpened] = useState(false)

  const { catalogId } = useGetCatalogId()

  const availableProductsQuery = useGetAvailableProductsQuery(catalogId)

  const isLoading = isRequestLoading(availableProductsQuery)

  const catalogQuery = useGetCatalogQuery(catalogId)

  const { navigate } = useNavigate()

  const { selectedOffers, setSelectedOffers, filteredProducts } = useGetFilteredProducts(
    availableProductsQuery?.data
  )

  const isDirty = useSelectedOffersChanged({
    alreadyAddedOffers: availableProductsQuery?.data?.selectedOffers ?? [],
    selectedOffers,
  })

  const redirectToCatalogProductsPage = useCallback(() => {
    navigate(Routes.MarketplaceCatalogProducts, {
      catalogId,
    })

    if (!isDirty) {
      setIsDrawerOpen(false)
    }
  }, [catalogId, isDirty, navigate])

  const handleUpdateCatalog = useCallback(() => {
    setIsConfirmationOpened(true)
  }, [])

  const handleCloseConfirmation = () => {
    setIsConfirmationOpened(false)
  }

  const handleSubmitConfirmation = () => {
    onUpdateCatalogProducts(availableProductsQuery?.data?.selectedOffers ?? [], selectedOffers)
  }

  const openPartialDialog = useCallback(() => {
    setIsPartialDialogOpened(true)
  }, [])

  const closePartialDialog = () => {
    setIsPartialDialogOpened(false)
    redirectToCatalogProductsPage()
  }

  const {
    isUpdateCatalogLoading,
    failedProducts,
    successfulProductsNumber,
    onUpdateCatalogProducts,
  } = useUpdateCatalogProducts({
    catalogOffers: availableProductsQuery?.data?.allOffers ?? [],
    onSuccess: redirectToCatalogProductsPage,
    onPartialSuccess: openPartialDialog,
  })

  usePrompt({
    isDirty,
    noConfirmationWithChangeOfQueryParamter: true,
  })

  return (
    <>
      <Drawer open={isDrawerOpen}>
        <DrawerContent
          className="ml-0 flex h-5/6 flex-col outline-none lg:ml-[260px] lg:mr-[4px]"
          onEscapeKeyDown={redirectToCatalogProductsPage}
        >
          {isUpdateCatalogLoading ? (
            <Spinner className={spinnerStyles({ type: 'layOverSpinner' })} />
          ) : null}
          <>
            <DrawerHeader
              className="p-4 pb-8 pr-12 text-start md:pl-12"
              onClose={redirectToCatalogProductsPage}
            >
              <DrawerTitle className="dark:text-white">
                {isRequestLoading(catalogQuery) ? (
                  <Skeleton className="h-[18px] w-[264px]" />
                ) : (
                  t('rsp:pages.marketplace.addProducts.title', { name: catalogQuery?.data?.name })
                )}
              </DrawerTitle>
              <DrawerDescription className="dark:text-white">
                {t('rsp:pages.marketplace.addProducts.description')}
              </DrawerDescription>
              <div className="mt-6 flex flex-col gap-4">
                <MarketplaceProductsSearch />
                <div className="wrap flex flex-col items-start justify-between gap-4 md:flex-row md:items-center">
                  <MarketplaceProductsAllFilters
                    facets={availableProductsQuery?.data?.facets}
                    isLoading={isLoading}
                  />
                  {isLoading ? (
                    <Skeleton className="h-[24px] w-[100px]" />
                  ) : (
                    <Typography variant="body2" className="whitespace-nowrap pl-4 md:pl-0 md:pr-4">
                      {t('rsp:pages.marketplace.addProducts.productsSelected', {
                        count: selectedOffers.length,
                      })}
                    </Typography>
                  )}
                </div>
              </div>
            </DrawerHeader>
            {isLoading ? (
              <div className="p-4 md:pl-12">
                <SkeletonTable count={4} />
              </div>
            ) : (
              <MarketplaceCatalogsAddProductsTable
                filteredProducts={filteredProducts}
                offers={selectedOffers}
                onSelectOffers={setSelectedOffers}
              />
            )}
            <DrawerFooter className="sticky bottom-0 px-4 sm:py-6 md:pr-12 lg:py-8">
              <div className="flex justify-end gap-4">
                <Button
                  variant={Variant.Outline}
                  data-testid="btnCancelManageCatalog"
                  onClick={redirectToCatalogProductsPage}
                >
                  {t('core:actions.cancel')}
                </Button>
                <LoaderButton
                  disabled={isLoading || !isDirty || isUpdateCatalogLoading}
                  dataTestId="btnUpdateCatalog"
                  isLoading={isUpdateCatalogLoading}
                  onClick={handleUpdateCatalog}
                >
                  {t('rsp:pages.marketplace.addProducts.updateCatalog')}
                </LoaderButton>
              </div>
            </DrawerFooter>
          </>
        </DrawerContent>
      </Drawer>
      <MarketplaceCatalogsAddProductsConfirmationModal
        open={isConfirmationOpened}
        onClose={handleCloseConfirmation}
        changedProductsCount={selectedOffers.length}
        onSubmit={handleSubmitConfirmation}
      />
      <MarketplaceCatalogsAddProductsPartialSuccessDialog
        open={isPartialDialogOpened}
        onClose={closePartialDialog}
        failedProducts={failedProducts}
        productsNumber={successfulProductsNumber}
      />
    </>
  )
}

export default protectPage(MarketplaceCatalogsAddProductsPage, Permission.CatalogProductsEdit)
