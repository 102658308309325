import config from '@config'

import { createClient } from '@sherweb/core/modules/reactQuery'

const { bff: api, whiteListed401Paths } = config

const reactQueryClient = createClient({
  api,
  whiteListed401Paths,
})

reactQueryClient.setDefaultOptions({
  queries: {
    staleTime: 10 * 1000,
    cacheTime: 60 * 1000,
  },
})

export default reactQueryClient
