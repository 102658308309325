import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { DataTestId } from '@sherweb/core/types/dataTestIdType'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'
import { convertToCamelCase } from '@sherweb/core/utils/string'

interface NoResultsProps extends DataTestId {
  hasBorder?: boolean
  emptyMessage?: React.ReactNode
  className?: string
}

export const NoResults = ({
  emptyMessage,
  hasBorder = true,
  dataTestId,
  className,
}: NoResultsProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={mergeClassName(
        'flex w-full flex-col items-center justify-center gap-4 rounded-xl p-8 font-normal text-gray-300 md:h-60',
        {
          'border-2 border-dashed border-gray-300': hasBorder,
        },
        className
      )}
      data-testid={`noResults${convertToCamelCase(dataTestId)}`}
    >
      <DocumentMagnifyingGlassIcon className="h-16 w-16 stroke-1" />
      <p>{emptyMessage ?? t('core:dataTable.noResults')}</p>
    </div>
  )
}
