import {
  InvoicesQuery,
  InvoiceQuerySort,
  InvoiceSortField,
  PaginatedResultsOfListOfOrganizationInvoiceQueryResult,
  OrganizationInvoiceQueryResult,
  InvoiceType,
  InvoiceStatus,
  BillingAddress,
  Money,
  Currency,
  InvoiceFormat,
  PaymentRestrictions,
  PayOrganizationInvoiceCommand,
  PayOrganizationInvoiceResult,
  PaymentDecision,
  PaymentMethodType,
  TransactionDeclinedReason,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class OrganizationInvoicesService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static queryOrganizationInvoicesAsync(
    params: {
      /**  */
      organizationId: string;
      /**  */
      query: InvoicesQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedResultsOfListOfOrganizationInvoiceQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/invoices/query';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['query'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInvoiceDetailsAsync(
    params: {
      /**  */
      organizationId: string;
      /**  */
      invoiceId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationInvoiceQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/invoices/{invoiceId}';
      url = url.replace('{organizationId}', params['organizationId'] + '');
      url = url.replace('{invoiceId}', params['invoiceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadInvoiceAsync(
    params: {
      /**  */
      organizationId: string;
      /**  */
      invoiceId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/invoices/{invoiceId}/download';
      url = url.replace('{organizationId}', params['organizationId'] + '');
      url = url.replace('{invoiceId}', params['invoiceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static payOrganizationInvoice(
    params: {
      /**  */
      organizationId: string;
      /**  */
      invoiceId: string;
      /**  */
      command: PayOrganizationInvoiceCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayOrganizationInvoiceResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/invoices/{invoiceId}/pay';
      url = url.replace('{organizationId}', params['organizationId'] + '');
      url = url.replace('{invoiceId}', params['invoiceId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
