import {
  Theme as ApiTheme,
  ImageResultDto,
  ImageType,
  PortalSettingsQueryResult,
  ResellersQueryResult,
  SupportInformationQueryResult,
  Theme,
} from '@sherweb/core/openapi-generated/index.defs'

import { Reseller, ResellerPortalSettings, SupportInformation } from './reseller.models'

export const buildSettings = (p: PortalSettingsQueryResult | undefined): ResellerPortalSettings => {
  const addHash = (url?: string, hash?: number) => {
    if (url && hash) {
      return `${url}?${hash}`
    }
    return url
  }

  return {
    logo: addHash(getImageByType(p?.images, ImageType.Logo)?.url ?? '', p?.logoHash ?? 0),
    logoHash: p?.logoHash ?? 0,
    theme: mapTheme(p?.theme),
    hostName: p?.hostName,
  }
}

export const getImageByType = (images: ImageResultDto[] | undefined, type: ImageType) => {
  return images?.find(p => p.type === type)
}

export const mapTheme = (theme?: ApiTheme): Theme | undefined => {
  switch (theme) {
    case ApiTheme.Green:
      return Theme.Green
    case ApiTheme.Default:
    default:
      return Theme.Default
  }
}

export const buildReseller = (response?: ResellersQueryResult): Reseller => {
  return {
    id: response?.id ?? '',
    name: response?.name ?? '',
    isOnboarded: response?.isOnboarded ?? false,
    portalSettings: buildSettings(response?.portalSettings),
    supportInformation: buildSupportInformation(response?.supportInformation),
  }
}

export const buildSupportInformation = (
  p: SupportInformationQueryResult | undefined
): SupportInformation => {
  return {
    address: p?.address ?? '',
    email: p?.email ?? '',
    phoneNumber: p?.phoneNumber ?? '',
  }
}
