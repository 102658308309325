import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CommitmentTermType } from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant as ButtonVariant } from '@sherweb/core/components/Button'
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@sherweb/core/components/Dialog'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import { useQueryParams } from '@sherweb/core/hooks'

import { useGetProductById } from '@ssp/modules/shop'
import {
  useBillingCyclePeriodSummaryTranslation,
  useBillingCycleSummaryTranslation,
  useContractDurationCommitmentTermTranslation,
} from '@ssp/modules/shop/core/shop.helpers'

import { useGetFormattedTotalAmount } from '../hooks/useGetFormattedTotalAmount'
import { useGetOffersByCommitmentTerm } from '../hooks/useGetOffersByCommitmentTerm'

type ShopDetailCreateSubscriptionContentProps = {
  isLoading?: boolean
  onConfirm: () => void
  onCancel: () => void
}

export const ShopDetailCreateSubscriptionContent = ({
  isLoading = false,
  onConfirm,
  onCancel,
}: ShopDetailCreateSubscriptionContentProps) => {
  const { t } = useTranslation()

  const { shopProductId } = useParams()

  const productQuery = useGetProductById(shopProductId)

  const { selectedOffer } = useGetOffersByCommitmentTerm()

  const { formattedTotalAmount } = useGetFormattedTotalAmount()

  const getBillingCycleSummaryTranslation = useBillingCycleSummaryTranslation()

  const getBillingCyclePeriodSummaryTranslation = useBillingCyclePeriodSummaryTranslation()

  const getContractDurationCommitmentTermTranslation =
    useContractDurationCommitmentTermTranslation()

  const { getParams } = useQueryParams<'productQuantity'>()

  const selectedQuantityParam = getParams('productQuantity')

  return (
    <>
      <DialogHeader>
        <DialogTitle>{t('ssp:pages.shop.detail.confirmationModal.title')}</DialogTitle>
      </DialogHeader>
      <DialogDescription asChild data-testid="dialogDescriptionContent">
        <div className="text-slate-900">
          {selectedOffer?.commitmentTermType === CommitmentTermType.Unknown ? (
            <Trans
              i18nKey="ssp:pages.shop.detail.confirmationModal.bodyWithoutCommitmentTerm"
              values={{
                productName: productQuery?.data?.name,
                billingCycle: getBillingCycleSummaryTranslation(selectedOffer?.billingCycle),
                totalAmount: formattedTotalAmount,
                billingCyclePeriod: getBillingCyclePeriodSummaryTranslation(
                  selectedOffer?.billingCycle
                ),
                count: Number(selectedQuantityParam),
              }}
              components={{ 1: <br />, 2: <b /> }}
            />
          ) : (
            <Trans
              i18nKey="ssp:pages.shop.detail.confirmationModal.bodyWithCommitmentTerm"
              values={{
                productName: productQuery?.data?.name,
                billingCycle: getBillingCycleSummaryTranslation(selectedOffer?.billingCycle),
                commitmentTerm: getContractDurationCommitmentTermTranslation(
                  selectedOffer?.commitmentTermType
                ),
                totalAmount: formattedTotalAmount,
                billingCyclePeriod: getBillingCyclePeriodSummaryTranslation(
                  selectedOffer?.billingCycle
                ),
                count: Number(selectedQuantityParam),
              }}
              components={{ 1: <br />, 2: <b /> }}
            />
          )}
        </div>
      </DialogDescription>
      <DialogFooter>
        <Button
          data-testid="dialogBtnCancel"
          onClick={onCancel}
          disabled={isLoading}
          size="sm"
          variant={ButtonVariant.Outline}
        >
          {t('ssp:actions.cancel')}
        </Button>

        <LoaderButton
          onClick={() => {
            if (!isLoading) {
              onConfirm()
            }
          }}
          size="sm"
          dataTestId="dialogBtnConfirm"
          isLoading={isLoading}
          loadingChildren={t('ssp:pages.shop.detail.confirmationModal.creatingOrder')}
        >
          {t('ssp:pages.shop.detail.confirmationModal.createOrder')}
        </LoaderButton>
      </DialogFooter>
    </>
  )
}
