import { ProcessOrderDecision } from '@sherweb/core/openapi-generated/index.defs'

import { validateUuid } from '@sherweb/core/utils/uuid'

import { useParams } from '@rsp/modules/navigation'

export const useOrderApprovalDecisionProps = () => {
  const params = useParams()
  const orderId = validateUuid(params.orderId) ? String(params.orderId) : undefined
  const decisionParam = String(params.decision)?.toLowerCase()
  const decision =
    decisionParam === ProcessOrderDecision.Approve.toLowerCase()
      ? ProcessOrderDecision.Approve
      : ProcessOrderDecision.Reject
  const havingRequiredProps = !!orderId && !!decisionParam

  return { havingRequiredProps, orderId, decision }
}
