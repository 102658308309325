import { BaseSyntheticEvent } from 'react'

import Card from '@sherweb/core/components/Card'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

type RowProps = {
  leftContent: React.ReactNode
  children: React.ReactNode
  contentContainerClassName?: string
}

type WrapperProps = {
  children?: React.ReactNode
  className?: string
}

type LeftContentProps = {
  label?: string | React.ReactNode
  subLabel?: string | React.ReactNode
}

type FormSplitScreenProps = {
  children?: React.ReactNode
  className?: string
  onSubmit?: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}

const FormSplitScreen = ({ children, className, onSubmit }: FormSplitScreenProps) => (
  <form
    onSubmit={onSubmit}
    className={mergeClassName(
      'my-4 flex flex-col gap-y-16 rounded-xl p-4 dark:bg-slate-900 md:px-8 md:py-16',
      className
    )}
  >
    {children}
  </form>
)

const Wrapper = ({ children, className = '' }: WrapperProps) => (
  <div className={mergeClassName('grid-col-1 grid gap-y-4 md:grid-cols-2 md:gap-y-0', className)}>
    {children}
  </div>
)

const Row = ({ leftContent, children, contentContainerClassName = '' }: RowProps) => (
  <>
    {leftContent}
    <div className="w-full">
      <div className="flex flex-col gap-8">
        <div className={mergeClassName('grid grid-cols-1 gap-y-4', contentContainerClassName)}>
          {children}
        </div>
      </div>
    </div>
  </>
)

const RowCard = ({ leftContent, children, contentContainerClassName = '' }: RowProps) => (
  <>
    {leftContent}
    <div className="w-full">
      <div className="flex flex-col gap-8">
        <Card
          padded={false}
          className={mergeClassName('grid grid-cols-1 gap-y-4 p-6', contentContainerClassName)}
        >
          {children}
        </Card>
      </div>
    </div>
  </>
)

const LeftContent = ({ label, subLabel }: LeftContentProps) => (
  <div className="flex w-full flex-col gap-y-1 sm:w-9/12">
    <label className="font-semibold" data-testid="textFormLabel">
      {label}
    </label>
    <div className="text-xs text-gray-500 dark:text-slate-300" data-testid="textFormSubLabel">
      {subLabel}
    </div>
  </div>
)

FormSplitScreen.Row = Row
FormSplitScreen.RowCard = RowCard
FormSplitScreen.LeftContent = LeftContent
FormSplitScreen.Wrapper = Wrapper

export default FormSplitScreen
