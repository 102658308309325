import { useTranslation } from 'react-i18next'

import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import { Permission } from '@sherweb/core/modules/authorization'

import { protectPage } from '@ssp/app/ProtectedPage'
import ContainedPage from '@ssp/components/layout/ContainedPage'

import { UsersTable } from './UsersTable/UsersTable'
import { UsersTableMobile } from './UsersTableMobile/UsersTableMobile'

const UsersPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ContainedPage>
      <div className="flex flex-col gap-12 md:gap-16">
        <PageTitleWrapper
          title={<PageTitle data-testid="pageUsersTitle">{t('ssp:pages.users.title')}</PageTitle>}
          subTitle={<PageSubTitle>{t('ssp:pages.users.pageDescription')}</PageSubTitle>}
        />
        <div className="hidden lg:block" data-testid="containerUsersTable">
          <UsersTable />
        </div>
        <div className="mt-4 lg:hidden" data-testid="containerUsersTableMobile">
          <UsersTableMobile />
        </div>
      </div>
    </ContainedPage>
  )
}

export default protectPage(UsersPage, Permission.UsersList)
