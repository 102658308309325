import { useTranslation } from 'react-i18next'

import { OrderSortField } from '@sherweb/core/openapi-generated/index.defs'

import Card from '@sherweb/core/components/Card'
import DataTableServerPagination from '@sherweb/core/components/DataTable/DataTableServerPagination'
import { useServerPagination } from '@sherweb/core/components/DataTable/hooks/useServerPagination'
import { useSetTablePaginationParams } from '@sherweb/core/components/DataTable/hooks/useSetTablePaginationParams'
import { Columns } from '@sherweb/core/components/DataTable/types'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import { getOrdersPaginationDefaultValues } from '@sherweb/core/modules/orders/core/orders.helpers'
import { Order } from '@sherweb/core/modules/orders/core/orders.model'
import { OrderStatus } from '@sherweb/core/modules/orders/ui/OrderStatus'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'

import { useGetOrdersQuery } from '@rsp/modules/orders'

import { OrdersTableRowActions } from './components/OrdersTableRowActions'
import { OrdersTableAmountRow } from './OrdersTableAmountRow'

const renderOrderValue = (order: Order) => <OrdersTableAmountRow order={order} />

const renderActionsDropdown = (order: Order) => <OrdersTableRowActions order={order} />

const renderOrderId = (order: Order) => <div className="font-medium">{order?.humanReadableId}</div>

const renderOrderStatus = (order: Order) => <OrderStatus orderStatus={order.orderStatus} />

const defaultMeta = { className: 'align-top' }

const OrdersTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const { currentPage, currentPageSize, onSetPaginationParams } = useSetTablePaginationParams()

  const { options, setOptions, onSortBy } = useServerPagination<OrderSortField>(
    getOrdersPaginationDefaultValues({ page: currentPage, pageSize: currentPageSize })
  )

  const ordersQuery = useGetOrdersQuery(options)

  const columns: Columns<Order> = [
    {
      accessorKey: 'humanReadableId',
      meta: { ...defaultMeta },
      header: t('rsp:pages.orders.list.orderId'),
      enableSorting: false,
      cell: ({ row }) => renderOrderId(row.original),
      minSize: 100,
    },
    {
      accessorKey: 'organizationName',
      header: t('rsp:pages.orders.list.organizationName'),
      meta: { ...defaultMeta },
      enableSorting: false,
      cell: ({ row }) => row.original.organizationName,
      minSize: 280,
    },
    {
      accessorKey: OrderSortField.CreatedOn,
      header: t('rsp:pages.orders.list.createdOn'),
      meta: { ...defaultMeta },
      cell: ({ row }) => formatDate(row.original.createdOn),
      minSize: 150,
    },
    {
      accessorKey: 'orderValue',
      meta: { ...defaultMeta },
      header: t('rsp:pages.orders.list.orderValue'),
      enableSorting: false,
      cell: ({ row }) => renderOrderValue(row.original),
    },
    {
      accessorKey: 'orderStatus',
      meta: { className: `${defaultMeta.className} text-center` },
      header: t('rsp:pages.orders.list.status'),
      enableSorting: false,
      cell: ({ row }) => renderOrderStatus(row.original),
      minSize: 20,
    },
    {
      id: 'actions',
      meta: { ...defaultMeta },
      enableSorting: false,
      cell: ({ row }) => renderActionsDropdown(row.original),
      minSize: 20,
    },
  ]

  return (
    <Card padded>
      {isRequestLoading(ordersQuery) ? (
        <SkeletonTable />
      ) : (
        <DataTableServerPagination
          columns={columns}
          data={ordersQuery?.data}
          dataTestId="tableOrders"
          isFetching={ordersQuery.isFetching}
          filterPlaceholder={t('rsp:pages.orders.list.filterPlaceholder')}
          fieldDescription={t('rsp:pages.orders.list.searchFieldDescription')}
          onSortBy={onSortBy}
          setPageParams={setOptions}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          onSetPaginationParams={onSetPaginationParams}
        />
      )}
    </Card>
  )
}

export default OrdersTable
