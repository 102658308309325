import { datadogRum } from '@datadog/browser-rum'

export const sendDatadogError = (error: any, details?: Record<string, unknown>) => {
  try {
    /* istanbul ignore next ; ignore missing lib in tests */
    if (!datadogRum) {
      return
    }
    datadogRum.addError(error, details)
  } catch (error) {
    console.error(error)
  }
}
