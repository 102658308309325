import { useCallback } from 'react'

import { usePrevious, useQueryParams } from '@sherweb/core/hooks'

export const useSetTableSearchParams = () => {
  const { getParams, setSearchParams } = useQueryParams<'search'>()

  const searchValue = getParams<string>('search')

  const previousSearchValue = usePrevious(searchValue)

  const onSetSearchParams = useCallback(
    (searchValue: string) => {
      setSearchParams('search', searchValue)
    },
    [setSearchParams]
  )

  return {
    search: searchValue,
    hasValueChanged: previousSearchValue !== searchValue,
    onSetSearchParams,
  }
}
