/* eslint-disable @typescript-eslint/no-misused-promises */
import { useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import Alert, { Variant as AlertVariant } from '../Alert/Alert'
import Button, { Variant as ButtonVariant } from '../Button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogTitle } from '../Dialog'
import Slider from '../Slider'
import Spinner from '../Spinner/Spinner'
import { acceptedFiles, editorPadding, transparentImageData } from './const'
import { getTrimmedCanvas } from './utils'

const DEFAULT_IMAGE_WIDTH = 300
const DEFAULT_IMAGE_HEIGHT = 300
const DEFAULT_IMAGE_FORMAT = 'image/png'
const DEFAULT_IMAGE_NAME = 'file.png'

type ImageUploaderDialogProps = {
  open?: boolean
  onClose?: () => void
  onSave?: (file: File) => void | Promise<void>
  title?: string
  imageWidth?: number
  imageHeight?: number
  imageType?: string
  imageName?: string
  trimVertically?: boolean
}

const ImageUploaderDialog: React.FC<ImageUploaderDialogProps> = ({
  open = false,
  onClose,
  onSave,
  title,
  imageWidth = DEFAULT_IMAGE_WIDTH,
  imageHeight = DEFAULT_IMAGE_HEIGHT,
  imageType = DEFAULT_IMAGE_FORMAT,
  imageName = DEFAULT_IMAGE_NAME,
  trimVertically = false,
}) => {
  const { t } = useTranslation()
  const [image, setImage] = useState<string | File>()
  const [scale, setScale] = useState(1)
  const [uploading, setUploading] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const editor = useRef<AvatarEditor>(null)
  const {
    getRootProps,
    getInputProps,
    open: openUploadDialog,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: dropped => setImage(dropped[0]),
    accept: acceptedFiles,
  })

  const closeDialog = () => {
    setImage(undefined)
    setScale(1)
    setUploading(false)
    setUploadError(false)
    onClose?.()
  }

  /* istanbul ignore next ; ignore image manipulation */
  const getImageBlob = async (): Promise<Blob> =>
    await new Promise((resolve, reject) => {
      if (!editor.current) {
        return reject(new Error())
      }
      const canvas = trimVertically
        ? getTrimmedCanvas(editor.current.getImageScaledToCanvas())
        : editor.current.getImageScaledToCanvas()

      canvas.toBlob(blob => (blob ? resolve(blob) : reject(new Error())), imageType)
    })

  const saveImage = async () => {
    /* istanbul ignore next ; ignore image manipulation */
    if (!editor.current) {
      return
    }

    try {
      setUploading(true)
      const file = new File([await getImageBlob()], imageName, { type: DEFAULT_IMAGE_FORMAT })
      await onSave?.(file)
      closeDialog()
    } catch (error) {
      setUploadError(true)
      console.error(error)
    } finally {
      setUploading(false)
    }
  }

  /* istanbul ignore next ; ignore image manipulation */
  const onScaleValueChange = (values: number[]) => setScale(values[0])

  return (
    <Dialog open={open} onOpenChange={closeDialog}>
      <DialogContent style={{ width: imageWidth + editorPadding * 2 }}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogDescription asChild>
          <div>
            {uploading || uploadError ? (
              <div
                className="flex items-center justify-center"
                style={{ width: imageWidth + editorPadding, height: imageWidth + editorPadding }}
              >
                {uploading && <Spinner />}
                {uploadError && !uploading && (
                  <Alert variant={AlertVariant.Danger}>{t('core:errors.generic')}</Alert>
                )}
              </div>
            ) : (
              <div {...getRootProps()}>
                <input {...getInputProps()} data-testid="upload-input" />

                {image ? (
                  <>
                    <AvatarEditor
                      ref={editor}
                      width={imageWidth}
                      height={imageHeight}
                      image={image}
                      scale={scale}
                      style={{ backgroundImage: 'url(' + transparentImageData + ')' }}
                    />

                    <div className="mt-3">
                      <strong>{t('core:imageUploaderDialog.zoom')}</strong>
                      <Slider
                        min={0.1}
                        max={2}
                        step={0.01}
                        defaultValue={[1]}
                        onValueChange={onScaleValueChange}
                        className="mt-2"
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className="flex cursor-pointer flex-col items-center justify-center border-2 border-dashed"
                    style={{
                      width: imageWidth + editorPadding,
                      height: imageHeight + editorPadding,
                    }}
                    data-testid="dropzone"
                    onClick={openUploadDialog}
                  >
                    {t('core:imageUploaderDialog.dropYourFile')}
                    <br />
                    <em>{t('core:imageUploaderDialog.clickToSelectAFile')}</em>
                  </div>
                )}
              </div>
            )}
          </div>
        </DialogDescription>
        <DialogFooter>
          <Button onClick={closeDialog} variant={ButtonVariant.Outline}>
            {t('core:actions.cancel')}
          </Button>
          <Button onClick={saveImage} variant={ButtonVariant.Primary} disabled={!image}>
            {t('core:actions.save')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ImageUploaderDialog
