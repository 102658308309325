import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { t } from 'i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Tooltip from '@sherweb/core/components/Tooltip'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess } from '@ssp/modules/authorization'

export type SubscriptionTableActionRowProps = {
  subscriptionId: string
}

export const SubscriptionTableActionRow = ({ subscriptionId }: SubscriptionTableActionRowProps) => (
  <HasAccess permission={Permission.SubscriptionDetails}>
    <Link
      to={Routes.Subscription}
      data-testid="linkEditSubscription"
      params={{ subscriptionId }}
      className="flex p-1 focus-visible:outline-offset-0 focus-visible:outline-indigo-300"
    >
      <Tooltip tooltip={t('ssp:actions.edit')}>
        <PencilSquareIcon className="h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
      </Tooltip>
    </Link>
  </HasAccess>
)
