/* eslint-disable @typescript-eslint/no-misused-promises */
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StepWizardChildProps } from 'react-step-wizard'
import * as z from 'zod'

import Button from '@sherweb/core/components/Button'
import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import { Label } from '@sherweb/core/components/Label'
import { successNotification } from '@sherweb/core/components/ToastNotifications'
import { useHostname } from '@sherweb/core/modules/browserRouter'

import { Reseller, useUpdateImageAction } from '@rsp/modules/reseller'
import ImageEditor from '@rsp/pages/settings/ImageEditor'

import portalConfigurationSchema from '../validationSchema/portalConfigurationSchema'

export type PortalConfigurationStepProps = {
  reseller: Reseller
} & Partial<StepWizardChildProps>

const PortalConfigurationStep: React.FC<PortalConfigurationStepProps> = ({
  reseller,
  ...props
}) => {
  const { t } = useTranslation()
  const { updateLogo, updateIcon } = useUpdateImageAction()

  const hostname = useHostname()
  const logoUrl = reseller?.portalSettings?.logo
    ? `${reseller.portalSettings.logo}?${reseller?.portalSettings?.logoHash ?? ''}`
    : ''

  const form: UseFormReturn<z.infer<typeof portalConfigurationSchema>> = useForm<
    z.infer<typeof portalConfigurationSchema>
  >({
    resolver: zodResolver(portalConfigurationSchema),
    defaultValues: {
      resellerName: reseller?.name ?? '',
      resellerSSPUrl: hostname,
    },
  })

  function onSubmit(values: z.infer<typeof portalConfigurationSchema>) {
    if (props?.nextStep !== undefined) {
      props?.nextStep()
    }
  }

  const onSaveLogo = async (file: File) => {
    await updateLogo(file)
    await updateIcon(file)
    successNotification(t('rsp:pages.settings.customizationsSettings.logo.updateSuccess'))
  }

  return (
    <div className="mt-6 flex flex-col gap-4 md:mt-12" data-testid="portal-configuration-step">
      <div className="flex flex-col gap-4 md:gap-12">
        <h1 className="text-3xl font-medium text-slate-900 dark:text-slate-200">
          {t('rsp:pages.onboarding.portalConfiguration.title')}
        </h1>
        <p className="text-sm text-slate-500 dark:text-slate-200">
          {t('rsp:pages.onboarding.portalConfiguration.description')}
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <Label className="mt-2 text-sm font-semibold text-gray-900 dark:text-slate-200">
            {t('rsp:pages.onboarding.portalConfiguration.labels.uploadLogo')}
          </Label>
          <ImageEditor
            imageUrl={logoUrl}
            onSave={onSaveLogo}
            title={t('rsp:pages.settings.customizationsSettings.logo.updateModal.title')}
          />
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="resellerName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('rsp:pages.onboarding.portalConfiguration.labels.resellerName')}
                  </FormLabel>
                  <FormControl>
                    <FormInput {...field} disabled data-testid="inputCompanyName" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="resellerSSPUrl"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('rsp:pages.onboarding.portalConfiguration.labels.portalUrl')}
                  </FormLabel>
                  <FormControl>
                    <FormInput {...field} data-testid="inputSelfServicePortalUrl" disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-4 grid w-full grid-flow-col">
              <div className="flex justify-end">
                <Button
                  type="submit"
                  className="w-1/3"
                  data-testid="btnPortalConfigurationNextStep"
                >
                  {t('rsp:pages.onboarding.nextStep')}
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}

export default PortalConfigurationStep
