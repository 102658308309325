import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import { DrawerFooter } from '@sherweb/core/components/Drawer'
import LoaderButton from '@sherweb/core/components/LoaderButton'

import { usePermissionCheck } from '@ssp/modules/authorization'
import { usePayInvoiceMutation } from '@ssp/modules/invoices/core/invoices.hooks'

import { useGetInvoiceDetail } from '../hooks/useGetInvoiceDetail'
import { useRedirectToInvoicesPage } from './hooks/useRedirectToInvoicesPage'

export type InvoiceDetailFooterProps = {
  selectedCardId?: string
  closeDrawer: () => void
}

export const InvoiceDetailFooter = ({ selectedCardId, closeDrawer }: InvoiceDetailFooterProps) => {
  const { t } = useTranslation()

  const { hasAccess } = usePermissionCheck()

  const invoiceDetail = useGetInvoiceDetail()

  const handleRedirectToInvoicesPage = useRedirectToInvoicesPage()

  const payInvoiceMutation = usePayInvoiceMutation(
    invoiceDetail.invoiceId,
    invoiceDetail.invoiceDetailsAsyncQuery?.data?.displayInvoiceNumber
  )

  useEffect(() => {
    if (payInvoiceMutation.isSuccess) {
      closeDrawer()
    }
  }, [closeDrawer, payInvoiceMutation.isSuccess])

  const handlePayInvoice = async () => {
    payInvoiceMutation.reset()

    payInvoiceMutation.mutate({
      paymentMethodId: selectedCardId,
      amount: invoiceDetail.invoiceDetailsAsyncQuery?.data?.amountDue,
    })
  }

  return (
    <DrawerFooter className="sticky bottom-2 p-4 pt-6 md:pr-12">
      <div className="flex justify-end gap-4">
        <Button
          variant={Variant.Outline}
          size="sm"
          data-testid="btnCancelInvoiceDetail"
          onClick={handleRedirectToInvoicesPage}
        >
          {t('core:actions.cancel')}
        </Button>
        {invoiceDetail.isInvoiceNotPaid && hasAccess(Permission.PayInvoice) ? (
          <LoaderButton
            isLoading={payInvoiceMutation.isLoading}
            disabled={!selectedCardId || payInvoiceMutation.isLoading}
            size="sm"
            onClick={handlePayInvoice}
            data-testid="btnPayInvoice"
            loadingChildren={t('ssp:pages.invoices.detail.actions.payingInvoice')}
          >
            {t('ssp:pages.invoices.detail.actions.payInvoice')}
          </LoaderButton>
        ) : null}
      </div>
    </DrawerFooter>
  )
}
