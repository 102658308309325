import { useEffect } from 'react'

import { removeUndefinedAndNullValues } from '@sherweb/core/utils/array'

import { useSetCatalogProductsMutation } from '@rsp/modules/martkeplace'
import { ICatalogOffer } from '@rsp/modules/martkeplace/core/marketplace.model'

import { useGetCatalogId } from '../../hooks/useGetCatalogId'

type UseUpdateCatalogProductsProps = {
  catalogOffers: ICatalogOffer[]
  onSuccess?: () => void
  onPartialSuccess: () => void
}

export const useUpdateCatalogProducts = ({
  catalogOffers,
  onSuccess,
  onPartialSuccess,
}: UseUpdateCatalogProductsProps) => {
  const { catalogId } = useGetCatalogId()

  const setCatalogProductsMutation = useSetCatalogProductsMutation(catalogId)

  const failedSkus = setCatalogProductsMutation?.data?.failedSkus ?? []

  const mapFailedProducts = (failedSkus?: string[]) => {
    return catalogOffers?.filter(x => failedSkus?.includes(x.sku ?? '')) ?? []
  }

  useEffect(() => {
    if (setCatalogProductsMutation.isSuccess) {
      if (failedSkus.length > 0) {
        onPartialSuccess()
      } else {
        onSuccess?.()
      }
    }
  }, [failedSkus.length, onPartialSuccess, onSuccess, setCatalogProductsMutation.isSuccess])

  const onUpdateCatalogProducts = (
    initiallySelectedOffers: ICatalogOffer[],
    currentlySelectedOffers: ICatalogOffer[]
  ) => {
    const addedProducts = currentlySelectedOffers.filter(
      currentlySelectedOffer =>
        !initiallySelectedOffers
          .map(initiallySelectedOffer => initiallySelectedOffer.sku)
          .includes(currentlySelectedOffer.sku)
    )

    const removedProducts = initiallySelectedOffers.filter(
      initiallySelectedOffer =>
        !currentlySelectedOffers
          .map(currentlySelectedOffer => currentlySelectedOffer.sku)
          .includes(initiallySelectedOffer.sku)
    )

    setCatalogProductsMutation.mutate({
      skusToAdd: removeUndefinedAndNullValues(addedProducts.map(offer => offer.sku)),
      skusToRemove: removeUndefinedAndNullValues(removedProducts.map(offer => offer.sku)),
    })
  }

  return {
    isUpdateCatalogLoading: setCatalogProductsMutation.isLoading,
    onUpdateCatalogProducts,
    failedProducts: mapFailedProducts(failedSkus),
    successfulProductsNumber:
      setCatalogProductsMutation?.data?.successfulSkus?.length ?? 0 - failedSkus.length,
  }
}
