import {
  SubscriberConnectionDataQueryResult,
  WebhookValidCommand,
  WebhookValidationResult,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class NotificationsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getNotificationsConnectionData(options: IRequestOptions = {}): Promise<SubscriberConnectionDataQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Notifications/connection-data';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static validateWebhook(
    params: {
      /**  */
      command: WebhookValidCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WebhookValidationResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Notifications/validate-webhook';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
