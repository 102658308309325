import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormField,
  FormInputWithChildren,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import Switch from '@sherweb/core/components/Switch'

import { OrderSettingsFormType } from './hooks/useOrderSettingsSchema'

type OrderTeamsNotificationsProps = {
  reset: () => void
  hasConnectionFailed?: boolean
  children: React.ReactNode
}

const FORM_FIELD_WEBHOOK_URL = 'teamNotifications.webHookUrl'

export const OrderTeamsNotifications = ({
  hasConnectionFailed = false,
  children,
  reset,
}: OrderTeamsNotificationsProps) => {
  const { t } = useTranslation()

  const form = useFormContext<OrderSettingsFormType>()

  const [teamNotificationEnabled] = form.watch(['teamNotifications.enabled'])

  useEffect(() => {
    if (hasConnectionFailed) {
      form.setError(FORM_FIELD_WEBHOOK_URL, {
        message: t(
          'rsp:pages.settings.orderSettings.orders.notifications.teams.webhook.connectionFailed'
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasConnectionFailed])

  return (
    <div className="flex flex-col gap-8">
      <div className="grid grid-cols-1 gap-y-4">
        <FormField
          control={form.control}
          name="teamNotifications.enabled"
          render={({ field }) => (
            <FormItem className="settings-form-item">
              <FormLabel>
                {' '}
                {t('rsp:pages.settings.orderSettings.orders.notifications.teams.labels.orderSend')}
              </FormLabel>
              <FormControl className="settings-form-control">
                <Switch
                  checked={field.value}
                  onCheckedChange={event => {
                    field.onChange(event)
                    form.clearErrors(FORM_FIELD_WEBHOOK_URL)
                  }}
                  data-testid="teamNotificationsEnabled"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {teamNotificationEnabled ? (
          <FormField
            control={form.control}
            name="teamNotifications.webHookUrl"
            render={({ field }) => (
              <FormItem className="w-full">
                <div className="flex gap-x-4">
                  <FormLabel>
                    {t('rsp:pages.settings.orderSettings.orders.notifications.teams.webhook.label')}
                  </FormLabel>
                </div>
                <FormControl>
                  <FormInputWithChildren
                    data-testid="inputWebhookUrl"
                    {...field}
                    containerClassName="h-[33px]"
                    placeholder={t(
                      'rsp:pages.settings.orderSettings.orders.notifications.teams.webhook.placeholder'
                    )}
                    onChange={event => {
                      field.onChange(event)
                      form.clearErrors('teamNotifications.webHookUrl')
                      reset()
                    }}
                  >
                    <div className="ml-2 flex items-center md:ml-3">{children}</div>
                  </FormInputWithChildren>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : null}
      </div>
    </div>
  )
}
