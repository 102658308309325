import {
  BillingCycle as ApiBillingCycle,
  CommitmentTermType as ApiCommitmentTermType,
  Currency as ApiCurrency,
  Order,
  ProcessOrderDecision,
  Status,
  SubscriptionQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import { Currency, MoneyAmount } from '@sherweb/core/common/money'

import {
  LastPendingOrFailedOrder,
  OrderStatus,
  Subscription,
  SubscriptionBillingCycle,
  SubscriptionCommitmentType,
  SubscriptionStatus,
} from './subscription.model'
import {
  CreateOrderPayload,
  DismissOrderErrorPayload,
  ProcessOrderPayload,
} from './subscription.type'

export const getTotalPrice = (
  price?: MoneyAmount | null,
  quantity?: number | null
): MoneyAmount | null => {
  if (!price?.currency) {
    return null
  }
  return {
    amount: price.amount * (quantity ?? 0),
    currency: price.currency,
  }
}

export const buildSubscription = (subscription: SubscriptionQueryResult): Subscription => {
  const price: MoneyAmount | null = subscription.price
    ? { currency: mapCurrency(subscription.price.currency), amount: subscription.price.amount }
    : null
  const lastPendingOrFailedOrder = mapLastPendingOrFailedOrder(
    subscription.lastPendingOrFailedOrder
  )

  const status = mapStatus(subscription?.status)
  const statusWithOrders = mapStatusWithOrders(status, lastPendingOrFailedOrder)
  return {
    ...subscription,
    commitmentType: mapCommitmentType(subscription?.commitmentType),
    billingCycle: mapBillingCycle(subscription?.billingCycle),
    orderApprovalSettings: subscription.orderApprovalSettings
      ? {
          isOrderApprovalRequired: subscription.orderApprovalSettings?.isOrderApprovalRequired,
        }
      : null,
    status,
    statusWithOrders,
    lastPendingOrFailedOrder,
    price,
    commitmentEndDate: subscription.commitmentEndDate ? subscription.commitmentEndDate : null,
    renewalDate: subscription.renewalDate ? subscription.renewalDate : null,
    total: getTotalPrice(price, subscription.quantity),
  }
}

export const mapStatusWithOrders = (
  status?: SubscriptionStatus | null,
  lastPendingOrFailedOrder?: LastPendingOrFailedOrder | null
): SubscriptionStatus | null => {
  if (status === SubscriptionStatus.Active && lastPendingOrFailedOrder) {
    if (
      lastPendingOrFailedOrder.status === OrderStatus.Failed &&
      lastPendingOrFailedOrder.error &&
      !lastPendingOrFailedOrder.error?.dismissed
    ) {
      return SubscriptionStatus.Failed
    }
    if (lastPendingOrFailedOrder.status === OrderStatus.Pending) {
      return SubscriptionStatus.Pending
    }

    if (lastPendingOrFailedOrder.status === OrderStatus.WaitingForApproval) {
      return SubscriptionStatus.WaitingForApproval
    }
  }
  return status ?? null
}

export const buildSubscriptions = (subscriptions?: SubscriptionQueryResult[]): Subscription[] =>
  subscriptions ? subscriptions.map(buildSubscription) : []

export const mapCurrency = (currency: ApiCurrency): Currency => {
  switch (currency) {
    case ApiCurrency.Cad:
      return Currency.CAD
    case ApiCurrency.Usd:
    default:
      return Currency.USD
  }
}

export const mapUpdateUpdateQuantity = (
  oldData?: Subscription[],
  variables?: CreateOrderPayload,
  data?: SubscriptionQueryResult[]
): Subscription[] => {
  if (!oldData) {
    return []
  }

  if (!variables) {
    return oldData
  }
  return [
    ...oldData.map(item => {
      if (item.id === variables.subscriptionId) {
        const castedData = buildSubscriptions(data ?? [])
        return {
          ...item,
          lastPendingOrFailedOrder: castedData?.[0]?.lastPendingOrFailedOrder,
        }
      }
      return item
    }),
  ]
}

export const mapUpdateOnDismissOrderError = (
  oldData?: Subscription[],
  variables?: DismissOrderErrorPayload
): Subscription[] => {
  if (!oldData) {
    return []
  }

  if (!variables) {
    return oldData
  }

  return [
    ...oldData.map(item => {
      if (variables.orderLines.some(p => p.subscriptionId === item.id)) {
        const lastPendingOrFailedOrder = {
          ...item.lastPendingOrFailedOrder,
          orderId: item.lastPendingOrFailedOrder?.orderId ?? '',
          quantity: item.lastPendingOrFailedOrder?.quantity ?? null,
          initialQuantity: item.quantity ?? 0,
          createdAt: item.lastPendingOrFailedOrder?.createdAt ?? null,
          error: {
            dismissed: true,
            content: item.lastPendingOrFailedOrder?.error?.content,
          },
        }
        const statusWithOrders = mapStatusWithOrders(item.status, lastPendingOrFailedOrder)
        return {
          ...item,
          statusWithOrders,
          lastPendingOrFailedOrder,
        }
      }
      return item
    }),
  ]
}

export const mapUpdateOnDataOnProcessOrder = (
  oldData?: Subscription[],
  variables?: ProcessOrderPayload
): Subscription[] => {
  if (!oldData) {
    return []
  }

  if (!variables) {
    return oldData
  }

  return [
    ...oldData.map(item => {
      if (variables.orderIds.some(p => p === item.lastPendingOrFailedOrder?.orderId)) {
        const lastPendingOrFailedOrder = {
          ...item.lastPendingOrFailedOrder,
          orderId: item.lastPendingOrFailedOrder?.orderId ?? '',
          quantity: item.lastPendingOrFailedOrder?.quantity ?? null,
          initialQuantity: item.quantity ?? 0,
          createdAt: item.lastPendingOrFailedOrder?.createdAt ?? null,
          status:
            variables.decision === ProcessOrderDecision.Approve
              ? OrderStatus.Pending
              : OrderStatus.Rejected,
          decisionNote: variables.decisionNote ?? undefined,
          error: null,
        }
        const statusWithOrders = mapStatusWithOrders(item.status, lastPendingOrFailedOrder)
        return {
          ...item,
          statusWithOrders,
          lastPendingOrFailedOrder,
        }
      }
      return item
    }),
  ]
}

const mapCommitmentType = (
  commitmentType?: ApiCommitmentTermType
): SubscriptionCommitmentType | null | undefined => {
  if (!commitmentType) {
    return null
  }
  return SubscriptionCommitmentType[commitmentType as keyof typeof SubscriptionCommitmentType]
}

const mapBillingCycle = (
  billingCycle?: ApiBillingCycle
): SubscriptionBillingCycle | null | undefined => {
  if (!billingCycle) {
    return null
  }
  return SubscriptionBillingCycle[billingCycle as keyof typeof SubscriptionBillingCycle]
}

const mapStatus = (status: Status | undefined): SubscriptionStatus | null | undefined => {
  if (!status) {
    return null
  }
  return SubscriptionStatus[status as keyof typeof SubscriptionStatus]
}

const mapLastPendingOrFailedOrder = (
  lastPendingOrFailedOrder?: Order
): LastPendingOrFailedOrder | null => {
  return lastPendingOrFailedOrder
    ? {
        orderId: lastPendingOrFailedOrder.orderId ?? '',
        error: lastPendingOrFailedOrder.error
          ? {
              content: lastPendingOrFailedOrder.error.content,
              dismissed: lastPendingOrFailedOrder.error.dismissed,
            }
          : null,
        quantity: lastPendingOrFailedOrder.quantity,
        initialQuantity: lastPendingOrFailedOrder.initialQuantity,
        status: OrderStatus[lastPendingOrFailedOrder.status] ?? lastPendingOrFailedOrder.status,
        createdAt: lastPendingOrFailedOrder.createdAt,
        decisionNote: lastPendingOrFailedOrder.decisionNote,
        humanReadableId: lastPendingOrFailedOrder.humanReadableId,
      }
    : null
}
