import config from '@config'

import { datadogRum } from '@datadog/browser-rum'

import packageInfo from '../package.json'

const { env, datadog: datadogConfig } = config
const { enabled, ...datadogRumConfig } = datadogConfig
const { version } = packageInfo
const { bff } = config

if (enabled) {
  const allowedTracingUrls: string[] = [bff.url]

  datadogRum.init({
    env,
    version,
    allowedTracingUrls,
    ...datadogRumConfig,
  })

  datadogRum.startSessionReplayRecording()
}

export const setDatadogUserSession = (id: string, name: string, email: string) => {
  if (enabled) {
    datadogRum.setUser({
      id,
      name,
      email,
    })
  }
}
