enum Routes {
  Dashboard = '/admin/dashboard',
  Organizations = '/admin/organizations',
  Organization = '/admin/organizations/:organizationId/detail',
  Settings = '/admin/settings',
  Users = '/admin/users',
  Orders = '/admin/orders',
  Order = '/admin/orders/:orderId/detail',
  OrderDecision = '/admin/orders/:orderId/:decision',
  Integrations = '/admin/integrations',
  IntegrationsConnectWise = '/admin/integrations/connectwise',
  SherwebIntegrationsServiceProvider = '/admin/integrations/service-provider/sherweb',
  IntegrationsConnectWiseApiInformation = '/admin/integrations/connectwise/api-information',
  IntegrationsConnectWiseBoardConfiguration = '/admin/integrations/connectwise/board-configuration',
  IntegrationsConnectWiseOrganizationMapping = '/admin/integrations/connectwise/organization-mapping',
  Marketplace = '/admin/marketplace',
  MarketplaceCatalogProducts = '/admin/marketplace/:catalogId',
  MarketplaceCatalogAddProducts = '/admin/marketplace/:catalogId/add-products',
  MarketplaceCatalogAssignedOrganizations = '/admin/marketplace/:catalogId/assigned-organizations',
  Login = '/admin/login',
  Onboarding = '/admin/onboarding',
  Subscription = '/:organizationUniqueName/subscriptions/:subscriptionId',
  InternalError = '/admin/500',
  UnauthorizedAccess = '/admin/401',
  ForbiddenAccess = '/admin/403',
  NotFound = '/admin/404',
}

export default Routes
