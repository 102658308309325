import { useTranslation } from 'react-i18next'

import { Order } from '@sherweb/core/modules/orders/core/orders.model'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

type OrdersTableAmountRowProps = {
  order: Order
}

export const OrdersTableAmountRow = ({ order }: OrdersTableAmountRowProps) => {
  const {
    i18n: { language },
  } = useTranslation()

  const formatMoneyAmount = useMoneyFormatter(language)

  return <div className="whitespace-nowrap">{formatMoneyAmount(order.orderValue)}</div>
}
