import {
  ResellersQueryResult,
  PortalSettingsQueryResult,
  Theme,
  ImageResultDto,
  ImageType,
  ResellerSettingsQueryResult,
  SupportInformationQueryResult,
  OrderSettingsQueryResult,
  SetSupportInformationCommand,
  SetResellerSettingsCommand,
  SetOrderSettingsCommand,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ResellersService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getReseller(
    params: {
      /**  */
      hostname: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResellersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Resellers/{hostname}';
      url = url.replace('{hostname}', params['hostname'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setSupportInformation(
    params: {
      /**  */
      resellerId: string;
      /**  */
      command: SetSupportInformationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResellersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Resellers/{resellerId}/set-support-information';
      url = url.replace('{resellerId}', params['resellerId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setResellerSettings(
    params: {
      /**  */
      resellerId: string;
      /**  */
      command: SetResellerSettingsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResellersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Resellers/{resellerId}/set-reseller-settings';
      url = url.replace('{resellerId}', params['resellerId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static onboardedReseller(
    params: {
      /**  */
      resellerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResellersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Resellers/{resellerId}/onboarded';
      url = url.replace('{resellerId}', params['resellerId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setOrderSettings(
    params: {
      /**  */
      resellerId: string;
      /**  */
      command: SetOrderSettingsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResellersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Resellers/{resellerId}/set-order-settings';
      url = url.replace('{resellerId}', params['resellerId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
