import { useEffect, useState } from 'react'
import type { Converter as ShowdownConverter } from 'showdown'

export const cleanHtmlExtension = {
  type: 'output',
  filter: (text: string) => {
    // Remove newline inside HTML tags
    return text.replace(/>\s*\n\s*</g, '><')
  },
}

export const useGetMarkdownPreviewer = () => {
  const [converter, setConverter] = useState<ShowdownConverter | null>(null)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      import('showdown')
        .then(module => {
          setConverter(
            new module.default.Converter({
              openLinksInNewWindow: true,
              extensions: [cleanHtmlExtension],
            })
          )
          setIsLoading(false)
        })
        .catch(error => {
          console.error('failed to load showdown', error)
          setIsLoading(false)
        })
    }

    return () => {
      isMounted = false
    }
  }, [])

  const convertMarkdown = (markdown?: string | null): string => {
    if (converter && markdown) {
      return converter.makeHtml(markdown)
    }

    return ''
  }

  return { converter, isLoading, convertMarkdown }
}
