// based on: https://ui.shadcn.com/docs/components/combobox#installation

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { convertToCamelCase } from '@sherweb/core/utils/string'

import { DataTestId } from '../../types/dataTestIdType'
import { mergeClassName } from '../../utils/mergeClassName'
import Button from '../Button'
import { Command, CommandEmpty, CommandInput } from '../Command'
import { Popover, PopoverContent, PopoverTrigger } from '../Popover'
import { ComboboxContent } from './ComboboxContent'
import { ComboboxGroupContent } from './ComboboxGroupContent'
import { ComboboxOptionProps, ComboBoxProps } from './types'

export const ComboBox = <T extends ComboboxOptionProps>({
  options,
  value: initialValue,
  onChange,
  searcheable = false,
  disabled = false,
  buttonClassName,
  buttonValueClassName,
  buttonTitle,
  listClassName,
  texts = {},
  icon,
  groupOptions,
  dataTestId,
}: ComboBoxProps<T> & DataTestId) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<string | undefined>(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onSelectItem = (selectedValue: string) => {
    if (selectedValue !== value) {
      setValue(selectedValue)
      onChange?.(selectedValue)
    }
    setOpen(false)
  }

  texts = {
    emptyValue: texts.emptyValue ?? t('core:combobox.emptyValue'),
    searchPlaceholder: texts.searchPlaceholder ?? t('core:combobox.searchPlaceholder'),
    noResults: texts.noResults ?? t('core:combobox.noResults'),
  }

  const flatGroupOptions = useMemo(
    () =>
      groupOptions
        ? Object.values(groupOptions).flatMap(options => options.map(option => option))
        : [],
    [groupOptions]
  )

  const getSelectedValue = (selectedValue: string) => {
    if (groupOptions) {
      const selectedOption = flatGroupOptions.find(option => option.id === value)
      return selectedOption?.label ?? selectedOption?.name
    }
    return options?.[selectedValue]
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          disabled={disabled}
          aria-expanded={open}
          className={mergeClassName(
            'justify-between gap-2 font-normal text-sm font-regular text-gray-900',
            buttonClassName
          )}
          title={buttonTitle}
          data-testid={`comboboxTrigger${convertToCamelCase(dataTestId)}`}
        >
          {icon}
          <span className={buttonValueClassName}>
            {value ? getSelectedValue(value) : texts.emptyValue}
          </span>
          <ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 text-white opacity-50 md:text-gray-900 dark:md:text-white" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="mt-[10px] p-0 md:mt-0">
        <Command className="m-[10px] ml-0 h-[calc(100vh-90px)] w-[calc(100vw-20px)] md:m-0 md:h-auto md:w-auto">
          {searcheable && (
            <CommandInput
              placeholder={String(texts.searchPlaceholder)}
              className="my-1"
              data-testid={`comboboxInputFilter${convertToCamelCase(dataTestId)}`}
            />
          )}
          <CommandEmpty>{texts.noResults}</CommandEmpty>
          <div className={listClassName}>
            {groupOptions ? (
              <ComboboxGroupContent<T>
                dataTestId={dataTestId}
                value={value}
                onSelectItem={onSelectItem}
                groupOptions={groupOptions}
              />
            ) : (
              <ComboboxContent
                dataTestId={`comboboxContent${convertToCamelCase(dataTestId)}`}
                value={value}
                onSelectItem={onSelectItem}
                options={options}
              />
            )}
          </div>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export default ComboBox
