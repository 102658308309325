import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@sherweb/core/components/Accordion'
import { Separator } from '@sherweb/core/components/Separator'
import { Typography } from '@sherweb/core/components/Typography'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'

import {
  useGetHelpDeskOrganizationTicketTypeQuery,
  useGetOrganizationHelpDeskTicketDetailQuery,
  useGetOrganizationHelpDeskTicketStatusesQuery,
} from '@ssp/modules/integrations/helpdesk'
import { HelpDeskTicketStatus } from '@ssp/modules/integrations/helpdesk/ui/HelpDeskTicketStatus'
import { useParams } from '@ssp/modules/navigation'

import { HelpDeskTicketNoteList } from './HelpDeskTicketNoteList'

export const HelpDeskTicketContent = () => {
  const { helpDeskTicketId } = useParams()

  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const oganizationHelpDeskTicketDetailQuery = useGetOrganizationHelpDeskTicketDetailQuery(
    {},
    helpDeskTicketId
  )

  const organizationHelpDeskTicketStatusesQuery = useGetOrganizationHelpDeskTicketStatusesQuery()

  const helpDeskOrganizationTicketTypeQuery = useGetHelpDeskOrganizationTicketTypeQuery()

  const notes = oganizationHelpDeskTicketDetailQuery?.data?.notes

  const statusId = oganizationHelpDeskTicketDetailQuery?.data?.statusId

  const firstColumn = [
    {
      label: t('ssp:pages.helpdesk.detail.id'),
      value: oganizationHelpDeskTicketDetailQuery?.data?.id,
    },
    {
      label: t('ssp:pages.helpdesk.detail.createdBy'),
      value:
        oganizationHelpDeskTicketDetailQuery?.data?.openedByName ??
        oganizationHelpDeskTicketDetailQuery?.data?.openedByEmail,
    },
    {
      label: t('ssp:pages.helpdesk.detail.type'),
      value:
        helpDeskOrganizationTicketTypeQuery?.data?.[
          oganizationHelpDeskTicketDetailQuery?.data?.ticketTypeId ?? ''
        ]?.name,
    },
  ]

  const secondColumn = [
    {
      label: t('ssp:pages.helpdesk.detail.status'),
      value: (
        <HelpDeskTicketStatus
          status={organizationHelpDeskTicketStatusesQuery?.data?.[statusId ?? '']}
        />
      ),
    },
    {
      label: t('ssp:pages.helpdesk.detail.createdAt'),
      value: formatDate(oganizationHelpDeskTicketDetailQuery?.data?.createdAt),
    },
    {
      label: t('ssp:pages.helpdesk.detail.lastUpdate'),
      value: formatDate(oganizationHelpDeskTicketDetailQuery?.data?.lastUpdated),
    },
  ]

  if (!oganizationHelpDeskTicketDetailQuery?.data) {
    return null
  }

  return (
    <>
      <Separator className="bg-slate-300 dark:bg-slate-700" />
      <div className="flex flex-col">
        <Accordion
          type="single"
          collapsible
          defaultValue={notes && notes?.length > 1 ? '' : 'ticket-detail'}
        >
          <AccordionItem value="ticket-detail" className="flex flex-col-reverse">
            <AccordionTrigger className="flex justify-center py-2 dark:bg-transparent dark:hover:bg-slate-950/50" />
            <AccordionContent className="[&>div]:pb-0">
              <div className="flex justify-between p-6 pb-0">
                <div className="flex flex-col">
                  <div className="flex flex-col justify-between gap-4">
                    {firstColumn.map(({ label, value }) => (
                      <div className="flex flex-col gap-y-1" key={label}>
                        <Typography variant="body2" colors="light" as="div">
                          {label}
                        </Typography>
                        <Typography variant="body2" as="div">
                          {value}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-col justify-between gap-4">
                    {secondColumn.map(({ label, value }) => (
                      <div className="flex flex-col gap-y-1" key={label}>
                        <Typography variant="body2" colors="light" as="div">
                          {label}
                        </Typography>
                        <Typography variant="body2" as="div">
                          {value}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      <HelpDeskTicketNoteList notes={notes} />
    </>
  )
}
