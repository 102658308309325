import { ICatalogOffer } from '@rsp/modules/martkeplace/core/marketplace.model'

type UseSelectedOffersChangedProps = {
  alreadyAddedOffers: ICatalogOffer[]
  selectedOffers: ICatalogOffer[]
}

export const useSelectedOffersChanged = ({
  alreadyAddedOffers,
  selectedOffers,
}: UseSelectedOffersChangedProps) => {
  return !(
    alreadyAddedOffers.length === selectedOffers.length &&
    alreadyAddedOffers.every(alreadyAddedOffer =>
      selectedOffers.some(selectedOffer => selectedOffer.sku === alreadyAddedOffer.sku)
    )
  )
}
