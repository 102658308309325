import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { HelpDeskApiInformationService } from '@sherweb/core/openapi-generated/HelpDeskApiInformationService'
import { HelpDeskBoardConfigurationService } from '@sherweb/core/openapi-generated/HelpDeskBoardConfigurationService'
import { HelpDeskOrganizationsService } from '@sherweb/core/openapi-generated/HelpDeskOrganizationsService'
import {
  HelpDeskServiceBoard,
  HelpDeskTicketStatus,
  HelpDeskTicketStatusMapping,
  IntegrationListQueryResult,
  IntegrationType,
  SetHelpDeskApiInformationCommand,
  SetHelpDeskOrganizationMapping,
} from '@sherweb/core/openapi-generated/index.defs'

import { successNotification } from '@sherweb/core/components/ToastNotifications'
import { errorInstance, missingParametersError } from '@sherweb/core/utils/error'

import { useGetResellerIntegrationsQuery } from '@rsp/modules/integrations/core/integrations.hooks'
import { resellerIntegrations } from '@rsp/modules/integrations/core/integrations.queries'
import { useHostname } from '@rsp/modules/navigation'
import { useSelectedResellerId } from '@rsp/modules/reseller'
import { reseller } from '@rsp/modules/reseller/core/reseller.queries'
import { BoardConfigurationFormType } from '@rsp/pages/integrations/helpdesk/connectwise/BoardConfiguration/hooks/useBoardConfigurationSchema'
import { OrganizationMappingFormType } from '@rsp/pages/integrations/helpdesk/connectwise/OrganizationMapping/hooks/useOrganizationMappingSchema'

import { buildTicketStatuses, buildTicketStatusMapping, buildTicketTypes } from './helpdesk.builder'
import { IUnMappedOrganizationType } from './helpdesk.model'
import {
  connectWiseApiInformation,
  connectWiseServiceBoards,
  connectWiseTicketStatuses,
  connectWiseTicketStatusMappings,
  connectWiseTicketTypes,
  unMappedOrganizations,
} from './helpdesk.queries'

export const useGetConnectWiseIntegrationIdQuery = () => {
  return useGetResellerIntegrationsQuery<string>({
    select: (resellerIntegrations?: IntegrationListQueryResult) =>
      resellerIntegrations?.helpDesk?.find(
        helpdeskIntegration => helpdeskIntegration.integrationType === IntegrationType.ConnectWise
      )?.id ?? '',
  })
}

export const useGetConnectWiseConfiguredQuery = () => {
  const query = useGetResellerIntegrationsQuery<boolean>({
    select: (resellerIntegrations?: IntegrationListQueryResult) =>
      resellerIntegrations?.helpDesk?.find(
        helpdeskIntegration => helpdeskIntegration.integrationType === IntegrationType.ConnectWise
      )?.configured ?? false,
  })

  return {
    isLoading: query?.isLoading || query?.data === undefined,
    isEnabled: query?.data,
  }
}

export const useGetConnectWiseApiInformationQuery = () => {
  const integrationId = useGetConnectWiseIntegrationIdQuery()?.data

  const isConnectWiseConfigured = useGetConnectWiseConfiguredQuery()?.isEnabled

  const resellerId = useSelectedResellerId()

  return useQuery({
    queryKey: connectWiseApiInformation.queryKey(resellerId, integrationId),
    queryFn: async () => await connectWiseApiInformation.queryFn(resellerId, integrationId),
    enabled: !!resellerId && !!integrationId && isConnectWiseConfigured,
    staleTime: connectWiseApiInformation.staleTime,
  })
}

export const usePostConnectWiseApiInformationMutation = () => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const integrationId = useGetConnectWiseIntegrationIdQuery()?.data

  const activeServiceBoardId = useGetConnectWiseActiveServiceBoardIdQuery()?.data

  const resellerId = useSelectedResellerId()

  const hostname = useHostname()

  return useMutation({
    mutationFn: async (
      data: Omit<SetHelpDeskApiInformationCommand, 'clientId'> & { clientId?: string }
    ) => {
      if (!resellerId || !integrationId) {
        throw missingParametersError()
      }

      return await HelpDeskApiInformationService.setHelpDeskApiInformation({
        resellerId,
        integrationId,
        command: data,
      })
    },
    onSuccess: async () => {
      successNotification(
        t('rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.updateSuccess')
      )
      await queryClient.invalidateQueries({ queryKey: resellerIntegrations.queryKey(resellerId) })
      await queryClient.invalidateQueries({
        queryKey: connectWiseApiInformation.queryKey(resellerId, integrationId),
      })
      await queryClient.invalidateQueries({ queryKey: reseller.queryKey(hostname) })

      activeServiceBoardId &&
        (await Promise.all([
          queryClient.invalidateQueries({
            queryKey: connectWiseServiceBoards.queryKey(resellerId, integrationId),
          }),
          queryClient.invalidateQueries({
            queryKey: connectWiseTicketStatusMappings.queryKey(
              resellerId,
              integrationId,
              activeServiceBoardId
            ),
          }),
          queryClient.invalidateQueries({
            queryKey: connectWiseTicketTypes.queryKey(
              resellerId,
              integrationId,
              activeServiceBoardId
            ),
          }),
        ]))
    },
  })
}

export const useGetConnectWiseServiceBoardsQuery = <TResult = HelpDeskServiceBoard[]>(
  options?: Omit<UseQueryOptions<HelpDeskServiceBoard[], Error, TResult>, 'queryKey'>
) => {
  const integrationId = useGetConnectWiseIntegrationIdQuery()?.data

  const resellerId = useSelectedResellerId()

  const isConnectWiseConfigured = useGetConnectWiseConfiguredQuery()?.isEnabled

  return useQuery<HelpDeskServiceBoard[], Error, TResult>({
    queryKey: connectWiseServiceBoards.queryKey(resellerId, integrationId),
    queryFn: async () => await connectWiseServiceBoards.queryFn(resellerId, integrationId),
    enabled: !!resellerId && !!integrationId && isConnectWiseConfigured,
    staleTime: connectWiseServiceBoards.staleTime,
    ...options,
  })
}

export const useGetConnectWiseActiveServiceBoardIdQuery = () => {
  return useGetConnectWiseServiceBoardsQuery<string | undefined>({
    select: (serviceBoards?: HelpDeskServiceBoard[]) =>
      serviceBoards?.find(serviceBoard => serviceBoard?.active)?.id,
  })
}

export const useGetConnectWiseTicketStatusesQuery = () => {
  const resellerId = useSelectedResellerId()

  const integrationId = useGetConnectWiseIntegrationIdQuery()?.data

  const isConnectWiseConfigured = useGetConnectWiseConfiguredQuery()?.isEnabled

  return useQuery({
    queryKey: connectWiseTicketStatuses.queryKey(resellerId, integrationId),
    queryFn: async () => await connectWiseTicketStatuses.queryFn(resellerId, integrationId),
    enabled: !!resellerId && !!integrationId && isConnectWiseConfigured,
    select: (data?: HelpDeskTicketStatus[]) => buildTicketStatuses(data),
    staleTime: connectWiseTicketStatuses.staleTime,
  })
}

export const useGetConnectWiseTicketStatusMappingsQuery = (serviceBoardId?: string) => {
  const resellerId = useSelectedResellerId()

  const integrationId = useGetConnectWiseIntegrationIdQuery()?.data

  const isConnectWiseConfigured = useGetConnectWiseConfiguredQuery()?.isEnabled

  return useQuery({
    queryKey: connectWiseTicketStatusMappings.queryKey(resellerId, integrationId, serviceBoardId),
    queryFn: async () =>
      await connectWiseTicketStatusMappings.queryFn(resellerId, integrationId, serviceBoardId),
    select: (data?: HelpDeskTicketStatusMapping) => buildTicketStatusMapping(data, serviceBoardId),
    enabled: !!resellerId && !!integrationId && !!serviceBoardId && isConnectWiseConfigured,
    staleTime: connectWiseTicketStatusMappings.staleTime,
  })
}

export const useGetConnectWiseTicketTypesQuery = (serviceBoardId?: string) => {
  const resellerId = useSelectedResellerId()

  const integrationId = useGetConnectWiseIntegrationIdQuery()?.data

  const isConnectWiseConfigured = useGetConnectWiseConfiguredQuery()?.isEnabled

  return useQuery({
    queryKey: connectWiseTicketTypes.queryKey(resellerId, integrationId, serviceBoardId),
    queryFn: async () =>
      await connectWiseTicketTypes.queryFn(resellerId, integrationId, serviceBoardId),
    select: data => buildTicketTypes(data, serviceBoardId),
    enabled: !!resellerId && !!integrationId && !!serviceBoardId && isConnectWiseConfigured,
    staleTime: connectWiseTicketTypes.staleTime,
  })
}

export const usePostConnetWiseBoardConfigurationMutation = () => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const integrationId = useGetConnectWiseIntegrationIdQuery()?.data

  const resellerId = useSelectedResellerId()

  return useMutation({
    mutationFn: async (data: BoardConfigurationFormType) => {
      if (!resellerId || !integrationId) {
        throw missingParametersError()
      }

      return await HelpDeskBoardConfigurationService.setHelpDeskBoardConfiguration({
        resellerId,
        integrationId,
        configurationCommand: {
          serviceBoardId: data.serviceBoard,
          ticketStatusMappings: Object.entries(
            data.ticketStatusMappingList[data?.serviceBoard ?? '']
          )?.flatMap(([key, value]) => {
            if (!value) {
              return []
            }

            return value?.map(item => ({ ticketStatusId: key, integrationTicketStatusId: item }))
          }),
          ticketTypeMappings: data.ticketTypes[data?.serviceBoard ?? ''],
        },
      })
    },
    onSuccess: async (__, variables) => {
      successNotification(
        t('rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.updateSuccess')
      )

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: connectWiseServiceBoards.queryKey(resellerId, integrationId),
        }),
        queryClient.invalidateQueries({
          queryKey: connectWiseTicketStatusMappings.queryKey(
            resellerId,
            integrationId,
            variables?.serviceBoard
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: connectWiseTicketTypes.queryKey(
            resellerId,
            integrationId,
            variables?.serviceBoard
          ),
        }),
      ])
    },
  })
}

export const useGetUnMappedOrganizations = (
  options?: Omit<UseQueryOptions<IUnMappedOrganizationType[], Error>, 'queryKey'>
) => {
  const resellerId = useSelectedResellerId()

  const integrationId = useGetConnectWiseIntegrationIdQuery()?.data

  const isConnectWiseConfigured = useGetConnectWiseConfiguredQuery()?.isEnabled

  return useQuery<IUnMappedOrganizationType[], Error>({
    queryKey: unMappedOrganizations.queryKey(resellerId, integrationId),
    queryFn: async () => await unMappedOrganizations.queryFn(resellerId, integrationId),
    enabled: !!resellerId && !!integrationId && !!isConnectWiseConfigured,
    staleTime: unMappedOrganizations.staleTime,
    ...options,
  })
}

export const usePostConnetWiseMapOrganizationMutation = () => {
  const queryClient = useQueryClient()

  const resellerId = useSelectedResellerId()

  const integrationId = useGetConnectWiseIntegrationIdQuery()?.data

  const isConnectWiseConfigured = useGetConnectWiseConfiguredQuery()?.isEnabled

  return useMutation({
    mutationFn: async (data: OrganizationMappingFormType) => {
      if (!resellerId || !integrationId) {
        throw missingParametersError()
      }

      if (!isConnectWiseConfigured) {
        throw errorInstance('HelpDesk not configured')
      }

      return await HelpDeskOrganizationsService.mapOrganizations({
        resellerId,
        integrationId,
        command: {
          setOrganizationMappings: Object.keys(data?.organizations).reduce<
            SetHelpDeskOrganizationMapping[]
          >((organizations, key) => {
            organizations.push({
              organizationId: key,
              companyId: data?.organizations[key],
            })
            return organizations
          }, []),
        },
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: unMappedOrganizations.queryKey(resellerId, integrationId),
      })
    },
  })
}
