import { useTranslation } from 'react-i18next'

import { BillingCycle, CommitmentTermType } from '@sherweb/core/openapi-generated/index.defs'

import { Typography } from '@sherweb/core/components/Typography'

import {
  useBillingCycleSummaryTranslation,
  useCommitmentTermSummaryTranslation,
} from '@ssp/modules/shop/core/shop.helpers'

import { useGetFormattedTotalAmount } from '../hooks/useGetFormattedTotalAmount'
import { useGetOffersByCommitmentTerm } from '../hooks/useGetOffersByCommitmentTerm'

export const ShopDetailTotalPrice = () => {
  const { t } = useTranslation()

  const getBillingCycleSummaryTranslation = useBillingCycleSummaryTranslation()

  const getCommitmentTermSummaryTranslation = useCommitmentTermSummaryTranslation()

  const { selectedOffer } = useGetOffersByCommitmentTerm()

  const { formattedTotalAmount } = useGetFormattedTotalAmount()

  const isOneTimeBillingCycle =
    selectedOffer?.billingCycle === BillingCycle.Unknown ||
    selectedOffer?.billingCycle === BillingCycle.Once ||
    selectedOffer?.billingCycle === BillingCycle.None

  const isNotOneTimeBillingCycle = selectedOffer?.billingCycle
    ? ![BillingCycle.Unknown, BillingCycle.Once, BillingCycle.None].includes(
        selectedOffer?.billingCycle
      )
    : false

  const hasCommitmentCycle =
    selectedOffer?.commitmentTermType !== CommitmentTermType.Unknown &&
    selectedOffer?.commitmentTermType !== CommitmentTermType.Open

  const hasNoCommitmentCycle =
    selectedOffer?.commitmentTermType === CommitmentTermType.Unknown ||
    selectedOffer?.commitmentTermType === CommitmentTermType.Open

  const textTranslationWithCommitmentTermAndBillingCycle = {
    [t('ssp:pages.shop.detail.commitmentSummary.summary', {
      billingCycle: getBillingCycleSummaryTranslation(selectedOffer?.billingCycle),
      commitmentTerm: getCommitmentTermSummaryTranslation(selectedOffer?.commitmentTermType),
    })]: isNotOneTimeBillingCycle && hasCommitmentCycle,
    [t('ssp:pages.shop.detail.commitmentSummary.summaryWithoutBillingCycle', {
      commitmentTerm: getCommitmentTermSummaryTranslation(selectedOffer?.commitmentTermType),
    })]: isOneTimeBillingCycle && hasCommitmentCycle,
    [t('ssp:pages.shop.detail.commitmentSummary.summaryWithoutCommitment', {
      billingCycle: getBillingCycleSummaryTranslation(selectedOffer?.billingCycle),
    })]: isNotOneTimeBillingCycle && hasNoCommitmentCycle,
    [t('ssp:pages.shop.detail.commitmentSummary.summaryWithoutBillingCycleAndCommitment')]:
      isOneTimeBillingCycle && hasNoCommitmentCycle,
  }

  const textTranslationWithCommitmentTermAndBillingCycleKeys = Object.keys(
    textTranslationWithCommitmentTermAndBillingCycle
  )

  return (
    <>
      <Typography variant="body1" weight="medium" className="mb-2 text-wrap">
        {t('ssp:pages.shop.detail.total')}
      </Typography>

      <Typography
        variant="heading5"
        weight="bold"
        as="div"
        className="duration-1500 transition ease-out"
      >
        {formattedTotalAmount}
      </Typography>

      <Typography variant="body2" colors="light" weight="default" as="div" className="min-h-[20px]">
        {textTranslationWithCommitmentTermAndBillingCycleKeys.length !== 0
          ? textTranslationWithCommitmentTermAndBillingCycleKeys.find(
              option => textTranslationWithCommitmentTermAndBillingCycle?.[option]
            )
          : ''}
      </Typography>
    </>
  )
}
