import { t } from 'i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'

import { HasAccess } from '@ssp/modules/authorization'
import { License } from '@ssp/modules/license'

export type LicenseTableActionRowProps = {
  license: License
  setOpenDialog: (open: boolean) => void
  setSelectedLicense: (license: License) => void
}

export const LicenseTableActionRow = ({
  license,
  setSelectedLicense,
  setOpenDialog,
}: LicenseTableActionRowProps) => (
  <HasAccess permission={Permission.LicenseDetails}>
    <Button
      variant="outline"
      size="sm"
      type="button"
      data-testid="btnManageSubscribers"
      onClick={() => {
        setSelectedLicense(license)
        setOpenDialog(true)
      }}
    >
      {t('ssp:pages.licenses.manage')}
    </Button>
  </HasAccess>
)
