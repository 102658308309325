import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { PromptOptions } from './prompt.context'
import { usePromptContext } from './prompt.hooks'
import { useCustomBlocker } from './useCustomBlocker'

export interface UsePromptType extends PromptOptions {
  isDirty?: boolean
  noConfirmationWithChangeOfQueryParamter?: boolean
}

export const usePrompt = ({
  isDirty = false,
  noConfirmationWithChangeOfQueryParamter = false,
  title,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  children: childrenProps,
}: UsePromptType) => {
  const { t } = useTranslation()

  const blocker = useCustomBlocker({
    isDirty,
    noConfirmationWithChangeOfQueryParamter,
  })

  const children = childrenProps ?? t('core:prompt.unSavedChangesMessage')

  const { show } = usePromptContext()

  const confirm = useCallback(async () => {
    if (!isDirty) {
      return await Promise.resolve(true)
    }

    return await new Promise<boolean>(resolve => {
      show({
        title,
        confirmButtonText,
        cancelButtonText,
        children,
        onConfirm: () => {
          resolve(true)
          onConfirm?.()
        },
        onCancel: () => {
          resolve(false)
          onCancel?.()
        },
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, show])

  useEffect(() => {
    if (blocker.state === 'blocked' && isDirty) {
      // eslint-disable-next-line no-void
      void confirm().then(result => {
        if (result) {
          blocker.proceed()
          return
        }

        blocker.reset()
      })
    }
  }, [blocker, confirm, isDirty])

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => title ?? children
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [isDirty, title, children])

  return {
    confirm,
  }
}
