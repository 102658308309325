import { Table } from '@tanstack/react-table'

import { TableHeader, TableRow } from '../Table'
import Header from './DataTableHead'

type DataTableHeaderProps<TData> = {
  table: Table<TData>
}

export const DataTableHeader = <TData,>({ table }: DataTableHeaderProps<TData>) => {
  return (
    <TableHeader>
      {table.getHeaderGroups().map(headerGroup => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <Header key={header.id} {...header} />
          ))}
        </TableRow>
      ))}
    </TableHeader>
  )
}
