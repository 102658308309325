import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import { useTranslation } from 'react-i18next'

export const ServerError = () => {
  const { t } = useTranslation()

  return (
    <div data-testid="server-error" className="h-screen dark:bg-slate-900">
      <div className="mx-auto flex h-full max-w-2xl flex-col items-center text-center">
        <ExclamationTriangleIcon className="mx-auto mt-20 w-12 text-red-600 sm:mt-24" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white  sm:text-5xl">
          {t('core:errors.server.title')}
        </h1>
        <p className="mt-4 text-base leading-7 text-gray-600 dark:text-white sm:mt-6 sm:text-lg  sm:leading-8">
          {t('core:errors.server.description')}
        </p>
      </div>
    </div>
  )
}
