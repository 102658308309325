import { configureStore } from '@reduxjs/toolkit'
import { AnyAction, Reducer, Store } from 'redux'
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'

type CreateStoreOptions<T> = {
  mainReducer: T
  devTools?: boolean
  defaultState?: any
}

export const createStore = <T extends Reducer<any, any>>({
  mainReducer,
  devTools = false,
  defaultState,
}: CreateStoreOptions<T>) => {
  const store = configureStore({
    reducer: mainReducer,
    preloadedState: defaultState,
    devTools,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })

  return {
    store: store as Store<ReturnType<T>, AnyAction>,
    persistor: persistStore(store),
  }
}
