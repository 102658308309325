import {
  CreateAmendmentOrderCommand,
  CreateOrderLineDto,
  SubscriptionQueryResult,
  CommitmentTermType,
  BillingCycle,
  Status,
  Money,
  Currency,
  Order,
  OrderStatus,
  OrderError,
  OrderApprovalSettings,
  DismissAmendmentOrderErrorCommand,
  OrderLineDto,
  ProcessOrderCommand,
  ProcessOrderDecision,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class AmendmentOrdersService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static amendmentOrdersCreateOrder(
    params: {
      /**  */
      command: CreateAmendmentOrderCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubscriptionQueryResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/AmendmentOrders';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static amendmentOrdersDismissOrderError(
    params: {
      /**  */
      command: DismissAmendmentOrderErrorCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/AmendmentOrders/dismiss-error';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static amendmentOrdersProcessOrder(
    params: {
      /**  */
      command: ProcessOrderCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/AmendmentOrders/process';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
