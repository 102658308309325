import { Skeleton } from './Skeleton'

type SkeletonTableProps = {
  count?: number
}

export const SkeletonTable = ({ count = 4 }: SkeletonTableProps) => (
  <div className="flex flex-col justify-center gap-y-6" data-testid="skeletonTable">
    {Array.from({ length: count }, (_, index) => (
      <Skeleton key={index} className="h-[40px] w-full" />
    ))}
  </div>
)
