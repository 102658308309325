export const compare = <T>(a: T, b: T, key: keyof T) =>
  a[key] && b[key] && a[key] < b[key] ? -1 : 1

export const orderByDesc = <T>(items: T[], key: keyof T) => {
  return orderBy(items, key, true)
}

export const orderBy = <T>(items: T[], key: keyof T, desc: boolean = false) => {
  return [...items].sort((a, b) => compare(a, b, key) * (desc ? -1 : 1))
}

export const take = <T>(items: T[], take: number) => {
  return items.slice(0, items.length > take ? take : items.length)
}

export const arrayToObject = <T>(data?: T[], keyValue?: keyof T): Record<string | number, T> => {
  if (!data || data?.length === 0 || !keyValue) {
    return {}
  }

  return Object.fromEntries(data.map(d => [d[keyValue], d]))
}

export const uniqueArrayByKey = <T>(array: T[], key: keyof T) => {
  return [...new Map(array.map(item => [item[key], item])).values()]
}

export const removeUndefinedAndNullValues = <T>(array: Array<T | undefined>): T[] => {
  if (!Array.isArray(array)) {
    return []
  }

  return array.filter((element): element is T => element !== undefined && element !== null)
}
