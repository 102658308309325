import * as z from 'zod'

import { looseOptional } from '@sherweb/core/types/zod'

import { useGetConnectWiseTicketStatusesQuery } from '@rsp/modules/integrations/helpdesk'

export type BoardConfigurationFormType = z.infer<ReturnType<typeof useBoardConfigurationSchema>>

export type BoardConfigurationTicketStatusMappingListType = z.infer<
  ReturnType<typeof ticketStatusMappingListSchema>
>

const ticketStatusMappingListSchema = () =>
  z.record(z.record(z.string(), looseOptional(z.array(z.string()))))

export const useBoardConfigurationSchema = () => {
  const connectWiseTicketStatusesQuery = useGetConnectWiseTicketStatusesQuery()

  const requiredTicketStatusIds = connectWiseTicketStatusesQuery?.data?.requiredStatuses?.map(
    inputField => inputField.id
  )

  return z
    .object({
      serviceBoard: looseOptional(z.string()).refine(
        value => value !== undefined,
        'rsp:validations.mixed.required'
      ),
      ticketStatusMappingList: ticketStatusMappingListSchema(),
      ticketTypes: z.record(z.string(), looseOptional(z.array(z.string()))),
    })
    .superRefine((values, context) => {
      const { serviceBoard, ticketStatusMappingList, ticketTypes } = values

      if (serviceBoard !== undefined) {
        requiredTicketStatusIds?.forEach(id => {
          if (
            !ticketStatusMappingList?.[serviceBoard]?.[id] ||
            ticketStatusMappingList?.[serviceBoard]?.[id]?.length === 0
          ) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'rsp:validations.mixed.required',
              path: [`ticketStatusMappingList[${serviceBoard}][${id}]`],
            })
          }
        })

        if (ticketTypes[serviceBoard]?.length === 0) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              'rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.oneTicketTypeAtleast',
            path: [`ticketTypes[${serviceBoard}].ticketTypesMapping`],
          })
        }
      }
    })
}
