import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { DataTestId } from '@sherweb/core/types/dataTestIdType'
import { ComponentChildrenType } from '@sherweb/core/types/reactChildrenType'
import { DEFAULT_DEBOUNCE_MS } from '@sherweb/core/utils/const'

import { DebouncedSearchInput } from '../DebouncedSearchInput'

type DataTableInputMobileProps = {
  filteredText: string
  placeholder: string
  debounce?: number
  setFilteredText: (value: string) => void
}

export const DataTableInputMobile = ({
  filteredText,
  debounce = DEFAULT_DEBOUNCE_MS,
  setFilteredText,
  placeholder,
  dataTestId,
}: DataTableInputMobileProps & DataTestId) => (
  <>
    <MagnifyingGlassIcon
      className="absolute left-3 h-5 w-5 text-gray-500 focus-within:text-gray-600"
      aria-hidden="true"
    />
    <DebouncedSearchInput
      className="w-full rounded-lg border border-gray-300 py-2 pl-10 text-left text-sm font-normal leading-none text-gray-600 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700"
      type="text"
      debounce={debounce}
      value={filteredText}
      data-testid={dataTestId ?? 'inputFilterTableMobile'}
      placeholder={placeholder}
      onChange={(value: string) => {
        setFilteredText(value)
      }}
    />
  </>
)

export const DataTableMobileHeader = ({ children }: ComponentChildrenType) => (
  <div className="relative mb-4 flex w-full items-center gap-x-4">{children}</div>
)

DataTableMobileHeader.Input = DataTableInputMobile
