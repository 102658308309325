import { useTranslation } from 'react-i18next'

import { Typography } from '@sherweb/core/components/Typography'

export type DisplayEmail = { username: string; domain: string }

type EmailDisplayProps = {
  displayEmail: DisplayEmail
}

const EmailDisplay = ({ displayEmail }: EmailDisplayProps) => {
  const { t } = useTranslation()

  return displayEmail?.username && displayEmail?.domain ? (
    <div className="mt-2" data-testid="email-display-parent">
      <Typography variant="body2" colors="light" data-testid="create-subscriber-email-title">
        {t('ssp:pages.licenses.createSubcriberForm.email')}
      </Typography>
      <Typography
        variant="body2"
        className="truncate"
        data-testid="create-subscriber-email-display"
      >
        {`${displayEmail.username}@${displayEmail.domain}`}
      </Typography>
    </div>
  ) : null
}

export default EmailDisplay
