import React from 'react'

import { mergeClassName } from '../../utils/mergeClassName'
import { FormInput } from './FormInput'

export interface FormInputWithChildrenProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder'> {
  placeholder?: string | null
  containerClassName?: string
}

const FormInputWithChildren = React.forwardRef<HTMLInputElement, FormInputWithChildrenProps>(
  ({ containerClassName = '', children, ...props }, ref) => (
    <div className={mergeClassName('flex flex-row', containerClassName)}>
      <FormInput ref={ref} {...props} />
      {children}
    </div>
  )
)

FormInputWithChildren.displayName = 'FormInputWithChildren'

export { FormInputWithChildren }
