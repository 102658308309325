import { Avatar, AvatarFallback } from '@sherweb/core/components/Avatar'
import { getInitials } from '@sherweb/core/utils/initials'

type ProductAvatarProps = {
  fullName?: string
  avatar?: string
}

const ProductAvatar = ({ fullName, avatar }: ProductAvatarProps) => (
  <Avatar className="rounded-sm">
    <AvatarFallback className="rounded-sm bg-slate-200 dark:border dark:border-slate-800 dark:bg-slate-950">
      {avatar ? (
        <img
          src={avatar}
          className="h-6 max-h-32 w-fit object-contain align-middle italic"
          alt="Hey"
        />
      ) : (
        getInitials(fullName)
      )}
    </AvatarFallback>
  </Avatar>
)

export default ProductAvatar
