import { Permission, usePermissionsByOrganization } from '@sherweb/core/modules/authorization'

import { useSelectedOrganizationId } from '@ssp/modules/organization'

export const usePermissionCheck = () => {
  const selectedOrganizationId = useSelectedOrganizationId()
  const permissions = usePermissionsByOrganization(selectedOrganizationId ?? '')

  return {
    permissionsLoading: permissions.isLoading,
    permissionsFetched: permissions.data.length > 0,
    hasAccess: (permission: Permission) =>
      !!(permissions.data.length > 0 && permissions.data.some(p => p === permission)),
  }
}
