import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import Tooltip from '@sherweb/core/components/Tooltip'
import { Typography } from '@sherweb/core/components/Typography'

import { FormSplitScreen } from '@rsp/components/layout/FormSplitScreen'
import { useGetUnMappedOrganizations } from '@rsp/modules/integrations/helpdesk'

import { OrganizationMappingFormType } from './hooks/useOrganizationMappingSchema'

type OrganizationMappingFormProps = {
  disabled?: boolean
}

export const OrganizationMappingForm = ({ disabled }: OrganizationMappingFormProps) => {
  const { t } = useTranslation()

  const form = useFormContext<OrganizationMappingFormType>()

  const mappedOrganizations = useGetUnMappedOrganizations()

  return (
    <FormSplitScreen.RowCard
      contentContainerClassName="md:max-w-md max-w-full"
      leftContent={
        <FormSplitScreen.LeftContent
          label={
            <div className="flex items-center gap-2">
              {t('rsp:pages.integrations.helpdesk.connectWise.organizationMapping.title')}
              <Typography as="div">
                <Tooltip
                  className="max-w-lg"
                  tooltip={t(
                    'rsp:pages.integrations.helpdesk.connectWise.organizationMapping.titleTooltip'
                  )}
                >
                  <InformationCircleIcon className="h-4 w-4" />
                </Tooltip>
              </Typography>
            </div>
          }
          subLabel={t(
            'rsp:pages.integrations.helpdesk.connectWise.organizationMapping.description'
          )}
        />
      }
    >
      {mappedOrganizations?.data?.map(({ name, id }) => (
        <FormField
          key={`organizations.${id}`}
          control={form.control}
          name={`organizations.${id}`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{name}</FormLabel>
              <FormControl>
                <FormInput
                  {...field}
                  disabled={disabled}
                  data-testid="inputOrganizationMapping"
                  placeholder={t(
                    'rsp:pages.integrations.helpdesk.connectWise.organizationMapping.organizationIdPlaceholder'
                  )}
                  onChange={event => {
                    form.clearErrors(`organizations.${id}`)
                    field.onChange(event?.target.value)
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      ))}
    </FormSplitScreen.RowCard>
  )
}
