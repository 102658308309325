import { QueryKey } from '@tanstack/react-query'

import { ResellersService } from '@sherweb/core/openapi-generated/ResellersService'
import { ResellerUsersService } from '@sherweb/core/openapi-generated/ResellerUsersService'

export const reseller = {
  queryKey: (hostname: string): QueryKey => ['ResellersService/getReseller', hostname],
  queryFn: async (hostname: string) => await ResellersService.getReseller({ hostname }),
  staleTime: 60 * 60 * 1000,
}

export const resellerAdminEmails = {
  queryKey: (resellerId?: string) => ['ResellerUsersService/getAdminEmails', resellerId],
  queryFn: async (resellerId?: string) =>
    await ResellerUsersService.getResellerAdminsEmails({ resellerId: resellerId ?? '' }),
  staleTime: Infinity,
}
