/* istanbul ignore file */

import { MutationCache, QueryCache, QueryClient, QueryKey } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import i18n from 'i18next'
import { useEffect } from 'react'

import { isCORSError } from '@sherweb/core/utils/error'

import { CoreRoutes } from '../../../common/coreRoutes'
import { Language } from '../../../common/language'
import { errorNotification, showApiErrorToast } from '../../../components/ToastNotifications'
import { serviceOptions } from '../../../openapi-generated/index.defs'
import { getCodesToSkip, httpResponse400Codes, setLocation } from './utils'

type ClientConfig = {
  api: {
    url: string
    signInPath?: string
    signOutPath?: string
    includeCredentials?: boolean
  }
  whiteListed401Paths?: string[]
}

export let coreWhiteListed401Paths: string[] = []

export const getQueryClientInstance = () => {
  return new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (query?.meta?.errorMessage) {
          errorNotification(query?.meta?.errorMessage as string)
          return
        }

        return showApiErrorToast(error)
      },
    }),
    mutationCache: new MutationCache({
      onError: (error, variables, context, mutation) => {
        if (mutation?.options?.onError) {
          return null
        }

        showApiErrorToast(error)
      },
    }),
  })
}

export const createClient = ({ api, whiteListed401Paths = [] }: ClientConfig) => {
  const instance = axios.create({
    baseURL: api.url,
    timeout: 30 * 1000,
    withCredentials: api.includeCredentials,
  })

  const queryClient = getQueryClientInstance()

  instance.interceptors.request.use(request => {
    request.headers.set('Accept-Language', i18n.language ?? Language.EN)
    return request
  })

  instance.interceptors.response.use(
    response => response,
    async (error: AxiosError) => {
      const isSsr = typeof window === 'undefined'

      if (!error || isSsr) {
        return
      }

      if (isCORSError(error)) {
        return await actionOnCORSError(queryClient)
      }

      const statusCode = error.response ? error.response.status : null

      if (
        statusCode &&
        httpResponse400Codes.includes(statusCode) &&
        !getCodesToSkip(error?.config?.headers).some(p => p === statusCode)
      ) {
        setLocation(statusCode, whiteListed401Paths)
      }
      throw error
    }
  )

  coreWhiteListed401Paths = whiteListed401Paths
  serviceOptions.axios = instance

  return queryClient
}

export const canLocationMakeCallForCheckIsUserAuth = (): boolean => {
  return !coreWhiteListed401Paths.includes(window.location.pathname) && canLocationMakeCallForData()
}

const pagesWhichDoNotNeedToCallForData = [
  CoreRoutes.AdminForbiddenAccess.toString(),
  CoreRoutes.AdminNotFound.toString(),
  CoreRoutes.AdminUnauthorizedAccess.toString(),
  CoreRoutes.ForbiddenAccess.toString(),
  CoreRoutes.NotFound.toString(),
  CoreRoutes.UnauthorizedAccess.toString(),
  CoreRoutes.InternalError.toString(),
]

export const canLocationMakeCallForData = (): boolean => {
  return !pagesWhichDoNotNeedToCallForData.includes(window.location.pathname)
}

export const useInvalidateQuery = (
  queryKey: QueryKey,
  reload: boolean,
  queryClient: QueryClient
) => {
  useEffect(() => {
    if (reload) {
      const invalidateQuery = async () => {
        await queryClient.invalidateQueries(queryKey)
      }

      invalidateQuery().catch(console.error)
    }
  }, [queryClient, reload, queryKey])
}

export const actionOnCORSError = async (queryClient: QueryClient) => {
  await queryClient.cancelQueries()
  queryClient.removeQueries()
  queryClient.clear()
}
