import { XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import { Typography } from '@sherweb/core/components/Typography'
import { useQueryParams } from '@sherweb/core/hooks'

export const MarketPlaceFilterReset = () => {
  const { t } = useTranslation()

  const { getParams, setSearchParams } = useQueryParams<
    'productsVendor' | 'productsBillingCycle' | 'productsCommitment' | 'productsCategories'
  >()

  const hasFilters =
    getParams('productsVendor') ||
    getParams('productsBillingCycle') ||
    getParams('productsCommitment') ||
    getParams('productsCategories')

  const handleResetFilters = () => {
    setSearchParams('productsVendor', '')
    setSearchParams('productsBillingCycle', '')
    setSearchParams('productsCommitment', '')
    setSearchParams('productsCategories', '')
    setSearchParams('productsCategories', '')
  }

  if (hasFilters === '') {
    return null
  }

  return (
    <Button
      variant="None"
      size="small"
      className=" flex items-center px-4 py-2 hover:bg-white/50 dark:text-slate-100"
      onClick={handleResetFilters}
    >
      <XMarkIcon className="mr-1 h-4 w-4 text-indigo-600" />
      <Typography className="text-indigo-600" variant="body2">
        {t('core:marketplace.resetFilters')}
      </Typography>
    </Button>
  )
}
