import { useTranslation } from 'react-i18next'

import LoaderButton from '@sherweb/core/components/LoaderButton'

type SubmitButtonProps = {
  children?: React.ReactNode
  disabled?: boolean
  showLoader?: boolean
}

export const SubmitButton = ({
  disabled = false,
  showLoader = false,
  children,
}: SubmitButtonProps) => {
  const { t } = useTranslation()

  return (
    <div className="grid-col-1 grid gap-y-4 md:grid-cols-2 md:gap-y-0">
      <div className="w-full sm:w-9/12" />
      <div className="w-full">
        <LoaderButton
          type="submit"
          disabled={disabled}
          className="col-start-2 w-full sm:w-1/2"
          dataTestId="saveSettings"
          isLoading={showLoader}
          loadingChildren={children ?? t('rsp:pages.settings.labels.saving')}
        >
          {children ?? t('rsp:pages.settings.labels.save')}
        </LoaderButton>
      </div>
    </div>
  )
}
