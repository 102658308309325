import {
  ToastContainer as ToastifyContainer,
  ToastContainerProps as ToastifyToastContainerProps,
} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './style.scss'

type ToastContainerProps = {
  darkMode?: boolean
} & Omit<ToastifyToastContainerProps, 'theme'>

const ToastContainer: React.FC<ToastContainerProps> = ({ darkMode = false, ...props }) => {
  return (
    <div data-testid="toastContainer">
      <ToastifyContainer
        position="top-right"
        autoClose={5000}
        className="toastContainer"
        theme={darkMode ? 'dark' : 'light'}
        {...props}
      />
    </div>
  )
}

export default ToastContainer
