import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { DebouncedSearchInput } from '@sherweb/core/components/DebouncedSearchInput'
import { Skeleton } from '@sherweb/core/components/Skeleton'
import { useQueryParams } from '@sherweb/core/hooks'

type ShopSearchProps = {
  isLoading: boolean
}

export const ShopSearch = ({ isLoading }: ShopSearchProps) => {
  const { t } = useTranslation()

  const { getParams, setSearchParams } = useQueryParams<'productsQuery'>()

  const querySearchValue = getParams<string>('productsQuery')

  const handleChange = (inputValue: string) => {
    setSearchParams('productsQuery', inputValue)
  }

  return (
    <div className="mt-6 flex justify-center">
      <div className="relative flex h-14">
        {isLoading ? (
          <Skeleton className="h-14 w-[30rem] rounded-full" />
        ) : (
          <>
            <MagnifyingGlassIcon
              className="absolute left-4 top-[18px] h-5 w-5 text-gray-500 focus-within:text-gray-600"
              aria-hidden="true"
            />
            <DebouncedSearchInput
              type="text"
              className="text-md h-full w-full min-w-80 rounded-full border border-gray-300 py-2 pl-12 text-left text-gray-600 outline-none focus:border-blue-500 focus:ring-blue-500 focus-visible:outline-offset-0 focus-visible:outline-indigo-300 dark:border-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700 sm:min-w-[480px]"
              placeholder={t('ssp:pages.shop.searchPlaceholder')}
              data-testid="inputFilterProducts"
              value={querySearchValue}
              onChange={handleChange}
            />
          </>
        )}
      </div>
    </div>
  )
}
