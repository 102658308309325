import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import { Typography } from '@sherweb/core/components/Typography'
import { useQueryParams } from '@sherweb/core/hooks'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { useCommitmentTermTranslation } from '@ssp/modules/shop/core/shop.helpers'

import { useGetOffersByCommitmentTerm } from '../hooks/useGetOffersByCommitmentTerm'

export const ShopDetailCommitment = () => {
  const { t } = useTranslation()

  const { getParams, setSearchParams } = useQueryParams<'productCommitment' | 'productSku'>()

  const selectedCommitmentTerm = getParams('productCommitment')

  const getCommitmentTermTranslation = useCommitmentTermTranslation()

  const { offersByCommitmentTerm, offersByCommitmentTermKeys, hasOnlyNoCommitmentTerm } =
    useGetOffersByCommitmentTerm()

  useEffect(() => {
    if (!selectedCommitmentTerm && offersByCommitmentTerm) {
      const firstKey = offersByCommitmentTermKeys.find(
        key => offersByCommitmentTerm[key].length > 0
      )

      if (firstKey) {
        setSearchParams('productCommitment', firstKey)
      }
    }
  }, [offersByCommitmentTerm, offersByCommitmentTermKeys, selectedCommitmentTerm, setSearchParams])

  if (hasOnlyNoCommitmentTerm) {
    return null
  }

  return (
    <>
      <Typography variant="body1" weight="medium" className="text-wrap">
        {t('ssp:pages.shop.detail.commitmentTerm')}
      </Typography>
      <div className="mb-6 mt-2 flex flex-wrap gap-4">
        {offersByCommitmentTermKeys?.map(term => {
          const offers = offersByCommitmentTerm?.[term]
          if (offers?.length === 0) {
            return null
          }

          return (
            <Button
              className={mergeClassName({
                'border-2 border-indigo-600 dark:border-indigo-200':
                  selectedCommitmentTerm === term,
              })}
              key={term}
              variant="outline"
              size="sm"
              type="button"
              onClick={() => {
                if (selectedCommitmentTerm !== term) {
                  setSearchParams('productCommitment', term)
                  setSearchParams('productSku', '')
                }
              }}
            >
              {getCommitmentTermTranslation(term)}
            </Button>
          )
        })}
      </div>
    </>
  )
}
