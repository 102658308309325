import { Trans, useTranslation } from 'react-i18next'

import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'

export type MarketplaceCatalogsAssignedOrganizationsConfirmationDialogProps = {
  onSubmit: () => void
  cancelConfirmation: () => void
  confirmationDialogOpened: boolean
  catalogName?: string
  organizationsNumber: number
}

export const MarketplaceCatalogsAssignedOrganizationsConfirmationDialog = ({
  onSubmit,
  cancelConfirmation,
  confirmationDialogOpened,
  catalogName,
  organizationsNumber,
}: MarketplaceCatalogsAssignedOrganizationsConfirmationDialogProps) => {
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
      open={confirmationDialogOpened}
      onClose={cancelConfirmation}
      onConfirm={onSubmit}
      title={t('rsp:pages.marketplace.manageOrganizations.confirmation.title', {
        catalogName,
      })}
      confirmButtonText={t('rsp:pages.marketplace.manageOrganizations.confirmation.updateCatalog')}
      cancelButtonText={t('rsp:actions.cancel')}
    >
      <Trans
        i18nKey="rsp:pages.marketplace.manageOrganizations.confirmation.body"
        values={{
          catalogName,
          count: organizationsNumber,
        }}
      />
    </ConfirmationDialog>
  )
}
