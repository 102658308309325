import { useTranslation } from 'react-i18next'

import Alert, { Variant } from '@sherweb/core/components/Alert'

type QuantityFormAlertProps = {
  isQuantityZero?: boolean
  minimumQuantityReached?: boolean
  isCancelSubscriptionAllowed?: boolean
}

export const QuantityFormAlert = ({
  minimumQuantityReached,
  isQuantityZero,
  isCancelSubscriptionAllowed,
}: QuantityFormAlertProps) => {
  const { t } = useTranslation()

  if (minimumQuantityReached) {
    return (
      <Alert variant={Variant.Danger}>
        {t('ssp:pages.subscription.quantityForm.cannotDecreaseSubscription')}
      </Alert>
    )
  }

  return isQuantityZero && !isCancelSubscriptionAllowed ? (
    <Alert variant={Variant.Danger}>
      {t('ssp:pages.subscription.quantityForm.cannotCancelSubscription')}
    </Alert>
  ) : null
}
