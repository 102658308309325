/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { InformationCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { memo, useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { useTranslation } from 'react-i18next'

import { sort } from '@sherweb/core/common/dynamicSort'
import Alert, { Variant as AlertVariant } from '@sherweb/core/components/Alert/Alert'
import { Avatar, AvatarFallback } from '@sherweb/core/components/Avatar'
import Button from '@sherweb/core/components/Button'
import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@sherweb/core/components/Sheet'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import { errorNotification, successNotification } from '@sherweb/core/components/ToastNotifications'
import Tooltip from '@sherweb/core/components/Tooltip'
import { usePrevious } from '@sherweb/core/hooks'
import { Permission } from '@sherweb/core/modules/authorization'
import { getInitials } from '@sherweb/core/utils/initials'

import { usePermissionCheck } from '@ssp/modules/authorization'
import {
  hasPendingAddUpdateInLicense,
  License,
  Subscriber,
  SubscriberType,
  useSubscribers,
  useUpdateLicenses,
} from '@ssp/modules/license'
import {
  SubscriberUserPayload,
  UpdateLicenseSubscribersPayload,
} from '@ssp/modules/license/core/license.type'

import CreateSubscriberForm from '../CreateSubscriberForm'
import SubscriberActionsButton from './SubscriberActionsButton'

type SubscribersDialogProps = {
  open: boolean
  onOpenChange: (isOpen: boolean) => void
  license: License
}

const SubscribersDialog: React.FC<SubscribersDialogProps> = ({
  open = false,
  onOpenChange,
  license,
}) => {
  const { t } = useTranslation()
  const { isLoading, data: subscribers } = useSubscribers()

  const previousSubscribers = usePrevious(subscribers)
  const { updateLicenses, isLoading: updating } = useUpdateLicenses()
  const { hasAccess } = usePermissionCheck()
  const [subscriberIdsToRemove, setsubscriberIdsToRemove] = useState([] as string[])
  const [sortedSubscribers, setSortedSubscribers] = useState<Subscriber[]>([])
  const [subscriberEmailsToAdd, setsubscriberEmailsToAdd] = useState([] as string[])
  const [touched, setTouched] = useState(false)
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)
  const [isCreatingSubscriber, setIsCreatingSubscriber] = useState(false)
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showWarning, setShowWarning] = useState(false)

  const updateButtonContent = () => {
    const updateTranslateElement = (
      <div>
        {updating
          ? t('ssp:pages.licenses.subscribersDialog.confirmationButtonLoading')
          : t('ssp:pages.licenses.subscribersDialog.confirmationButton')}
      </div>
    )

    // Update button is disabled
    if (isUpdateButtonDisabled) {
      if (updating) {
        return updateTranslateElement
      } else {
        return (
          <Tooltip
            data-testid="tooltip"
            tooltip={t('ssp:pages.licenses.subscribersDialog.confirmationButtonDisableMessage')}
          >
            <span>{t('ssp:pages.licenses.subscribersDialog.confirmationButton')}</span>
            <InformationCircleIcon
              data-testid="information-circle-icon"
              className="ml-1 h-5 w-5 stroke-1 dark:stroke-2"
            />
          </Tooltip>
        )
      }
    }

    // Update button is enabled
    return updateTranslateElement
  }

  const resetSubscribers = () => {
    setsubscriberEmailsToAdd([])
    setsubscriberIdsToRemove([])
  }

  useEffect(() => {
    const subscribersPendingUpdate = subscribers?.filter(x =>
      hasPendingAddUpdateInLicense(license, x)
    )

    let remainingQuantity = license.availableQuantity - license.numberUsers

    remainingQuantity = remainingQuantity < 0 ? 0 : remainingQuantity

    const alreadySubscribersCount =
      subscriberEmailsToAdd.length +
      (subscribersPendingUpdate?.length ?? 0) -
      subscriberIdsToRemove.length

    const maxLicensesReached = alreadySubscribersCount > remainingQuantity

    const hasWarningAlertVisible = touched
      ? alreadySubscribersCount >= remainingQuantity
      : remainingQuantity <= 0

    setShowWarning(hasWarningAlertVisible)
    setIsUpdateButtonDisabled(updating || !touched || maxLicensesReached)
  }, [updating, touched, subscriberEmailsToAdd, subscriberIdsToRemove, subscribers, license])

  useEffect(() => {
    resetSubscribers()
  }, [license])

  useEffect(() => {
    if (!isEqual(previousSubscribers, subscribers) && subscribers?.length !== 0 && license?.id) {
      const assignedUsers = subscribers?.filter(x => {
        const licenseUpdates = x.licenseUpdates?.map(({ licenseId }) => licenseId)

        return x.assignedLicenses?.includes(license.id) ?? licenseUpdates?.includes(license.id)
      })

      const unassignedUsers = subscribers?.filter(x => !x.assignedLicenses?.includes(license.id))

      assignedUsers && sort(assignedUsers, 'firstName', { ignoreCase: true })
      unassignedUsers && sort(unassignedUsers, 'firstName', { ignoreCase: true })

      const sorted = unassignedUsers ? assignedUsers?.concat(unassignedUsers) : []
      sorted && setSortedSubscribers(sorted)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousSubscribers, subscribers])

  useEffect(() => {
    if (subscriberEmailsToAdd.length > 0 || subscriberIdsToRemove.length > 0) {
      setTouched(true)
    } else {
      setTouched(false)
    }
  }, [subscriberEmailsToAdd, subscriberIdsToRemove])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value
    setSearchTerm(newSearchTerm)
  }

  const handleClosingDialogRequest = () => {
    if (touched) {
      setConfirmationDialogOpened(true)
    } else {
      onOpenChange(false)
    }
  }

  const handleRemoveLicense = (subscriberId: string | null | undefined) => {
    if (subscriberId) {
      const ids = subscriberIdsToRemove.slice()

      if (ids.includes(subscriberId)) {
        const index = ids.indexOf(subscriberId, 0)
        if (index > -1) {
          ids.splice(index, 1)
        }
      } else {
        ids.push(subscriberId)
      }

      setsubscriberIdsToRemove(ids)
    }
  }

  const handleAddLicense = (email: string | null | undefined) => {
    if (email) {
      const emails = subscriberEmailsToAdd.slice()

      if (emails.includes(email)) {
        const index = emails.indexOf(email, 0)
        if (index > -1) {
          emails.splice(index, 1)
        }
      } else {
        emails.push(email)
      }

      setsubscriberEmailsToAdd(emails)
    }
  }

  const handleUpdateUsers = async () => {
    const subscibersToAdd: Subscriber[] = subscriberEmailsToAdd.map(value => {
      return sortedSubscribers.filter(x => x.email === value)[0]
    })

    const subscribersToAdd: SubscriberUserPayload[] = subscibersToAdd.map(value => {
      if (value.type === SubscriberType.Subscriber && value.id) {
        return {
          id: value.id,
          firstName: undefined,
          lastName: undefined,
          email: undefined,
        }
      } else {
        return {
          id: undefined,
          firstName: value.firstName ?? '',
          lastName: value.lastName ?? '',
          email: value.email ?? '',
        }
      }
    })

    const updateLicenseCommand: UpdateLicenseSubscribersPayload = {
      licenseId: license.id,
      subscriberIdsOrUsersToBeAdded: subscribersToAdd,
      subscriberIdsToBeRemoved: subscriberIdsToRemove,
    }

    try {
      await updateLicenses(updateLicenseCommand)
      successNotification(t('ssp:pages.licenses.subscribersDialog.requestSentWithSuccess'))
      onOpenChange(false)
    } catch (error) {
      errorNotification(t('ssp:errors.generic'))
    }
  }

  const handleCancelChanges = () => {
    resetSubscribers()
    onOpenChange(false)
  }

  const filteredSubscribers = sortedSubscribers?.filter(subscriber => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase()
    const firstName = (subscriber.firstName ?? '').toLowerCase()
    const lastName = (subscriber.lastName ?? '').toLowerCase()
    const fullName = firstName.concat(' ', lastName)
    const email = subscriber.email?.toLowerCase()

    return fullName?.includes(lowerCaseSearchTerm) || email?.includes(lowerCaseSearchTerm)
  })

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        className="flex w-full flex-col overflow-auto sm:w-[550px]"
        onPointerDownOutside={e => {
          e.preventDefault()
          handleClosingDialogRequest()
        }}
        onEscapeKeyDown={e => {
          e.preventDefault()
          handleClosingDialogRequest()
        }}
        onClose={() => {
          handleClosingDialogRequest()
        }}
      >
        {isLoading ? (
          <Spinner className={spinnerStyles({ type: 'layOverSpinner' })} />
        ) : (
          <>
            <SheetHeader>
              <SheetTitle>{license.productName ?? ''}</SheetTitle>

              {showWarning && (
                <Alert
                  data-testid="alertSubscribersDialog"
                  variant={AlertVariant.Warning}
                  className="text-black dark:text-black"
                >
                  {t('ssp:warnings.noLicensesAvailable')}
                </Alert>
              )}

              <div className="flex flex-row justify-between">
                <SheetDescription>
                  {t('ssp:pages.licenses.subscribersDialog.title')}
                </SheetDescription>
              </div>
            </SheetHeader>

            <div className="mt-2">
              {isCreatingSubscriber && (
                <CreateSubscriberForm
                  existingSubscribers={sortedSubscribers}
                  license={license}
                  onCancel={() => setIsCreatingSubscriber(false)}
                  onSuccess={() => {
                    setIsCreatingSubscriber(false)
                    onOpenChange(false)
                  }}
                />
              )}
              {!isCreatingSubscriber && (
                <div className="mb-4 flex flex-row justify-between gap-4">
                  <div className="relative flex w-full items-center">
                    <MagnifyingGlassIcon
                      className="absolute left-3 h-5 w-5 text-gray-500 focus-within:text-gray-600"
                      aria-hidden="true"
                    />
                    <input
                      type="text"
                      placeholder={t('ssp:pages.licenses.subscribersDialog.filterPlaceholder')}
                      value={searchTerm}
                      onChange={handleInputChange}
                      data-testid="inputFilterUsers"
                      className="w-full rounded-lg border border-gray-300 py-2 pl-10 text-left text-sm font-normal leading-none text-gray-600 outline-none focus:border-blue-500 focus:ring-blue-500 focus-visible:outline-offset-0 focus-visible:outline-indigo-300 dark:border-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700"
                    />
                  </div>
                  <Button
                    variant="primary"
                    disabled={!hasAccess(Permission.LicenseAssign)}
                    className="h-9 w-1/5 lg:w-3/5"
                    onClick={() => {
                      setIsCreatingSubscriber(true)
                    }}
                    data-testid="dialogBtnCreateSubscriberEnabler"
                  >
                    <span className="hidden lg:block">
                      {t('ssp:pages.licenses.subscribersDialog.createSubscriberButton')}
                    </span>
                    <span className="lg:hidden">+</span>
                  </Button>
                </div>
              )}
            </div>
            <div
              className="h-full overflow-y-auto pb-16 sm:pb-8"
              data-testid="dialogSubscribersContent"
            >
              {filteredSubscribers && filteredSubscribers.length > 0 ? (
                filteredSubscribers.map(subscriber => {
                  return (
                    <div
                      key={subscriber?.id ?? subscriber?.firstName}
                      className="flex flex-row pr-2"
                      data-testid="subscribers-dialog-single-subscriber"
                    >
                      <div className="flex w-full flex-row justify-between border-b border-b-slate-200 py-4 dark:border-b-slate-800">
                        <div className="flex flex-row gap-x-4">
                          <Avatar>
                            <AvatarFallback className="dark:bg-slate-950 dark:text-slate-200">
                              {getInitials(`${subscriber.firstName} ${subscriber.lastName}`)}
                            </AvatarFallback>
                          </Avatar>
                          <div className="flex flex-col gap-1">
                            <div className="text-sm font-semibold text-foreground">
                              {`${subscriber.firstName} ${subscriber.lastName}`}
                            </div>
                            <div className="text-xs font-normal text-slate-500">
                              {subscriber?.email}
                            </div>
                          </div>
                        </div>
                        <SubscriberActionsButton
                          license={license}
                          subscriber={subscriber}
                          subscriberEmailsToAdd={subscriberEmailsToAdd}
                          subscriberIdsToRemove={subscriberIdsToRemove}
                          onAddLicense={handleAddLicense}
                          onRemoveLicense={handleRemoveLicense}
                        />
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="text-foreground dark:text-slate-200">
                  {t('ssp:pages.licenses.subscribersDialog.noSubscriber')}
                </div>
              )}
            </div>

            {!isCreatingSubscriber && (
              <SheetFooter
                className="fixed bottom-0 left-0 flex w-full flex-row gap-4 bg-gradient-to-b from-white/60 to-white/50 px-8 py-4 shadow-[0px_4px_24px_#00000012,inset_0px_-1px_1px_#ffffff20,inset_0px_1px_1px_#ffffff40] backdrop-blur-[32px] backdrop-brightness-[100%] dark:bg-slate-900 dark:bg-none dark:text-white
          dark:shadow-none dark:backdrop-blur-none  sm:relative sm:px-0 sm:shadow-none"
              >
                <Button
                  variant="outline"
                  type="button"
                  className="w-full"
                  data-testid="btnCancelUpdateSubscribers"
                  onClick={() => {
                    handleClosingDialogRequest()
                  }}
                >
                  {t('ssp:actions.cancel')}
                </Button>
                <LoaderButton
                  className="w-full"
                  disabled={isUpdateButtonDisabled}
                  dataTestId="btnUpdateSubscribers"
                  onClick={async () => await handleUpdateUsers()}
                  type="button"
                  isLoading={updating}
                >
                  {updateButtonContent()}
                </LoaderButton>
              </SheetFooter>
            )}
          </>
        )}
      </SheetContent>
      <ConfirmationDialog
        open={confirmationDialogOpened}
        onClose={() => setConfirmationDialogOpened(false)}
        onConfirm={() => handleCancelChanges()}
        title={t('ssp:pages.licenses.subscribersDialog.confirmationModal.title')}
        confirmButtonText={t(
          'ssp:pages.licenses.subscribersDialog.confirmationModal.confirmationButtonText'
        )}
        cancelButtonText={t('ssp:actions.cancel')}
      >
        <p>{t('ssp:pages.licenses.subscribersDialog.confirmationModal.description')}</p>
      </ConfirmationDialog>
    </Sheet>
  )
}

export default memo(SubscribersDialog)
