import {
  OrganizationPaymentMethodResponse,
  PaymentMethodType,
  Currency,
  ExpirationDate,
  PaymentMethodAddress,
  RegisterPaymentMethodCommand,
  PaymentAddress,
  RegisterPaymentMethodResponse,
  OperationResult,
  OperationResultReason,
  PaymentMethodCreditCardResult,
  ExpirationDate2,
  ThreeDSecureAuthenticationResult,
  ThreeDSecureAuthenticationStatus,
  PaymentMethodAchResult,
  AchVerificationMethod,
  AchVerificationState,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class OrganizationPaymentMethodsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getPaymentMethodsByOrganizationId(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationPaymentMethodResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/paymentMethods';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static registerOrganizationPaymentMethod(
    params: {
      /**  */
      organizationId: string;
      /**  */
      command: RegisterPaymentMethodCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RegisterPaymentMethodResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/paymentMethods';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
