import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'

import { DataTestId } from '@sherweb/core/types/dataTestIdType'
import { ComponentChildrenType } from '@sherweb/core/types/reactChildrenType'

export const NoResultsMobile = ({ children, dataTestId }: ComponentChildrenType & DataTestId) => (
  <div
    className="flex w-full flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-300 p-8 font-normal text-gray-400 md:h-60"
    data-testid={dataTestId ?? 'containerNoResultsMobile'}
  >
    <ArchiveBoxXMarkIcon className="h-16 w-16 stroke-1 text-gray-400" data-testid="archiveIcon" />
    <p>{children}</p>
  </div>
)
