import { useCallback, useRef } from 'react'

import { InfiniteScrollSpinner } from './InfiniteScrollSpinner'

type InfiniteScrollProps = {
  isLoading?: boolean
  hasMore?: boolean
  next: () => void
  threshold?: number
  root?: Element | null
  rootMargin?: string
}

export const InfiniteScroll = ({
  isLoading = false,
  hasMore,
  next,
  threshold = 0.8,
  root = null,
  rootMargin = '0px',
}: InfiniteScrollProps) => {
  const observer = useRef<IntersectionObserver | null>(null)

  const observeElement = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading || !hasMore || !node) {
        return
      }

      if (observer.current) {
        observer.current.disconnect()
      }

      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            next()
          }
        },
        { threshold, root, rootMargin }
      )

      observer.current.observe(node)
    },
    [hasMore, isLoading, next, root, rootMargin, threshold]
  )

  return <div ref={observeElement}>{isLoading ? <InfiniteScrollSpinner /> : null}</div>
}
