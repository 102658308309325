import { Label } from '@sherweb/core/components/Label'
import {
  Typography,
  TypographyProps,
  typographyVariants,
} from '@sherweb/core/components/Typography'
import { ChildrenType } from '@sherweb/core/types/dataTestIdType'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

export const InvoiceDetailLayoutLabel = ({
  children,
  className,
}: ChildrenType & { className?: string }) => (
  <Label
    htmlFor="div"
    className={typographyVariants({
      variant: 'body1',
      colors: 'light',
      weight: 'medium',
      className,
    })}
  >
    {children}
  </Label>
)

export const InvoiceDetailLayoutContent = ({
  children,
  ...restProps
}: ChildrenType & TypographyProps) => (
  <Typography variant="body1" weight="medium" {...restProps} as="div">
    {children}
  </Typography>
)

export const InvoiceDetailLayoutColumn = ({
  children,
  className,
}: ChildrenType & { className?: string }) => (
  <div className={mergeClassName('flex flex-col gap-8', className)}>{children}</div>
)

export const InvoiceDetailLayout = ({
  children,
  className,
}: ChildrenType & { className?: string }) => (
  <div className={mergeClassName('flex flex-col', className)}>{children}</div>
)

InvoiceDetailLayout.Column = InvoiceDetailLayoutColumn
InvoiceDetailLayout.Content = InvoiceDetailLayoutContent
InvoiceDetailLayout.Label = InvoiceDetailLayoutLabel
