import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Tooltip from '@sherweb/core/components/Tooltip'
import { Typography } from '@sherweb/core/components/Typography'

export const OrganizationSettingsHeader = () => {
  const { t } = useTranslation()

  return (
    <>
      {t('rsp:pages.organizations.list.settings')}
      <Tooltip
        wrapperClassName="align-middle pl-3"
        tooltip={
          <Typography variant="body2">
            {t('rsp:pages.organizations.list.settingsTooltip')}
          </Typography>
        }
      >
        <InformationCircleIcon data-testid="settingsTooltip" className="h-4 w-4" />
      </Tooltip>
    </>
  )
}
