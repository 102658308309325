import { t } from 'i18next'
import { Trans } from 'react-i18next'

import { CatalogOrganizationQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'
import { Typography } from '@sherweb/core/components/Typography'

export type MarketplaceCatalogsAssignedOrganizationsPartialSuccessDialogProps = {
  onClose: () => void
  confirmationDialogOpened: boolean
  failedOrganizations?: CatalogOrganizationQueryResult[]
  organizationsNumber: number
}

export const MarketplaceCatalogsAssignedOrganizationsPartialSuccessDialog = ({
  onClose,
  confirmationDialogOpened,
  failedOrganizations,
  organizationsNumber,
}: MarketplaceCatalogsAssignedOrganizationsPartialSuccessDialogProps) => (
  <ConfirmationDialog
    open={confirmationDialogOpened}
    onClose={onClose}
    onCancel={onClose}
    title={t('rsp:pages.marketplace.manageOrganizations.partialSucess.title')}
    cancelButtonText={t('core:actions.close')}
  >
    <p>
      <Trans
        i18nKey="rsp:pages.marketplace.manageOrganizations.partialSucess.body"
        values={{
          count: organizationsNumber,
        }}
      />
      <Trans
        i18nKey="rsp:pages.marketplace.manageOrganizations.partialSucess.body2"
        values={{
          count: failedOrganizations?.length ?? 0,
        }}
      />
    </p>
    <div className="mt-4 max-h-36 overflow-y-auto">
      <ul className="list-disc p-2 pl-10">
        {failedOrganizations?.map(organization => (
          <li key={`failed-organization-${organization.organizationId}`}>
            <Typography variant="body2">{organization.name}</Typography>
          </li>
        ))}
      </ul>
    </div>
  </ConfirmationDialog>
)
