import React from 'react'

type SummaryItemProps = {
  description: React.ReactNode
  Icon?: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>
}

const SummaryItem: React.FC<SummaryItemProps> = ({ description, Icon }) => {
  return (
    <div className="flex flex-row items-center gap-4 py-1 text-slate-600">
      {Icon && (
        <div className="flex h-11 w-11 shrink-0 items-center justify-center rounded-full bg-slate-200 text-slate-900 dark:border dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200">
          <Icon className="w-6" />
        </div>
      )}
      <span className="text-sm text-gray-900 dark:text-slate-200">{description}</span>
    </div>
  )
}

export default SummaryItem
