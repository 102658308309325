import EyeIcon from '@heroicons/react/24/outline/EyeIcon'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import Tooltip from '@sherweb/core/components/Tooltip'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess } from '@ssp/modules/authorization'
import { useGetHelpDeskRouteParams } from '@ssp/modules/integrations/helpdesk/core/helpdesk.helpers'

type HelpDeskTicketTableActionRowProps = {
  ticketId?: string
}

export const HelpDeskTicketTableActionRow = ({ ticketId }: HelpDeskTicketTableActionRowProps) => {
  const { t } = useTranslation()

  const helpDeskRouteParams = useGetHelpDeskRouteParams({ helpDeskTicketId: ticketId ?? '' })

  return (
    <div className="flex justify-center">
      <HasAccess permission={Permission.HelpDeskDetails}>
        <Tooltip tooltip={t('ssp:pages.helpdesk.detail.show')}>
          <Button asChild className="p-0" variant="none" data-testid="btnHelpDeskTicketShowDetail">
            <Link to={Routes.HelpDeskTicketDetail} params={helpDeskRouteParams}>
              <EyeIcon className="h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
            </Link>
          </Button>
        </Tooltip>
      </HasAccess>
    </div>
  )
}
