import { useTranslation } from 'react-i18next'

import {
  OrderSortField,
  OrganizationOrderQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import Card from '@sherweb/core/components/Card'
import DataTableServerPagination from '@sherweb/core/components/DataTable/DataTableServerPagination'
import { useServerPagination } from '@sherweb/core/components/DataTable/hooks/useServerPagination'
import { Columns } from '@sherweb/core/components/DataTable/types'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import { getOrdersPaginationDefaultValues } from '@sherweb/core/modules/orders/core/orders.helpers'
import { OrderStatus } from '@sherweb/core/modules/orders/ui/OrderStatus'
import { OrderType } from '@sherweb/core/modules/orders/ui/OrderType'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'

import { useGetOrdersQuery } from '@ssp/modules/orders'

import { OrdersTableRowActions } from './OrdersTableRowActions'

const defaultMeta = { className: 'align-top text-center' }

const renderId = (orderId?: string) => <div className="font-medium">{orderId}</div>

const renderOrderType = (order: OrganizationOrderQueryResult) => (
  <OrderType orderType={order?.type} />
)

const renderStatus = (order: OrganizationOrderQueryResult) => (
  <OrderStatus orderStatus={order?.orderStatus} />
)

const renderActionsDropdown = (order: OrganizationOrderQueryResult) => (
  <OrdersTableRowActions order={order} />
)

const OrdersTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { options, setOptions, onSortBy } = useServerPagination<OrderSortField>(
    getOrdersPaginationDefaultValues()
  )

  const ordersQuery = useGetOrdersQuery(options)

  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const columns: Columns<OrganizationOrderQueryResult> = [
    {
      accessorKey: 'humanReadableId',
      meta: { ...defaultMeta },
      header: t('ssp:pages.orders.list.id'),
      enableSorting: false,
      cell: ({ row }) => renderId(row.original.humanReadableId),
    },
    {
      accessorKey: OrderSortField.Name,
      header: t('ssp:pages.orders.list.productName'),
      meta: { className: 'align-top' },
      cell: ({ row }) => row.original.productName,
    },
    {
      accessorKey: OrderSortField.CreatedOn,
      header: t('ssp:pages.orders.list.createdOn'),
      meta: { className: 'align-top' },
      cell: ({ row }) => formatDate(row.original.createdOn),
    },
    {
      accessorKey: 'type',
      meta: { ...defaultMeta },
      header: t('ssp:pages.orders.list.type'),
      enableSorting: false,
      cell: ({ row }) => renderOrderType(row.original),
    },
    {
      accessorKey: OrderSortField.Quantity,
      meta: { ...defaultMeta },
      enableSorting: true,
      header: t('ssp:pages.orders.list.quantity'),
      cell: ({ row }) => row.original.requestedQuantity,
    },
    {
      accessorKey: 'orderStatus',
      meta: { ...defaultMeta },
      header: t('ssp:pages.orders.list.status'),
      enableSorting: false,
      cell: ({ row }) => renderStatus(row.original),
    },
    {
      id: 'actions',
      meta: { ...defaultMeta },
      enableSorting: false,
      cell: ({ row }) => renderActionsDropdown(row.original),
      minSize: 20,
    },
  ]

  return (
    <Card padded>
      {isRequestLoading(ordersQuery) ? (
        <SkeletonTable />
      ) : (
        <DataTableServerPagination
          columns={columns}
          data={ordersQuery?.data}
          dataTestId="tableOrders"
          isFetching={ordersQuery.isFetching}
          emptyMessage={t('ssp:pages.orders.noOrder')}
          filterPlaceholder={t('ssp:pages.orders.filterPlaceholder')}
          fieldDescription={t('ssp:pages.orders.fieldDescription')}
          onSortBy={onSortBy}
          setPageParams={setOptions}
        />
      )}
    </Card>
  )
}

export default OrdersTable
