import { UserRole, UsersQueryResult } from '../../../openapi-generated/index.defs'
import { Role } from '../../authorization/core/role.enum'
import { User } from './user.model'

export const buildUser = (response?: UsersQueryResult): User | null => {
  return response
    ? {
        id: response?.id ?? '',
        createdAt: response?.createdAt,
        email: response?.email,
        firstName: response?.firstName,
        lastName: response?.lastName,
        lastSeen: response?.lastSeen,
        jobTitle: response?.jobTitle,
        updatedAt: response?.updatedAt,
        isResellerAdminOrInternalAdmin:
          response.roles?.some(
            ({ userRole }) =>
              userRole === UserRole.InternalAdmin || userRole === UserRole.ResellerAdmin
          ) ?? false,
        roles: response?.roles?.map(p => ({
          id: p.id,
          organizationId: p.organizationId,
          resellerId: p.resellerId,
          userRole: Role[p.userRole as keyof typeof Role],
        })),
      }
    : null
}
