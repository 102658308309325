import { useTranslation } from 'react-i18next'

import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'

export type ResetOrganizationSettingsConfirmationDialogProps = {
  onSubmit: () => void
  cancelConfirmation: () => void
  confirmationDialogOpened: boolean
}

export const ResetOrganizationSettingsConfirmationDialog = ({
  onSubmit,
  cancelConfirmation,
  confirmationDialogOpened,
}: ResetOrganizationSettingsConfirmationDialogProps) => {
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
      open={confirmationDialogOpened}
      onClose={cancelConfirmation}
      onConfirm={onSubmit}
      title={t('rsp:pages.organizations.list.actions.resetSettingsconfirmationModal.title')}
      confirmButtonText={t(
        'rsp:pages.organizations.list.actions.resetSettingsconfirmationModal.confirmationButtonText'
      )}
      cancelButtonText={t('rsp:actions.cancel')}
    >
      {t('rsp:pages.organizations.list.actions.resetSettingsconfirmationModal.description')}
    </ConfirmationDialog>
  )
}
