import { CommandGroup } from 'cmdk'

import { DataTestId } from '@sherweb/core/types/dataTestIdType'

import { ComboboxItem } from './ComboboxItem'
import { ComboboxCommonProps } from './types'

interface ComboboxContentProps extends ComboboxCommonProps, DataTestId {
  options?: Record<string, React.ReactNode>
}

export const ComboboxContent = ({
  options = {},
  dataTestId,
  value,
  onSelectItem,
}: ComboboxContentProps) => (
  <CommandGroup>
    {Object.entries(options)?.map(([optionValue, label]) => (
      <ComboboxItem
        key={optionValue}
        optionValue={optionValue}
        dataTestId={dataTestId}
        searchableValue={label?.toString()}
        label={label}
        value={value}
        onSelectItem={onSelectItem}
      />
    ))}
  </CommandGroup>
)
