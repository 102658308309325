import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { DEFAULT_TABLE_ROWS_PER_PAGE } from '@sherweb/core/utils/const'

import Button from '../Button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../Select'
import { PaginatedResults, Pagination } from './types'

type ServerPaginationProps<TData> = {
  rowsPerPageOptions: number[]
  isFetching: boolean
  result?: PaginatedResults<TData>
  currentPage: number
  currentPageSize?: number
  setPageParams: ({ page, pageSize }: Pagination) => void
}

const DEFAULT_RESULT = {
  totalPages: 0,
  pageIndex: 1,
  hasPreviousPage: false,
  hasNextPage: false,
  results: [],
  totalItemsCount: 0,
}

export const ServerPagination = <TData,>({
  rowsPerPageOptions,
  result = DEFAULT_RESULT,
  isFetching,
  currentPage,
  currentPageSize = DEFAULT_TABLE_ROWS_PER_PAGE,
  setPageParams,
}: ServerPaginationProps<TData>) => {
  const { t } = useTranslation()

  const handleSelect = (value: string) => {
    setPageParams({
      pageSize: Number(value),
      page: currentPage,
    })
  }

  const handleSetFirstPage = () => {
    setPageParams({
      pageSize: currentPageSize,
      page: 1,
    })
  }

  const handleSetPreviousPage = () => {
    setPageParams({
      pageSize: currentPageSize,
      page: currentPage > result?.totalPages ? result?.totalPages : currentPage - 1,
    })
  }

  const handleSetNextPage = () => {
    setPageParams({
      pageSize: currentPageSize,
      page: currentPage + 1,
    })
  }

  const handleGoToLastPage = () => {
    setPageParams({
      pageSize: currentPageSize,
      page: result?.totalPages,
    })
  }

  return (
    <div className="flex items-center justify-end border-t border-slate-200 p-4 dark:border-slate-700 dark:bg-slate-900">
      <div className="lg:space-x-8' flex grow items-center justify-end space-x-6">
        {rowsPerPageOptions.length > 1 && (
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium">{t('core:dataTable.resultsPerPage')}</p>
            <Select value={`${currentPageSize}`} onValueChange={handleSelect}>
              <SelectTrigger className="h-8 w-[70px]" data-testid="pageSizeSelectTrigger">
                <SelectValue placeholder={currentPageSize} />
              </SelectTrigger>
              <SelectContent side="top">
                {rowsPerPageOptions.map(pageSize => (
                  <SelectItem
                    key={pageSize}
                    value={`${pageSize}`}
                    data-testid={`pageSizeSelectValue${pageSize}`}
                  >
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}

        <div className="flex w-[100px] items-center justify-start text-sm font-medium">
          {t('core:dataTable.pagination', {
            currentPage,
            totalPage: result.totalPages,
          })}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 text-gray-900 dark:bg-slate-950 lg:flex"
            onClick={handleSetFirstPage}
            disabled={isFetching || !result.hasPreviousPage}
          >
            <span className="sr-only">{t('core:dataTable.goToFirstPage')}</span>
            <ChevronDoubleLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0 text-gray-900 dark:bg-slate-950"
            onClick={handleSetPreviousPage}
            disabled={isFetching || !result.hasPreviousPage}
            data-testid="btnTablePreviousPage"
          >
            <span className="sr-only">{t('core:dataTable.goToPreviousPage')}</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0 text-gray-900 dark:bg-slate-950"
            onClick={handleSetNextPage}
            disabled={isFetching || !result?.hasNextPage}
            data-testid="btnTableNextPage"
          >
            <span className="sr-only">{t('core:dataTable.goToNextPage')}</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 text-gray-900 dark:bg-slate-950 lg:flex"
            onClick={handleGoToLastPage}
            disabled={isFetching || !result?.hasNextPage}
          >
            <span className="sr-only">{t('core:dataTable.goToLastPage')}</span>
            <ChevronDoubleRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}
