import { cva } from 'class-variance-authority'
import React from 'react'

import { Skeleton } from '@sherweb/core/components/Skeleton'

import { InvoiceDetailLayout } from './InvoiceDetailLayout'

const invoiceDetailSkeletonStyles = cva('', {
  variants: {
    type: {
      label: 'mb-4 h-[18px] w-[94%] md:w-60',
      content: 'h-[36px] w-[94%] md:w-60',
    },
  },
})

export const InvoiceDetailSkeleton = () => (
  <InvoiceDetailLayout className="grid grid-cols-12 gap-1 md:gap-8">
    {Array.from({ length: 2 }, (_, index) => (
      <React.Fragment key={index}>
        <InvoiceDetailLayout.Column className="col-span-12 md:col-span-6">
          <div>
            <InvoiceDetailLayout.Label>
              <Skeleton className={invoiceDetailSkeletonStyles({ type: 'label' })} />
            </InvoiceDetailLayout.Label>
            <InvoiceDetailLayout.Content>
              <Skeleton className={invoiceDetailSkeletonStyles({ type: 'content' })} />
            </InvoiceDetailLayout.Content>
          </div>
          <div>
            <InvoiceDetailLayout.Label>
              <Skeleton className={invoiceDetailSkeletonStyles({ type: 'label' })} />
            </InvoiceDetailLayout.Label>
            <InvoiceDetailLayout.Content>
              <Skeleton className={invoiceDetailSkeletonStyles({ type: 'content' })} />
            </InvoiceDetailLayout.Content>
          </div>
        </InvoiceDetailLayout.Column>
        <div className="hidden md:col-span-1 md:block" />
        <InvoiceDetailLayout.Column className="col-span-5 hidden md:flex">
          <div>
            <InvoiceDetailLayout.Label>
              <Skeleton className={invoiceDetailSkeletonStyles({ type: 'label' })} />
            </InvoiceDetailLayout.Label>
            <InvoiceDetailLayout.Content>
              <Skeleton className={invoiceDetailSkeletonStyles({ type: 'content' })} />
            </InvoiceDetailLayout.Content>
          </div>
          <div>
            <InvoiceDetailLayout.Label>
              <Skeleton className={invoiceDetailSkeletonStyles({ type: 'label' })} />
            </InvoiceDetailLayout.Label>
            <InvoiceDetailLayout.Content>
              <Skeleton className={invoiceDetailSkeletonStyles({ type: 'content' })} />
            </InvoiceDetailLayout.Content>
          </div>
        </InvoiceDetailLayout.Column>
      </React.Fragment>
    ))}
  </InvoiceDetailLayout>
)
