import {
  ArrowUpTrayIcon,
  PencilSquareIcon,
  UserPlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import Button from '@sherweb/core/components/Button'
import Card from '@sherweb/core/components/Card'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { NoResultsMobile } from '@sherweb/core/components/NoResults/NoResultsMobile'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import { Permission } from '@sherweb/core/modules/authorization'
import { isRequestLoading, isRequestRefetching } from '@sherweb/core/modules/reactQuery'
import { UserDeleteConfirmationDialog } from '@sherweb/core/modules/user/ui/UserDeleteConfirmationDialog'
import { useDateFormatter } from '@sherweb/core/utils/date'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess, usePermissionCheck } from '@ssp/modules/authorization'
import {
  useDeleteUserMutation,
  User,
  useResendInvitationMutation,
  useUserRolesTranslation,
  useUsersByOrganization,
  useUserStatusBadge,
} from '@ssp/modules/user'

export const UsersTableMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const usersByOrganization = useUsersByOrganization()

  const [deleting, setDeleting] = useState(false)

  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  const deleteUserMutation = useDeleteUserMutation()

  const formatDate = useDateFormatter(language)

  const getUserRoles = useUserRolesTranslation()

  const userStatusBadge = useUserStatusBadge()

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const isUserUpdating = isRequestRefetching(usersByOrganization) || deleteUserMutation.isLoading

  const { hasAccess, permissionsLoading } = usePermissionCheck()

  const isOptionsVisible =
    hasAccess(Permission.UsersDelete) ||
    hasAccess(Permission.UsersCreate) ||
    hasAccess(Permission.UsersEdit)

  const filteredUsers = usersByOrganization?.data?.filter(user => {
    const fullName = `${String(user.firstName)} ${String(user.lastName)}`.toLowerCase()

    return (
      fullName?.includes(searchText.toLowerCase()) ||
      (user.email?.toLowerCase() ?? '').includes(searchText.toLowerCase()) ||
      user.jobTitle?.toLowerCase().includes(searchText.toLowerCase())
    )
  })

  const resendInvitationMutation = useResendInvitationMutation()

  const handleResendInvitation = (user: User) => {
    resendInvitationMutation.mutate(user)
  }

  const renderContent = () =>
    filteredUsers?.length === 0 ? (
      <NoResultsMobile>{t('ssp:pages.users.noUsers')}</NoResultsMobile>
    ) : (
      filteredUsers?.map(user => {
        const fullName = `${user.firstName as string} ${user.lastName as string}`
        return (
          <Accordion key={user.id} type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                <span>{fullName}</span>
                <AccordionTriggerSubContent>{user.email}</AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('ssp:pages.users.list.columns.email')}
                    value={user.email}
                    dataTestId="accEmailUser"
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.users.list.columns.jobTitle')}
                    value={user.jobTitle}
                    dataTestId="accJobUser"
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.users.list.columns.roles')}
                    value={getUserRoles(user.roles)}
                    dataTestId="accRolesUser"
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.users.list.columns.createdOn')}
                    value={formatDate(user.createdAt)}
                    dataTestId="accCreationUser"
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.users.list.columns.status')}
                    value={userStatusBadge(user.status)}
                    dataTestId="accCreationUser"
                  />
                  {isOptionsVisible ? (
                    <AccordionContentListItem
                      label=" "
                      value={
                        <div className="flex items-center gap-x-2">
                          <HasAccess permission={Permission.UsersCreate}>
                            <Button
                              className="gap-1 p-0"
                              variant="none"
                              disabled={resendInvitationMutation?.isLoading}
                              data-testid="btnResendInvitationUserMobile"
                              onClick={() => handleResendInvitation(user)}
                            >
                              <>
                                <ArrowUpTrayIcon className="h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
                                <span className="text-indigo-500">
                                  {t('ssp:pages.users.list.resendInvitation')}
                                </span>
                              </>
                            </Button>
                          </HasAccess>
                          <HasAccess permission={Permission.UsersEdit}>
                            <Button
                              asChild
                              className="ml-2 p-0"
                              variant="none"
                              data-testid="btnEditUserMobile"
                            >
                              <Link
                                to={Routes.UserEdit}
                                params={{ userId: user.id }}
                                className="flex gap-1"
                              >
                                <PencilSquareIcon className="h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
                                <span className="text-indigo-500">
                                  {t('ssp:pages.users.list.edit')}
                                </span>
                              </Link>
                            </Button>
                          </HasAccess>
                          <HasAccess permission={Permission.UsersDelete}>
                            <button
                              className="ml-2 flex flex-row gap-1 text-indigo-500"
                              data-testid="btnDeleteUserMobile"
                              onClick={() => {
                                setSelectedUser(user)
                                setDeleting(true)
                              }}
                            >
                              <XCircleIcon className="inline h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
                              <span>{t('core:actions.delete')}</span>
                            </button>
                          </HasAccess>
                        </div>
                      }
                    />
                  ) : null}
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )
      })
    )

  return (
    <>
      <Card
        padded
        data-testid="mobileLayoutUsers"
        className={isUserUpdating ? spinnerStyles({ type: 'layOverBackground' }) : ''}
      >
        {isUserUpdating ? <Spinner className={spinnerStyles({ type: 'layOverSpinner' })} /> : null}
        <div className="flex flex-col gap-4">
          <DataTableMobileHeader>
            <DataTableMobileHeader.Input
              placeholder={t('ssp:pages.users.list.filterPlaceholder')}
              filteredText={searchText}
              setFilteredText={onSetSearchParams}
            />
            <HasAccess permission={Permission.UsersCreate}>
              <Button
                variant="primary"
                size="sm"
                className="h-full"
                onClick={() => {}}
                data-testid="btnAddUserMobile"
                asChild
              >
                <Link
                  to={Routes.UsersInvite}
                  className="rounded-lg focus-visible:outline-indigo-300"
                >
                  <UserPlusIcon className="h-4 w-4" />
                </Link>
              </Button>
            </HasAccess>
          </DataTableMobileHeader>
          {isRequestLoading(usersByOrganization) || permissionsLoading ? (
            <SkeletonTable />
          ) : (
            renderContent()
          )}
        </div>
      </Card>
      {selectedUser ? (
        <UserDeleteConfirmationDialog
          open={deleting}
          selectedUser={selectedUser}
          onClose={() => setDeleting(false)}
          onConfirm={() => {
            deleteUserMutation.mutate(selectedUser?.id)
          }}
        />
      ) : null}
    </>
  )
}
