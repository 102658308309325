import { useMutationState } from '@sherweb/core/modules/reactQuery'

import { payInvoiceMutation } from '@ssp/modules/invoices/core/invoices.mutation'

import { useGetInvoiceDetail } from '../../hooks/useGetInvoiceDetail'

export const usePayInvoiceMutation = () => {
  const invoiceDetail = useGetInvoiceDetail()

  return useMutationState({
    mutationKey: payInvoiceMutation.mutationKey(
      invoiceDetail?.organizationId,
      invoiceDetail?.invoiceId
    ),
    status: ['loading', 'error'],
  })
}
