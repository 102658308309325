import { Permission } from '@sherweb/core/modules/authorization'

import { usePermissionCheck } from '@ssp/modules/authorization'

export const useIsSubscriptionCancellationAllowed = (
  subscriptionCommitedMinimalQuantity?: number | null
) => {
  const { hasAccess } = usePermissionCheck()

  return (
    (subscriptionCommitedMinimalQuantity == null || subscriptionCommitedMinimalQuantity === 0) &&
    hasAccess(Permission.SubscriptionCancel)
  )
}
