export type License = {
  id: string
  productName?: string | null
  vendorName?: string | null
  availableQuantity: number
  numberUsers: number
  lastSyncedOn: Date
}

export type Subscriber = {
  id?: string
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  type: SubscriberType
  assignedLicenses?: string[] | null
  licenseUpdates?: LicenseUpdate[] | null
}

export type LicenseUpdate = {
  licenseId?: string
  licenseUpdateId?: string
  updateStatus?: LicenseUpdateStatus
}

export type DomainByVendor = {
  isDefault?: boolean
  domain?: string | null
  vendorName?: string | null
}

export type UsedLicenseStatistics = {
  currentUserLicenses: string[]
  currentUserLicensesCount: number
  isRecalculating: boolean
}

export type UnusedLicenseStatistics = {
  unusedLicenses: Array<{
    name: string
    unusedLicenseCount: number
  }>
  unusedLicensesCount: number
  isRecalculating: boolean
}

export enum SubscriberType {
  Subscriber = 'Subscriber',
  User = 'User',
  LicenseUpdate = 'LicenseUpdate',
}

export enum LicenseUpdateStatus {
  NotApplicable = 'NotApplicable',
  Pending = 'Pending',
  Failure = 'Failure',
}

export function hasPendingAddUpdateInLicense(
  license: License,
  subscriber: Subscriber
): boolean | undefined {
  return (
    !subscriber.assignedLicenses?.some(y => y === license.id) &&
    subscriber.licenseUpdates?.some(
      y => y.licenseId === license.id && y.updateStatus === LicenseUpdateStatus.Pending
    )
  )
}
