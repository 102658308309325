import { ResellerIntegrationsService } from '@sherweb/core/openapi-generated/ResellerIntegrationsService'

import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'

export const resellerIntegrations = {
  queryKey: (resellerId?: string) => [
    'ResellerIntegrationsService/getResellerIntegrations',
    resellerId,
  ],
  queryFn: async (resellerId?: string) => {
    if (!resellerId) {
      return await Promise.reject(new Error('Missing parameters'))
    }

    return await ResellerIntegrationsService.getResellerIntegrations({ resellerId })
  },
  staleTime: DEFAULT_STALE_TIME,
}
