import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import Card from '@sherweb/core/components/Card'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { InfiniteScroll } from '@sherweb/core/components/InfiniteScroll'
import { NoResultsMobile } from '@sherweb/core/components/NoResults/NoResultsMobile'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import { getOrdersPaginationDefaultValues } from '@sherweb/core/modules/orders/core/orders.helpers'
import { Order } from '@sherweb/core/modules/orders/core/orders.model'
import { OrderStatus } from '@sherweb/core/modules/orders/ui/OrderStatus'
import {
  getAllPagesResults,
  isInfiniteScrollRequestHavingNoResults,
  isRequestLoading,
} from '@sherweb/core/modules/reactQuery'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { useGetInfiniteScrollOrderssQuery } from '@rsp/modules/orders'

import { OrderActions } from './OrderActions'

const OrdersTableMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const ordersQuery = useGetInfiniteScrollOrderssQuery(
    getOrdersPaginationDefaultValues({
      searchTerm: searchText,
    })
  )

  const noResults = isInfiniteScrollRequestHavingNoResults<Order>(ordersQuery)

  const orders = getAllPagesResults<Order>(ordersQuery)

  const handleFetchNextPage = async () => {
    await ordersQuery.fetchNextPage()
  }

  const formatMoneyAmount = useMoneyFormatter(language)

  const renderContent = () => {
    return noResults ? (
      <NoResultsMobile>{t('rsp:pages.orders.noOrders')}</NoResultsMobile>
    ) : (
      <>
        {orders?.map(order => (
          <Accordion key={order.id} type="single" collapsible>
            <AccordionItem value={order.id}>
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                {order.organizationName} - {order.humanReadableId}
                <AccordionTriggerSubContent>
                  <OrderStatus orderStatus={order?.orderStatus} />
                </AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.organizationName')}
                    value={order?.organizationName}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.createdOn')}
                    value={formatDate(order?.createdOn)}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.orderValue')}
                    value={formatMoneyAmount(order.orderValue)}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.status')}
                    value={<OrderStatus orderStatus={order.orderStatus} />}
                  />
                  <AccordionContentListItem
                    className="justify-end"
                    labelClassName="flex flex-wrap"
                    label={<OrderActions order={order} className="flex flex-wrap" />}
                  />
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ))}
        {ordersQuery?.hasNextPage ? (
          <InfiniteScroll
            isLoading={ordersQuery.isFetchingNextPage}
            hasMore={ordersQuery?.hasNextPage}
            next={handleFetchNextPage}
          />
        ) : null}
      </>
    )
  }

  return (
    <Card padded data-testid="mobileLayoutOrders">
      <div className="flex flex-col gap-4">
        <DataTableMobileHeader>
          <DataTableMobileHeader.Input
            placeholder={t('rsp:pages.orders.list.filterPlaceholder')}
            filteredText={searchText}
            setFilteredText={onSetSearchParams}
          />
        </DataTableMobileHeader>
        {isRequestLoading(ordersQuery) ? <SkeletonTable /> : renderContent()}
      </div>
    </Card>
  )
}

export default OrdersTableMobile
