import { ComponentChildrenType } from '@sherweb/core/types/reactChildrenType'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

interface SheetPageLayoutProps extends ComponentChildrenType {
  className?: string
}

export const SheetPageLayout = ({ children, className }: SheetPageLayoutProps) => (
  <div className={mergeClassName('p-6', className)}>{children}</div>
)
