import { ComponentChildrenType } from '@sherweb/core/types/reactChildrenType'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

interface DataTableContainerProps extends ComponentChildrenType {
  className?: string
}

export const DataTableContainer = ({ children, className }: DataTableContainerProps) => {
  return (
    <div
      className={mergeClassName(
        'overflow-hidden dark:border-zinc-700 md:max-w-[calc(100vw-340px)]',
        className
      )}
    >
      {children}
    </div>
  )
}
