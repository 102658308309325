import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

import { Form } from '@sherweb/core/components/Form'
import { errorNotification, successNotification } from '@sherweb/core/components/ToastNotifications'
import { PortalMode } from '@sherweb/core/modules/portalMetadata'
import { portalMetadataQuery } from '@sherweb/core/modules/portalMetadata/core/portalMetadata.queries'
import { useInvalidateQuery } from '@sherweb/core/modules/reactQuery'

import { useReseller, useUpdateImageAction, useUpdatePortalSettings } from '@rsp/modules/reseller'

import { SubmitButton } from '../components/SubmitButton'
import ImageEditor from '../ImageEditor'
import customizationsSettingsSchema from '../validationSchema/customizationsSettingsSchema'

const CustomizationsSettings: React.FC = () => {
  const { t } = useTranslation()
  const { data: reseller } = useReseller()
  const { updateLogo, updateIcon } = useUpdateImageAction()
  const {
    isSuccess: portalSettingsSuccess,
    isError: portalSettingsError,
    isLoading: portalSettingsLoading,
  } = useUpdatePortalSettings()

  const form: UseFormReturn<z.infer<typeof customizationsSettingsSchema>> = useForm<
    z.infer<typeof customizationsSettingsSchema>
  >({
    resolver: zodResolver(customizationsSettingsSchema),
    defaultValues: {},
  })

  const [logoUrl, faviconUrl] = form.watch(['logoUrl', 'faviconUrl'])

  const queryClient = useQueryClient()

  useInvalidateQuery(
    portalMetadataQuery.queryKey(PortalMode.RSP),
    portalSettingsSuccess,
    queryClient
  )

  useEffect(() => {
    const settings = reseller?.portalSettings

    if (reseller?.id && !!settings) {
      form.setValue('faviconUrl', settings.favicon ?? '')
      form.setValue('logoUrl', settings.logo ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reseller?.id])

  useEffect(() => {
    if (portalSettingsSuccess) {
      successNotification(
        t('rsp:pages.settings.customizationsSettings.theme.settingSuccessToastMessage')
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalSettingsSuccess])

  useEffect(() => {
    if (portalSettingsError) {
      errorNotification(
        t('rsp:pages.settings.customizationsSettings.theme.settingsFailureToastMessage')
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalSettingsError])

  async function onSubmit(values: z.infer<typeof customizationsSettingsSchema>) {
    if (values.logo) {
      await updateLogo(values.logo).then(() => {
        form.setValue('logo', null)
        successNotification(t('rsp:pages.settings.customizationsSettings.logo.updateSuccess'))
      })
    }
    if (values.favicon) {
      await updateIcon(values.favicon).then(() => {
        form.setValue('favicon', null)
        successNotification(t('rsp:pages.settings.customizationsSettings.favicon.updateSuccess'))
      })
    }
  }

  const onSaveLogo = async (file: File) => {
    form.setValue('logo', file)
    const url = await getTempFileUrl(file)
    form.setValue('logoUrl', url)
  }

  const onSaveFavicon = async (file: File) => {
    form.setValue('favicon', file)
    const url = await getTempFileUrl(file)
    form.setValue('faviconUrl', url)
  }

  const getTempFileUrl = async (file: File) => {
    const blob = await file
      .arrayBuffer()
      .then(arrayBuffer => new Blob([arrayBuffer], { type: file.type }))
    return await new Promise<string>((resolve, reject) => {
      const fr = new FileReader()
      fr.onload = () => {
        resolve(fr.result?.toString() ?? '')
      }
      fr.onerror = reject
      fr.readAsDataURL(blob)
    })
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="my-4 flex flex-col gap-y-16 rounded-xl p-4 dark:bg-slate-900 md:px-8 md:py-16"
      >
        <div className="grid-col-1 grid gap-y-4 md:grid-cols-2 md:gap-y-0">
          <div className="w-full sm:w-9/12">
            <label className="font-semibold">
              {t('rsp:pages.settings.customizationsSettings.logo.title')}
            </label>
            <div className="text-xs text-gray-500 dark:text-slate-300">
              {t('rsp:pages.settings.customizationsSettings.logo.description')}
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-col gap-8">
              <div className="grid grid-cols-1 gap-y-4">
                <ImageEditor
                  imageUrl={logoUrl}
                  title={t('rsp:pages.settings.customizationsSettings.logo.updateModal.title')}
                  onSave={onSaveLogo}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid-col-1 grid gap-y-4 md:grid-cols-2 md:gap-y-0">
          <div className="w-full sm:w-9/12">
            <label className="font-semibold">
              {t('rsp:pages.settings.customizationsSettings.favicon.title')}
            </label>
          </div>
          <div className="w-full">
            <div className="flex flex-col gap-8">
              <div className="grid grid-cols-1 gap-y-4">
                <ImageEditor
                  imageUrl={faviconUrl}
                  title={t('rsp:pages.settings.customizationsSettings.favicon.updateModal.title')}
                  onSave={onSaveFavicon}
                />
              </div>
            </div>
          </div>
        </div>
        <SubmitButton disabled={portalSettingsLoading} />
      </form>
    </Form>
  )
}

export default CustomizationsSettings
