import { Menu as HLMenu, MenuButtonProps, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import { mergeClassName } from '../../utils/mergeClassName'

type MenuProps = {
  children?: React.ReactNode
  buttonContent?: React.ReactNode
} & MenuButtonProps<any>

const Menu: React.FC<MenuProps> = ({ children, buttonContent, className, ...buttonProps }) => {
  return (
    <HLMenu as="div" className="relative">
      <HLMenu.Button
        className={mergeClassName(
          'flex max-w-xs items-center rounded-full text-sm focus:bg-slate-200 focus:outline-none ',
          className
        )}
        {...buttonProps}
        title="User menu button"
      >
        {buttonContent}
      </HLMenu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <HLMenu.Items className="absolute right-0 z-50 mt-2 origin-top-right divide-y divide-gray-200 rounded-xl border-[rgba(255,255,255,0.18)] bg-white/50 py-2 shadow-[0px_4px_24px_#00000012,inset_0px_-1px_1px_#ffffff20,inset_0px_1px_1px_#ffffff40] ring-1 ring-black/5 backdrop-blur-[32px] backdrop-brightness-[100%] focus:outline-none dark:divide-slate-800 dark:border dark:border-slate-800 dark:bg-slate-900 dark:text-white dark:shadow-none dark:backdrop-blur-none">
          {children}
        </HLMenu.Items>
      </Transition>
    </HLMenu>
  )
}

export default Menu
