import { mergeClassName } from '../../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type CardMetricProps = {
  large?: boolean
} & ReactDiv

const CardMetric: React.FC<CardMetricProps> = ({ children, large = false, ...props }) => {
  return (
    <div
      {...props}
      className={mergeClassName(
        'mr-2 text-3xl font-bold leading-9 text-gray-900 dark:text-slate-200',
        large && 'flex h-full items-center justify-center text-6xl font-bold',
        props?.className
      )}
    >
      {children}
    </div>
  )
}

export default CardMetric
