import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { ErroneousLicenseUpdateDto } from '@sherweb/core/openapi-generated/index.defs'

import Alert, { Variant } from '@sherweb/core/components/Alert'
import Card from '@sherweb/core/components/Card/Card'
import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import Spinner from '@sherweb/core/components/Spinner/Spinner'
import { errorNotification } from '@sherweb/core/components/ToastNotifications'
import { Permission } from '@sherweb/core/modules/authorization'

import { protectPage } from '@ssp/app/ProtectedPage'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import ServerError from '@ssp/components/ServerError/ServerError'
import { usePermissionCheck } from '@ssp/modules/authorization'
import {
  useDismissLicenseUpdate,
  useErroneousLicenseUpdates,
  useLicenses,
} from '@ssp/modules/license'

import LicenseTable from './LicensesTable/LicensesTable'
import LicensesTableMobile from './LicensesTableMobile'

const LicensesPage: React.FC = () => {
  const { t } = useTranslation()
  const { isLoading, error, data: licenses = [], isFetched } = useLicenses()
  const { data: erroneousLicenseUpdates } = useErroneousLicenseUpdates()
  const { dismissLicenseUpdate } = useDismissLicenseUpdate()
  const { hasAccess } = usePermissionCheck()

  const noLicenses = isFetched && !isLoading && !error && !licenses?.length

  const onDismissError = async (licenseUpdateId: string) => {
    try {
      await dismissLicenseUpdate(licenseUpdateId)
    } catch (error) {
      errorNotification(t('ssp:errors.generic'))
    }
  }

  return (
    <ContainedPage>
      <div className="flex flex-col gap-12 md:gap-16">
        <PageTitleWrapper
          title={
            <PageTitle data-testid="pageLicensesTitle">{t('ssp:pages.licenses.title')}</PageTitle>
          }
          subTitle={<PageSubTitle>{t('ssp:pages.licenses.pageDescription')}</PageSubTitle>}
        />
        {isLoading && <Spinner floating />}
        {error && <ServerError error={error} />}

        {noLicenses && (
          <div
            className="flex w-full flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-300 p-8 font-normal text-gray-400 md:h-60"
            data-testid="containerNoLicenses"
          >
            <ArchiveBoxXMarkIcon className="h-16 w-16 stroke-1 text-gray-400" />
            <p>{t('ssp:pages.licenses.noLicenses')}</p>
          </div>
        )}

        {licenses?.length > 0 && (
          <>
            {erroneousLicenseUpdates && erroneousLicenseUpdates.length > 0 && (
              <div>
                {erroneousLicenseUpdates?.map((error: ErroneousLicenseUpdateDto) => {
                  return (
                    <Alert
                      data-testid={`alert-${error.licenseUpdateId}`}
                      key={error.licenseUpdateId}
                      variant={Variant.Danger}
                      onClose={
                        hasAccess(Permission.LicenseAssign)
                          ? async () => await onDismissError(error.licenseUpdateId)
                          : undefined
                      }
                    >
                      {t('ssp:pages.licenses.errors.licenseUpdateFailed', {
                        licenseName: error.productName,
                        subscriberName: error.subscriberName,
                      })}
                    </Alert>
                  )
                })}
              </div>
            )}

            <div className="hidden lg:block" data-testid="containerLicensesTable">
              <Card padded>
                <LicenseTable />
              </Card>
            </div>

            <div className="mt-4 lg:hidden" data-testid="containerLicensesTableMobile">
              <Card padded>
                <LicensesTableMobile />
              </Card>
            </div>
          </>
        )}
      </div>
    </ContainedPage>
  )
}

export default protectPage(LicensesPage, Permission.LicenseList)
