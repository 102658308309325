import * as SeparatorPrimitive from '@radix-ui/react-separator'
import React from 'react'

import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(({ className, orientation = 'horizontal', decorative = true, ...props }, ref) => (
  <SeparatorPrimitive.Root
    ref={ref}
    data-testid="separator"
    decorative={decorative}
    orientation={orientation}
    className={mergeClassName(
      orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]',
      className
    )}
    {...props}
  />
))
Separator.displayName = SeparatorPrimitive.Root.displayName

export { Separator }
