type PageSubTitleProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>

const PageSubTitle = ({ children, ...props }: PageSubTitleProps) => {
  return (
    <p
      className="md:text-normal text-sm leading-none text-slate-500 dark:text-slate-200"
      {...props}
    >
      {children}
    </p>
  )
}

export default PageSubTitle
