import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import PageTitle, { PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import { ScrollArea, ScrollBar } from '@sherweb/core/components/ScrollArea'
import Spinner from '@sherweb/core/components/Spinner'
import { Tabs, TabsList, TabsTrigger } from '@sherweb/core/components/Tabs'

import { protectPage } from '@rsp/app/ProtectedPage'
import ContainedPage from '@rsp/components/layout/ContainedPage'
import { usePermissionCheck } from '@rsp/modules/authorization'
import { useGetResellerIntegrationsQuery } from '@rsp/modules/integrations'

import { IntegrationTabContent } from './components/Integration'

export const IntegrationsPage = () => {
  const { t } = useTranslation()

  const integrationsQuery = useGetResellerIntegrationsQuery({
    staleTime: 0,
    refetchOnMount: true,
  })

  const { hasAccess } = usePermissionCheck()

  const hasIntegrationsPermissions = hasAccess([
    Permission.IntegrationList,
    Permission.IntegrationDetails,
    Permission.IntegrationConfigure,
  ])

  const allIntegrations = [
    ...(hasIntegrationsPermissions ? integrationsQuery.data?.helpDesk ?? [] : []),
    ...(integrationsQuery.data?.serviceProvider ?? []),
  ]

  return (
    <ContainedPage>
      <div className="mb-8 sm:mb-16">
        <PageTitleWrapper
          title={
            <PageTitle data-testid="pageIntegrationsTitle">
              {t('rsp:pages.integrations.title')}
            </PageTitle>
          }
        />
      </div>
      {(integrationsQuery?.isLoading || integrationsQuery?.data === undefined) && (
        <Spinner floating />
      )}
      <Tabs defaultValue="all" className="w-full">
        <ScrollArea>
          <TabsList className="h-16 sm:h-auto">
            <TabsTrigger
              value="all"
              data-testid="tabsTriggerAll"
              className="focus-visible:ring-indigo-300"
            >
              {t('rsp:pages.integrations.tabs.all')}
            </TabsTrigger>
            <TabsTrigger
              value="serviceProvider"
              disabled={
                integrationsQuery.data?.serviceProvider == null ||
                integrationsQuery.data.serviceProvider?.length === 0
              }
              data-testid="tabsTriggerServiceProvider"
              className="focus-visible:ring-indigo-300"
            >
              {t('rsp:pages.integrations.tabs.serviceProvider')}
            </TabsTrigger>
            {hasIntegrationsPermissions ? (
              <TabsTrigger
                value="support"
                disabled={
                  integrationsQuery.data?.helpDesk == null ||
                  integrationsQuery.data.helpDesk?.length === 0
                }
                data-testid="tabsTriggerSupport"
                className="focus-visible:ring-indigo-300"
              >
                {t('rsp:pages.integrations.tabs.support')}
              </TabsTrigger>
            ) : null}
          </TabsList>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        <IntegrationTabContent
          value="all"
          dataTestId="tabsContentAll"
          integrations={allIntegrations}
          key="allIntegrations"
        />
        <IntegrationTabContent
          value="serviceProvider"
          dataTestId="tabsContentServiceProvider"
          integrations={integrationsQuery?.data?.serviceProvider}
          key="serviceProviderIntegrations"
        />
        <IntegrationTabContent
          value="support"
          dataTestId="tabsContentSupport"
          integrations={integrationsQuery?.data?.helpDesk}
          key="supportIntegrations"
        />
      </Tabs>
    </ContainedPage>
  )
}

export default protectPage(IntegrationsPage)
