import {
  UsersQueryResult,
  UserStatus,
  RoleQueryResult,
  UserRole,
  CountResult,
  UserWithLicensesQueryResult,
  UserLicenseQueryResult,
  UpdateUserCommand,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class OrganizationUsersService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getUsersAsync(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UsersQueryResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/users';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCountUsersAsync(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CountResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/users/count';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      organizationId: string;
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserWithLicensesQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/users/{userId}';
      url = url.replace('{organizationId}', params['organizationId'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateOrganizationUser(
    params: {
      /**  */
      organizationId?: string;
      /**  */
      userId: string;
      /**  */
      command: UpdateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UsersQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/{userId}/update';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { organizationId: params['organizationId'] };

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
