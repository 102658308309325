import { AmendmentOrdersService } from '@sherweb/core/openapi-generated/AmendmentOrdersService'

import {
  CreateOrderPayload,
  DismissOrderErrorPayload,
  ProcessOrderPayload,
} from './subscription.type'

export const createOrder = {
  mutationFn: async (data: CreateOrderPayload) => {
    return await AmendmentOrdersService.amendmentOrdersCreateOrder({
      command: {
        organizationId: data.organizationId,
        orderLines: [
          {
            subscriptionId: data.subscriptionId,
            quantity: data.quantity,
          },
        ],
      },
    })
  },
}

export const dismissOrderError = {
  mutationFn: async (data: DismissOrderErrorPayload) => {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await AmendmentOrdersService.amendmentOrdersDismissOrderError({
      command: { ...data },
    })
  },
}

export const processOrder = {
  mutationFn: async (data: ProcessOrderPayload) => {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await AmendmentOrdersService.amendmentOrdersProcessOrder({
      command: { ...data },
    })
  },
}
