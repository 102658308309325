import { useCallback, useEffect, useState } from 'react'

import Routes from '@rsp/app/Routes'
import { useNavigate, usePathGenerator } from '@rsp/modules/navigation'

import { useGetCatalogId } from '../../hooks/useGetCatalogId'

type UseAssignedOrganizationsProps = {
  initiallyAssignedOrganizationIds?: string[]
}

export const useAssignedOrganizations = ({
  initiallyAssignedOrganizationIds,
}: UseAssignedOrganizationsProps) => {
  const [selectedAssignedOrganizationIds, setSelectedAssignedOrganizationIds] = useState<string[]>(
    []
  )

  const [selectedRemovedOrganizationIds, setSelectedRemovedOrganizationIds] = useState<string[]>([])

  const { catalogId } = useGetCatalogId()

  const { navigate } = useNavigate()

  const generatePath = usePathGenerator()

  useEffect(() => {
    if (selectedAssignedOrganizationIds.length !== 0) {
      setSelectedAssignedOrganizationIds(previousOrganizationIds =>
        previousOrganizationIds?.filter(id => !initiallyAssignedOrganizationIds?.includes(id))
      )
    }
  }, [initiallyAssignedOrganizationIds, selectedAssignedOrganizationIds.length])

  useEffect(() => {
    if (selectedRemovedOrganizationIds.length !== 0) {
      setSelectedRemovedOrganizationIds(previousOrganizationIds =>
        previousOrganizationIds?.filter(id => initiallyAssignedOrganizationIds?.includes(id))
      )
    }
  }, [initiallyAssignedOrganizationIds, selectedRemovedOrganizationIds.length])

  const redirectToProductsPage = useCallback(() => {
    navigate(
      generatePath(Routes.MarketplaceCatalogProducts, {
        catalogId,
      })
    )
  }, [catalogId, generatePath, navigate])

  const reset = useCallback(() => {
    setSelectedAssignedOrganizationIds([])
    setSelectedRemovedOrganizationIds([])
  }, [])

  return {
    selectedAssignedOrganizationIds,
    setSelectedAssignedOrganizationIds,
    selectedRemovedOrganizationIds,
    setSelectedRemovedOrganizationIds,
    reset,
    redirectToProductsPage,
    selectedOrganizationsCount:
      (initiallyAssignedOrganizationIds?.length ?? 0) +
      selectedAssignedOrganizationIds.length -
      selectedRemovedOrganizationIds.length,
  }
}
