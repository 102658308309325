import { useCallback, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { ServiceProviderConfigurationQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { DEFAULT_VALUES } from '../sherwebServiceProvider.helper'
import { SherwebServiceProviderFormType } from './useSherwebServiceProviderSchema'

type UseServiceProviderProps = {
  form: UseFormReturn<SherwebServiceProviderFormType>
}

export const useServiceProvider = ({ form }: UseServiceProviderProps) => {
  const [isEditMode, setIsEditMode] = useState(false)

  const setFormValues = useCallback(
    (serviceProvider?: ServiceProviderConfigurationQueryResult) => {
      form.reset(serviceProvider)
    },
    [form]
  )

  const onResetEditMode = useCallback(() => {
    form.reset({ ...DEFAULT_VALUES, serviceProviderId: form.getValues('serviceProviderId') })
    form.clearErrors()
    setIsEditMode(prevIsMode => !prevIsMode)
  }, [form])

  return { isEditMode, onResetEditMode, setFormValues, setIsEditMode }
}
