import { flexRender, Table } from '@tanstack/react-table'

import { DataTestId } from '@sherweb/core/types/dataTestIdType'
import { ComponentChildrenType } from '@sherweb/core/types/reactChildrenType'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'
import { getDataTestId } from '@sherweb/core/utils/string'

import { NoResults } from '../NoResults'
import { TableBody, TableCell, TableRow } from '../Table'

type DataTableBodyContentProps<TData> = {
  table: Table<TData>
} & DataTestId

export const DataTableBody = ({ children }: ComponentChildrenType) => {
  return <TableBody>{children}</TableBody>
}

export const DataTableBodyContent = <TData,>({
  table,
  dataTestId,
}: DataTableBodyContentProps<TData>) => {
  return (
    <>
      {table.getRowModel().rows.map(row => (
        <TableRow key={row.id} className="align-top dark:border-transparent dark:bg-transparent">
          {row.getVisibleCells().map(cell => {
            return (
              <TableCell
                key={cell.id}
                {...getDataTestId(cell.column.id, dataTestId)}
                className={mergeClassName(
                  'px-8 py-5',
                  (cell.column.columnDef.meta as any)?.className,
                  (cell.column.columnDef.meta as any)?.cellClassName
                )}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            )
          })}
        </TableRow>
      ))}
    </>
  )
}

type DataTableBodyContentEmptyProps = {
  colSpanLength: number
  emptyMessage: React.ReactNode
} & DataTestId

export const DataTableBodyContentEmpty = ({
  colSpanLength,
  emptyMessage,
  dataTestId,
}: DataTableBodyContentEmptyProps) => {
  return (
    <TableRow>
      <TableCell
        colSpan={colSpanLength}
        className="bg-transparent p-4 dark:bg-transparent dark:text-white"
      >
        <NoResults dataTestId={dataTestId} emptyMessage={emptyMessage} />
      </TableCell>
    </TableRow>
  )
}

DataTableBody.Content = DataTableBodyContent
DataTableBody.EmptyContent = DataTableBodyContentEmpty
