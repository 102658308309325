import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { errorNotification, successNotification } from '@sherweb/core/components/ToastNotifications'

import {
  hasPendingAddUpdateInLicense,
  License,
  Subscriber,
  useUpdateLicenses,
} from '@ssp/modules/license'
import {
  SubscriberUserPayload,
  UpdateLicenseSubscribersPayload,
} from '@ssp/modules/license/core/license.type'

import { CreateSubscriberFormType } from './useCreateSubscriberSchema'

type UseCreateSubscriberFormProps = {
  license: License
  onSuccess: () => void
  existingSubscribers: Subscriber[]
}

export const useCreateSubscriberForm = ({
  license,
  onSuccess,
  existingSubscribers,
}: UseCreateSubscriberFormProps) => {
  const { t } = useTranslation()

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true)

  const { updateLicenses, isLoading: updating } = useUpdateLicenses()

  useEffect(() => {
    const subscribersPendingUpdate = existingSubscribers.filter(x =>
      hasPendingAddUpdateInLicense(license, x)
    )

    let remainingQuantity = license.availableQuantity - license.numberUsers

    remainingQuantity = remainingQuantity < 0 ? 0 : remainingQuantity

    const maxLicensesReached =
      remainingQuantity <= 0 || subscribersPendingUpdate.length + 1 > remainingQuantity

    setIsCreateButtonDisabled(updating || maxLicensesReached)
  }, [updating, license.availableQuantity, existingSubscribers, license])

  const onSubmit = async (values: CreateSubscriberFormType) => {
    const email: string = `${values.username}@${values?.currentDomain ?? ''}`

    if (
      existingSubscribers.filter(x => x.email?.toLowerCase() === email.toLowerCase()).length === 0
    ) {
      const subscribersToAdd: SubscriberUserPayload[] = [
        {
          id: undefined,
          firstName: values.firstName ?? '',
          lastName: values.lastName ?? '',
          email,
        },
      ]

      const updateLicenseCommand: UpdateLicenseSubscribersPayload = {
        licenseId: license.id,
        subscriberIdsOrUsersToBeAdded: subscribersToAdd,
        subscriberIdsToBeRemoved: [],
      }

      try {
        await updateLicenses(updateLicenseCommand)
        successNotification(t('ssp:pages.licenses.subscribersDialog.created'))
        onSuccess()
      } catch (error) {
        errorNotification(t('ssp:errors.generic'))
      }
    } else {
      errorNotification(t('ssp:pages.licenses.createSubcriberForm.errors.subscriberAlreadyExists'))
    }
  }

  return { isCreateButtonDisabled, updating, onSubmit }
}
