import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'

import {
  PortalSettingsQueryResult,
  ResellersQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import {
  canLocationMakeCallForData,
  QueryResult,
  useInvalidateQuery,
} from '@sherweb/core/modules/reactQuery'

import { useHostname } from '@ssp/modules/navigation'

import * as actions from './reseller.actions'
import { buildReseller, buildSettings } from './reseller.builder'
import { Reseller, ResellerPortalSettings } from './reseller.models'
import { portalSettingsByHostname, reseller } from './reseller.queries'
import * as selectors from './reseller.selectors'

/**
 * returns reseller portal settings based on current hostname
 */
export const useResellerPortalSettings = ({
  ...queryArgs
} = {}): QueryResult<ResellerPortalSettings> => {
  /* istanbul ignore next ; hostname is always defined in test environment */
  const hostname = useHostname()

  const queryKey = portalSettingsByHostname.queryKey(hostname)

  const { data, isLoading, isFetching, ...queryProps } = useQuery<PortalSettingsQueryResult, Error>(
    {
      queryKey,
      queryFn: async () => await portalSettingsByHostname.queryFn(hostname),
      staleTime: portalSettingsByHostname.staleTime,
      enabled: canLocationMakeCallForData(),
    }
  )

  return {
    data: buildSettings(data),
    isLoading: isLoading && isFetching,
    ...queryProps,
  }
}

/**
 * returns current reseller information
 */
export const useReseller = ({ reload = false, ...queryArgs } = {}): QueryResult<Reseller> => {
  /* istanbul ignore next ; ignore data reloading */
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const hostname = useHostname()
  const queryKey = reseller.queryKey(hostname)
  const queryClient = useQueryClient()
  useInvalidateQuery(queryKey, reload, queryClient)

  const { data, isLoading, isFetching, ...queryProps } = useQuery<ResellersQueryResult, Error>({
    queryKey,
    queryFn: async () => await reseller.queryFn(hostname),
    enabled: isLoggedIn,
    staleTime: reseller.staleTime,
  })

  return {
    data: buildReseller(data),
    isLoading: isLoading && isFetching,
    ...queryProps,
  }
}

export const useSelectedResellerId = () => {
  return useSelector(selectors.selectedReseller)
}

export const useResellerActions = () => {
  const dispatch = useDispatch()
  return {
    setSelectedReseller: (...args: any) => dispatch(actions.setSelectedReseller.apply(null, args)),
  }
}
