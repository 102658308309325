import React from 'react'

import { sendDatadogError } from '../../core/datadog.actions'

type ErrorBoundaryProps = {
  fallback: React.ReactNode
  children: React.ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: Record<string, unknown>) {
    sendDatadogError(error, errorInfo)
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{this.props.fallback}</>
    }
    return this.props.children
  }
}

export default ErrorBoundary
