import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DetailedOrganizationListResult } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@sherweb/core/components/DropdownMenu'

import Routes from '@rsp/app/Routes'
import { useNavigate } from '@rsp/modules/navigation'
import { useResetOrganizationSettings } from '@rsp/modules/organizations'
import { GoToOrganizationLink } from '@rsp/pages/organizations/components/GoToOrganizationLink'
import { ResetOrganizationSettingsConfirmationDialog } from '@rsp/pages/organizations/components/ResetOrganizationSettingsConfirmationDialog'

export type OrganizationTableRowActionsProps = {
  organization: DetailedOrganizationListResult
}

export const OrganizationsTableRowActions = ({
  organization,
}: OrganizationTableRowActionsProps) => {
  const { t } = useTranslation()

  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { navigate } = useNavigate()

  const resetOrganizationSettings = useResetOrganizationSettings(organization?.organizationId)

  const handleShowDetails = () => {
    navigate(Routes.Organization, { organizationId: organization.organizationId })
  }

  const openConfirmationDialog = () => {
    setConfirmationDialogOpened(true)
  }

  const cancelConfirmation = () => {
    setConfirmationDialogOpened(false)
    setDropdownOpen(false)
  }

  const onSubmit = () => {
    resetOrganizationSettings.mutate()
  }

  return (
    <>
      <div className="flex gap-x-4">
        <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
          <DropdownMenuTrigger asChild data-testid="dropdownViewOrganizationOptions">
            <Button variant="ghost" className="h-8 w-8 p-0">
              <EllipsisVerticalIcon className="mr-0.5 w-6 text-gray-500 hover:bg-slate-50" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem data-testid="dropdownItemViewSettings" onClick={handleShowDetails}>
              {t('rsp:pages.organizations.list.actions.viewSettings')}
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <GoToOrganizationLink
                className="hover:bg-accent hover:text-accent-foreground"
                dataTestId="dropdownItemViewOrganization"
                organizationUniqueName={organization?.uniqueName}
              >
                {t('rsp:pages.organizations.list.actions.openOrganization')}
              </GoToOrganizationLink>
            </DropdownMenuItem>
            {!organization?.usesDefaultSettings && (
              <DropdownMenuItem
                data-testid="dropdownItemResetSettings"
                onClick={openConfirmationDialog}
              >
                {t('rsp:pages.organizations.list.actions.resetSettings')}
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <ResetOrganizationSettingsConfirmationDialog
        cancelConfirmation={cancelConfirmation}
        onSubmit={onSubmit}
        confirmationDialogOpened={confirmationDialogOpened}
      />
    </>
  )
}
