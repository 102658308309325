import { convertToCamelCase } from '@sherweb/core/utils/string'

import { DataTestId } from '../../types/dataTestIdType'
import { CommandGroup } from '../Command'
import { ComboboxItem } from './ComboboxItem'
import { ComboboxCommonProps, ComboboxOptionProps } from './types'

type ComboboxGroupContentProps<T> = ComboboxCommonProps & {
  groupOptions: Record<string, T[]>
} & DataTestId

export const ComboboxGroupContent = <T extends ComboboxOptionProps>({
  groupOptions,
  dataTestId,
  ...restProps
}: ComboboxGroupContentProps<T>) => (
  <>
    {Object.entries(groupOptions).map(([heading, options]) => (
      <CommandGroup
        key={heading}
        heading={heading}
        data-testid={`comboboxGroupContentSubHeading${convertToCamelCase(dataTestId)}`}
      >
        {options.map(option => (
          <ComboboxItem
            key={option.id}
            dataTestId={`comboboxGroupContent${convertToCamelCase(dataTestId)}`}
            {...restProps}
            optionValue={option.id}
            searchableValue={option?.name}
            label={option?.label ?? option.name}
          />
        ))}
      </CommandGroup>
    ))}
  </>
)
