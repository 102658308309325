import { useTranslation } from 'react-i18next'

import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'

import { useGetSubscription } from './hooks/useGetSubscription'

type SubscriptionCancelConfirmationProps = {
  visible: boolean
  onClose?: () => void
  onSubmit: (quantity: number) => void
}

export const SubscriptionCancelConfirmation = ({
  visible,
  onClose,
  onSubmit,
}: SubscriptionCancelConfirmationProps) => {
  const { t } = useTranslation()

  const subscription = useGetSubscription()

  const handleSubmit = () => {
    onSubmit(0)
  }

  if (!visible) {
    return null
  }

  return (
    <ConfirmationDialog
      className="sm:max-w-2xl"
      open={visible}
      onClose={onClose}
      onConfirm={handleSubmit}
      title={t(
        'ssp:pages.subscription.details.tabs.manageSubscription.cancel.confirmationDialog.title',
        {
          productName: subscription.productName,
        }
      )}
      confirmButtonText={t(
        'ssp:pages.subscription.details.tabs.manageSubscription.cancel.confirmationDialog.confirmButtonText'
      )}
      cancelButtonText={t(
        'ssp:pages.subscription.details.tabs.manageSubscription.cancel.confirmationDialog.dismissButtonText'
      )}
    >
      {t(
        'ssp:pages.subscription.details.tabs.manageSubscription.cancel.confirmationDialog.content',
        {
          productName: subscription.productName,
        }
      )}
      <br />
      {t(
        'ssp:pages.subscription.details.tabs.manageSubscription.cancel.confirmationDialog.contentHelper'
      )}
    </ConfirmationDialog>
  )
}
