import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { Permission } from '@sherweb/core/modules/authorization'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess } from '@ssp/modules/authorization'
import { Subscription, useStatusBadge, useSubscriptions } from '@ssp/modules/subscription'

const SubscriptionTableMobile: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { isLoading, error, data: subscriptions = [] } = useSubscriptions()

  const formatMoneyAmount = useMoneyFormatter(language)

  const getStatusBadge = useStatusBadge()

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const filteredData = subscriptions?.filter(subscription => {
    return subscription.productName?.toLowerCase().includes(searchText.toLowerCase())
  })

  if (isLoading || error) {
    return null
  }

  return (
    <div className="flex flex-col gap-4">
      <DataTableMobileHeader>
        <DataTableMobileHeader.Input
          placeholder={t('ssp:pages.subscriptions.list.filterPlaceholder')}
          filteredText={searchText}
          setFilteredText={onSetSearchParams}
        />
      </DataTableMobileHeader>
      {filteredData.map((subscription: Subscription, index) => {
        return (
          <Accordion key={subscription.id} type="single" collapsible>
            <AccordionItem value="item-1" data-testid={`listSubscription${index}`}>
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                {subscription.productName}
                <AccordionTriggerSubContent>
                  {getStatusBadge(subscription.statusWithOrders)}
                </AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('ssp:pages.subscriptions.list.columns.quantity')}
                    value={subscription?.quantity}
                    dataTestId="accQuantitySubscriptions"
                  />
                  <HasAccess permission={Permission.SubscriptionPrices}>
                    <AccordionContentListItem
                      label={t('ssp:pages.subscriptions.list.columns.price')}
                      value={subscription.price ? formatMoneyAmount(subscription.price) : null}
                      dataTestId="accPriceamountSubscriptions"
                    />
                    <AccordionContentListItem
                      label={t('ssp:pages.subscriptions.list.columns.total')}
                      value={subscription.total ? formatMoneyAmount(subscription.total) : null}
                      dataTestId="accTotalamountSubscriptions"
                    />
                  </HasAccess>
                  <AccordionContentListItem
                    label={t('ssp:pages.subscriptions.list.columns.status')}
                    value={getStatusBadge(subscription?.statusWithOrders)}
                    dataTestId="accStatusWithOrdersSubscriptions"
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.subscriptions.list.columns.billingCycle')}
                    value={subscription.billingCycle}
                    dataTestId="accBillingCycleSubscriptions"
                  />
                  <HasAccess permission={Permission.SubscriptionDetails}>
                    <AccordionContentListItem
                      label=""
                      value={
                        <Link
                          to={Routes.Subscription}
                          data-testid="linkEditSubscription"
                          params={{ subscriptionId: subscription.id }}
                        >
                          <div className="flex flex-row gap-2 text-indigo-500">
                            <PencilSquareIcon className="inline h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
                            <span>{t('ssp:pages.subscriptions.list.edit')}</span>
                          </div>
                        </Link>
                      }
                      dataTestId="accActionsSubscriptions"
                    />
                  </HasAccess>
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )
      })}
    </div>
  )
}

export default SubscriptionTableMobile
