import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'
import { Form } from '@sherweb/core/components/Form'
import Spinner from '@sherweb/core/components/Spinner'

import { protectPage } from '@rsp/app/ProtectedPage'
import Routes from '@rsp/app/Routes'
import { FormSubmitButton } from '@rsp/components/FormSubmitButton'
import { FormSplitScreen } from '@rsp/components/layout/FormSplitScreen'
import {
  useGetConnectWiseActiveServiceBoardIdQuery,
  useGetConnectWiseConfiguredQuery,
  usePostConnetWiseBoardConfigurationMutation,
} from '@rsp/modules/integrations/helpdesk'

import { DEFAULT_VALUES } from './boardConfiguration.helper'
import { BoardConfigurationServiceBoard } from './BoardConfigurationServiceBoard'
import { BoardConfigurationStatusMapping } from './BoardConfigurationStatusMapping'
import { BoardConfigurationTicketTypes } from './BoardConfigurationTicketTypes'
import { useBoardConfiguration } from './hooks/useBoardConfiguration'
import {
  BoardConfigurationFormType,
  useBoardConfigurationSchema,
} from './hooks/useBoardConfigurationSchema'

export const BoardConfiguration = () => {
  const { t } = useTranslation()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const schema = useBoardConfigurationSchema()

  const form = useForm<BoardConfigurationFormType>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_VALUES,
  })

  const connectWiseConfiguredQuery = useGetConnectWiseConfiguredQuery()

  const connectWiseActiveServiceBoardIdQuery = useGetConnectWiseActiveServiceBoardIdQuery()

  const connetWiseBoardConfigurationMutation = usePostConnetWiseBoardConfigurationMutation()

  const { isServiceBoardLoading, setFormServiceBoard, setFormValues } = useBoardConfiguration({
    form,
  })

  useEffect(() => {
    if (
      !connectWiseActiveServiceBoardIdQuery.isLoading &&
      !!connectWiseActiveServiceBoardIdQuery?.data
    ) {
      setFormServiceBoard(connectWiseActiveServiceBoardIdQuery?.data)
    }
  }, [
    connectWiseActiveServiceBoardIdQuery?.data,
    connectWiseActiveServiceBoardIdQuery.isLoading,
    setFormServiceBoard,
  ])

  useEffect(() => {
    if (
      connectWiseActiveServiceBoardIdQuery?.data !== undefined &&
      form.getValues('serviceBoard') === connectWiseActiveServiceBoardIdQuery?.data
    ) {
      setFormValues(connectWiseActiveServiceBoardIdQuery?.data)
    }
  }, [connectWiseActiveServiceBoardIdQuery?.data, form, setFormValues])

  const handleConfirm = () => {
    connetWiseBoardConfigurationMutation.mutate(form.getValues())
  }

  const onSubmit = (values: BoardConfigurationFormType) => {
    if (
      !connectWiseActiveServiceBoardIdQuery?.isLoading &&
      connectWiseActiveServiceBoardIdQuery?.data !== undefined &&
      connectWiseActiveServiceBoardIdQuery?.data !== form.getValues('serviceBoard')
    ) {
      setIsModalVisible(true)
    } else {
      connetWiseBoardConfigurationMutation.mutate(values)
    }
  }

  if (!connectWiseConfiguredQuery?.isEnabled) {
    return <Navigate replace to={Routes.IntegrationsConnectWiseApiInformation} />
  }

  if (isServiceBoardLoading || connectWiseConfiguredQuery?.isLoading) {
    return <Spinner floating />
  }

  return (
    <>
      <Form {...form}>
        <FormSplitScreen onSubmit={form.handleSubmit(onSubmit)}>
          <FormSplitScreen.Wrapper>
            <BoardConfigurationServiceBoard />
          </FormSplitScreen.Wrapper>
          <FormSplitScreen.Wrapper>
            <BoardConfigurationStatusMapping />
          </FormSplitScreen.Wrapper>
          <FormSplitScreen.Wrapper>
            <BoardConfigurationTicketTypes />
          </FormSplitScreen.Wrapper>
          <FormSubmitButton
            showLoader={connetWiseBoardConfigurationMutation?.isLoading}
            disabled={connetWiseBoardConfigurationMutation?.isLoading}
          />
        </FormSplitScreen>
      </Form>
      <ConfirmationDialog
        open={isModalVisible}
        title={t('rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.modal.title')}
        onClose={() => setIsModalVisible(false)}
        onConfirm={handleConfirm}
        cancelButtonText={t('rsp:actions.cancel')}
        confirmButtonText={t('rsp:actions.save')}
      >
        {t('rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.modal.description')}
      </ConfirmationDialog>
    </>
  )
}

export default protectPage(BoardConfiguration, [
  Permission.IntegrationConfigure,
  Permission.IntegrationList,
])
