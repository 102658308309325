import format from 'date-fns/format'
import dateFnsEn from 'date-fns/locale/en-CA'
import dateFnsFr from 'date-fns/locale/fr'

import { Language } from '../../common/language'

export enum dateFormatTypes {
  fullDate = 'fullDate',
  numeric = 'numeric',
  datetime = 'datetime',
}

export const useDateFnsLocale = (language: string) => {
  switch (language) {
    case Language.FR:
      return dateFnsFr
    case Language.EN:
    default:
      return dateFnsEn
  }
}

export const useDateFormatter = (languageParam?: string, dateFormat?: dateFormatTypes) => {
  const language = languageParam ?? Language.EN
  const locale = useDateFnsLocale(language)
  const pureLanguage = language.slice(0, 2)
  let pattern: string

  const numericDateFormats: Record<string, string> = {}
  const dateTimeFormats: Record<string, string> = {}
  const defaultFormats: Record<string, string> = {}

  numericDateFormats[Language.EN] = 'MM-dd-yyyy'
  numericDateFormats[Language.FR] = 'dd-MM-yyyy'

  dateTimeFormats[Language.EN] = "MMMM do, y hh:mm aaaaa'm'"
  dateTimeFormats[Language.FR] = "d MMMM y hh:mm aaaaa'"

  defaultFormats[Language.EN] = 'MMMM do, y'
  defaultFormats[Language.FR] = 'd MMMM y'

  switch (dateFormat) {
    case dateFormatTypes.numeric:
      pattern = numericDateFormats[pureLanguage]
      break
    case dateFormatTypes.datetime:
      pattern = dateTimeFormats[pureLanguage]
      break
    default:
      pattern = defaultFormats[pureLanguage]
  }

  // eslint-disable-next-line react/display-name
  return (date?: any): string | React.ReactNode => {
    if (!date) {
      return undefined
    }
    if (typeof date === 'string') {
      date = new Date(date)
    }
    if (date instanceof Date && !Number.isNaN(date.getTime())) {
      date = handleTimeZone(date, dateFormat)
      const formatted = format(date, pattern, { locale })
      /* quick hack for french display (ex: 1er janvier) */
      if (language === Language.FR && formatted.startsWith('1 ')) {
        return (
          <>
            1<sup>er</sup>
            {formatted.substring(1)}
          </>
        )
      }
      return formatted
    }
  }
}

const formatsToDisplayInLocalTimeZone = [dateFormatTypes.datetime]

const handleTimeZone = (date: Date, dateFormat: dateFormatTypes | undefined): Date => {
  if (!!dateFormat && formatsToDisplayInLocalTimeZone.includes(dateFormat)) {
    return date
  }

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}
