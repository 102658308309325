import BuildingOffice2Icon from '@heroicons/react/24/outline/BuildingOffice2Icon'
import { Separator } from '@radix-ui/react-separator'
import { useCallback, useEffect, useState } from 'react'
import { generatePath, Navigate, useLocation, useParams } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Badge from '@sherweb/core/components/Badge'
import { Dialog, DialogContent } from '@sherweb/core/components/Dialog'
import { Markdown } from '@sherweb/core/components/Markdown'
import { SheetPage } from '@sherweb/core/components/Sheet'
import { Typography } from '@sherweb/core/components/Typography'
import { useQueryParams } from '@sherweb/core/hooks'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { protectPage } from '@ssp/app/ProtectedPage'
import Routes from '@ssp/app/Routes'
import { useSelectedOrganization } from '@ssp/modules/organization'
import {
  useGetOrganizationCatalogsSyncQuery,
  useGetProductById,
  usePlaceOrderMutation,
} from '@ssp/modules/shop'
import { useNavigateToShopPage } from '@ssp/modules/shop/core/shop.helpers'

import { ShopDetailBillingCycle } from './components/ShopDetailBillingCycle'
import { ShopDetailCommitment } from './components/ShopDetailCommitment'
import { ShopDetailCreateSubscription } from './components/ShopDetailCreateSubscription'
import { ShopDetailCreateSubscriptionContent } from './components/ShopDetailCreateSubscriptionContent'
import { ShopDetailOrderCreated } from './components/ShopDetailOrderCreated'
import { ShopDetailQuantity } from './components/ShopDetailQuantity'
import { ShopDetailTotalPrice } from './components/ShopDetailTotalPrice'
import { useGetFormattedTotalAmount } from './hooks/useGetFormattedTotalAmount'

const ShopDetailPage = () => {
  const location: { state?: { previousFilterParams?: string } } = useLocation()

  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)

  const [previousQueryParams, setPreviousQueryParams] = useState<undefined | string>(undefined)

  const { getParams } = useQueryParams<'productQuantity' | 'productCommitment' | 'productSku'>()

  const organization = useSelectedOrganization()

  const { shopProductId } = useParams()

  const quantityParam = getParams('productQuantity')

  const quantity = Number(quantityParam === '' ? '0' : quantityParam)

  const sku = getParams('productSku')

  const navigateToShopPage = useNavigateToShopPage()

  const productQuery = useGetProductById(shopProductId)

  const isProductQueryLoading = productQuery.isLoading || !productQuery?.data

  const { totalAmount, currency } = useGetFormattedTotalAmount()

  const organizationCatalogsSyncQuery = useGetOrganizationCatalogsSyncQuery()

  const placeOrderMutation = usePlaceOrderMutation()

  const isOrderPlaced = placeOrderMutation?.isSuccess || placeOrderMutation?.isError

  useEffect(() => {
    if (location?.state?.previousFilterParams) {
      setPreviousQueryParams(location.state.previousFilterParams)
    }
  }, [location?.state?.previousFilterParams])

  const handleRedirectToShop = useCallback(() => {
    navigateToShopPage(previousQueryParams)
  }, [navigateToShopPage, previousQueryParams])

  const handleCloseConfirmation = () => {
    if (isOrderPlaced) {
      setConfirmationDialogOpened(false)
      handleRedirectToShop()

      return
    }

    setConfirmationDialogOpened(false)
  }

  const onSubmit = () => {
    if (productQuery?.data?.isProductAvailable) {
      placeOrderMutation.mutate({
        serviceProviderId: productQuery.data?.serviceProviderId,
        orderItems: [
          {
            productName: productQuery.data?.name,
            quantity,
            sku,
            vendorName: productQuery.data?.vendor,
            vendorId: productQuery.data?.vendorId ?? '',
            totalPrice: {
              amount: totalAmount,
              currency,
            },
          },
        ],
      })
    }
  }

  if (organizationCatalogsSyncQuery?.data) {
    return (
      <Navigate
        replace
        to={generatePath(Routes.Shop, { organizationUniqueName: organization?.uniqueName ?? '' })}
        state={location.state}
      />
    )
  }

  return (
    <>
      <SheetPage
        aria-hidden={isOrderPlaced}
        isLoading={isProductQueryLoading}
        title={
          <Typography variant="heading4" weight="bold" className="text-wrap">
            {productQuery.data?.name}
          </Typography>
        }
        onClose={handleRedirectToShop}
      >
        <Separator className="my-2 mt-0 h-px bg-slate-300 dark:bg-slate-700 md:my-2" />
        <div className="flex flex-col p-6 pt-0">
          <div className="mt-2 flex md:mt-4">
            <div className="flex flex-wrap items-center gap-x-2">
              <BuildingOffice2Icon className="mt-1 h-4 w-4 dark:lg:text-white" />
              <Typography
                variant="body2"
                colors="light"
                className="mt-1"
                data-testid="productVendor"
              >
                {productQuery.data?.vendor}
              </Typography>
              <div className="mt-1 flex items-center gap-x-2">
                {productQuery.data?.categories?.map(category => (
                  <Badge
                    data-testid="productCategories"
                    key={category.name}
                    className="dark:bg-slate-200"
                  >
                    {category.name}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
          <div className="my-4 mb-8 flex">
            <Markdown
              variant="body2"
              colors="light"
              as="div"
              content={productQuery.data?.description ?? ''}
            />
          </div>
          <ShopDetailCommitment />
          <ShopDetailBillingCycle />
          <ShopDetailQuantity />
          <ShopDetailTotalPrice />
          <ShopDetailCreateSubscription
            disabled={!productQuery?.data?.isProductAvailable}
            isLoading={placeOrderMutation.isLoading}
            setConfirmationDialogOpened={setConfirmationDialogOpened}
          />
        </div>
      </SheetPage>
      <Dialog open={confirmationDialogOpened} onOpenChange={handleCloseConfirmation}>
        <DialogContent
          className={mergeClassName('sm:max-w-2xl', {
            'min-h-[400px] !max-w-[418px] p-10 pb-0': isOrderPlaced,
          })}
        >
          {isOrderPlaced ? (
            <ShopDetailOrderCreated
              isSuccess={placeOrderMutation?.isSuccess}
              onContinueShopping={handleCloseConfirmation}
            />
          ) : (
            <ShopDetailCreateSubscriptionContent
              isLoading={placeOrderMutation?.isLoading}
              onConfirm={onSubmit}
              onCancel={handleCloseConfirmation}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default protectPage(ShopDetailPage, Permission.MarketplaceProductDetail)
