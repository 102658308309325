import { t } from 'i18next'
import { Trans } from 'react-i18next'

import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'
import { Typography } from '@sherweb/core/components/Typography'

import { ICatalogOffer } from '@rsp/modules/martkeplace/core/marketplace.model'

export type MarketplaceCatalogsAddProductsPartialSuccessDialogProps = {
  onClose: () => void
  open: boolean
  failedProducts?: ICatalogOffer[]
  productsNumber: number
}

export const MarketplaceCatalogsAddProductsPartialSuccessDialog = ({
  onClose,
  open,
  failedProducts,
  productsNumber,
}: MarketplaceCatalogsAddProductsPartialSuccessDialogProps) => {
  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('rsp:pages.marketplace.addProducts.partialSucess.title')}
      cancelButtonText={t('core:actions.close')}
    >
      <p>
        <Trans
          i18nKey="rsp:pages.marketplace.addProducts.partialSucess.body"
          values={{
            count: productsNumber,
          }}
        />
        <Trans
          i18nKey="rsp:pages.marketplace.addProducts.partialSucess.body2"
          values={{
            count: failedProducts?.length ?? 0,
          }}
        />
      </p>
      <div className="mt-4 max-h-36 overflow-y-auto">
        <ul className="list-disc p-2 pl-10">
          {failedProducts?.map(product => (
            <li key={`failed-organization-${product.id ?? ''}`}>
              <Typography variant="body2">{product.name}</Typography>
            </li>
          ))}
        </ul>
      </div>
    </ConfirmationDialog>
  )
}
