import { useTranslation } from 'react-i18next'

import { useDateFormatter } from '@sherweb/core/utils/date'

import { OrderStatus, useSubscription } from '@ssp/modules/subscription'

import { useGetSubscriptionId } from './useGetSubscriptionId'

export const useGetSubscription = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language)

  const { subscriptionId } = useGetSubscriptionId()

  const { isLoading, error, data: subscription } = useSubscription(subscriptionId)

  let lastOrderFailedQuantityDelta = 0

  let subscriptionUpdateOperation = ''

  const currentQuantity =
    subscription?.lastPendingOrFailedOrder?.initialQuantity ?? subscription?.quantity

  const lastOrderQuantity = subscription?.lastPendingOrFailedOrder?.quantity

  if (!!currentQuantity && !!lastOrderQuantity) {
    lastOrderFailedQuantityDelta = Math.abs(currentQuantity - lastOrderQuantity)

    subscriptionUpdateOperation =
      currentQuantity < lastOrderQuantity
        ? t('ssp:pages.subscription.quantityForm.orderOperation.add')
        : t('ssp:pages.subscription.quantityForm.orderOperation.remove')
  }

  const lastFailedOrPendingOrderDate = formatDate(subscription?.lastPendingOrFailedOrder?.createdAt)

  const hasLastOrderFailed =
    !!subscription?.lastPendingOrFailedOrder &&
    subscription?.lastPendingOrFailedOrder?.status === OrderStatus.Failed &&
    !!subscription?.lastPendingOrFailedOrder.error &&
    !subscription?.lastPendingOrFailedOrder.error?.dismissed

  const hasLastOrderPendingQuantity =
    !!subscription?.lastPendingOrFailedOrder &&
    [OrderStatus.Pending, OrderStatus.WaitingForApproval].some(
      p => p.toString() === subscription?.lastPendingOrFailedOrder?.status
    )

  const hasSummary =
    subscription?.renewalDate ?? subscription?.billingCycle ?? subscription?.commitmentType

  let quantity = subscription?.quantity ?? 0

  if (hasLastOrderPendingQuantity) {
    quantity = subscription?.lastPendingOrFailedOrder?.quantity ?? quantity
  }

  return {
    ...subscription,
    subscriptionId,
    isLoading: isLoading || !subscription,
    notFoundError: error?.message === 'not found',
    hasError: !!error || !subscriptionId,
    error,
    hasSummary,
    hasLastOrderFailed,
    hasLastOrderPendingQuantity,
    showLicenseUpdateFailedAlert:
      hasLastOrderFailed && subscription?.lastPendingOrFailedOrder?.quantity !== undefined,
    showLastOrder:
      subscription?.lastPendingOrFailedOrder?.status &&
      typeof lastFailedOrPendingOrderDate === 'string',
    lastOrderFailedQuantityDelta,
    subscriptionUpdateOperation,
    lastFailedOrPendingOrderDate,
    lastOrderQuantity,
    quantity,
    isOrderApprovalRequired: subscription?.orderApprovalSettings?.isOrderApprovalRequired,
  }
}
