import { useMutation } from '@tanstack/react-query'

import { NotificationsService } from '@sherweb/core/openapi-generated/NotificationsService'

export const useTestWebHookConnection = () => {
  return useMutation({
    mutationFn: async ({ webhookUrl }: { webhookUrl: string }) => {
      return await NotificationsService.validateWebhook({
        command: {
          webhookUrl,
        },
      })
    },
  })
}
