import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button, { Variant } from '@sherweb/core/components/Button'
import {
  FormControl,
  FormDescription,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
  formMessageVariants,
} from '@sherweb/core/components/Form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@sherweb/core/components/Select'
import { Textarea } from '@sherweb/core/components/Textarea'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { useGetHelpDeskOrganizationTicketTypesQuery } from '@ssp/modules/integrations/helpdesk'

import {
  DESCRIPTION_MAX_CHARACTERS,
  isMaxCharacterError,
  lockBodyScroll,
  TITLE_MAX_CHARACTERS,
} from '../helpdesk.const'
import { HelpDeskCreateTicketFormType } from './hooks/useHelpDeskCreateTicketSchema'

type HelpDeskCreateTicketFormProps = {
  isLoading?: boolean
  onRedirectToHelpDesk: () => void
}

export const HelpDeskCreateTicketForm = ({
  isLoading,
  onRedirectToHelpDesk,
}: HelpDeskCreateTicketFormProps) => {
  const { t } = useTranslation()

  const form = useFormContext<HelpDeskCreateTicketFormType>()

  /**
   * Select in `Sheet` component when closed add the scrollbar which is not
   * required when the `Sheet` component is opened
   * */
  lockBodyScroll()

  const helpDeskOrganizationTicketTypesQuery = useGetHelpDeskOrganizationTicketTypesQuery()

  return (
    <div className="flex w-full flex-col gap-6">
      <FormField
        control={form.control}
        name="ticketTypeId"
        render={({ field }) => (
          <FormItem>
            <FormLabel data-testid="labelHelpDeskCreateTicketRequestType">
              {t('ssp:pages.helpdesk.create.requestType')}*
            </FormLabel>
            <Select
              disabled={isLoading}
              onOpenChange={lockBodyScroll}
              value={field.value}
              onValueChange={field.onChange}
            >
              <FormControl>
                <SelectTrigger autoFocus data-testid="selectTriggerRequestType">
                  <SelectValue
                    placeholder={t('ssp:pages.helpdesk.create.requestTypePlaceholder')}
                  />
                </SelectTrigger>
              </FormControl>
              <SelectContent side="bottom">
                {helpDeskOrganizationTicketTypesQuery?.data?.map(ticketType => (
                  <SelectItem
                    key={ticketType.name}
                    value={ticketType.id ?? ''}
                    data-testid="selectItemTicketType"
                  >
                    {ticketType.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="title"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full justify-between">
              <FormLabel data-testid="labelHelpDeskCreateTicketTitle">
                {t('ssp:pages.helpdesk.create.title')}*
              </FormLabel>
              <FormDescription
                data-testid="descriptionHelpDeskCreateTicketTitle"
                className={mergeClassName('text-slate-500', {
                  [formMessageVariants()]: isMaxCharacterError(form.formState.errors?.title),
                })}
              >
                {field.value.length}/{TITLE_MAX_CHARACTERS}
              </FormDescription>
            </div>
            <FormControl>
              <FormInput
                {...field}
                disabled={isLoading}
                data-testid="inputHelpDeskCreateTicketTitle"
                placeholder={t('ssp:pages.helpdesk.create.titlePlaceholder')}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="description"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full justify-between">
              <FormLabel>{t('ssp:pages.helpdesk.create.description')}</FormLabel>
              <FormDescription
                data-testid="descriptionHelpDeskCreateTicketDescription"
                className={mergeClassName('text-slate-500', {
                  [formMessageVariants()]: isMaxCharacterError(form.formState.errors?.description),
                })}
              >
                {field?.value?.length}/{DESCRIPTION_MAX_CHARACTERS}
              </FormDescription>
            </div>
            <FormControl>
              <Textarea
                {...field}
                disabled={isLoading}
                data-testid="inputHelpDeskCreateTicketDescription"
                placeholder={t('ssp:pages.helpdesk.create.descriptionPlaceholder')}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="flex w-full justify-end gap-x-2">
        <Button
          className="px-4 py-2"
          type="button"
          variant={Variant.Outline}
          disabled={isLoading}
          onClick={onRedirectToHelpDesk}
          data-testid="btnCreateRequestCancel"
        >
          {t('ssp:actions.cancel')}
        </Button>
        <Button
          type="submit"
          className="px-4 py-2"
          variant={Variant.Primary}
          disabled={isLoading}
          data-testid="btnCreateRequestSend"
        >
          {t('ssp:pages.helpdesk.create.send')}
        </Button>
      </div>
    </div>
  )
}
