import MagnifyingGlassIcon from '@heroicons/react/24/outline/MagnifyingGlassIcon'

import { Typography } from '@sherweb/core/components/Typography'

import ContainedPage from '@ssp/components/layout/ContainedPage'

type OrganizationEmptyContainerProps = {
  title: string
  content: string
  children?: React.ReactNode
  icon?: React.ReactNode
  showSidebar?: boolean
}

export const OrganizationEmptyContainer = ({
  showSidebar = false,
  title,
  content,
  children,
  icon,
}: OrganizationEmptyContainerProps) => (
  <ContainedPage showSidebar={showSidebar}>
    <div className="mx-auto mt-40 flex max-w-2xl flex-col items-center text-center sm:mt-24">
      {icon ?? <MagnifyingGlassIcon className="mx-auto w-12" />}
      <Typography as="h1" weight="bold" variant="heading3" className="mt-6">
        {title}
      </Typography>
      <Typography as="h2" variant="body2" className="mt-6">
        {content}
      </Typography>
      {children}
    </div>
  </ContainedPage>
)
