import { ImageType, PortalMetadataResponse, Theme } from '../../../openapi-generated/index.defs'
import { PortalMetadata } from './portalMetadata.models'

export const buildSettings = (setting: PortalMetadataResponse | undefined): PortalMetadata => {
  return {
    name: setting?.name ?? '',
    themeColor: setting?.themeColor ?? '',
    theme: setting?.theme ?? Theme.Default,
    resellerName: setting?.resellerName ?? '',
    icon: setting?.images?.find(p => p.type === ImageType.Icon)?.url ?? '',
    logo: setting?.images?.find(p => p.type === ImageType.Logo)?.url ?? '',
  }
}
