import * as z from 'zod'

import { looseOptional } from '@sherweb/core/types/zod'

export type ApiInformationFormType = z.infer<ReturnType<typeof useApiInformationSchema>>

const inputFieldsMapper = (values: ApiInformationFormType) => [
  {
    id: 'companyName',
    value: values.companyName,
  },
  {
    id: 'clientId',
    value: values.clientId,
  },
  {
    id: 'privateApiKey',
    value: values.privateApiKey,
  },
  {
    id: 'publicApiKey',
    value: values.publicApiKey,
  },
  {
    id: 'url',
    value: values.url,
  },
]

export const useApiInformationSchema = () => {
  return z
    .object({
      enabled: z.boolean(),
      companyName: looseOptional(z.string()),
      clientId: looseOptional(
        z
          .string()
          .uuid(
            'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.fields.errorClientIdFormat'
          )
      ),
      privateApiKey: looseOptional(z.string()),
      publicApiKey: looseOptional(z.string()),
      url: looseOptional(z.string()),
    })
    .superRefine((values, context) => {
      if (values.enabled) {
        const errorInputFieldIds = inputFieldsMapper(values)
          .filter(inputField => !inputField.value)
          ?.map(inputField => inputField.id)

        errorInputFieldIds.forEach(errorInputField => {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'rsp:validations.mixed.required',
            path: [errorInputField],
          })
        })
      }
    })
}
