import {
  MutationKey,
  MutationStatus,
  QueryKey,
  UseInfiniteQueryResult,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { PaginatedResults } from '@sherweb/core/components/DataTable/types'
import { removeUndefinedAndNullValues } from '@sherweb/core/utils/array'

export const localizedKey = 'Localized'

const filterEmptyValues = (keys: unknown[]) => {
  return keys.filter((key): key is string => !!key)
}

export const getLocalizedQueryKey = (keys: unknown[]): QueryKey => {
  return removeUndefinedAndNullValues(filterEmptyValues([localizedKey, ...keys]))
}

export const isRequestLoading = <T extends UseQueryResult>(query: T) => {
  return query?.isLoading || query?.data === undefined
}

export const isRequestFetching = <T extends UseQueryResult>(query: T) => {
  return query?.isFetching
}

export const isRequestRefetching = <T extends UseQueryResult>(query: T) => {
  return query?.isRefetching
}

export const isRequestHavingNoResults = <T extends UseQueryResult<unknown[]>>(query: T) => {
  return !query?.isLoading && !!query?.data && query?.data?.length === 0
}

export const isInfiniteScrollRequestHavingNoResults = <T>(
  query: UseInfiniteQueryResult<PaginatedResults<T>, unknown>
) => {
  return !query.isLoading && !!query.data && query.data?.pages?.[0]?.results?.length === 0
}

export const getAllPagesResults = <T>(
  query: UseInfiniteQueryResult<PaginatedResults<T>, unknown>
) => query?.data?.pages?.flatMap(({ results }) => results) as T[] | undefined

export const getQueryKey = (queryKey: unknown[]) =>
  removeUndefinedAndNullValues(queryKey) as QueryKey

export const getMutationKey = (mutationKey: unknown[]) =>
  removeUndefinedAndNullValues(mutationKey) as MutationKey

// TODO: Replace it with useMutationState in-built by react query in v5
// Workitem : https://dev.azure.com/SWEngineering/SSP/_backlogs/backlog/SSP%20Team/Epics?workitem=96784
type UseMutationStateProps = {
  mutationKey: MutationKey
  status?: MutationStatus | MutationStatus[]
}

interface MutationStateType<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> {
  context: TContext | undefined
  data: TData | undefined
  error: TError | null
  failureCount: number
  failureReason: TError | null
  isPaused: boolean
  status: MutationStatus
  variables: TVariables | undefined
}

export const useMutationState = ({ mutationKey, status = 'loading' }: UseMutationStateProps) => {
  const queryClient = useQueryClient()

  const [mutationState, setMutationState] = useState<MutationStateType | undefined>(undefined)

  useEffect(() => {
    const unsubscribe = queryClient.getMutationCache().subscribe(({ mutation }) => {
      const statuses = Array.isArray(status) ? status : [status]

      if (
        mutationKey &&
        mutation?.options.mutationKey?.toString() === mutationKey?.toString() &&
        mutation?.state?.status &&
        statuses.includes(mutation?.state?.status)
      ) {
        setMutationState(mutation?.state)
      }
    })

    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return mutationState
}
