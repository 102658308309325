import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { OrganizationsQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { useLocation, useParams, usePathGenerator } from '@sherweb/core/modules/browserRouter'
import { QueryResult } from '@sherweb/core/modules/reactQuery'

import Routes from '@ssp/app/Routes'
import { useSelectedResellerId } from '@ssp/modules/reseller'

import * as actions from './organization.actions'
import { buildOrganization } from './organization.builder'
import { Organization } from './organization.model'
import { availableOrganizations } from './organization.queries'
import { findByUniqueName } from './organization.repository.hooks'
import * as selectors from './organization.selectors'
import { getFirstPreferredOrganization, sortOrganizations } from './organization.utils'

/**
 * returns user's available organizations
 */
export const useAvailableOrganizations = ({ ...queryArgs } = {}): QueryResult<Organization[]> => {
  const { isLoggedIn } = useAuthenticationLoggedInState()
  const resellerId = useSelectedResellerId() ?? ''

  const { data, isLoading, isFetching, ...queryProps } = useQuery<
    OrganizationsQueryResult[],
    Error
  >({
    queryKey: availableOrganizations.queryKey(resellerId),
    queryFn: async () => await availableOrganizations.queryFn(resellerId),
    enabled: isLoggedIn && resellerId !== '',
    staleTime: availableOrganizations.staleTime,
  })

  return {
    data: sortOrganizations((data ?? []).map(buildOrganization)),
    isLoading: isLoading && isFetching,
    ...queryProps,
  }
}

export const useSelectedOrganizationId = () => {
  const { data: organizations, isLoading } = useAvailableOrganizations()
  const selectedOrganizationId = useSelector(selectors.selectedOrganization)
  const { setSelectedOrganization } = useOrganizationActions()
  const nonExistingOrganization = organizations?.every(p => p.id !== selectedOrganizationId)

  useEffect(() => {
    if (nonExistingOrganization && !isLoading && organizations.length !== 0) {
      setSelectedOrganization(getFirstPreferredOrganization(organizations)?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, nonExistingOrganization, organizations.length])

  return !nonExistingOrganization
    ? selectedOrganizationId
    : getFirstPreferredOrganization(organizations)?.id
}

export const useOrganizationsBeenActivated = () => {
  const { data: organizations, isLoading, isFetched } = useAvailableOrganizations()

  return {
    isLoading,
    hasNoActivatedOrganizations: !isLoading && isFetched && organizations?.length === 0,
  }
}

export const useOrganizationIsSuspended = () => {
  const { data: organizations, isLoading, isFetched } = useAvailableOrganizations()
  const { organizationUniqueName } = useParams()
  const findOrganization = findByUniqueName(organizations)
  const selectedOrganization = organizationUniqueName
    ? findOrganization(organizationUniqueName)
    : undefined

  return {
    isLoading,
    isActive: !isLoading && isFetched && selectedOrganization && !selectedOrganization?.isSuspended,
    isSuspended: !isLoading && isFetched && selectedOrganization?.isSuspended,
    selectedOrganizationUniqueName: selectedOrganization?.uniqueName,
  }
}

export const useSelectedOrganization = () => {
  const { data: organizations } = useAvailableOrganizations()
  const selectedOrganizationId = useSelectedOrganizationId()
  if (!selectedOrganizationId) {
    return organizations[0]
  }

  return organizations.find(({ id }) => id === selectedOrganizationId)
}

export const useOrganizationPathSwitcher = () => {
  const { pathname } = useLocation()
  const { organizationUniqueName } = useParams()
  const generatePath = usePathGenerator()

  const blockingRoutes = [Routes.OrganizationSuspended]

  return (newOrganizationUniqueName: string) => {
    if (!organizationUniqueName) {
      return pathname
    }

    const currentRoute = pathname.replace(organizationUniqueName, ':organizationUniqueName')
    const currentRouteContainsVariables = !Object.values(Routes).includes(currentRoute as any)

    if (currentRouteContainsVariables) {
      return Routes.Root
    }

    if (blockingRoutes.some(x => x === currentRoute)) {
      return generatePath(Routes.Dashboard, { organizationUniqueName: newOrganizationUniqueName })
    }

    return pathname.replace(organizationUniqueName, newOrganizationUniqueName)
  }
}

export const useOrganizationActions = () => {
  const dispatch = useDispatch()
  return {
    setSelectedOrganization: (...args: any) =>
      dispatch(actions.setSelectedOrganization.apply(null, args)),
  }
}
