import { useEffect, useState } from 'react'
import { GA4 } from 'react-ga4/types/ga4'

export const useGetGoogleAnalyticsInstance = () => {
  const [googleAnalytics, setGoogleAnalytics] = useState<GA4 | null>(null)

  useEffect(() => {
    import('react-ga4')
      .then(module => {
        setGoogleAnalytics(module.default)
      })
      .catch(error => {
        console.error('failed to load google analytics', error)
      })
  }, [])

  return googleAnalytics
}
