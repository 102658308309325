export enum UserStatus {
  'Inactive' = 'Inactive',
  'Active' = 'Active',
  'InvitationSent' = 'InvitationSent',
}

export enum UserRole {
  BasicUser = 'BasicUser',
  OrganizationAdmin = 'OrganizationAdmin',
  ResellerAdmin = 'ResellerAdmin',
  InternalAdmin = 'InternalAdmin',
  ResellerGuest = 'ResellerGuest',
}

export type User = {
  id: string
  firstName?: string | null
  lastName?: string | null
  jobTitle?: string | null
  roles?: UserRole[] | null
  email?: string | null
  createdAt: Date | null
  status: UserStatus
}

export type UpdateUser = {
  firstName: string
  lastName: string
  jobTitle: string
  email: string
  role: UserRole
  preferredCulture: string
}

export type UserLicense = {
  id: string
  productName?: string
}
