import * as z from 'zod'

import i18n from '@i18n'

const loginFormSchema = z.object({
  username: z.string().min(1, {
    message: `${i18n.t('ssp:validations.mixed.required', { path: i18n.t('ssp:pages.login.loginform.generic.username.label') })}`,
  }),
})

export default loginFormSchema
