import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@sherweb/core/components/Select'

import { FormSplitScreen } from '@rsp/components/layout/FormSplitScreen'
import { useGetConnectWiseServiceBoardsQuery } from '@rsp/modules/integrations/helpdesk'

import { BoardConfigurationFormType } from './hooks/useBoardConfigurationSchema'

export const BoardConfigurationServiceBoard = () => {
  const { t } = useTranslation()

  const form = useFormContext<BoardConfigurationFormType>()

  const connectWiseServiceBoardsQuery = useGetConnectWiseServiceBoardsQuery()

  return (
    <FormSplitScreen.Row
      contentContainerClassName="md:max-w-md max-w-full"
      leftContent={
        <FormSplitScreen.LeftContent
          label={t(
            'rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.serviceBoard.title'
          )}
          subLabel={t(
            'rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.serviceBoard.description'
          )}
        />
      }
    >
      <FormField
        control={form.control}
        name="serviceBoard"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t(
                'rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.serviceBoard.label'
              )}
            </FormLabel>
            <Select
              value={field.value}
              onValueChange={serviceBoard => {
                field.onChange(serviceBoard)
                form.reset({
                  serviceBoard,
                  ticketStatusMappingList: {
                    ...form.getValues('ticketStatusMappingList'),
                    [serviceBoard]: {
                      ...form.getValues('ticketStatusMappingList')?.[serviceBoard],
                    },
                  },
                  ticketTypes: {
                    ...form.getValues('ticketTypes'),
                    [serviceBoard]: Array.from(
                      new Set([...(form.getValues('ticketTypes')?.[serviceBoard] ?? [])])
                    ),
                  },
                })
              }}
            >
              <FormControl>
                <SelectTrigger data-testid="selectTriggerServiceBoard">
                  <SelectValue
                    placeholder={t(
                      'rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.serviceBoard.placeholder'
                    )}
                  />
                </SelectTrigger>
              </FormControl>
              <SelectContent side="bottom">
                {connectWiseServiceBoardsQuery?.data?.map(serviceBoard => (
                  <SelectItem
                    key={serviceBoard.name}
                    value={serviceBoard.id ?? ''}
                    data-testid="selectItemServiceBoard"
                  >
                    {serviceBoard.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
    </FormSplitScreen.Row>
  )
}
